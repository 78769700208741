import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty, parsePagination} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {AccountsListRow} from "./AccountsListRow";
import {Pagination} from "@material-ui/lab";

/**
 * 管理者側のアカウントリスト コンポーネントのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pagination: {
        '& .MuiPaginationItem-page': {
            color: "#7E7E7E",
            backgroundColor: "#F2F2F2",
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
    },

}));

/**
 * 管理者側のアカウント一覧
 *
 * @param {Object} className - 管理者クラスのアカウントリスト
 * @param {Object} accounts - ロードされたアカウントレコード
 * @param {*} toggleDelete - モーダル削除表示・非表示
 * @param {boolean} managerViewEditToggle - アカウント編集アクション
 * @param {boolean} managerLoading - 読み込みイベント
 * @param maximumPage
 * @param changePage
 * @param totalRows
 * @param currentPage
 * @param managerSearchKeyword
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AccountsList =
    ({
         className,
         accounts,
         toggleDelete,
         managerViewEditToggle,
         managerLoading,
         maximumPage,
         changePage,
         totalRows,
         currentPage,
         managerAccountSearchKeyword,

         managerAccountLoginLoading,
         managerAccountLoginError,
         managerLoginEvent,

         t
     }) => {
        // 編集ボタンをクリックすると
        function handleEditClick(id) {
            managerViewEditToggle(id)
        }

        // ボタンの削除クリック時
        function handleDeleteClick(id) {
            toggleDelete(id)
        }

        // アカウントリストのグローバルスタイルクラス
        const classes = useStyles();
        const common = managerCommonStyles();
        const _globalTheme = globalTheme._default();
        const maxRows = parseInt(process.env.REACT_APP_MANAGER_ACCOUNT_LIST_MAX_ROWS);
        return (
            <Card
                className={clsx(classes.root, className)}
            >
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 900, marginTop: 7}}
                            >
                                {t('accounts.list')}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {!empty(accounts) && !empty(accounts) && managerLoading === false &&
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Pagination color="primary" page={currentPage} variant={"text"} shape={"rounded"}
                                                style={{float: "right"}}
                                                showFirstButton
                                                showLastButton
                                                boundaryCount={3}
                                                className={classes.pagination}
                                                onChange={(event, page) => changePage(page)}
                                                count={maximumPage}
                                    />
                                    <Typography
                                        color="textPrimary"
                                        variant="body1"
                                        style={{'textAlign': 'right', marginTop: 7, marginRight: 250}}
                                    >
                                        {t('general.rows_between',
                                            parsePagination(
                                                currentPage,
                                                !empty(accounts?.accounts?.length) ? accounts.accounts.length: 0,
                                                maxRows))}
                                        ({t('general.list_amount', {amount: !empty(totalRows) ? totalRows : 0})})
                                    </Typography>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Grid container className={common.tableHeader} style={{paddingRight: 0}}>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.supplier_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.note')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                            {t('actions.login')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                            {t('actions.edit')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                            {t('actions.delete')}
                        </Typography>
                    </Grid>
                </Grid>
                {managerLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center">
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              data-testid="loading"
                        >
                            <Grid item xs={12}>
                                <Loading/>
                            </Grid>
                        </Grid>
                    </Box>
                    : !empty(accounts) && !empty(accounts.accounts) &&
                    <Box className={_globalTheme.list} data-testid="has-accounts">
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    width={width}
                                    height={height}
                                    itemCount={accounts.accounts.length}
                                    itemSize={45}
                                    itemData={{
                                        accounts: accounts.accounts,
                                        t: t,
                                        handleEditClick: (id) => handleEditClick(id),
                                        handleDeleteClick: (id) => handleDeleteClick(id),
                                        managerLoginEvent: managerLoginEvent
                                    }}
                                >
                                    {AccountsListRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </Box>
                }
            </Card>
        );
    };

export default AccountsList;
