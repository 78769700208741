import ApiUtils from "../../../../utils/ApiUtils";
import {GET_ACCOUNT_BASIC_INFORMATION_API_PATH} from './constants'


/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /usersのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 基本情報取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param userId - ユーザーID
 * @param params - GETクエリー
 * @returns {AxiosPromise}
 */
export function callBasicInformation(accessToken, userId, params) {
    return new ApiUtils(accessToken).get(
        GET_ACCOUNT_BASIC_INFORMATION_API_PATH.replace('{UID}', userId),
        params,
    );
}
