import React from 'react';
import {Box, Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import Loading from "../../../../../atoms/Loading";
import globalTheme from "../../../../../theme/globalTheme";
import EditableCheck from "../../../../../atoms/EditableCheck";
import ContactUpdateList from "./list/ContactUpdateList";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";

/**
 * コンポーネントスタイル
 * @returns {{transform: string, top: string, left: string, width: number}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: 705,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const ContactUpdateModal = (
    {
        activeContractContact,
        activeInvoiceContact,
        otherContactContacts,
        otherInvoiceContacts,
        otherContactsLoading,
        otherContactsError,
        updateLoading,
        isUpdateModalOpen,
        toggleUpdateModal,
        contractContactUuid,
        setContractContactUuid,
        invoiceContactUuid,
        setInvoiceContactUuid,
        updateServiceContractContact,
        managerProfile,
        isRequiredUpdate,
        id,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // グローバルスタイル
    const globalClass = globalTheme._default();
    // auth0 アクセストークンの取得リクエスト
    const {getAccessTokenSilently} = useAuth0();
    const classes = globalTheme.defaultFormStyle();
    
    return (
        <Modal open={isUpdateModalOpen}
               onClose={() => toggleUpdateModal(false)}
               disableBackdropClick
               className={updateLoading ? globalClass.loading : undefined}
        >
            <div className={globalClass.modal} style={{...modalStyle, width: 1080}}>
                {/* Load Modal */}
                {otherContactsLoading === true ?
                    <Box spacing={3}>
                        <Loading/>
                    </Box>
                    :
                    <Grid container alignItems="flex-start" spacing={3}>
                        <Grid item xs={12} spacing={3} justify="flex-end">
                            <div>
                                <Typography variant="h3"
                                            style={{'paddingBottom': 30}}>{t('service_contracts.contacts.update_modal.contract_title')}</Typography>
                            </div>
                            <Typography variant={"subtitle1"} style={{paddingBottom: 5, whiteSpace: "pre-wrap"}}>
                                {t('service_contracts.contacts.update_modal.help_description')}
                            </Typography>
                            <ContactUpdateList
                                contacts={otherContactContacts}
                                updateLoading={updateLoading}
                                selectedContactUuid={contractContactUuid}
                                setContactUuid={setContractContactUuid}
                                t={t}
                            />
                            <div>
                                <Typography variant="h3"
                                            style={{'padding': "30px 0"}}>{t('service_contracts.contacts.update_modal.invoice_title')}</Typography>
                            </div>
                            <Typography variant={"subtitle1"}
                                        style={{paddingBottom: 5, whiteSpace: "pre-wrap"}}>
                                {t('service_contracts.contacts.update_modal.help_description')}
                            </Typography>
                            <ContactUpdateList
                                contacts={otherInvoiceContacts}
                                updateLoading={updateLoading}
                                selectedContactUuid={invoiceContactUuid}
                                setContactUuid={setInvoiceContactUuid}
                                t={t}
                            />
                            {/*<Grid item xs={12}>*/}
                            {/*    <Grid>*/}
                            {/*        <Typography className={globalClass.error}>*/}
                            {/*            {t(addressEditError?.translation_error_key)}*/}
                            {/*        </Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Grid item container spacing={3} justify="flex-end">
                            <Grid item>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color={"secondary"}
                                    disabled={updateLoading}
                                    onClick={() => toggleUpdateModal(false)}
                                >
                                    {t('actions.cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <EditableCheck>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            (async () => {
                                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                    () => {
                                                        return getAccessTokenSilently();
                                                    },
                                                );
                                                updateServiceContractContact(access_token, managerProfile?.account?.uuid, id, { "sold_to_contact_id": contractContactUuid  , "bill_to_contact_id": invoiceContactUuid })
                                            })()
                                        }}
                                        disabled={updateLoading || isRequiredUpdate === false}
                                    >
                                        {updateLoading ? t('actions.sending') : t('actions.change')}
                                    </Button>
                                </EditableCheck>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        </Modal>
    )
};

export default ContactUpdateModal;
