import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
/**
 * 契約詳細情報の取得
 * @type {(function(*=, *=, *=): {payload: {access_token, accountId, serviceContractId}, type: string})|*}
 */
const requestSingleServiceContract = actions.requestSingleServiceContract;
const loadOtherAccountContacts = actions.loadOtherAccountContacts;
const toggleUpdateModal = actions.toggleUpdateModal;
const updateServiceContractContact = actions.updateServiceContractContact;

const operations = {
    requestSingleServiceContract,
    loadOtherAccountContacts,
    toggleUpdateModal,
    updateServiceContractContact,
}
export default operations;
