import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているusersグループに束縛されています。
 * //////////////////////////////////////////
 */
export const accountsBasicInformationState = {
    loading: false,
    error: {},
    basic_information: {},
};

export default function accountsBasicInformationReducer(state = accountsBasicInformationState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // アカウント基本情報取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_BASIC_INFORMATION_LOADING:
            return {
                ...state,
                loading: state.loading = true,
                basic_information: state.basic_information = {},
                error: state.error = {},
            }
        case types.REQUEST_ACCOUNT_BASIC_INFORMATION_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                basic_information: state.basic_information = action.payload.basicInformation,
                error: state.error = {},
            }
        case types.REQUEST_ACCOUNT_BASIC_INFORMATION_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                error: state.error = action.payload.error,
            }
        default:
            return state;
    }
}
