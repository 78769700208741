import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {managerNoticeOperations} from "../../../../state/ducks/manager/managerNotices";
import NoticeModal from "../../../components/manager/notices/modal/NoticeModal";
import {empty} from "../../../../state/utils/Common";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        activeNotice: state.managerNotice.activeNotice,

        managerIsRegisterView: state.managerNotice.managerIsRegisterView,
        managerSearchKeyword: state.managerNotice.managerSearchKeyword,
        managerSelectedAccount: state.managerNotice.managerSelectedAccount,
        managerSearchAccountLoading: state.managerNotice.managerSearchAccountLoading,
        managerSearchAccountResult: state.managerNotice.managerSearchAccountResult,
        managerRegisterLoading: state.managerNotice.managerRegisterLoading
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    managerNotificationRegisterToggle: managerNoticeOperations.managerNotificationRegisterToggle,
    managerRegisterNewNotice: managerNoticeOperations.managerRegisterNewNotice,
    managerUpdateNotice: managerNoticeOperations.managerUpdateNotice,
    managerNotificationSearchAccount: managerNoticeOperations.managerNotificationSearchAccount,
    managerNotificationChooseAccount: managerNoticeOperations.managerNotificationChooseAccount,
    managerNotificationSearchAccountSuccess: managerNoticeOperations.managerNotificationSearchAccountSuccess
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
利用契約 -> 連絡先コンテイナ
 */
const _managerNoticeModalContainer = (
    {
        managerProfile,
        activeNotice,
        managerRegisterLoading,

        managerSearchKeyword,
        managerSelectedAccount,
        managerSearchAccountLoading,
        managerSearchAccountResult,

        managerNotificationRegisterToggle,
        managerRegisterNewNotice,
        managerUpdateNotice,

        managerNotificationSearchAccount,
        managerNotificationChooseAccount,
        managerIsRegisterView,
        managerNotificationSearchAccountSuccess,

        t
    }) => {

    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if(!empty(activeNotice?.account)) {
            managerNotificationSearchAccountSuccess([activeNotice.account])
        }
    }, [activeNotice, managerNotificationSearchAccountSuccess])

    return (
        <NoticeModal
            activeNotice={activeNotice}
            managerSearchKeyword={managerSearchKeyword}
            managerSelectedAccount={managerSelectedAccount}
            managerSearchAccountLoading={managerSearchAccountLoading}
            managerSearchAccountResult={managerSearchAccountResult}
            toggleEvent={managerNotificationRegisterToggle}
            managerRegisterNewNotice={managerRegisterNewNotice}
            managerUpdateNotice={managerUpdateNotice}
            managerNotificationSearchAccount={managerNotificationSearchAccount}
            managerNotificationChooseAccount={managerNotificationChooseAccount}
            registerLoading={managerRegisterLoading}
            t={t}
            isOpen={managerIsRegisterView}
        />
    )
}


/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerNoticeModalContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerNoticeModalContainer)

export default ManagerNoticeModalContainer;
