import React, {useEffect} from "react";
import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useParams} from "react-router";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {makeStyles} from "@material-ui/core";
import ManagerAccountUserView from "../../../components/manager/accounts/users/Users";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {managerAccountUserOperations} from "../../../../state/ducks/manager/managerAccounts/users";

/**
 * コンテイナのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        managerAccountUsersLoading: state.managerAccountUser.managerAccountUsersLoading,
        managerAccountActiveUsers: state.managerAccountUser.managerAccountActiveUsers,
        managerAccountUsersError: state.managerAccountUser.managerAccountUsersError,

        managerDetailAccountData: state.managerAccount.managerDetailAccountData,
        managerActiveProfile: state.managerAccountUser.managerActiveProfile,

        managerIsRegisterView: state.managerAccountUser.managerIsRegisterView,
        managerDeleteView: state.managerAccountUser.managerDeleteView,
        
        managerDeleteLoading: state.managerAccountUser.managerDeleteLoading,
        managerDeleteError: state.managerAccountUser.managerDeleteError,
        managerRegisterLoading: state.managerAccountUser.managerRegisterLoading,
        managerRegisterError: state.managerAccountUser.managerRegisterError,
        
        managerLoading: state.managerAccountUser.managerLoading,
        managerIsError: state.managerAccountUser.managerIsError,
        managerUserList_CurrentPage: state.managerAccountUser.managerUserList_CurrentPage,
        
        managerSearchLoad: state.managerAccountUser.managerSearchLoad,
        managerSearchUserResult: state.managerAccountUser.managerSearchUserResult,
        
        managerAuth0UserUnblockLoading: state.managerAccountUser.managerAuth0UserUnblockLoading,
        managerAuth0UserUnblockError: state.managerAccountUser.managerAuth0UserUnblockError,
        managerUnblockNotificationShow: state.managerAccountUser.managerUnblockNotificationShow,
        
        managerResendAuth0MailLoading: state.managerAccountUser.managerResendAuth0MailLoading,
        managerResendAuth0MailError: state.managerAccountUser.managerResendAuth0MailError,
        managerResendNotificationShow: state.managerAccountUser.managerResendNotificationShow,
        
        managerError_code: state.managerAccountUser.managerError_code,
        managerError: state.managerAccountUser.managerError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    saveContractNameToSidebarLink: managerAccountListOperations.saveContractNameToSidebarLink,

    managerSelectAccountUsers: managerAccountUserOperations.managerSelectAccountUsers,

    registerNewUser: managerAccountUserOperations.registerNewUser,
    updateUser: managerAccountUserOperations.updateUser,
    deleteUsers: managerAccountUserOperations.deleteUsers,
    unlockAuth0User: managerAccountUserOperations.unlockAuth0User,
    resendAuth0VerifyMail: managerAccountUserOperations.resendAuth0VerifyMail,
    resendAuth0VerifyMailCloseNotification: managerAccountUserOperations.resendAuth0VerifyMailCloseNotification,
    closeUnlockAuth0UserModal: managerAccountUserOperations.closeUnlockAuth0UserModal,
    viewRegisterToggle: managerAccountUserOperations.viewRegisterToggle,
    viewUserDeleteToggle: managerAccountUserOperations.viewUserDeleteToggle,
    mangerGetAccountDetail: managerAccountListOperations.mangerGetAccountDetail,
}

/**
 * 管理者側のユーザー一覧コンテイナ
 *
 * @param {Object} managerProfile
 * @param managerAccountUsersLoading
 * @param managerAccountActiveUsers
 * @param managerAccountUsersError
 * @param saveContractNameToSidebarLink
 * @param managerSelectAccountUsers
 * @param managerDetailAccountData
 * @param loadSidebarItems
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountUserContainer = (
    {
        managerProfile,
        managerAccountUsersLoading,
        managerAccountActiveUsers,
        managerAccountUsersError,
        saveContractNameToSidebarLink,
        managerSelectAccountUsers,
        managerDetailAccountData,
        loadSidebarItems,
        managerIsRegisterView,
        managerDeleteView,
        managerDeleteLoading,
        managerDeleteError,
        managerRegisterLoading,
        managerRegisterError,
        managerLoading,
        managerIsError,
        managerUserList_CurrentPage,
        managerSearchLoad,
        managerSearchUserResult,
        managerAuth0UserUnblockLoading,
        managerAuth0UserUnblockError,
        managerUnblockNotificationShow,
        managerResendAuth0MailLoading,
        managerResendAuth0MailError,
        managerResendNotificationShow,
        managerError_code,
        managerError,
        registerNewUser,
        updateUser,
        deleteUsers,
        unlockAuth0User,
        resendAuth0VerifyMail,
        resendAuth0VerifyMailCloseNotification,
        closeUnlockAuth0UserModal,
        viewRegisterToggle,
        viewUserDeleteToggle,
        mangerGetAccountDetail,
        managerActiveProfile,
        
        
        t
    }) => {
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    // URL履歴
    let {id} = useParams();
    const classes = useStyles();

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    }
                );
                managerSelectAccountUsers(access_token, id);
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, managerSelectAccountUsers])

    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    }
                );
                mangerGetAccountDetail(access_token, id);
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, mangerGetAccountDetail])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(managerAccountActiveUsers)) {
            saveContractNameToSidebarLink(managerDetailAccountData?.name)
        }
    }, [managerDetailAccountData, saveContractNameToSidebarLink, managerProfile, managerAccountActiveUsers])

    const history = useHistory();
    // 連絡先情報があるかどうかのチェック、
    // 連絡先情報が無ければ、/service_redirectionにリダイレクトする。
    if (
        managerAccountUsersLoading === false
        && !empty(managerAccountUsersError)
    ) {
        history.push('/manager/accounts')
        return (<div> リダイレクト中</div>)
    }



    return (
        <ManagerAccountUserView
            managerProfile={managerProfile}
            classes={classes}
            managerAccountUsersLoading={managerAccountUsersLoading}
            managerAccountActiveUsers={managerAccountActiveUsers}
            managerAccountUsersError={managerAccountUsersError}
            managerDetailAccountData={managerDetailAccountData}
            accountId={id}
            t={t}
            managerIsRegisterView={managerIsRegisterView}
            managerDeleteView={managerDeleteView}
            managerDeleteLoading={managerDeleteLoading}
            managerDeleteError={managerDeleteError}
            managerRegisterLoading={managerRegisterLoading}
            managerRegisterError={managerRegisterError}
            managerLoading={managerLoading}
            managerIsError={managerIsError}
            managerUserList_CurrentPage={managerUserList_CurrentPage}
            managerSearchLoad={managerSearchLoad}
            managerSearchUserResult={managerSearchUserResult}
            managerAuth0UserUnblockLoading={managerAuth0UserUnblockLoading}
            managerAuth0UserUnblockError={managerAuth0UserUnblockError}
            managerUnblockNotificationShow={managerUnblockNotificationShow}
            managerResendAuth0MailLoading={managerResendAuth0MailLoading}
            managerResendAuth0MailError={managerResendAuth0MailError}
            managerResendNotificationShow={managerResendNotificationShow}
            managerError_code={managerError_code}
            managerError={managerError}
            registerNewUser={registerNewUser}
            updateUser={updateUser}
            deleteUsers={deleteUsers}
            unlockAuth0User={unlockAuth0User}
            resendAuth0VerifyMail={resendAuth0VerifyMail}
            resendAuth0VerifyMailCloseNotification={resendAuth0VerifyMailCloseNotification}
            closeUnlockAuth0UserModal={closeUnlockAuth0UserModal}
            viewRegisterToggle={viewRegisterToggle}
            viewUserDeleteToggle={viewUserDeleteToggle}
            managerActiveProfile={managerActiveProfile}
        />
    )
};


/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */
const managerAccountUserContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ManagerAccountUserContainer)

export default managerAccountUserContainer;
