import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているusersグループに束縛されています。
 * //////////////////////////////////////////
 */
export const accountsUserListState = {
    isRegisterView: false,
    deleteView: false,
    activeProfile: {},

    deleteLoading: false,
    deleteError: {},
    registerLoading: false,
    registerError: {},

    loading: true,
    isError: false,
    userList_CurrentPage: 0,

    searchLoad: false,
    searchUserResult: {},

    auth0UserUnblockLoading: false,
    auth0UserUnblockError: {},
    unblockNotificationShow: false,

    resendAuth0MailLoading: false,
    resendAuth0MailError: {},
    resendNotificationShow: false,

    error_code: "",
    error: {},
    userList: {},
};

export default function accountsUserList(state = accountsUserListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // ユーザー一覧取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_USERS_LOADING:
            return {
                ...state,
                loading: state.loading = true,
                isError: state.isError = false,
                isRegisterView: state.isRegisterView = false
            }
        case types.REQUEST_USERS_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                userList: state.userList = action.payload.data.users,
                isError: state.isError = false,
                error: state.error = {},
                error_code: ""
            }
        case types.REQUEST_USERS_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                error: state.error = action.payload.error,
                isError: state.isError = true,
            }
        // -----------------------------------------------
        // ユーザーページ変更
        // -----------------------------------------------
        case types.USER_CHANGE_PAGE:
            return {
                ...state,
                userList_CurrentPage: state.userList_CurrentPage = action.payload.page_no
            }
        // -----------------------------------------------
        // ユーザー登録
        // -----------------------------------------------
        case types.TOGGLE_REGISTER:
            return {
                ...state,
                isRegisterView: !state.isRegisterView,
                activeProfile: state.activeProfile = action.payload.user_id,
                registerError: state.registerError = {},
            }
        case types.ADD_USER_LOADING:
            return {
                ...state,
                registerError: state.registerError = {},
                registerLoading: state.registerLoading = true
            }
        case types.ADD_USER_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = false,
                error: state.error = {},
                registerLoading: state.registerLoading = false,
                registerError: state.registerError = {},
                isRegisterView: state.isRegisterView = false,
                error_code: ""
            }
        case types.ADD_USER_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = false,
                registerLoading: state.registerLoading = false,
                registerError: state.registerError = action.payload.error,
            }
        // -----------------------------------------------
        // ユーザー検索
        // -----------------------------------------------
        case types.SEARCH_USER_LOADING:
            return {
                ...state,
                searchLoad: state.searchLoad = true,
                isError: state.isError = false,
            }
        case types.SEARCH_USER_SUCCESS:
            return {
                ...state,
                searchLoad: state.searchLoad = false,
                isError: state.isError = false,
                searchUserResult: state.searchUserResult = action.payload.userResult
            }
        case types.SEARCH_USER_FAILURE:
            return {
                ...state,
                searchLoad: state.searchLoad = false,
                isError: state.isError = true,
                searchUserResult: {},
                error_code: state.error_code = "404",
                error: state.error = action.payload.error
            }

        // -----------------------------------------------
        // ユーザー削除
        // -----------------------------------------------
        case types.DELETE_USER_TOGGLE:
            return {
                ...state,
                deleteView: !state.deleteView,
                deleteError: state.deleteError = {},
                activeProfile: state.activeProfile = action.payload.accountId
            }
        case types.DELETE_USER_LOADING:
            return {
                ...state,
                deleteLoading: state.deleteLoading = true,
                deleteError: state.deleteError = {},
            }
        case types.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteLoading: state.deleteLoading = false,
                deleteError: state.deleteError = {},
                activeProfile: state.activeProfile = {},
                deleteView: state.deleteView = false
            }
        case types.DELETE_USER_FAILURE:
            return {
                ...state,
                deleteError: state.deleteError = action.payload.error,
                deleteLoading: state.deleteLoading = false,
            }
        // -----------------------------------------------
        // ブロック解除
        // -----------------------------------------------
        case types.AUTH0_UNLOCK_USER_LOADING:
            return {
                ...state,
                auth0UserUnblockLoading: state.auth0UserUnblockLoading = true,
                auth0UserUnblockError: state.auth0UserUnblockError = {},
            }
        case types.AUTH0_UNLOCK_USER_SUCCESS:
            return {
                ...state,
                unblockNotificationShow: state.unblockNotificationShow = true,
                auth0UserUnblockLoading: state.auth0UserUnblockLoading = false
            }
        case types.AUTH0_UNLOCK_USER_FAILED:
            return {
                ...state,
                auth0UserUnblockLoading: state.auth0UserUnblockLoading = false,
                auth0UserUnblockError: state.auth0UserUnblockError = action.payload.error,
            }
        case types.AUTH0_UNLOCK_USER_CLOSE_MODAL:
            return {
                ...state,
                unblockNotificationShow: state.unblockNotificationShow = false
            }
        // -----------------------------------------------
        // 認証メール再送信
        // -----------------------------------------------
        case types.AUTH0_RESEND_VERIFY_EMAIL_LOADING:
            return {
                ...state,
                resendAuth0MailLoading: state.resendAuth0MailLoading = true,
                resendAuth0MailError: state.resendAuth0MailError = {},
            }
        case types.AUTH0_RESEND_VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                resendNotificationShow: state.resendNotificationShow = true,
                resendAuth0MailLoading: state.resendAuth0MailLoading = false
            }
        case types.AUTH0_RESEND_VERIFY_EMAIL_FAILED:
            return {
                ...state,
                resendAuth0MailLoading: state.resendAuth0MailLoading = false,
                resendAuth0MailError: state.resendAuth0MailError = action.payload.error,
            }
        case types.AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL:
            return {
                ...state,
                resendNotificationShow: state.resendNotificationShow = false
            }
        default:
            return state;
    }
}
