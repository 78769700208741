import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import {rootOperations} from "../../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../../../state/ducks/user/requests";
import {useParams} from "react-router";
import PlanOptionDetails from "../../../../../../state/utils/data/request_application/plan_option_to_input_block.json";
import PlanOptionInputBlocks from "../../../../../../state/utils/data/request_application/option_input_blocks.json";
import InitialSettingsPage from "../../../../../components/user/requests/detail/intialSetting/initial/InitialSettingsPage";
import FullScreenLoading from "../../../../../atoms/FullScreenLoading";
import request_types from "../../../../../../state/utils/data/application_request_type_flat.json";
import {Redirect} from "react-router-dom";
import InitialContractSettingsContainer from "./InitialContractSettingsContainer";
import UpdateContractSettingsContainer from "./UpdateContractSettingsContainer";
import requestType from "../../../../../../state/utils/data/application_request_type_flat.json";
import requestStatus from "../../../../../../state/utils/data/application_request_status_flat.json";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestQuote: state.request.requestQuote,
        requestDetailLoading: state.request.requestDetailLoading,
        requestDetail: state.request.requestDetail,

        requestStartUpInformationStep: state.request.requestStartUpInformationStep,
        requestStartUpInformation: state.request.requestStartUpInformation,
        ebisAccountConfirmedLoading: state.request.ebisAccountConfirmedLoading,
        ebisAccountConfirmedError: state.request.ebisAccountConfirmedError,
        additionalOptionInformation: state.request.additionalOptionInformation,
        formattedAdditionalOptionInformation: state.request.formattedAdditionalOptionInformation,
        additionalOptionValidation: state.request.additionalOptionValidation,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailLoad: requestOperations.requestsDetailLoad,
    resetFormInformation: requestOperations.resetFormInformation,
    initializeOptionFormInformation: requestOperations.initializeOptionFormInformation,
    settingSubmitEbisFormInformation: requestOperations.settingSubmitEbisFormInformation,
    settingConfirmEbisFormInformation: requestOperations.settingConfirmEbisFormInformation,
    settingAdditionalSteps: requestOperations.settingAdditionalSteps,
    settingEbisBackButton: requestOperations.settingEbisBackButton
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _initialSettingsContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestDetailLoading,
        requestsDetailLoad,
        resetFormInformation,
        requestStartUpInformationStep,
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        settingConfirmEbisFormInformation,
        initializeOptionFormInformation,
        additionalOptionInformation,
        formattedAdditionalOptionInformation,
        settingAdditionalSteps,
        additionalOptionValidation,
        settingEbisBackButton,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        requestDetail,

        sendMailValidation,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,

        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rgid} = useParams();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailLoad(access_token, managerProfile?.account?.uuid, rgid)
            })()
        }
    }, [rgid, getAccessTokenSilently, requestsDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。

    let quoteDetailsArray = [];
    let additionalFieldsArray = [];

    // 追加オプションの初期化
    if (!empty(requestDetail)) {
        quoteDetailsArray = requestDetail?.rate_plan_codes;

        Object.entries(PlanOptionDetails.ASSOCIATE_PLAN_TO_FORM).forEach(([key, value]) => {
            value.forEach((value) => {
                if (quoteDetailsArray.includes(value)) {
                    additionalFieldsArray.push(PlanOptionInputBlocks[key]);
                }
            })
        });
    }
    
    const mailValidationPackage = {
        sendMailValidation: async (emailValue) => {
            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                () => {
                    return getAccessTokenSilently();
                },
            );
            await sendMailValidation(access_token, managerProfile?.account?.uuid, requestDetail?.uuid, emailValue);
        },
        resendEmailCheckLoading: resendEmailCheckLoading,
        resendEmailCheckPassed: resendEmailCheckPassed,
        resendEmailCheckError: resendEmailCheckError,
    }

    // 追加オプションの初期化
    useEffect(() => {
        if (empty(additionalOptionInformation) && !empty(additionalFieldsArray)) {
            initializeOptionFormInformation(additionalFieldsArray)
        }
    }, [additionalOptionInformation, additionalFieldsArray, initializeOptionFormInformation])


    // サイドバーのロード
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])

    // 申請情報のリセット
    useEffect(() => {
        resetFormInformation()
    }, [resetFormInformation])

    // ロード完了後のコンポーネントの表示
    if(!requestDetailLoading && !empty(requestDetail)) {
        if(requestDetail?.status === requestStatus.APPLIED && requestDetail?.type === request_types.NEW) {
            // 新規申請の場合
            return (
                <InitialContractSettingsContainer
                    requestDetailLoading={requestDetailLoading}
                    requestQuote={requestQuote}
                    additionalFieldsArray={additionalFieldsArray}
                    settingAdditionalSteps={settingAdditionalSteps}
                    additionalOptionValidation={additionalOptionValidation}
                    additionalOptionInformation={additionalOptionInformation}
                    formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                    requestStartUpInformationStep={requestStartUpInformationStep}
                    requestStartUpInformation={requestStartUpInformation}
                    settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}
                    settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
                    ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
                    ebisAccountConfirmedError={ebisAccountConfirmedError}
                    managerProfile={managerProfile}
                    requestGroupId={rgid}
                    settingEbisBackButton={settingEbisBackButton}
                    mailValidationPackage={mailValidationPackage}
                />
            )
        } else if(requestDetail?.status === requestStatus.WAIT_FOR_APPLY && [requestType.CHANGE, requestType.RENEW].includes(requestDetail?.type) && !empty(additionalFieldsArray)) {
            // 変更申請の場合
            return (
                <UpdateContractSettingsContainer
                    requestDetailLoading={requestDetailLoading}
                    requestQuote={requestQuote}
                    additionalFieldsArray={additionalFieldsArray}
                    settingAdditionalSteps={settingAdditionalSteps}
                    additionalOptionValidation={additionalOptionValidation}
                    additionalOptionInformation={additionalOptionInformation}
                    formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                    requestStartUpInformationStep={requestStartUpInformationStep}
                    requestStartUpInformation={requestStartUpInformation}
                    settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}
                    settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
                    ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
                    ebisAccountConfirmedError={ebisAccountConfirmedError}
                    managerProfile={managerProfile}
                    requestGroupId={rgid}
                    settingEbisBackButton={settingEbisBackButton}
                    mailValidationPackage={mailValidationPackage}
                />
            )
        } else {
            // それ以外の場合
            return (
                <Redirect to={'/requests/' + rgid}/>
            )
        }
    } 
    // ロード中の場合
    return (
        <FullScreenLoading/>
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const InitialSettingsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_initialSettingsContainer)

export default InitialSettingsContainer;
