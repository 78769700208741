/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /notificationsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

import ApiUtils from "../../../utils/ApiUtils";
import {GET_ALL_NOTIFICATIONS_API_PATH, GET_NOTIFICATIONS_API_PATH} from "./constants";

/**
 * お知らせ一覧取得リクエスト
 * @returns {AxiosPromise}
 */
export function callNotifications(accessToken, account_id) {
    return new ApiUtils(accessToken).get(
        GET_NOTIFICATIONS_API_PATH.replace('{UID}', account_id),
        "",
    )
}

/**
 * 全てのお知らせの一覧取得リクエスト
 * @returns {AxiosPromise}
 */
export function callAllNotifications(accessToken, account_id) {
    return new ApiUtils(accessToken).get(
        GET_ALL_NOTIFICATIONS_API_PATH.replace('{UID}', account_id),
        "",
    )
}
