import ApiUtils from "../../../../utils/ApiUtils";
import * as pass from './constants'

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /managerAccounts/usersのすべてのAPIイベントは、ここで呼び出されます。
 * これらのメソッドは、主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */
/**
 * SFアカウントIDでSFアカウントのユーザー取得リクエスト
 *
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function managerCallAccountUsers(accessToken, accountId) {
    return new ApiUtils(accessToken).get(
        pass.MANAGER_GET_ACCOUNT_USERS_API_PATH.replace('{SFID}', accountId),
        null,
        null
    )
}

/**
 * 新規ユーザーリクエスト
 * @param {Object} user - ユーザーデータ
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function addUsers(user, accessToken, accountId) {
    return new ApiUtils(accessToken).post(
        pass.GET_USERS_API_PATH.replace('{UID}', accountId),
        user
    )
}

/**
 * ユーザー更新リクエスト
 * @param {string} updateUserId - ユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @param updateUser - ユーザー更新情報
 * @returns {AxiosPromise}
 */
export function updateUsers(updateUserId, accessToken, accountId, updateUser) {
    return new ApiUtils(accessToken).put(
        pass.GET_USERS_API_PATH.replace('{UID}', accountId),
        updateUserId,
        updateUser
    )
}

/**
 * ユーザー削除リクエスト
 * @param {string} deleteUserId - 削除ユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function deleteUsers(deleteUserId, accessToken, accountId) {
    return new ApiUtils(accessToken).delete(
        pass.GET_USERS_API_PATH.replace('{UID}', accountId),
        deleteUserId
    )
}

/**
 * ブロックされたAUTH0ユーザーの解除処理。
 * @param userId - ユーザーID
 * @param accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function unlockAuth0UserAPI(userId, accessToken, accountId) {
    return new ApiUtils(accessToken).put(
        pass.UNLOCK_AUTH0_USER_API_PATH.replace('{UID}', accountId).replace('{UUID}', userId),
        ""
    )
}

/**
 * 認証メールの再送信APIリクエスト
 *
 * @param userId - ユーザーID
 * @param accessToken - auth0 アクセストークン
 * @param accountId - アカウントID
 * @returns {AxiosPromise}
 */
export function resendVerificationEmailAPI(userId, accessToken, accountId) {
    return new ApiUtils(accessToken).put(
        pass.RESEND_AUTH0_USER_VERIFY_API_PATH.replace('{UID}', accountId).replace('{UUID}', userId),
        ""
    )
}
