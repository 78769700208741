import {call, put} from 'redux-saga/effects';
import {
    callAccountContacts,
    callAccountContactsDetail,
    deleteAccountContactAPI,
    registerAccountContact,
    updateAccountContactAPI
} from "./api";
import {error_raise} from "../../../root/actions";
import {
    deleteAccountContactFailure,
    deleteAccountContactLoading, deleteAccountContactSuccess,
    loadAccountContactDetails,
    loadAccountContacts,
    loadedAccountContactDetailsFailure,
    loadedAccountContactDetailsSuccess,
    loadedAccountContactsFailure,
    loadedAccountContactsSuccess,
    loadingAccountContactDetails,
    loadingAccountContacts,
    registerAccountContactFailure,
    registerAccountContactLoading,
    registerAccountContactSuccess, updateAccountContactFailure,
    updateAccountContactLoading, updateAccountContactSuccess
} from "./actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * アカウント担当者一覧取得リクエスト
 * @param data
 */
export function* selectAccountContactsListSaga(data) {
    yield put(loadingAccountContacts())
    try {
        const accountContacts = yield call(callAccountContacts, data.payload.access_token, data.payload?.user_id, null);
        yield put(loadedAccountContactsSuccess(accountContacts));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(loadedAccountContactsFailure(e));
    }
}

/**
 * アカウント担当者詳細取得リクエスト
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* getAccountContactDetailSaga(data) {
    yield put(loadingAccountContactDetails());
    try {
        const accountContacts = yield call(callAccountContactsDetail, data.payload.access_token, data.payload?.user_id, data.payload?.account_id);
        yield put(loadedAccountContactDetailsSuccess(accountContacts));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(loadedAccountContactDetailsFailure(e));
    }
}


/**
 * アカウント担当者登録リクエスト
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* registerNewAccountContact(data) {
    yield put(registerAccountContactLoading());
    try {
        yield call(registerAccountContact, data.payload.access_token, data.payload?.user_id, data.payload?.accountContactData);
        yield put(registerAccountContactSuccess());
        yield put(loadAccountContacts(data.payload.access_token, data.payload?.user_id))
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(registerAccountContactFailure(e));
    }
}

/**
 * アカウント担当者更新リクエスト
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* updateAccountContact(data) {
    yield put(updateAccountContactLoading());
    try {
        yield call(updateAccountContactAPI, data.payload.access_token, data.payload?.user_id, data.payload?.account_contact_uuid, data.payload?.accountContactData);
        yield put(updateAccountContactSuccess());
        yield put(loadAccountContactDetails(data.payload.access_token, data.payload?.user_id, data.payload?.account_contact_uuid))
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(updateAccountContactFailure(e));
    }
}

/**
 * アカウント担当者削除リクエスト
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* deleteAccountContact(data)  {
    yield put(deleteAccountContactLoading());
    try {
        yield call(deleteAccountContactAPI, data.payload.access_token, data.payload?.user_id, data.payload?.account_contact_uuid);
        yield put(deleteAccountContactSuccess());
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(deleteAccountContactFailure(e));
    }
}
