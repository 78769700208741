import React from 'react';
import clsx from "clsx";
import {Box, Checkbox, FormControlLabel, Grid, Link as LinkMaterial, Typography} from "@material-ui/core";
import {Trans} from "react-i18next";

/**
 * ロゴコンポーネント
 * @param {*} props - 追加情報
 * @returns {JSX.Element}
 * @constructor
 */
const SpotGpsPaiedAgreementCheckField = ({
                                       classes,
                                       hasScrolledToTermsBottom,
                                       applyToggleTerms,
                                       requestApplyAcceptTerms,
                                       position="center",
                                       t
                                   }) => {
    return (
        <Box className={clsx(classes.root)} marginY={1}>
            <Grid container justify={position} alignItems={"center"} spacing={5}>
                <Grid item>
                    <FormControlLabel color={"primary"}
                                      control={<Checkbox color={"primary"} onChange={() => applyToggleTerms()}
                                                         checked={requestApplyAcceptTerms}/>}
                                      label={
                                          <Trans i18nKey="general.agree_terms">
                                              { /** TODO: 時間があれば下記のリンクを環境設定変数に */}
                                              上記申込内容、お客様への注意事項、
                                              「<LinkMaterial target="_blank" style={{color: "#0D66D0"}} href={ process.env.REACT_APP_GPS_PAID_SERVICE_POLICY_PAGE }>有償導入支援プログラム契約条項</LinkMaterial>」、
                                              「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.ebis.ne.jp/policy/rule.php">AD EBiS 利用契約約款</LinkMaterial>」、
                                              「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href={ process.env.REACT_APP_COMPANY_SERVICE_POLICY_PAGE }>ご契約者さま専用マイページ利用規約</LinkMaterial> 」、
                                              「<LinkMaterial target="_blank" style={{ color: "#0D66D0" }} href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」 
                                              ならびに
                                              「<LinkMaterial target="_blank" style={{color: "#0D66D0"}} href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                                              に同意の上、申し込みます。
                                          </Trans>
                                      }/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SpotGpsPaiedAgreementCheckField;
