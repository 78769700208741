import React from 'react';
import {Box, Button, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {Field, Form} from "react-final-form";
import FinalFormTextField from "../../../../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../../../../state/utils/Validation";
import arrayMutators from 'final-form-arrays'
import globalTheme from "../../../../../../../theme/globalTheme";
import {empty} from "../../../../../../../../state/utils/Common";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../../../../state/utils/Auth0Utils";
import FinalFormTextArea from "../../../../../../../atoms/FinalFormTextArea";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
const inputTypes = {
    'text': FinalFormTextField,
    'textarea': FinalFormTextArea
}

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeContractSettingsInputView = (
    {
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        additionalFieldsArray,
        requestGroupId,
        managerProfile,
        settingAdditionalSteps,
        additionalOptionValidation,
        mailValidationPackage,
        t
    }) => {
    const classes = useStyles();
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    const globalStyle = globalTheme._default();
    const formClasses = globalTheme.defaultFormStyle();

    const handleOnSubmit = async values => {
        if (!empty(additionalFieldsArray)) {
            settingAdditionalSteps(values?.additional_fields)
        }
        settingSubmitEbisFormInformation(values)
    }

    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography variant="body1">{t('requests.detail.quotes.setting.step_1_ebis.description')}</Typography>
            </Box>
            <Box className={clsx(classes.root)}>
                <Form
                    onSubmit={handleOnSubmit}
                    mutators={{
                        ...arrayMutators
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        return translate(t, Validation({...e.additional_fields}, additionalOptionValidation))
                    }}
                    // （編集モード）最初のバリュー
                    initialValues={requestStartUpInformation}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({
                                 handleSubmit,
                                 form: {
                                     mutators: {push, pop}
                                 },
                                 submitting,
                                 pristine,
                                 hasValidationErrors,
                                 values
                             }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {!empty(additionalFieldsArray) && additionalFieldsArray.map((value) => {
                                return (
                                    <div>
                                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                                        <h2>{value.label}</h2>
                                        <Typography variant="body1" style={{
                                            marginTop: 15,
                                            marginBottom: 15
                                        }}>{value.description}</Typography>
                                        {value.fields.map((field_value) => {
                                            return (<Grid container alignItems="flex-start" spacing={2}>
                                                <Grid item md={3}>
                                                    <Grid container>
                                                        <Grid item xs={11}>
                                                            <Field
                                                                fullWidth
                                                                size={"small"}
                                                                required={field_value.required}
                                                                label={t(field_value.label)}
                                                                name={`additional_fields.${field_value.name}`}
                                                                disabled={field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckLoading === true}
                                                                onBlur={() => {
                                                                    if (field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage)) {
                                                                        mailValidationPackage.sendMailValidation(values[field_value.name])
                                                                    }
                                                                }}
                                                                component={inputTypes[field_value.input]}
                                                                type="text"
                                                            />
                                                        </Grid>
                                                        {field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckPassed === false &&
                                                            <Grid item xs={12}>
                                                                <Typography className={globalStyle.error}>
                                                                    {t(mailValidationPackage.resendEmailCheckError?.translation_error_key)}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {!empty(field_value?.hint) &&
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography style={{paddingTop: 5}}
                                                                            variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.id_hint')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>);
                                        })}
                                        <Divider style={{marginTop: 15, marginBottom: 15}}/>
                                    </div>
                                )
                            })}
                            <Box className={clsx(classes.root)} marginY={2} alignContent="middle">
                                <Grid container justify="center" alignItems={"center"} spacing={5}>
                                    <Link
                                        to={'/requests/' + requestGroupId}>
                                        <Button variant={"contained"} color={"secondary"}
                                                style={{marginRight: 20, fontWeight: 900}}>
                                            {t('actions.back')}
                                        </Button>
                                    </Link>
                                    <Button variant={"contained"} style={{fontWeight: 900}}
                                            color={"primary"}
                                            type="submit"
                                            disabled={hasValidationErrors}
                                    >
                                        {t('actions.next')}
                                    </Button>
                                </Grid>
                            </Box>
                        </form>)}/>
            </Box>
        </>
    );
}

export default ChangeContractSettingsInputView;
