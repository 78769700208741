import types from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractContactState = {
    activeServiceContract: {},
    activeServiceContractError: {},
    contactLoading: true,
    
    otherContactContacts: [],
    otherInvoiceContacts: [],
    otherContactsLoading: false,
    otherContactsError: {},
    
    isUpdateModalOpen: false,
    updateLoading: false,
    updateError: {},
}

export default function serviceContractsContact(state = serviceContractContactState, action) {
    switch (action.type) {
        // ------------------------------------
        // 連絡先の契約情報の取得
        // ------------------------------------
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_LOADING:
            return {
                ...state,
                activeServiceContract: state.activeServiceContract = {},
                contactLoading: state.contactLoading = true,
                activeServiceContractError: state.activeServiceContractError = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS:
            return {
                ...state,
                contactLoading: state.contactLoading = false,
                activeServiceContract: state.activeServiceContract = action.payload.activeServiceContract
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_FAILED:
            return {
                ...state,
                contactLoading: state.contactLoading = false,
                activeServiceContract: state.activeServiceContract = {},
                activeServiceContractError: state.activeServiceContractError = action.payload.error
            }
            // ------------------------------------
            // 他の担当者情報の取得
            // ------------------------------------
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_LOADING:
            return {
                ...state,
                isUpdateModalOpen: state.isUpdateModalOpen = true,
                otherContactsLoading: state.otherContactsLoading = true,
                otherContactContacts: state.otherContactContacts = [],
                otherInvoiceContacts: state.otherInvoiceContacts = [],
                otherContactsError: state.otherContactsError = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_SUCCESS:
            return {
                ...state,
                otherContactsLoading: state.otherContactsLoading = false,
                otherContactContacts: state.otherContactContacts = action.payload.contractContacts,
                otherInvoiceContacts: state.otherInvoiceContacts = action.payload.invoiceContacts
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_FAILED:
            return {
                ...state,
                otherContactsLoading: state.otherContactsLoading = false,
                otherContactContacts: state.otherContactContacts = [],
                otherInvoiceContacts: state.otherInvoiceContacts = [],
                otherContactsError: state.otherContactsError = action.payload.error
            }
            // ------------------------------------
            // 更新モーダルのトグル
            // ------------------------------------
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_TOGGLE_UPDATE_MODAL:
            return {
                ...state,
                isUpdateModalOpen: state.isUpdateModalOpen = action?.payload?.forceState ?? !state.isUpdateModalOpen
            }
        // ------------------------------------
        // 担当者更新
        // ------------------------------------
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_LOADING:
            return {
                ...state,
                updateLoading: state.updateLoading = true,
                updateError: state.updateError = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_SUCCESS:
            return {
                ...state,
                updateLoading: state.updateLoading = false,
                updateError: state.updateError = {},
                isUpdateModalOpen: state.isUpdateModalOpen = false
            }
            case types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_FAILED:
            return {
                ...state,
                updateLoading: state.updateLoading = false,
                updateError: state.updateError = action.payload.error
            }
        default:
            return state
    }
}
