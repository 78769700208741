import ApiUtils from "../../../../utils/ApiUtils";
import {
    GET_CONTACT_DETAIL_API_PATH,
    GET_CONTACTS_API_PATH, POST_CONTACTS_API_PATH, PUT_CONTACTS_API_PATH
} from './constants'


/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /usersのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * アカウント担当者一覧取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param userId - ユーザーID
 * @param params - GETクエリー
 * @returns {AxiosPromise}
 */
export function callAccountContacts(accessToken, userId, params) {
    return new ApiUtils(accessToken).get(
        GET_CONTACTS_API_PATH.replace('{UID}', userId),
        params,
    );
}

/**
 * アカウント担当者詳細取得リクエスト
 * @param accessToken
 * @param userId
 * @param contactId
 * @returns {AxiosPromise}
 */
export function callAccountContactsDetail(accessToken, userId, contactId) {
    return new ApiUtils(accessToken).get(
        GET_CONTACT_DETAIL_API_PATH.replace('{UID}', userId).replace('{ACID}', contactId),
        null
    );
}

/**
 * アカウント担当者登録リクエスト
 * @param accessToken
 * @param userId
 * @param data
 * @returns {AxiosPromise}
 */
export function registerAccountContact(accessToken, userId, data) {
    return new ApiUtils(accessToken).post(
        POST_CONTACTS_API_PATH.replace('{UID}', userId),
        data
    );
}

/**
 * アカウント担当者更新リクエスト
 * @param accessToken
 * @param userId
 * @param contactId
 * @param data
 * @returns {AxiosPromise}
 */
export function updateAccountContactAPI(accessToken, userId, contactId, data) {
    return new ApiUtils(accessToken).put(
        PUT_CONTACTS_API_PATH.replace('{UID}', userId),
        contactId,
        data
    );
}

/**
 * アカウント担当者削除リクエスト
 * @param accessToken
 * @param userId
 * @param contactId
 * @returns {AxiosPromise}
 */
export function deleteAccountContactAPI(accessToken, userId, contactId) {
    return new ApiUtils(accessToken).delete(
        PUT_CONTACTS_API_PATH.replace('{UID}', userId),
        contactId
    );
}
