/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */


/***
 * 新規住所追加・住所編集フォーム
 * @type {{postal_code, state, city, address}}
 */

const createAccountContactBaseForm = {
    last_name: null,
    first_name: null,
    work_phone: null,
    mobile_phone: null,
    email: null,
    department: null,
    status: 1,
    role: null
}

const createAccountContactStateForm = {
    ...createAccountContactBaseForm,
    country: null,
    postal_code: null,
    state: null,
    city: null,
    address: null
}

const createAccountContactNoStateForm = {
    ...createAccountContactBaseForm,
    country: null,
    postal_code: null,
    city: null,
    address: null
}

const forms = {
    createAccountContactNoStateForm,
    createAccountContactStateForm,
}

export default forms;
