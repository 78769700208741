import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    Container,
    Divider,
    FormLabel,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../Page";
import {Field, Form} from "react-final-form";
import {useHistory} from "react-router";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import FinalFormCheckBox from "../../../../atoms/FinalFormCheckBox";
import FinalFormTextArea from "../../../../atoms/FinalFormTextArea";
import globalTheme from "../../../../theme/globalTheme";
import {serviceContractTerminateForms} from "../../../../../state/ducks/user/service_contracts/terminate";
import {
    terminateServiceContractValidation,
    terminateServiceContractWithoutPreferredDay
} from "../../../../../state/ducks/user/service_contracts/terminate/validation";
import FinalFormFieldIndependentError from "../../../../atoms/FinalFormFieldIndependentError";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {Link} from "react-router-dom";
import FinalFormDate from "../../../../atoms/FinalFormDate";
import services from "../../../../../state/utils/data/services.json";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const TerminatePage = (
    {
        t,
        formData,
        response,
        service_contract_id,
        cancelReasonsLoading,
        minDate,
        saveTerminateForm
    }) => {
    const classes = useStyles();
    const globalStyle = globalTheme.defaultFormStyle();
    const history = useHistory();
    formData.service_name = []
    let activeServices = []
    { Object.values(services).map(item => {
        if (!empty(response) && response?.[item] && response?.[item]?.service_contract?.applied_cancel === true) {
            formData.service_name.push(item)
        } else if (!empty(response) && response?.[item]) {
            activeServices.push(item)
        }
    })}
    
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.terminate.title', {service_contract_name: cancelReasonsLoading === true ? '...' : !empty(response?.service_account_name) ? response?.service_account_name : ""})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('service_contracts.terminate.title', {service_contract_name: !empty(response?.service_account_name) ? response?.service_account_name : ""})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.terminate.href.replace('{SCID}', service_contract_id)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.terminate.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.terminate.terminate_form.header')}</Typography>
                        </Box>
                        <Divider/>
                        <Box p={2}>
                            <Form
                                onSubmit={(e) => {
                                    e = mergeWithDefaultForm(e, serviceContractTerminateForms.terminateForm);
                                    e.cancel_subscriptions = [];
                                    
                                    
                                    Object.values(services).map(item => {
                                        let cancel_preferrd_ym = null;
                                        
                                        if(response?.[item]?.service_contract?.applied_cancel === true) {
                                            e.service_name = e.service_name.filter(itemArray => itemArray !== item)
                                        }
                                        
                                        if (response?.ADEBiS?.service_contract?.allow_prefer_ym) {
                                            cancel_preferrd_ym = e.ADEBiS_cancel_prefferd_ym;
                                        }
                                        
                                        
                                        
                                        if(e.service_name.includes(item)) {
                                            e.cancel_subscriptions.push({
                                                uuid: response?.[item]?.service_contract?.uuid,
                                                cancel_preferrd_ym: cancel_preferrd_ym
                                            });
                                        }
                                    })
                                    
                                    saveTerminateForm(e);
                                    history.push('/service_contracts/' + service_contract_id + '/terminate/confirm');
                                }}
                                // ここでフォームデータを妥当性確認し、キーを変換します。
                                validate={e => {
                                    if (!response?.ADEBiS?.service_contract?.allow_prefer_ym || response?.ADEBiS?.service_contract?.applied_cancel) {
                                        return translate(t, Validation({...e}, terminateServiceContractWithoutPreferredDay))
                                    }
                                    return translate(t, Validation({...e}, terminateServiceContractValidation))
                                }}
                                // （編集モード）最初のバリュー
                                initialValues={formData}
                                // ここでは、フォームのレンダリングと制御を行います
                                // エラー処理やダブルクリック防止などはここで行います
                                render={({handleSubmit, form, pristine, invalid, values}) => (
                                    <form onSubmit={handleSubmit} noValidate>
                                        <Box paddingBottom={"20px"}>
                                            <FormLabel>{t('service_contracts.terminate.terminate_form.associated_account')}</FormLabel>
                                            <Box paddingTop={1}>
                                                <Typography variant={"body1"}>
                                                    <span
                                                        style={{paddingRight: 25}}>{response?.service_account_id}</span>
                                                    <span
                                                        style={{paddingRight: 60}}>{response?.service_account_name}</span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <FormLabel>
                                                {t('service_contracts.terminate.terminate_form.associated_contracts')}
                                                <Chip label={"必須"} size="small" className={globalStyle.chip}/>
                                                <Typography variant={"subtitle1"} style={{paddingLeft: 10, display: "inline"}}>
                                                    {t('service_contracts.terminate.terminate_form.check_help_text')}
                                                </Typography>
                                            </FormLabel>
                                            { Object.values(services).map(item => {
                                                return (<>
                                                    { !empty(response) && response?.[item] &&
                                                        <Box paddingY={1} paddingX={1}>
                                                        <Field
                                                            name="service_name"
                                                            listStyle={"vertical"}
                                                            type={"checkbox"}
                                                            size={"small"}
                                                            component={FinalFormCheckBox}
                                                            disabled={response?.[item]?.service_contract?.applied_cancel === true}
                                                            value={item}
                                                            label={item}
                                                        />
                                                        <Grid container style={{marginLeft: 25}}>
                                                            <Grid item xs={2}>
                                                                <Typography variant={"body1"}>
                                                                    {t('service_contracts.terminate.terminate_form.contract_number')}: {response?.[item]?.subscription_number}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant={"body1"}>
                                                                    {t('service_contracts.contents.card_header.contract_period', {term: !empty(response?.[item]) ? response?.[item]?.current_term : '', period_measurement: !empty(response?.[item]) ? t('general.time_periods.' + response?.[item]?.current_term_period_type?.toLowerCase()) : "" })}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant={"body1"}>
                                                                    {t('service_contracts.contents.card_header.next_update', {contract_update_date: !empty(response?.[item]) ? response?.[item].renewal_date : ''})}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant={"body1"}>
                                                                    {t('service_contracts.terminate.terminate_form.termination_date', {cancel_date: !empty(response?.[item]) ? response?.[item]?.service_contract?.cancel_at : ''})}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                            {response?.[item]?.service_contract?.allow_prefer_ym === true && item === 'ADEBiS' && !response?.[item]?.service_contract?.applied_cancel && values.service_name.includes(services.ADEBIS) &&
                                                                /** 解約希望年月（入力) -> 任意入力可能 */
                                                                <Box paddingBottom={2}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} sm={5} md={2}>
                                                                            <Field
                                                                                fullWidth
                                                                                name='ADEBiS_cancel_prefferd_ym'
                                                                                required={true}
                                                                                dateFormat={'YYYY/MM/21'}
                                                                                minDate={minDate.find(itemMinDate => {  return itemMinDate?.service_name === item})?.minDate}
                                                                                maxDate={response?.[item]?.renewal_ym}
                                                                                component={FinalFormDate}
                                                                                okByDateClick={true}
                                                                                size="small"
                                                                                type="text"
                                                                                views={["year", "month"]}
                                                                                initialFocusedDate={minDate}
                                                                                label={t('service_contracts.terminate.terminate_form.preferred_date_of_terminate')}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            }
                                                        </Box>
                                                }
                                                </>)
                                            })}
                                        </Box>
                                        <Box marginTop={2} marginBottom={3}
                                             style={{backgroundColor: "#EDE8DF", borderRadius: 3, padding: 13}}>
                                            <Typography variant={"body1"} style={{fontWeight: 900}}>
                                                {t('service_contracts.terminate.about_termination_day.title')}
                                            </Typography>
                                            <Typography variant={"body1"} style={{paddingBottom: 15, whiteSpace: "prewrap"}}>
                                                {t('service_contracts.terminate.about_termination_day.description')}
                                            </Typography>
                                            <Typography variant={"body1"} style={{fontWeight: 900}}>
                                                {t('service_contracts.terminate.about_terminating_multiple_contracts_simultaneously.title')}
                                            </Typography>
                                            <Typography variant={"body1"}>
                                                {t('service_contracts.terminate.about_terminating_multiple_contracts_simultaneously.description')}
                                            </Typography>
                                        </Box>
                                        
                                        <Box paddingBottom={1}>
                                            {/** 解約理由１ */}
                                            <Grid container>
                                                <Grid item xs={12} sm={5} md={2}>
                                                    <FormLabel>{t('service_contracts.terminate.terminate_form.terminate_reason')}
                                                        <Chip label={"必須"} size="small" className={globalStyle.chip}/>
                                                    </FormLabel>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box paddingX={1}>
                                            {!empty(response?.cancel_reasons) && response.cancel_reasons.map((item) => {
                                                return (<Field
                                                    name="cancel_reasons1"
                                                    listStyle={"vertical"}
                                                    type={"checkbox"}
                                                    size={"small"}
                                                    component={FinalFormCheckBox}
                                                    value={item.value}
                                                    label={item.label}
                                                />)
                                            })}
                                            <FinalFormFieldIndependentError name={"cancel_reasons1"}/>
                                            <Grid container style={{marginTop: 20}}>
                                                <Grid item xs={12} md={6} lg={4}>
                                                    {/** 解約理由２ */}
                                                    <Box paddingBottom={"20px"}>
                                                        <Field
                                                            fullWidth={true}
                                                            name={"cancel_reason2"}
                                                            component={FinalFormTextArea}
                                                            required={false}
                                                            rows={5}
                                                            label={t('service_contracts.terminate.terminate_form.reason_for_option')}
                                                        />
                                                    </Box>
                                                    {/** 解約理由３ */}
                                                    <Box paddingBottom={"20px"}>
                                                        <Field
                                                            fullWidth={true}
                                                            name={"cancel_reason3"}
                                                            component={FinalFormTextArea}
                                                            required={false}
                                                            rows={5}
                                                            label={t('service_contracts.terminate.terminate_form.changing_service_to')}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box paddingBottom={5}>
                                            <Grid container justify={"center"} alignItems={"center"}
                                                  alignContent={"center"}>
                                                <Grid item xs={12} style={{textAlign: "center"}}>
                                                        <Button disabled={invalid|!activeServices.length > 0} type={"submit"} variant={"contained"}
                                                                color={"primary"}>
                                                            {t('actions.confirm')}
                                                        </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </form>)}
                            />
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TerminatePage;
