import * as Root from "./root";
import * as UserAccountBasicInfo from './user/accounts/basic_info'
import * as UserAccountUser from './user/accounts/users'
import * as UserAccountContact from './user/accounts/contacts'
import * as UserNotification from './user/notifications'
import * as ManagerUser from './manager/managerUsers'
import * as ManagerAccount from './manager/managerAccounts'
import * as ManagerAccountUser from './manager/managerAccounts/users'
import * as ManagerNotice from './manager/managerNotices'
import * as ManagerRequest from './manager/managerRequests'
import * as UserRequest from './user/requests'
import * as UserServiceContract from './user/service_contracts'
import * as UserServiceContractContact from './user/service_contracts/contacts'
import * as UserServiceContractContent from './user/service_contracts/contents'
import * as UserServiceContractTransfer from './user/service_contracts/transfer'
import * as UserServiceContractTerminate from './user/service_contracts/terminate'
import * as UserContact from './user/contact'
import * as GuestTransfer from './guest/transfer'

/***
 * ///////////////////////////////////////////////
 * REDUCKSメインファイル 🦆
 * ---------------------------
 *
 * これは、reduxの子グループを束ねるreducksのメインファイルです。
 * これで、ステイツ、リデューサー、レドゥーサガが結ばれる。
 *
 * reducksの詳細と、新しいreduxグループの追加については、以下のリンクをご参照ください。
 * reducksのテンプレート化の例 :  https://github.com/alexnm/re-ducks#enter-re-ducks
 * //////////////////////////////////////////////
 */
import {all, call, spawn} from 'redux-saga/effects';


/**
 * すべての子のreduxステートを束ねる
 */
export const StoreState = {
    root: Root.rootState,
    transferApprove: GuestTransfer.transferApproveState,

    notification: UserNotification.notificationListState,

    serviceContractList: UserServiceContract.serviceContractListState,
    serviceContractContact: UserServiceContractContact.serviceContractContactState,
    serviceContractContent: UserServiceContractContent.serviceContractContentState,
    serviceContractTransfer: UserServiceContractTransfer.serviceContractTransferState,
    serviceContractTerminate: UserServiceContractTerminate.serviceContractTerminateState,

    request: UserRequest.requestState,
    contact: UserContact.contactState,

    accountsBasicInformation: UserAccountBasicInfo.accountsBasicInformationState,
    accountsUser: UserAccountUser.accountsUserListState,
    accountContact: UserAccountContact.accountContactsState,

    managerUser: ManagerUser.managerUserListState,
    managerAccount: ManagerAccount.managerAccountListState,
    managerAccountUser: ManagerAccountUser.managerAccountUserState,
    managerNotice: ManagerNotice.managerNoticeState,
    managerApplication: ManagerRequest.managerRequestState
};

/**
 * すべてのリデューサーイベントを束ねる
 */
export const reducers = {
    root: Root.mainReducer,
    transferApprove: GuestTransfer.transferApproveReducer,

    accountsBasicInformation: UserAccountBasicInfo.accountsBasicInformationReducer,
    accountsUser: UserAccountUser.accountsUserReducer,
    accountContact: UserAccountContact.accountContactsReducer,

    serviceContractList: UserServiceContract.serviceContractListReducer,
    serviceContractContact: UserServiceContractContact.serviceContractContactReducer,
    serviceContractContent: UserServiceContractContent.serviceContractContentReducer,
    serviceContractTransfer: UserServiceContractTransfer.serviceContractTransferReducer,
    serviceContractTerminate: UserServiceContractTerminate.serviceContractTerminateReducer,
    request: UserRequest.requestReducer,
    contact: UserContact.contactReducer,
    notification: UserNotification.notificationReducer,

    managerUser: ManagerUser.managerUserReducer,
    managerAccount: ManagerAccount.managerAccountReducer,
    managerAccountUser: ManagerAccountUser.managerAccountUserReducer,
    managerNotice: ManagerNotice.managerNoticeReducer,
    managerRequest: ManagerRequest.managerRequestReducer
};

/**
 * すべてのサガ・ウォッチャーを束ね、束ねられたウォッチャーの呼び出しに成功した場合、グローバル・キャッチを設定します。
 */
export function* rootSaga() {
    const watchers = [
        ...UserAccountBasicInfo.accountsBasicInfoWatcherSagas,
        ...UserAccountUser.accountsUserWatcherSagas,
        ...UserAccountContact.accountContactsWatcherSagas,

        ...ManagerAccount.managerAccountWatcherSagas,
        ...ManagerAccountUser.managerAccountUserWatcherSagas,
        ...Root.rootWatcherSagas,
        ...ManagerUser.managerUserWatcherSagas,
        ...UserServiceContract.serviceContractListWatcherSagas,
        ...UserServiceContractContact.serviceContractContactWatcherSagas,
        ...UserServiceContractContent.serviceContractContentSagas,
        ...UserServiceContractTransfer.serviceContractTransferSagas,
        ...UserServiceContractTerminate.fetchServiceContractTerminateSagas,
        ...ManagerNotice.noticeWatcherSagas,
        ...ManagerRequest.managerRequestWatcherSagas,
        ...UserRequest.RequestWatcherSagas,
        ...UserContact.ContactWatcherSagas,
        ...UserNotification.notificationWatcherSagas,
        ...GuestTransfer.transferApproveWatcherSagas
    ];

    yield all(
        watchers.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }),
        ),
    );
}
