import {takeLeading} from 'redux-saga/effects';
import {
    deleteAccountContact,
    getAccountContactDetailSaga,
    registerNewAccountContact,
    selectAccountContactsListSaga,
    updateAccountContact
} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* selectAccountContactsList() {
    yield takeLeading(types.REQUEST_ACCOUNT_CONTACTS, selectAccountContactsListSaga);
    yield takeLeading(types.REQUEST_ACCOUNT_CONTACT_DETAILS, getAccountContactDetailSaga);
    yield takeLeading(types.REQUEST_ACCOUNT_CONTACT_REGISTRATION, registerNewAccountContact);
    yield takeLeading(types.REQUEST_ACCOUNT_CONTACT_UPDATE, updateAccountContact);
    yield takeLeading(types.REQUEST_ACCOUNT_CONTACT_DELETE, deleteAccountContact);
}
