import {call, put} from "redux-saga/effects";
import {failureServiceContractList, loadingServiceContractList, successServiceContractList} from "./actions";
import {error_raise} from "../../root/actions";
import {callServiceContracts} from "./api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 利用契約取得リクエスト
 * @param data
 */
export function* selectServiceContracts(data) {
    yield put(loadingServiceContractList())
    try {
        const serviceContracts = yield call(callServiceContracts, data.payload.access_token, data.payload?.user_id, null);
        yield put(successServiceContractList(serviceContracts.data));
    } catch (e) {
        yield put(failureServiceContractList(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
