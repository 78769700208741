import React from 'react';
import {Box, Button, Grid, Modal, Typography} from '@material-ui/core';
import globalTheme from "../../../../../../../theme/globalTheme";

/**
 * 通知モーダル
 * @returns {{transform: string, top: string, left: string}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: 705,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const TermsAndConditionsModal = (
    {
        isOpen,
        handleClose,
        requestQuote,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes_global = globalTheme._default();
    return (
        <Modal
            open={isOpen}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <Box className={classes_global.modal} style={modalStyle}>
                    <Typography id="modal-modal-title" variant="h3" component="h2" style={{marginBottom: 20}}>
                        {t('requests.detail.quotes.terms_of_service_modal.title')}
                    </Typography>
                    <Grid container>
                        <Grid item style={{height: 636, overflowY: "scroll"}}>
                            {requestQuote.is_old_plan === true &&
                                <Typography id="modal-modal-description" sx={{mt: 2}}>
                                    (1) 株式会社イルグルムは、AD
                                    EBiSに関するご契約・ご請求手続きの処理（広告主様のご担当者様等の個人データを取扱う業務を含みます。）
                                    を、外部の事業者（米国その他の外国事業者を含みます。）に委託する場合があります。
                                    そのため、本手続きを行っていただくに際しては、画面上にお名前等を入力いただいたご担当者様等にその旨説明をし、必ず同意をいただくようにお願いいたします。
                                    なお、株式会社イルグルムは、個人データの取扱いの委託にあたっては、業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りに留意します。<br/>
                                    <br/>
                                    (2)
                                    請求書送付先の住所、請求書記載のご担当者様及びアドエビスのプラン変更完了メール等の送付先ご担当者様を変更する場合、アドエビス管理画面のお問い合わせフォームからご依頼をお願いいたします。<br/>
                                    <br/>
                                    (3) 契約期間内の基本料金及びオプションのダウングレードは承っておらず、契約更新時のみとなっております。<br/>
                                    <br/>
                                    (4) 契約更新月の前々月までの平均計測実績値が、ご利用の各サービスの計測上限値を上回る場合、次回契約内容が変更となるため当社よりご連絡させていただきます。<br/>
                                    <br/>
                                    (5) ご契約プランのトラフィック量を超過する場合、一時的にご利用の制限を行い、ご契約更新前であっても翌月から上位プランへ移行していただく場合がございます。<br/>
                                    <br/><br/>
                                    【LTVForecastをご利用のお客様】<br/>
                                    <br/>
                                    (6)
                                    お客様は、「LTVForecast」（以下「本付加サービス」といいます。）のご利用に際して、お客様ECショップで購買履歴のある方（以下「会員」といいます。）
                                    一人当たりの利益額（LTV）の視点による広告投資の適正化を図ること等を目的として、会員の個人データ※（以下「本個人データ」といいます。）をAD
                                    EBiSに送信することにより、その取扱いを当社に委託し、当社はこれを受託するものとします。
                                    ※お客様が、本付加サービスのご利用に際してAD
                                    EBiSに送信する情報（顧客ID等。詳細は、第（７）項をご参照ください。）は、お客様における当該情報の管理状況次第で、個人情報保護法上の「個人データ」に該当します。<br/>
                                    <br/>
                                    (7) お客様がAD EBiSに送信する本個人データには、その情報単独で、またはお客様が過去にAD
                                    EBiSに格納した他の情報と組み合わせることによって、特定の個人を識別できる情報が含まれてはならないものとします。<br/>
                                    <br/>
                                    (8)
                                    お客様は、本個人データを、個人情報保護法その他適用のある法令（ガイドライン、省令、及び該当する場合は外国法令を含み、以下総称して「法令等」といいます。）に準拠して適切な方法で取得しており、当該取得が法令等に違反しないこと、及び会員その他の第三者の権利を侵害しないことを表明し保証するものとします。<br/>
                                    <br/>
                                    (9) 当社は、本個人データを、第（６）項に記載の目的の範囲内でのみ取り扱うものとし、善良な管理者の注意をもって、適切・厳重に保護または管理するものとします。<br/>
                                    <br/>
                                    (10)
                                    本個人データについて、データ主体である会員その他の第三者との間でクレームやトラブル等が発生した場合、それが当社の故意または重過失による場合を除き、お客様自身の費用と責任をもってこれを解決するものとし、当社は一切の責任を負わないものとします。<br/>
                                    <br/>
                                    (11) 当社は、本付加サービスの利用契約が終了した場合には、終了後９０日以内に本個人データを削除するものとします。
                                    但し、当該削除は、技術、費用等の観点で合理的かつ現実に実施可能な場合に限り、行うものとします。
                                    なお、法令等の規定に基づき、当該期間経過後においても当該個人データを保存しなればならないときは、その規定に従います。<br/>
                                </Typography>
                            }
                            {requestQuote.is_old_plan === false &&
                                <Typography id="modal-modal-description" sx={{mt: 2}}>
                                    (1) 株式会社イルグルムは、AD
                                    EBiSに関するご契約・ご請求手続きの処理（広告主様のご担当者様等の個人データを取扱う業務を含みます。）
                                    を、外部の事業者（米国その他の外国事業者を含みます。）に委託する場合があります。
                                    そのため、本手続きを行っていただくに際しては、
                                    画面上にお名前等を入力いただいたご担当者様等にその旨説明をし、
                                    必ず同意をいただくようにお願いいたします。なお、株式会社イルグルムは、
                                    個人データの取扱いの委託にあたっては、
                                    業務委託先としての適格性を十分審査するとともに、
                                    契約にあたって守秘義務に関する事項等を規定し、
                                    情報が適正に管理される体制作りに留意します。<br/>
                                    <br/>
                                    (2) 契約期間内の基本料金及びオプションのダウングレードは承っておらず、契約更新時のみとなっております。
                                    ただし、契約期間内に基本料金のアップグレードをした場合に限り、
                                    その直後の契約満了時の翌月から契約更新頂く際、基本料金については同グレードでの更新となります。 <br/>
                                    <br/>
                                    (3) 新規アカウント発行時、「上限枠超過計測の設定」は有効となります。無効に変更される場合は、AD EBiSのアカウント発行後、AD EBiSにログインの上、
                                    管理画面にてご変更ください。<br/>
                                    <br/>
                                    (4) 「上限枠超過計測の設定」を有効にしている場合、契約プランの上限枠を超えて継続して計測いただくことが可能です。<br/>
                                    <br/>
                                    (5) 上限枠を超えた計測分については、計測トラフィック量に応じて従量課金にて超過分のご請求が発生いたしますのでご注意ください。<br/>
                                    <br/>
                                    (6) 「上限枠超過計測の設定」を無効にしている場合、契約プランの上限枠に達した時点で、該当月の計測を停止いたします。<br/>
                                    <br/>
                                    (7) AD EBiSに関するご契約手続き等は、当社がご提供する利用契約管理ツールである「ご契約者さま専用マイページ」にてお客様ご自身により行っていただき
                                    ますようお願いいたします。<br/>
                                    <br/>
                                    (8) AD EBiSの利用契約を解約される場合、ご契約者さま専用マイページ利用規約第18条～第20条が適用されるものとします。
                                    <br/><br/>
                                    【LTVForecastをご利用のお客様】<br/>
                                    <br/>
                                    (9) お客様は、「LTVForecast」（以下「本付加サービス」といいます。）のご利用に際して、お客様ECショップで購買履歴のある方（以下「会員」といいま
                                    す。）一人当たりの利益額（LTV）の視点による広告投資の適正化を図ること等を目的として、会員の個人データ※（以下「本個人データ」といいます。）
                                    をAD EBiSに送信することにより、その取扱いを当社に委託し、当社はこれを受託するものとします。
                                    ※ お客様が、本付加サービスのご利用に際してAD EBiSに送信する情報
                                    （顧客ID等。詳細は、第（10）項をご参照ください。）は、
                                    お客様における当該情報の管理状況次第で、個人情報保護法上の「個人データ」に該当します。<br/>
                                    <br/>
                                    (10) お客様がAD EBiSに送信する本個人データには、その情報単独で、またはお客様が過去にAD EBiSに格納した他の情報と組み合わせることによって、特定
                                    の個人を識別できる情報が含まれてはならないものとします。<br/>
                                    <br/>
                                    (11) お客様は、本個人データを、個人情報保護法その他適用のある法令（ガイドライン、省令、及び該当する場合は外国法令を含み、以下総称して「法令等」と
                                    いいます。）に準拠して適切な方法で取得しており、当該取得が法令等に違反しないこと、及び会員その他の第三者の権利を侵害しないことを表明し保証す
                                    るものとします。<br/>
                                    <br/>
                                    (12)
                                    当社は、本個人データを、第（8）項に記載の目的の範囲内でのみ取り扱うものとし、善良な管理者の注意をもって、適切・厳重に保護または管理するものとします。<br/>
                                    <br/>
                                    (13) 本個人データについて、データ主体である会員その他の第三者との間でクレームやトラブル等が発生した場合、それが当社の故意または重過失による場合を
                                    除き、お客様自身の費用と責任をもってこれを解決するものとし、当社は一切の責任を負わないものとします。<br/>
                                    <br/>
                                    (14) 当社は、本付加サービスの利用契約が終了した場合には、終了後９０日以内に本個人データを削除するものとします。但し、当該削除は、技術、費用等の観
                                    点で合理的かつ現実に実施可能な場合に限り、行うものとします。なお、法令等の規定に基づき、当該期間経過後においても当該個人データを保存しなれば
                                    ならないときは、その規定に従います。<br/>
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid item container justify="flex-end" spacing={2}>
                        <Grid item style={{marginTop: 16}}>
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={() => handleClose()
                                }>
                                {t('actions.ok')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </Modal>
    );
}

export default TermsAndConditionsModal;
