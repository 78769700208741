import React from 'react';
import {Box, Button, Card, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {Link} from "react-router-dom";


/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        textAlign: "center"
    },
    root_alternative: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 *
 *
 * @param requestDetail
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeContractInitialSetting = (
    {
        requestDetail,
        t
    }) => {
    const classes = useStyles();
    return (
        <Box marginTop={4}>
            <Card>
                <Box className={clsx(classes.root)}>
                    {/** 新規+エビスログID未設定（アカウント設定前）の場合 */}
                    <Typography variant="h4">{t('requests.detail.initial_setting.incomplete.title')}</Typography>
                    <Typography
                        variant="body1">{t('requests.detail.initial_setting.incomplete.additional_information')}</Typography>
                    <Link to={'/requests/' + requestDetail.uuid + '/setting'}>
                        <Button variant="contained" color="primary"
                                className={clsx(classes.button)}>{t('requests.detail.initial_setting.incomplete.button')}</Button>
                    </Link>
                </Box>
            </Card>
        </Box>
    );
}

export default ChangeContractInitialSetting;
