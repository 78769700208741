import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// アカウント基本情報
//-------------------------------------------------

export function loadAccountBasicInformation(access_token, account_id) {
    console.log(access_token, account_id);
    return {
        type: types.REQUEST_ACCOUNT_BASIC_INFORMATION,
        payload: {
            access_token: access_token,
            account_id: account_id
        }
    }
}

/**
 * 基本情報取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadingAccountBasicInformation() {
    return {
        type: types.REQUEST_ACCOUNT_BASIC_INFORMATION_LOADING
    }
}

/***
 * 基本情報覧取得リクエスト（成功）
 * @param {Object} basicInformation 取得した基本情報
 * @returns {{payload, type: string}}
 */
export function loadedAccountBasicInformationSuccess(basicInformation) {
    return {
        type: types.REQUEST_ACCOUNT_BASIC_INFORMATION_SUCCESS,
        payload: {
            basicInformation: basicInformation.data
        }
    }
}

/***
 * 基本情報取得リクエスト（失敗)
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function loadedAccountBasicInformationFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_BASIC_INFORMATION_FAILURE,
        payload: {
            error: error
        }
    }
}

const actions = {
    loadAccountBasicInformation,
    loadingAccountBasicInformation,
    loadedAccountBasicInformationSuccess,
    loadedAccountBasicInformationFailure
};
export default actions;

