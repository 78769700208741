/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないrequestsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const GET_REQUESTS_API_PATH = "/accounts/{UID}/requests/";
export const GET_REQUEST_DETAIL_API_PATH = "/accounts/{UID}/requests/{RID}"
export const GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH = "/accounts/{UID}/requests/{RGID}/{RID}/quotes/{QID}"
export const GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD = "/accounts/{UID}/requests/{RGID}/{RID}/quotes/{QID}/pdf"

export const GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD_DATA = "/accounts/{UID}/requests/{RGID}/{RID}/quotes/{QID}/pdf_data"
export const PUT_REQUEST_APPLY = "/accounts/{UID}/requests/{RGID}/{RID}/quotes/{QID}/apply"
export const PUT_REQUEST_EBIS_SETTING_INFORMATION = "/accounts/{UID}/requests/{RGID}/setting"
export const PUT_REQUEST_DETAIL_CANCEL = "/accounts/{UID}/requests/{RID}/cancel"
export const GET_CONFIRM_LOG_ID = "/accounts/{UID}/requests/{RGID}/setting/validate"

export const POST_REQUEST_RESEND_MAIL = "/accounts/{UID}/requests/{RGID}/{RID}/send_ad_owners_mail";
export const POST_REQUEST_RESEND_MAIL_CHECK = "/accounts/{UID}/requests/{RGID}/{RID}/is_bounce"

export const PARTNER_ADVERT_INFO_HTML_TERMS_SERVICE = "/segments/legal/adebis/PARTNER_ADVERT_INFO.html"
export const TERMS_OF_SERVICE_NEW = "/segments/legal/adebis/NOTES_NEW_PLAN.html"
export const TERMS_OF_SERVICE_OLD = "/segments/legal/adebis/NOTES_OLD_PLAN.html"
export const CAPPICO_TERMS_OF_SERVICE = "/segments/legal/capico/PLAN.html"

export const SPOT_TERMS_OF_SERVICE = "/segments/legal/spot/PLAN.html"
export const SPOT_GSP_TERMS_OF_SERVICE = "/segments/legal/spot/GSP_PAIED_PLAN.html"

