import React, {useState} from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../../Page";
import clsx from "clsx";
import {GetAppRounded} from "@material-ui/icons";
import {Link} from "react-router-dom";
import CorrespondenceQuoteSegment from "./segments/Correspondence";
import BasicInformationQuoteSegment from "./segments/BasicInformation";
import NotesQuoteSegment from "./segments/Notes";
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import requestType from "../../../../../../state/utils/data/application_request_type_flat.json"
import requestStatus from "../../../../../../state/utils/data/application_request_status_flat.json"
import urlLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import QuotePDFViewer from "./segments/QuotePDFViewer";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestQuoteDetailView = (
    {
        managerProfile,
        requestGroupId,
        requestId,
        quoteId,
        requestQuoteLoading,
        requestQuote,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        pdfIsLoading,
        termsAndConditionsIsOpen,
        getAccessTokenSilently,
        downloadQuotePDF,
        requestDetail,
        pdfDataBlobPreview,
        pdfDataBlobPreviewLoading,
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestQuoteLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                <Link to={urlLinks.navigation.top.index.href}>
                                    <Typography color="textPrimary">{t('general.top')}</Typography>
                                </Link>
                                <Link to={urlLinks.navigation.requests.index.href}>
                                    <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                                </Link>
                                <Link to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestGroupId)}>
                                    <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                                </Link>
                                <Link to={urlLinks.navigation.requests.index.sub_links.detail.sub_links.quote.href.replace('{RGIDRID}', requestGroupId + '/' + requestId).replace('{QID}', quoteId)}>
                                    <Typography color="textPrimary">{t('requests.detail.quotes.title')}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Grid>

                        {/** PDFダウンロード */}
                        <Grid item xs={6} md={6} style={{textAlign: "right", marginTop: "-10px"}}>
                            <Button type={"button"} variant={"text"} disabled={pdfIsLoading}
                                    onClick={async () => {
                                        if (!empty(managerProfile)) {
                                            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                () => {
                                                    return getAccessTokenSilently();
                                                },
                                            );
                                            await downloadQuotePDF(access_token, managerProfile?.account?.uuid, requestGroupId, requestId, quoteId, requestQuote.number);
                                        }
                                    }}
                                    style={{
                                        border: "none",
                                        fontSize: 11
                                    }}><GetAppRounded/> {t('requests.detail.quotes.download_document')}</Button>

                        </Grid>
                        <Grid container alignItems={"flex-end"} alignContent={"flex-end"} justify={"flex-end"}>
                            <Grid item>
                                {pdfIsLoading === true &&
                                    <LinearProgress style={{width: 132, marginRight:10, marginTop:"-10px", float: "right"}} />
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    <Box mt={1}>
                        <Card>
                            {/** 宛先 */}
                            <CorrespondenceQuoteSegment
                                requestQuoteLoading={requestQuoteLoading}
                                quoteId={quoteId}
                                requestId={requestId}
                                requestDetail={requestDetail}
                                t={t}
                            />
                            <Divider/>
                            {/** 基本情報 */}
                            <BasicInformationQuoteSegment
                                t={t}
                                requestQuote={requestQuote}
                                requestId={requestId}
                                quoteId={quoteId}
                                requestDetail={requestDetail}   
                                requestQuoteLoading={requestQuoteLoading}
                            />
                            <Divider/>  
                            {!empty(pdfDataBlobPreview) && pdfDataBlobPreviewLoading === false &&
                                <QuotePDFViewer pdfDataBlobPreview={pdfDataBlobPreview} t={t} />
                            }
                            <Divider/>
                            <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                                <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                    {( requestQuote.status === 1
                                        && (requestDetail?.type === requestType.NEW || requestDetail?.type === requestType.CHANGE || requestDetail?.type === requestType.RENEW)
                                        && requestDetail?.status === requestStatus.WAIT_FOR_APPLY &&
                                        /** 本申込ボタン 本申込待ち + 新規契約(非移管) */
                                        <Link
                                            to={'/requests/' + requestGroupId + "/" + requestId + "/quotes/" + quoteId + '/apply'}>
                                            <Button variant={"contained"} style={{marginLeft: 15, fontWeight: 900}}
                                                    color={"primary"}>
                                                {t('requests.detail.quotes.main_application_link')}
                                            </Button>
                                        </Link>
                                    )}
                                </Grid> 
                            </Box>
                            <Divider/>
                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    );
}

export default RequestQuoteDetailView;
