import roles from '../../../../utils/data/roles'
/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /users 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */
/**
 * アカウント担当者登録バリデーション
 */
export const baseAccountContactUpsertValidation = {
    last_name: {
        presence: true,
        length: {maximum: 80}
    },
    first_name: {
        presence: true,
        length: {maximum: 40}
    },
    role: {
        presence: true,
    },
    work_phone: {
        eitherFieldRequired: {compareField: "mobile_phone"},
        length: {maximum: 40},
        presence: false,
        phone_validation: true,
    },
    mobile_phone: {
        length: {maximum: 40},
        presence: false,
        phone_validation: true,
    },
    department: {
        presence: false,
        length: {maximum: 40}
    }
}

/**
 * アカウント担当者登録バリデーション
 * @type {{email: {length: {maximum: number}, presence: boolean, email: boolean}}}
 */
export const accountContactInsertOnlyValidation = {
    email: {
        presence: true,
        length: {maximum: 80},
        email: true
    },
}
    
export const accountContactJapanAddressStoreValidation = {
    ...baseAccountContactUpsertValidation,
    postal_code1: {
        presence: true,
        numericality: {only_integer: true},
        length: {is: 3},
    },
    postal_code2: {
        presence: true,
        numericality: {only_integer: true},
        length: {is: 4},
    },
    state: {
        presence: true,
        length: {maximum: 80}
    },
    city: {
        presence: true,
        length: {maximum: 40}
    },
    address: {
        presence: true,
        length: {maximum: 255}
    }
}

export const accountContactForeignAddressStoreValidation = {
    ...baseAccountContactUpsertValidation,
    postal_foreign: {
        presence: true,
        length: {maximum: 10},
    },
    state: {
        presence: true,
        length: {maximum: 80}
    },
    city: {
        presence: true,
        length: {maximum: 40}
    },
    address: {
        presence: true,
        length: {maximum: 255}
    }
}

export const accountContactForeignAddressNoStateStoreValidation = {
    ...baseAccountContactUpsertValidation,
    postal_foreign: {
        presence: true,
        length: {maximum: 10},
    },
    city: {
        presence: true,
        length: {maximum: 40}
    },
    address: {
        presence: true,
        length: {maximum: 255}
    }
}
