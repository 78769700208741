import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router";
import {makeStyles} from "@material-ui/core";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {rootOperations} from "../../../../state/ducks/root";
import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import ManagerAccountServiceContractsView from "../../../components/manager/accounts/service_contracts/Page";

/**
 * コンテイナのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        managerDetailListLoading: state.managerAccount.managerDetailListLoading,
        managerDetailListError: state.managerAccount.managerDetailListError,
        managerDetailListData: state.managerAccount.managerDetailListData,

        managerDetailAccountData: state.managerAccount.managerDetailAccountData,

    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    saveContractNameToSidebarLink: managerAccountListOperations.saveContractNameToSidebarLink,

    mangerGetAccountDetail: managerAccountListOperations.mangerGetAccountDetail,
    managerLoginEvent: managerAccountListOperations.managerLoginEvent
}

/**
 * 管理者側のユーザー一覧コンテイナ
 *
 * @param {Object} managerProfile
 * @param loadSidebarItems
 * @param managerDetailListLoading
 * @param managerDetailListError
 * @param managerDetailListData
 * @param mangerGetAccountDetail
 * @param managerDetailAccountData
 * @param saveContractNameToSidebarLink
 * @param managerLoginEvent
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const _serviceContractContainer = (
    {
        managerProfile,
        loadSidebarItems,

        managerDetailListLoading,
        managerDetailListError,
        managerDetailListData,
        mangerGetAccountDetail,
        managerDetailAccountData,
        saveContractNameToSidebarLink,
        managerLoginEvent,
        t
    }) => {
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    // URL履歴
    let {id} = useParams();
    const classes = useStyles();

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    }
                );
                mangerGetAccountDetail(access_token, id);
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, mangerGetAccountDetail])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(managerDetailListData)) {
            saveContractNameToSidebarLink(managerDetailAccountData?.name)
        }
    }, [managerDetailAccountData, saveContractNameToSidebarLink, managerProfile, managerDetailListData])

    return (
        <ManagerAccountServiceContractsView
            classes={classes}
            managerDetailListLoading={managerDetailListLoading}
            managerDetailListError={managerDetailListError}
            managerDetailListData={managerDetailListData}
            managerDetailAccountData={managerDetailAccountData}
            managerLoginEvent={managerLoginEvent}
            accountId={id}
            t={t}
        />
    )
};


/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */
const ManagerServiceContractContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_serviceContractContainer)

export default ManagerServiceContractContainer;
