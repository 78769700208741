/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */
// アカウントのユーザー一覧データの取得
export const MANAGER_REQUEST_ACCOUNT_USERS = "MANAGER_REQUEST_ACCOUNT_USERS";
export const MANAGER_REQUEST_ACCOUNT_USERS_LOADING = "MANAGER_REQUEST_ACCOUNT_USERS_LOADING";
export const MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS = "MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS";
export const MANAGER_REQUEST_ACCOUNT_USERS_FAILURE = "MANAGER_REQUEST_ACCOUNT_USERS_FAILURE";

export const MANAGER_ACCOUNT_LOGIN_AS_USER = "MANAGER_ACCOUNT_LOGIN_AS_USER";
export const MANAGER_ACCOUNT_LOGIN_AS_USER_LOADING = "MANAGER_ACCOUNT_LOGIN_AS_USER_LOADING";
export const MANAGER_ACCOUNT_LOGIN_AS_USER_SUCCESS = "MANAGER_ACCOUNT_LOGIN_AS_USER_SUCCESS";
export const MANAGER_ACCOUNT_LOGIN_AS_USER_FAILURE = "MANAGER_ACCOUNT_LOGIN_AS_USER_FAILURE";

export const MANAGER_ACCOUNT_USER_TOGGLE_REGISTER = "MANAGER_ACCOUNT_USER_TOGGLE_REGISTER";

export const MANAGER_ACCOUNT_USER_ADD_USER = "MANAGER_ACCOUNT_USER_ADD_USER";
export const MANAGER_ACCOUNT_USER_UPDATE_USER = "MANAGER_ACCOUNT_USER_UPDATE_USER";
export const MANAGER_ACCOUNT_USER_ADD_USER_LOADING = "MANAGER_ACCOUNT_USER_ADD_USER_LOADING";
export const MANAGER_ACCOUNT_USER_ADD_USER_SUCCESS = "MANAGER_ACCOUNT_USER_ADD_USER_SUCCESS";
export const MANAGER_ACCOUNT_USER_ADD_USER_FAILURE = "MANAGER_ACCOUNT_USER_ADD_USER_FAILURE";

export const MANAGER_ACCOUNT_USER_DELETE_USER_TOGGLE = "MANAGER_ACCOUNT_USER_DELETE_USER_TOGGLE";

export const MANAGER_ACCOUNT_USER_DELETE_USER = "MANAGER_ACCOUNT_USER_DELETE_USER";
export const MANAGER_ACCOUNT_USER_DELETE_USER_LOADING = "MANAGER_ACCOUNT_USER_DELETE_USER_LOADING";
export const MANAGER_ACCOUNT_USER_DELETE_USER_SUCCESS = "MANAGER_ACCOUNT_USER_DELETE_USER_SUCCESS";
export const MANAGER_ACCOUNT_USER_DELETE_USER_FAILURE = "MANAGER_ACCOUNT_USER_DELETE_USER_FAILURE";

export const MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER = "MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER";
export const MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_LOADING = "MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_LOADING";
export const MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_SUCCESS = "MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_SUCCESS";
export const MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_FAILURE = "MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_FAILURE";

export const MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_CLOSE_MODAL = "MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_CLOSE_MODAL";

export const MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL = "MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL";
export const MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_LOADING = "MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_LOADING";
export const MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS = "MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS";
export const MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_FAILURE = "MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_FAILURE";

export const MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL = "MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL";



const types = {
    MANAGER_REQUEST_ACCOUNT_USERS,
    MANAGER_REQUEST_ACCOUNT_USERS_LOADING,
    MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS,
    MANAGER_REQUEST_ACCOUNT_USERS_FAILURE,

    MANAGER_ACCOUNT_LOGIN_AS_USER,
    MANAGER_ACCOUNT_LOGIN_AS_USER_LOADING,
    MANAGER_ACCOUNT_LOGIN_AS_USER_SUCCESS,
    MANAGER_ACCOUNT_LOGIN_AS_USER_FAILURE,

    MANAGER_ACCOUNT_USER_TOGGLE_REGISTER,
    
    MANAGER_ACCOUNT_USER_ADD_USER,
    MANAGER_ACCOUNT_USER_UPDATE_USER,
    MANAGER_ACCOUNT_USER_ADD_USER_LOADING,
    MANAGER_ACCOUNT_USER_ADD_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_ADD_USER_FAILURE,
    
    MANAGER_ACCOUNT_USER_DELETE_USER_TOGGLE,
    MANAGER_ACCOUNT_USER_DELETE_USER,
    MANAGER_ACCOUNT_USER_DELETE_USER_LOADING,
    MANAGER_ACCOUNT_USER_DELETE_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_DELETE_USER_FAILURE,
   
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_LOADING,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_FAILURE,
  
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_CLOSE_MODAL,
  
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_LOADING,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_FAILURE,
  
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL,
    
};
export default types;
