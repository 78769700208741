import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json"
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {useHistory, useParams} from "react-router";
import {managerRequestOperations} from "../../../../state/ducks/manager/managerRequests";
import ManagerRequestDetailView from "../../../components/manager/requests/detail/ManagerPage";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        managerRequestDetail: state.managerRequest.managerRequestDetail,
        managerRequestDetailLoading: state.managerRequest.managerRequestDetailLoading,

        isManagerCancelConfirmationOpen: state.managerRequest.isManagerCancelConfirmationOpen,
        managerCancelRequestLoading: state.managerRequest.managerCancelRequestLoading,
        managerCancelRequestError: state.managerRequest.managerCancelRequestError,

        isManagerCancelConfirmedModalOpen: state.managerRequest.isManagerCancelConfirmedModalOpen,

        isManagerAcceptTerminatedModalOpen: state.managerRequest.isManagerAcceptTerminatedModalOpen,
        isManagerAcceptTerminateConfirmationOpen: state.managerRequest.isManagerAcceptTerminateConfirmationOpen,

        related_quotes: state.managerRequest.managerRequestDetail?.related_quotes,

        managerTerminateRequestLoading: state.managerRequest.managerTerminateRequestLoading,
        managerTerminateRequestError: state.managerRequest.managerTerminateRequestError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailLoad: managerRequestOperations.managerRequestsDetailLoad,

    toggleCancelConfirmation: managerRequestOperations.toggleCancelConfirmation,
    cancelRequestSend: managerRequestOperations.cancelRequestSend,

    toggleApproveTerminateRequest: managerRequestOperations.toggleApproveTerminateRequest,
    approveTerminateRequest: managerRequestOperations.approveTerminateRequest

}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _managerRequestsDetailContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestsDetailLoad,
        managerRequestDetail,
        managerRequestDetailLoading,

        isManagerCancelConfirmationOpen,
        toggleCancelConfirmation,

        managerCancelRequestLoading,
        managerCancelRequestError,
        cancelRequestSend,

        isManagerCancelConfirmedModalOpen,

        isManagerAcceptTerminatedModalOpen,
        isManagerAcceptTerminateConfirmationOpen,
        toggleApproveTerminateRequest,
        approveTerminateRequest,
        managerTerminateRequestLoading,
        managerTerminateRequestError,

        related_quotes,


        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rgid} = useParams();
    const history = useHistory();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailLoad(access_token, rgid)
            })()
        }
    }, [rgid, getAccessTokenSilently, requestsDetailLoad, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.requests)
    }, [loadSidebarItems])
    return (
        <ManagerRequestDetailView
            managerProfile={managerProfile}
            requestGroupId={rgid}
            requestDetailLoading={managerRequestDetailLoading}
            requestDetail={managerRequestDetail}
            isManagerCancelConfirmationOpen={isManagerCancelConfirmationOpen}
            toggleCancelConfirmation={toggleCancelConfirmation}
            managerCancelRequestLoading={managerCancelRequestLoading}
            managerCancelRequestFailed={managerCancelRequestError}
            cancelRequestSend={cancelRequestSend}
            isManagerCancelConfirmedModalOpen={isManagerCancelConfirmedModalOpen}
            t={t}
            history={history}

            isManagerAcceptTerminatedModalOpen={isManagerAcceptTerminatedModalOpen}
            isManagerAcceptTerminateConfirmationOpen={isManagerAcceptTerminateConfirmationOpen}
            toggleApproveTerminateRequest={toggleApproveTerminateRequest}
            approveTerminateRequest={approveTerminateRequest}
            managerTerminateRequestLoading={managerTerminateRequestLoading}
            managerTerminateRequestError={managerTerminateRequestError}
            related_quotes={related_quotes}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerRequestsDetailContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerRequestsDetailContainer)

export default ManagerRequestsDetailContainer;
