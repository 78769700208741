import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import clsx from "clsx";
import {empty} from "../../../../../state/utils/Common";
import ManagerEstimatesListContainer
    from "../../../../containers/manager/requests/detail/ManagerEstimatesListContainer";
import requestTypes from "../../../../../state/utils/data/application_request_type_flat.json"
import requestStatus from "../../../../../state/utils/data/application_request_status_flat.json"
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../atoms/AlertModal";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import requestType from "../../../../../state/utils/data/application_request_type_flat.json";
import InitialSetting from "./block/InitialSetting";
import RequestBlock from "./block/RequestBlock";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerRequestDetailView = (
    {
        requestGroupId,
        managerProfile,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,
        resendMailModalOpen,
        resendMailLoading,
        resendMailError,
        openResendModal,
        closeResendModal,
        sendResendMail,
        sendMailValidation,
        
        
        requestDetailLoading,
        requestDetail,

        isManagerCancelConfirmationOpen,
        toggleCancelConfirmation,

        managerCancelRequestLoading,
        managerCancelRequestFailed,
        cancelRequestSend,
        isManagerCancelConfirmedModalOpen,
        history,

        isManagerAcceptTerminatedModalOpen,
        isManagerAcceptTerminateConfirmationOpen,
        toggleApproveTerminateRequest,
        approveTerminateRequest,

        managerTerminateRequestLoading,
        managerTerminateRequestError,

        related_quotes,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.title')}
        >
            {requestDetailLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{t('requests.detail.title')}</Typography>
                            <Grid container alignContent={"center"} alignItems={"center"}>
                                <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                                    <Breadcrumbs separator={">"} style={{color: "#000000"}} aria-label="breadcrumb">
                                        <Link to={managerUrlLinks.navigation.requests.index.href}>
                                            <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                                        </Link>
                                        <Link
                                            to={managerUrlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestGroupId)}>
                                            <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                                        </Link>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                            {requestDetail.cancelable &&
                                /** キャンセル関係 */
                                <Box alignContent={"end"} style={{float: "right"}}>
                                    <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                        {/** キャンセル期限日 */}
                                        {requestDetail.cancelable && !empty(requestDetail?.cancel_limit_at) &&
                                            <Typography
                                                variant="body1">{t('requests.detail.request_cancel_final_date')}：{requestDetail.cancel_limit_at}</Typography>
                                        }
                                        {requestDetail.cancelable && !empty(requestDetail?.cancel_limit_at) &&
                                            /** キャンセル確定ボタン＋モーダル */
                                            /** 本申込完了移行～アクティベーション待ちまでキャンセル可能 */
                                            <>
                                                    <Button variant={"outlined"}
                                                            onClick={() => toggleCancelConfirmation(true)}
                                                            style={{marginLeft: 15, fontWeight: 900}}
                                                            color={"primary"}>
                                                        {t('requests.detail.request_cancel_application')}
                                                    </Button>
                                                    <ConfirmationModal
                                                        isOpen={isManagerCancelConfirmationOpen}
                                                        setClose={() => toggleCancelConfirmation(false)}
                                                        confirmationTitle={t('requests.detail.request_cancel_application')}
                                                        cancelText={t('actions.cancel')}
                                                        buttonText={t('actions.ok')}
                                                        loadingText={t('actions.sending')}
                                                        isLoading={managerCancelRequestLoading}
                                                        error={managerCancelRequestFailed}
                                                        id={requestDetail.uuid}
                                                        profile={managerProfile}
                                                        event={(request_id, access_token, account_uuid) => cancelRequestSend(access_token, request_id, account_uuid)}
                                                    />
                                                    <AlertModal
                                                        isOpen={isManagerCancelConfirmedModalOpen}
                                                        event={() => history.push('/manager/requests')}
                                                        buttonText={t('actions.ok')}
                                                        confirmationTitle={t('requests.detail.application_cancelled')}
                                                    />
                                            </>
                                        }
                                    </Grid>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Typography variant="h4"> {t('requests.detail.information')}</Typography>
                                <Grid container style={{marginTop: 26}}>
                                    {/** 申込担当者 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_manager')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.application_user_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込年月日 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_date')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.application_at}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込種別 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_category')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.type_name}</Typography>
                                    </Grid>
                                </Grid>
                                {(requestDetail.type === requestType.NEW) && !empty(requestDetail?.usage_category) &&
                                    <Grid container style={{marginTop: 15}}>
                                        <Grid item xs={2} xl={1}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('requests.detail.usage_category')}</Typography>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <Typography variant="body1">{requestDetail?.usage_category}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込担当者 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.service_name')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{requestDetail.service_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込ステータス */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_status')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.status_name}</Typography>
                                    </Grid>
                                </Grid>
                                {requestDetail.status === requestStatus.CANCELED &&
                                    <Grid container style={{marginTop: 15}}>
                                        {/** 申込手続き完了日 */}
                                        <Grid item xs={2} xl={1}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('requests.detail.request_cancel_day')}</Typography>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <Typography variant="body1">
                                                {requestDetail.cancelled_at}
                                                {!empty(requestDetail?.cancel_user_name) &&
                                                    <>
                                                        ({requestDetail.cancel_user_name})
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Box>
                        </Card>

                        {/** 初期設定（管理者画面はebis_log_idが設定されていれば常に表示） */}
                        <InitialSetting
                            t={t}
                            requestDetail={requestDetail}
                        >
                        </InitialSetting>

                        {!empty(requestDetail.requests) && requestDetail.requests.map((request, index) => (
                            <RequestBlock
                                managerProfile={managerProfile}
                                request={request}
                                requestGroupId={requestGroupId}
                                requestId={request?.uuid}
                                resendEmailCheckLoading={resendEmailCheckLoading}
                                resendEmailCheckPassed={resendEmailCheckPassed}
                                resendEmailCheckError={resendEmailCheckError}

                                resendMailModalOpen={resendMailModalOpen}
                                resendMailLoading={resendMailLoading}
                                resendMailError={resendMailError}
                                openResendModal={openResendModal}
                                closeResendModal={closeResendModal}
                                sendResendMail={sendResendMail}
                                sendMailValidation={sendMailValidation}

                                t={t}
                            />
                        ))}
                    </Box>
                    <Box className={clsx(classes.root)} paddingY={2} paddingX={2} alignContent="middle">
                        <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                            {(requestDetail.type === requestTypes.CANCEL && requestDetail.status === requestStatus.APPLIED && empty(requestDetail.cancel_accepted_at) === true && requestDetail.cancel_acceptable) &&
                                <>
                                    <Button style={{marginLeft: 20}}
                                            onClick={() => toggleApproveTerminateRequest(true)}
                                            variant={"contained"}
                                            color={"primary"}>
                                        解約受理
                                    </Button>
                                    <ConfirmationModal
                                        isOpen={isManagerAcceptTerminateConfirmationOpen}
                                        setClose={() => toggleApproveTerminateRequest(false)}
                                        confirmationTitle={t('requests.manager.detail.quote.detailed.confirm_terminate_message')}
                                        cancelText={t('actions.cancel')}
                                        buttonText={t('actions.ok')}
                                        loadingText={t('actions.sending')}
                                        isLoading={managerTerminateRequestLoading}
                                        error={managerTerminateRequestError}
                                        id={requestDetail.uuid}
                                        event={(id, access_token, uuid) => approveTerminateRequest(access_token, id)}
                                    />
                                    <AlertModal
                                        isOpen={isManagerAcceptTerminatedModalOpen}
                                        event={() => history.push('/manager/requests')}
                                        buttonText={t('actions.ok')}
                                        confirmationTitle={t('requests.manager.detail.quote.detailed.confirmed_terminate_message')}
                                    />
                                </>
                            }
                        </Grid>
                    </Box>
                </Container>
            }
        </Page>
    );
}

export default ManagerRequestDetailView;
