import React from 'react';
import {Field, Form} from 'react-final-form';
import {Box, Button, Chip, FormLabel, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import FinalFormTextField from "../../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../../state/utils/Validation";
import {empty, mergeWithDefaultForm} from "../../../../../../state/utils/Common";
import globalTheme from "../../../../../theme/globalTheme";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import EditableCheck from "../../../../../atoms/EditableCheck";
import FinalFormSelect from "../../../../../atoms/FinalFormSelect";
import {roles} from "../../../../../../state/utils/data/roles";
import states from "../../../../../../state/utils/data/states";
import foreign_states from "../../../../../../state/utils/data/foreign_states";
import FormForPostalCode from "../../../../../atoms/FormForPostalCode";
import FinalFormFieldIndependentError from "../../../../../atoms/FinalFormFieldIndependentError";
import {
    accountContactForeignAddressNoStateStoreValidation,
    accountContactForeignAddressStoreValidation, accountContactInsertOnlyValidation,
    accountContactJapanAddressStoreValidation
} from "../../../../../../state/ducks/user/accounts/contacts/validation"
import {accountContactsForms} from "../../../../../../state/ducks/user/accounts/contacts";

/**
 * コンポーネントスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const AccountContactModal = (
    {
        isOpen,
        setClose,
        activeAccountContact,
        accountContactRegisterRequest,
        accountContactEditRequest,
        accountContactLoading,
        accountContactError,
        profile,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // アプリケーションテーマ
    const globalStyle = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();

    let isJapan = profile.is_japan;
    let country = profile.account.country;
    if (!empty(activeAccountContact)) {
        isJapan = activeAccountContact.country === 'Japan';
        country = activeAccountContact.country;
    }
    console.log(activeAccountContact);

    let selectedStates = states.states;
    let selectedValidation = accountContactJapanAddressStoreValidation;
    let form = accountContactsForms.createAccountContactStateForm;
    if (!isJapan) {
        form = accountContactsForms.createAccountContactNoStateForm;
        const countryName = Object.keys(foreign_states);
        selectedStates = null;
        selectedValidation = accountContactForeignAddressNoStateStoreValidation;
        if (countryName.includes(country)) {
            selectedStates = foreign_states[country];
            selectedValidation = accountContactForeignAddressStoreValidation;
            form = accountContactsForms.createAccountContactStateForm;
        }
    }
    
    if(empty(activeAccountContact)) {
        selectedValidation = { ...selectedValidation, ...accountContactInsertOnlyValidation }  
    }

    return (
        <Modal open={isOpen}
               onClose={() => setClose(false)}
               disableBackdropClick
               className={accountContactLoading ? globalStyle.loading : undefined}
        >
            <div className={globalStyle.modal} style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{!empty(activeAccountContact) ? t('account_contacts.update.title') : t('account_contacts.create.title')}</Typography>
                <Box style={{padding: "0 10px", maxHeight: '70vh', overflowY: 'scroll', overflowX: "hidden"}}>
                    <Form
                        onSubmit={async (e) => {
                            e = mergeWithDefaultForm(e, form)

                            if (isJapan) {
                                e.postal_code = e.postal_code1 + '-' + e.postal_code2
                            } else {
                                e.postal_code = e.postal_foreign
                            }

                            e.country = profile.account.country
                            if (!empty(activeAccountContact)) {
                                e.country = activeAccountContact.country
                            }

                            delete e.postal_code1;
                            delete e.postal_code2;

                            if (!selectedStates) {
                                delete e.state;
                            }

                            // 現在の選択したユーザーの存在があるか上で編集リクエストを投げる。
                            // いない場合、新規ユーザーリクエストを投げる
                            if (empty(activeAccountContact)) {
                                // 新規ユーザーリクエスト
                                if (!accountContactLoading) {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    await accountContactRegisterRequest(access_token, profile?.account?.uuid, e);
                                }
                            } else {
                                // 更新ユーザーリクエスト
                                if (!accountContactLoading) {
                                    e.email = activeAccountContact.email;
                                    e.uuid = activeAccountContact.uuid;
                                    e.updated_at = activeAccountContact.updated_at;
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    await accountContactEditRequest(access_token, profile?.account?.uuid, activeAccountContact.uuid, e);
                                }
                            }
                        }}
                        // ここでフォームデータを妥当性確認し、キーを変換します。
                        validate={e => {
                            return translate(t, Validation({...e}, selectedValidation))
                        }}
                        // （編集モード）最初のバリュー
                        initialValues={{
                            last_name: !empty(activeAccountContact) ? activeAccountContact.last_name : "",
                            first_name: !empty(activeAccountContact) ? activeAccountContact.first_name : "",
                            role: !empty(activeAccountContact) ? activeAccountContact.role : "",
                            work_phone: !empty(activeAccountContact) ? activeAccountContact.work_phone : "",
                            mobile_phone: !empty(activeAccountContact) ? activeAccountContact.mobile_phone : "",
                            department: !empty(activeAccountContact) ? activeAccountContact.department : "",
                            postal_code1: (!empty(activeAccountContact) && activeAccountContact.postal_code.includes('-')) ? activeAccountContact.postal_code.split('-')[0] : "",
                            postal_code2: (!empty(activeAccountContact) && activeAccountContact.postal_code.includes('-')) ? activeAccountContact.postal_code.split('-')[1] : "",
                            postal_foreign: !empty(activeAccountContact) ? activeAccountContact.postal_code : "",
                            state: !empty(activeAccountContact) ? activeAccountContact.state : "",
                            city: !empty(activeAccountContact) ? activeAccountContact.city : "",
                            address: !empty(activeAccountContact) ? activeAccountContact.address : "",
                        }}
                        // ここでは、フォームのレンダリングと制御を行います
                        // エラー処理やダブルクリック防止などはここで行います
                        render={({handleSubmit, form, pristine, invalid, values}) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            required
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            size={"small"}
                                            name="last_name"
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('account_contacts.last_name')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            required
                                            size={"small"}
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            value={!empty(activeAccountContact) && activeAccountContact.first_name}
                                            name="first_name"
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('account_contacts.first_name')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            fullWidth
                                            required
                                            size={"large"}
                                            name="role"
                                            component={FinalFormSelect}
                                            options={roles}
                                            type="text"
                                            label={t('account_contacts.status')}
                                        />
                                    </Grid>
                                    {empty(activeAccountContact) ? (
                                        <Grid item xs={12}>
                                            <Field
                                                name="email"
                                                fullWidth
                                                required
                                                loadingOnDisable={accountContactLoading}
                                                disabled={accountContactLoading}
                                                size={"small"}
                                                value={!empty(activeAccountContact) && activeAccountContact.email}
                                                component={FinalFormTextField}
                                                type="email"
                                                label={t('account_contacts.email_address')}
                                            />
                                        </Grid>
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{fontWeight: "bold", top: '40px'}}>
                                                    {t('account_contacts.email_address')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{top: '40px'}}>
                                                    {activeAccountContact.email}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <FormLabel>
                                            {t('general.phone_number')}
                                            <Chip label={"必須"} size="small"
                                                  style={{
                                                      backgroundColor: "#F4B069",
                                                      color: "#FFFFFF",
                                                      borderRadius: 5,
                                                      fontWeight: 500
                                                  }}
                                                  className={globalStyle.chip}/>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="work_phone"
                                            fullWidth
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.work_phone')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="mobile_phone"
                                            fullWidth
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.mobile_phone')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{color: "#7E7E7E"}}>
                                            {t('service_contracts.detail.phone_warning')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: "bold", top: '40px'}}>
                                            {t('addresses.country')}
                                        </Typography>
                                    </Grid>
                                    {empty(activeAccountContact) &&
                                        <Grid item xs={12}>
                                            <Typography style={{top: '40px'}}>
                                                {profile.account.country}
                                            </Typography>
                                        </Grid>
                                    }
                                    {!empty(activeAccountContact) &&
                                        <Grid item xs={12}>
                                            <Typography style={{top: '40px'}}>
                                                {activeAccountContact.country}
                                            </Typography>
                                        </Grid>
                                    }
                                    {isJapan &&
                                        <>
                                            <Grid item xs={4}>
                                                <Field
                                                    name="postal_code1"
                                                    fullWidth
                                                    required
                                                    size={"small"}
                                                    loadingOnDisable={accountContactLoading}
                                                    disabled={accountContactLoading}
                                                    component={FormForPostalCode}
                                                    type="tel"
                                                    label={t('addresses.postal_code')}
                                                />
                                            </Grid>
                                            <Grid item style={{paddingLeft: 0, paddingRight: 0}}>
                                                <Typography style={{position: 'relative', top: '40px'}}>
                                                    {t('address.postal_code_hyphen')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Field
                                                    style={{paddingTop: 6}}
                                                    name="postal_code2"
                                                    fullWidth
                                                    size={"small"}
                                                    loadingOnDisable={accountContactLoading}
                                                    disabled={accountContactLoading}
                                                    component={FormForPostalCode}
                                                    type="tel"
                                                    label="　"
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {!isJapan &&
                                        <Grid item xs={6}>
                                            <Field
                                                name="postal_foreign"
                                                fullWidth
                                                required
                                                size={"small"}
                                                loadingOnDisable={accountContactLoading}
                                                disabled={accountContactLoading}
                                                component={FormForPostalCode}
                                                type="text"
                                                label={t('addresses.postal_code')}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography style={{color: "#7E7E7E"}}>
                                            {t('address.postal_code_warning')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}
                                          style={{paddingTop: 0, paddingLeft: 11, paddingRight: 16, paddingBottom: 0}}>
                                        <FinalFormFieldIndependentError name={"postal_code1"}/>
                                    </Grid>
                                    <Grid item xs={12}
                                          style={{paddingTop: 0, paddingLeft: 11, paddingRight: 16, paddingBottom: 0}}>
                                        <FinalFormFieldIndependentError name={"postal_code2"}/>

                                    </Grid>
                                    {selectedStates !== null &&
                                        <Grid item xs={6}>
                                            <Field
                                                name="state"
                                                fullWidth
                                                required
                                                size={"small"}
                                                loadingOnDisable={accountContactLoading}
                                                disabled={accountContactLoading}
                                                component={FinalFormSelect}
                                                options={selectedStates}
                                                label={t('addresses.state')}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Field
                                            name="city"
                                            fullWidth
                                            required
                                            size={"small"}
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('addresses.city')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="address"
                                            fullWidth
                                            required
                                            size={"small"}
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('addresses.address')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name="department"
                                            fullWidth
                                            loadingOnDisable={accountContactLoading}
                                            disabled={accountContactLoading}
                                            size={"small"}
                                            component={FinalFormTextField}
                                            type="text"
                                            label={t('service_contracts.detail.modal.department')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={globalStyle.error} style={{whiteSpace: "pre-wrap"}}>
                                            {t(accountContactError?.translation_error_key)}
                                        </Typography>
                                    </Grid>
                                    <Grid item container spacing={3} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                variant="contained"
                                                disabled={accountContactLoading}
                                                onClick={() => setClose(false)}
                                            >
                                                {t('actions.cancel')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <EditableCheck>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={invalid || pristine || accountContactLoading}
                                                >
                                                    {accountContactLoading ? t('actions.sending') : !empty(activeAccountContact) ? t('actions.change') : t('actions.add')}
                                                </Button>
                                            </EditableCheck>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>)}
                    />
                </Box>
            </div>
        </Modal>
    )
};

export default AccountContactModal;
