import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../theme/globalTheme";

export const MailRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container alignItems="center"  key={props.data.related_mail[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 8,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={1}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].id}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].quote_number}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].send_at}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].email}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].company_name}<br/>
                    {props.data.related_mail[props.index].contact_name}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].type_name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.related_mail[props.index].user_name}
                </Typography>
            </Grid>
        </Grid>
    )
}
