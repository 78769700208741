import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {empty} from "../../state/utils/Common";
import manager_roles from "../../state/utils/data/application_manager_user_roles_flat.json"

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {}

/**
 * 編集可能チェック
 *
 * @returns {JSX.Element}
 * @constructor
 */
const _restrictUserId = (
    {
        managerProfile,
        children,
        id
    }
) => {
    
    if(!empty(managerProfile) && managerProfile?.id !== id) {
        return (children)
    }
    return (<></>)
};

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RestrictUserId = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_restrictUserId)

export default RestrictUserId;
