/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /service_contracts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */
import services from "../../../../../state/utils/data/services.json";

/**
 * 連絡先妥当性確認
 */
export const terminateServiceContractValidation = {
    service_name: {
        presence: true,
    },

    ADEBiS_cancel_prefferd_ym: {
        presenceIf: {
            field: "service_name",
            operator: "IN",
            value: services.ADEBIS,
            errorKey: "ebis_require_date_error",
        },
        datetime: {
            dateOnly: true
        }
    },

    cancel_reasons1: {
        presence: true,
        typeOfArray: {
            numericality: true
        }
    },
    cancel_reason2: {
        presence: false
    },
    cancel_reason3: {
        presence: false
    },
}

export const terminateServiceContractWithoutPreferredDay = {
    service_name: {
        presence: true,
    },
    
    cancel_reasons1: {
        presence: true,
        typeOfArray: {
            numericality: true
        }
    },
    cancel_reason2: {
        presence: false
    },
    cancel_reason3: {
        presence: false
    },
}
