import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
// 担当者一覧取得
const loadAccountContacts = actions.loadAccountContacts;
// 担当者詳細取得
const loadAccountContactDetails = actions.loadAccountContactDetails;

const toggleAccountContactRegistrationModal = actions.toggleAccountContactRegistrationModal;
const toggleAccountContactUpdateModal = actions.toggleAccountContactUpdateModal;
const toggleAccountContactDeleteModal = actions.toggleAccountContactDeleteModal;
const toggleAccountContactDeleteCompleteModal = actions.toggleAccountContactDeleteCompleteModal;


const registerAccountContact = actions.registerAccountContact;
const updateAccountContact = actions.updateAccountContact;
const deleteAccountContact = actions.deleteAccountContact;

const operations = {
    loadAccountContacts,
    loadAccountContactDetails,
    
    
    toggleAccountContactRegistrationModal,
    toggleAccountContactUpdateModal,
    toggleAccountContactDeleteModal,
    toggleAccountContactDeleteCompleteModal,
    
    
    registerAccountContact,
    updateAccountContact,
    deleteAccountContact,
};
export default operations;
