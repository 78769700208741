import types from "./types";
import roles_flat from '../../../../utils/data/roles_flat.json';
/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// 単一のサービス契約の読み込み（現在のデータがない場合)
//-------------------------------------------------
/***
 * 単一のサービス契約のリクエスト
 * @param access_token - auth0アクセストークン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function requestSingleServiceContract(access_token, accountId, serviceContractId) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId
        }
    }
}

/***
 * 単一のサービス契約のリクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadSingleServiceContract() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOADING
    }
}

/***
 * 単一のサービス契約のリクエスト (成功）
 * @param serviceContract - 利用契約ID
 * @returns {{payload: {activeServiceContract}, type: string}}
 */
export function singleServiceContractSuccess(serviceContract) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS,
        payload: {
            activeServiceContract: serviceContract
        }
    }
}

/***
 * 単一のサービス契約のリクエスト（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function singleServiceContractFailed(e) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_FAILED,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// 他の担当者の読み込み
//-------------------------------------------------
/**
 * 他の担当者の読み込み
 * @param access_token
 * @param user_id
 * @returns {{payload: {access_token, user_id}, type: string}}
 */
export function loadOtherAccountContacts(access_token, user_id) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    }
}

/**
 * 他の担当者の読み込み（ロード中）
 * @returns {{type: string}}
 */
export function loadOtherAccountContactsLoading() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_LOADING
    }
}

/**
 * 他の担当者の読み込み（成功）
 * @param rawContactData
 * @returns {{type: string}}
 */
export function loadOtherAccountContactsSuccess(rawContactData) {
    // Break the raw data into separate arrays based on the value of the contact "role"
    let invoiceContacts = rawContactData.contacts.filter(contact => contact.role === roles_flat.INVOICE_ROLE || contact.role === roles_flat.CONTRACT_AND_INVOICE_ROLE);
    let contractContacts = rawContactData.contacts.filter(contact => contact.role === roles_flat.CONTRACT_ROLE || contact.role === roles_flat.CONTRACT_AND_INVOICE_ROLE);
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_SUCCESS,
        payload: {
            contractContacts: contractContacts,
            invoiceContacts: invoiceContacts
        }
    }
}

/**
 * 他の担当者の読み込み（失敗）
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function loadOtherAccountContactsFailed(e) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_FAILED,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// 担当者変更モーダル
//-------------------------------------------------
/**
 * 担当者変更モーダルの表示・非表示
 * @param {bool|null} forceState
 * @returns {{type: string}}
 */
export function toggleUpdateModal(forceState = null) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_TOGGLE_UPDATE_MODAL
    }
}

//-------------------------------------------------
// 担当者変更
//-------------------------------------------------

/**
 * 担当者変更
 * @param access_token
 * @param accountId
 * @param serviceContractId
 * @param data
 * @returns {{payload: {access_token, accountId, data, serviceContractId}, type: string}}
 */
export function updateServiceContractContact(access_token, accountId, serviceContractId, data) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId,
            data: data
        }
    }
}

/**
 * 担当者変更（ロード中）
 * @returns {{type: string}}
 */
export function updateServiceContractContactLoading() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_LOADING
    }
}

/**
 * 担当者変更（成功）
 * @returns {{type: string}}
 */
export function updateServiceContractContactSuccess() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_SUCCESS
    }
}

/**
 * 担当者変更（失敗）
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function updateServiceContractContactFailed(e) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_FAILED,
        payload: {
            error: e
        }
    }
}



const actions = {
    requestSingleServiceContract,
    loadSingleServiceContract,
    singleServiceContractSuccess,
    singleServiceContractFailed,

    loadOtherAccountContacts,
    loadOtherAccountContactsLoading,
    loadOtherAccountContactsSuccess,
    loadOtherAccountContactsFailed,
    toggleUpdateModal,

    updateServiceContractContact,
    updateServiceContractContactLoading,
    updateServiceContractContactFailed,
    updateServiceContractContactSuccess
};
export default actions;
