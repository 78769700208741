import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../theme/globalTheme";

export const InitialSettingRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container alignItems="center"  key={props.data.request[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 8,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.request[props.index].service_name}    
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.request[props.index].ebis_log_id}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.request[props.index].ebis_client_name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    { (props.data.request[props.index].ebis_host_name != null && 
                        props.data.request[props.index].ebis_host_name.length > 39) ? 
                        props.data.request[props.index].ebis_host_name.slice(0, 36) + "..."
                     : 
                        props.data.request[props.index].ebis_host_name
                    }
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.request[props.index].application_complete_at}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.request[props.index].application_complete_user_name}
                </Typography>
            </Grid>
        </Grid>
    )
}
