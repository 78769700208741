/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_ACCOUNT_BASIC_INFORMATION = 'REQUEST_ACCOUNT_BASIC_INFORMATION'
export const REQUEST_ACCOUNT_BASIC_INFORMATION_LOADING = 'REQUEST_ACCOUNT_BASIC_INFORMATION_LOADING'
export const REQUEST_ACCOUNT_BASIC_INFORMATION_SUCCESS = 'REQUEST_ACCOUNT_BASIC_INFORMATION_SUCCESS'
export const REQUEST_ACCOUNT_BASIC_INFORMATION_FAILURE = 'REQUEST_ACCOUNT_BASIC_INFORMATION_FAILURE'

const types = {
    REQUEST_ACCOUNT_BASIC_INFORMATION,
    REQUEST_ACCOUNT_BASIC_INFORMATION_LOADING,
    REQUEST_ACCOUNT_BASIC_INFORMATION_SUCCESS,
    REQUEST_ACCOUNT_BASIC_INFORMATION_FAILURE,
};

export default types;
