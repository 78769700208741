import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {rootOperations} from "../../../../../state/ducks/root";
import TerminatePage from "../../../../components/user/serviceContracts/terminate/TerminatePage";
import {serviceContractTerminateOperations} from "../../../../../state/ducks/user/service_contracts/terminate";
import {empty} from "../../../../../state/utils/Common";
import TerminateConfirmPage from "../../../../components/user/serviceContracts/terminate/ConfirmPage";
import TerminateCompletePage from "../../../../components/user/serviceContracts/terminate/CompletePage";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {serviceContractListOperations} from "../../../../../state/ducks/user/service_contracts";
import moment from "moment";
import services from "../../../../../state/utils/data/services.json";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        formData: state.serviceContractTerminate.formData,
        accepted_terms: state.serviceContractTerminate.accepted_terms,
        cancelRequestLoading: state.serviceContractTerminate.cancelRequestLoading,
        cancelRequestError: state.serviceContractTerminate.cancelRequestError,

        cancelReasonsData: state.serviceContractTerminate.cancelReasonsData,
        cancelReasonsLoading: state.serviceContractTerminate.cancelReasonsLoading,
        cancelReasonsError: state.serviceContractTerminate.cancelRequestError,

        termsHasReachedBottom: state.serviceContractTerminate.termsHasReachedBottom
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    saveTerminateFormData: serviceContractTerminateOperations.saveTerminateFormData,
    toggleTerms: serviceContractTerminateOperations.toggleTerms,
    sendCancelServiceContract: serviceContractTerminateOperations.sendCancelServiceContract,
    resetCancellationForm: serviceContractTerminateOperations.resetCancellationForm,
    getCancelContractReasons: serviceContractTerminateOperations.getCancelContractReasons,
    resetTerms: serviceContractTerminateOperations.resetTerms,
    saveContractNameToSidebarLink: serviceContractListOperations.saveContractNameToSidebarLink,
    scrolledTermsToBottom: serviceContractTerminateOperations.scrolledTermsToBottom
}

/*
利用契約 -> 連絡先コンテイナ
 */
const _terminateContainer = (
    {
        managerProfile,
        loadSidebarItems,
        formData,
        saveTerminateFormData,
        t,
        toggleTerms,
        resetTerms,
        accepted_terms,

        cancelRequestLoading,
        sendCancelServiceContract,
        saveContractNameToSidebarLink,

        getCancelContractReasons,
        cancelReasonsLoading,
        cancelReasonsData,
        resetCancellationForm,

        termsHasReachedBottom,
        scrolledTermsToBottom
    }) => {

    let {scid, action} = useParams();
    const history = useHistory();
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    useEffect(() => {
        resetTerms()
    }, [resetTerms, action])

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                getCancelContractReasons(access_token, managerProfile?.account?.uuid, scid)
            })()
            if(scid !== cancelReasonsData?.service_contract?.uuid && action !== 'confirm') {
                resetCancellationForm()
            }
        }
    }, [getAccessTokenSilently, scid, managerProfile, getCancelContractReasons])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(cancelReasonsData)) {
            saveContractNameToSidebarLink(cancelReasonsData?.service_account_name)
        }
    }, [managerProfile, cancelReasonsData, saveContractNameToSidebarLink])
    
    if (action === "confirm" && empty(formData)) {
        history.push('/service_contracts/' + scid + '/terminate/')
        return (<></>)
    }

    const SendSuccessCallBackAction = () => history.push('/service_contracts/' + scid + '/terminate/complete');
    
    let minDate =  Object.values(services).map(item => {
        if (!empty(cancelReasonsData) && cancelReasonsData?.[item] && cancelReasonsData?.[item]?.service_contract?.today) {
            let minDateMoment = moment(cancelReasonsData?.[item]?.service_contract?.today)
            if (minDateMoment.date() > process.env.REACT_APP_MYPAGE_APPLICATION_SAME_MONTH_APPLICATION_DEADLINE) {
                minDateMoment.add(1, 'months')
            }
            
            return { 'minDate' : minDateMoment.format('YYYY/MM/DD'), 'service_name': item};
        }
    });

    switch (action) {
        default:
            return (
                <TerminatePage
                    t={t}
                    response={cancelReasonsData}
                    service_contract_id={scid}
                    formData={formData}
                    cancelReasonsLoading={cancelReasonsLoading}
                    saveTerminateForm={saveTerminateFormData}
                    minDate={minDate}
                />);
        case ("confirm"):
            return (
                <TerminateConfirmPage
                    service_contract_id={scid}
                    t={t}
                    sendCancelServiceContract={sendCancelServiceContract}
                    SendSuccessCallBackAction={SendSuccessCallBackAction}
                    cancelReasonsData={cancelReasonsData}
                    cancelRequestLoading={cancelRequestLoading}
                    managerProfile={managerProfile}
                    terminateForm={formData}
                    toggleTerms={toggleTerms}
                    accepted_terms={accepted_terms}
                    termsHasReachedBottom={termsHasReachedBottom}
                    scrolledTermsToBottom={scrolledTermsToBottom}
                />
            );
        case("complete"):
            return (<TerminateCompletePage
                cancelReasonsData={cancelReasonsData}
                service_contract_id={scid}
                t={t}
            />);
    }
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const TerminateContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_terminateContainer)

export default TerminateContainer;
