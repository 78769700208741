import types from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractTransferState = {
    transferForm: {},
    transfer_other_party_informed: false,
    transfer_other_party_data_disclosure_agreement: false,

    transfer_accepted_terms: false,

    transferRequestLoading: false,
    transferRequestError: {},

    transferDataLoading: true,
    transferData: {},
    transferDataError: {},

    termsHasReachedBottom: false
}

export default function serviceContractTransferReducer(state = serviceContractTransferState, action) {
    switch (action.type) {
        case(types.SAVE_TRANSFER_FORM_INFORMATION):
            return {
                ...state,
                transferForm: action.payload.transferForm
            }
        case(types.TRANSFER_TOGGLE_TERMS_AND_CONDITIONS):
            return {
                ...state,
                transfer_accepted_terms: state.transfer_accepted_terms = !state.transfer_accepted_terms
            }
        case(types.TRANSFER_TERMS_REACHED_BOTTOM):
            return {
                ...state,
                termsHasReachedBottom: state.termsHasReachedBottom = true,
            }
        case(types.TRANSFER_RESET_TERMS_AND_CONDITIONS):
            return {
                ...state,
                transfer_accepted_terms: state.transfer_accepted_terms = false,
                termsHasReachedBottom: state.termsHasReachedBottom = false,
            }
        case(types.TRANSFER_TOGGLE_OTHER_PARTY_INFORMED):
            return {
                ...state,
                transfer_other_party_informed: state.transfer_other_party_informed = !state.transfer_other_party_informed,
            }
        case(types.TRANSFER_TOGGLE_OTHER_PARTY_DATA_DISCLOSURE_AGREEMENT):
            return {
                ...state,
                transfer_other_party_data_disclosure_agreement: state.transfer_other_party_data_disclosure_agreement = !state.transfer_other_party_data_disclosure_agreement
            }
        case(types.TRANSFER_CLEAR_OTHER_PARTY_AGREEMENTS):
            return {
                ...state,
                transfer_other_party_informed: state.transfer_other_party_informed = false,
                transfer_other_party_data_disclosure_agreement: state.transfer_other_party_data_disclosure_agreement = false
            }
        /*************
         * 契約移管データの取得リクエスト
         *************/
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = true,
                transferDataError: state.transferDataError = {},
                transferData: state.transferData = {},
            }
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = false,
                transferData: state.transferData = action.payload.reasons
            }
        case(types.GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED):
            return {
                ...state,
                transferDataLoading: state.transferDataLoading = false,
                transferDataError: state.transferDataError = action.payload.error
            }
        /*************
         * 移管申込の送信
         *************/
        case(types.SEND_TRANSFER_FORM_REQUEST_LOADING):
            return {
                ...state,
                transferRequestLoading: state.transferRequestLoading = true,
                transferRequestError: state.transferRequestError = {}
            }
        case(types.SEND_TRANSFER_FORM_REQUEST_SUCCESS):
            return {
                ...state,
                transferForm: state.transferForm = {},
                transferRequestLoading: state.transferRequestLoading = false,
            }
        case(types.SEND_TRANSFER_FORM_REQUEST_FAILURE):
            return {
                ...state,
                transferRequestLoading: state.transferRequestLoading = false,
                transferRequestError: state.transferRequestError = action.payload.error
            }
        default:
            return state
    }
}
