import React from 'react';
import {Box, Button, Card, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {empty} from "../../../../../../state/utils/Common";
import EstimatesListContainer from "../../../../../containers/user/requests/detail/EstimatesListContainer";
import requestType from "../../../../../../state/utils/data/application_request_type_flat.json"
import PartnerReSendEmailContainer from "../../../../../containers/user/requests/detail/PartnerReSendEmailContainer";
import ResendEmailModal from "../modal/ResendEmailModal";
import services from "../../../../../../state/utils/data/services.json";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 * 申込みのサービスブロック
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestBlock = (
    {
        managerProfile,
        request,
        requestId,
        requestGroupId,

        sendMailValidation,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,

        resendMailModalOpen,
        resendMailLoading,
        resendMailError,
        openResendModal,
        closeResendModal,
        sendResendMail,

        t
    }) => {
    const classes = useStyles();
    return (
        <Box marginTop={4}>
            <Card>
                <Box className={clsx(classes.root)}>
                    {/** 新規+エビスログID未設定（アカウント設定前）以外の場合 */}
                    <Typography variant="h4">{request.service_name}</Typography>
                    {!empty(request?.usage_category) && request.service_name === services.ADEBIS &&
                        <Grid container style={{marginTop: 26}}>
                            {/** アカウント情報 */}
                            <Grid item xs={2} xl={1}>
                                <Typography variant="body1"
                                            className={classes.strong}>{t('requests.detail.usage_category')}</Typography>
                            </Grid>
                            <Grid item xs={10} xl={11}>
                                <Typography variant="body1">
                                    {request.usage_category}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {!empty(request?.application_complete_at) &&
                        <Grid container style={{marginTop: 26}}>
                            {/** アカウント情報 */}
                            <Grid item xs={2} xl={1}>
                                <Typography variant="body1"
                                            className={classes.strong}>{t('requests.detail.request_completed_date')}</Typography>
                            </Grid>
                            <Grid item xs={10} xl={11}>
                                <Typography variant="body1">
                                    {request.application_complete_at} ({request?.application_user_name})
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Box>

                {request.type !== requestType.CANCEL && !empty(request?.related_quotes?.length) &&
                    <>
                        <Divider/>
                        <Box className={clsx(classes.root)} paddingBottom={2} paddingX={3}>
                            <Grid container>
                                <Grid item sm={6}>
                                    <Typography variant="h4">{t('requests.detail.quote_list')}</Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography variant={"body1"} style={{textAlign: "right"}}>
                                        {t('general.list_amount', {amount: request?.related_quotes?.length})}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>
                        {request.related_quotes.length > 0 &&
                            /** 見積り一覧 */
                            <EstimatesListContainer related_quotes={request.related_quotes} requestUUID={request.uuid}/>
                        }
                    </>
                }
                {request.type === requestType.NEW && !empty(request?.ad_owner_mail_logs) &&
                    <>
                        <Divider/>
                        <Box className={clsx(classes.root)} paddingBottom={2} paddingX={3}>
                            <Grid container>
                                <Grid item sm={6}>
                                    <Typography variant="h4">{t('requests.detail.mail_list')}</Typography>
                                </Grid>
                                <Grid item sm={6} style={{textAlign: "right"}}>
                                    <Button variant={"contained"}
                                            onClick={() => openResendModal()}
                                            style={{marginLeft: 15, fontWeight: 900}}
                                            color={"primary"}>
                                        {t('requests.detail.resend_email')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {request?.ad_owner_mail_logs.length > 0 &&
                            /** 見積り一覧 */
                            <PartnerReSendEmailContainer requestUUID={request.uuid}
                                                         ad_owner_mail_logs={request?.ad_owner_mail_logs}
                            />
                        }
                        <ResendEmailModal
                            isOpen={resendMailModalOpen}
                            resendMailLoading={resendMailLoading}
                            resendModalSend={sendResendMail}
                            profile={managerProfile}
                            requestId={requestId}
                            requestGroupId={requestGroupId}
                            resendMailError={resendMailError}
                            resendModalClose={closeResendModal}
                            sendMailValidation={sendMailValidation}
                            resendEmailCheckLoading={resendEmailCheckLoading}
                            resendEmailCheckPassed={resendEmailCheckPassed}
                            resendEmailCheckError={resendEmailCheckError}
                            t={t}
                            requestData={request}
                        />
                    </>
                }
            </Card>
        </Box>)
}

export default RequestBlock;
