/**
 * ///////////////////////////////////////////////
 * 共通ユーティリティファイル
 * ----------------------------------
 *
 *  アプリケーションで使用される基本ユーティリティー関数
 * ///////////////////////////////////////////////
 */

/**
 * 変数が "空 "であるかどうかをチェックします
 * 空 == "", {}, false, null, 'undefined', 0
 *
 * @param {*} mixedVar
 * @returns {boolean}
 */
export function empty(mixedVar) {
    let undef
    let key
    let i
    let len
    const emptyValues = [undef, null, false, 0, '', '0']
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true
        }
    }

    if(mixedVar instanceof RegExp) {
        return false;
    }

    if (typeof mixedVar === 'object') {
        for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
                return false
            }
        }
        return true
    }
    return false
}

/**
 * 現在のフォーム内容とフォームテンプレをマージする。
 * @param currentForm 送信したフォーム
 * @param defaultForm テンプレフォーム
 */
export function mergeWithDefaultForm(currentForm, defaultForm) {
    return {
        ...defaultForm,
        ...currentForm
    }
}

/**
 * JSアプリのスタックトレース機能
 * @returns {string|null} スタックトレース内容
 */
export function stackTracer() {
    let obj = {};
    /**
     * Google・Safari・Edge対応のみ
     */
    if(Error.captureStackTrace) {
        Error.captureStackTrace(obj, stackTracer);
    }
    /***
     * Firefox対応のみ
     * ※ スタックトレース関数名はなくなってしまいます。
     */
    else if(Error().stack) {
        obj = {
            stack: Error().stack
        }
    }

    return obj.stack;
}

/**
 * 文字をインデックスキーに変換して配列に格納する
 * @param substring - 探しているインデックス
 * @param string - 全体文字列
 * @returns {string|Array|null} - インデックス配列
 */
export function stringIndexSearch(substring,string){
    let a=[],i=-1;
    while((i=string.indexOf(substring,i+1)) >= 0) a.push(i);
    return a;
}

/**
 * 文字列になっている数学演算子を元に戻って、インプット比較処理
 * @type {{"<=": (function(*, *): boolean), "<": (function(*, *): boolean), "===": (function(*, *): boolean), ">": (function(*, *): boolean), ">=": (function(*, *): boolean)}}
 */
export const stringOperatorsToMath = {
    '>=': function (x, y) { return x >= y },
    '>': function (x, y) { return x > y },
    '===': function (x, y) { return x === y },
    '<=': function (x, y) { return x <= y },
    '<': function (x, y) { return x < y },
}

/**
 * 通貨のフォーマット
 * @param {number} value - 数字
 * @returns {string} - フォーマットした通貨
 */
export function currencyFormatter(value) {
    const internationalFormatter = new Intl.NumberFormat('ja-JP', {
        style: "currency",
        currency: 'JPY'
    })
    return internationalFormatter.format(value)
}

/**
 *
 * @param date
 * @returns {boolean}
 */
export function dateMoreThanNow(date) {
    if(empty(date) && isNaN(Date.parse(date))) {
        return true;
    }
    const nowDate = new Date();
    const verifyDate = new Date(date);

    return verifyDate <= nowDate;
}

/**
 *
 * ページネーション件数表示の共通関数
 * @param {number} currentPage - 現在のページ番号
 * @param {number} currentList - APIから取得した一覧
 * @param {number} listMaxRow - 最大行数
 * @returns {{from: (number), to: (number)}}
 */
export function parsePagination(currentPage, currentList, listMaxRow) {

    return {
        from: !empty(currentPage) && !empty(currentList) && !empty(listMaxRow) ?
            ((listMaxRow * currentPage) + 1) - listMaxRow
            : 0,
        to: !empty(currentPage) && !empty(currentList) && !empty(listMaxRow) ?
            ((listMaxRow * currentPage)) - listMaxRow + currentList
            : 0,
    }
}

