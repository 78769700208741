import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, Typography} from '@material-ui/core';
import Page from "../../Page";
import AccountUserList from "./list/AccountUserList";
import {Link} from "react-router-dom";
import globalTheme from "../../../../theme/globalTheme";
import managerUrlLinks from "../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {AlertModal} from "../../../../atoms/AlertModal";
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import ManagerUserModal from "./modal/ManagerUserModal";
import RestrictSales from "../../../../atoms/RestrictSales";
import RestrictSystem from "../../../../atoms/RestrictSystem";
import RestrictAdministrator from "../../../../atoms/RestrictAdministrator";

/**
 * アカウントのユーザー一覧
 * @param {Object} classes
 * @param {boolean} managerAccountUsersLoading - ロード中かどうか
 * @param {Object} managerAccountActiveUsers - アカウント ユーザーリスト データ
 * @param managerDetailAccountData
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountUserView = (
    {
        managerProfile,
        classes,
        managerAccountUsersLoading,
        managerAccountActiveUsers,
        managerDetailAccountData,
        accountId,
        managerIsRegisterView,
        managerDeleteView,
        managerDeleteLoading,
        managerDeleteError,
        managerRegisterLoading,
        managerRegisterError,
        managerLoading,
        managerIsError,
        managerUserList_CurrentPage,
        managerSearchLoad,
        managerSearchUserResult,
        managerAuth0UserUnblockLoading,
        managerAuth0UserUnblockError,
        managerUnblockNotificationShow,
        managerResendAuth0MailLoading,
        managerResendAuth0MailError,
        managerResendNotificationShow,
        managerError_code,
        managerError,
        registerNewUser,
        updateUser,
        deleteUsers,
        unlockAuth0User,
        resendAuth0VerifyMail,
        resendAuth0VerifyMailCloseNotification,
        closeUnlockAuth0UserModal,
        viewRegisterToggle,
        viewUserDeleteToggle,
        managerActiveProfile,

        t
    }) => {
    const globalClass = globalTheme._default();
    return (
        <Page
            className={classes.root}
            title={t('accounts.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h2">{t('accounts.users.title', {account_name: managerDetailAccountData?.name})}</Typography>
                    </Grid>
                    <RestrictSales>
                        <RestrictSystem>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            data-testid="create-button"
                            onClick={() => viewRegisterToggle()}
                        >
                            {t('users.new_account')}
                        </Button>
                    </Grid>
                        </RestrictSystem>
                    </RestrictSales>
                    <RestrictAdministrator>
                        <Grid container item xs={12} md={6} justify="flex-end">
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                disabled={true}
                                data-testid="create-button"
                            >
                                顧客ユーザー追加
                            </Button>
                        </Grid>
                    </RestrictAdministrator>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs aria-label="breadcrumb" color={"#000000"} separator={">"} style={{fontSize: 11}}>
                            <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                  to={managerUrlLinks.navigation.accounts.index.href}>
                                {t('accounts.breadcrumb')}
                            </Link>
                            <Link color="inherit" className={globalClass.a} style={{textDecoration: "none"}}
                                  to={managerUrlLinks.navigation.accounts.index.sub_links.users.href.replace('{UID}', accountId)}>
                                {t('accounts.users.breadcrumb')}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <RestrictSales>
                    <RestrictSystem>
                        {/* 作成/編集 モーダル コンポーネント */}
                        <ManagerUserModal
                            isOpen={managerIsRegisterView}
                            setClose={viewRegisterToggle}
                            userRegister={registerNewUser}
                            registerLoading={managerRegisterLoading}
                            activeUser={managerActiveProfile}
                            profile={managerDetailAccountData}
                            registerError={managerRegisterError}
                            userEdit={updateUser}
                            t={t}
                        />
                    </RestrictSystem>
                </RestrictSales>

                {/* 削除モーダルコンポーネント */}
                <ConfirmationModal
                    isOpen={managerDeleteView}
                    isLoading={managerDeleteLoading}
                    error={managerDeleteError?.translation_error_key}
                    profile={managerProfile}
                    loadingText={t('actions.sending')}
                    overrideId={managerActiveProfile !== null && managerActiveProfile.uuid}
                    setClose={viewUserDeleteToggle}
                    confirmationTitle={t('users.delete.title')}
                    confirmationDescription={t('users.delete.description')}
                    buttonText={t('actions.delete')}
                    id={managerActiveProfile !== null && managerActiveProfile.uuid}
                    deleteInformation={t('users.delete.delete_account_title', {user: managerActiveProfile !== null ? (managerActiveProfile.last_name + " " + managerActiveProfile.first_name) : null})}
                    event={deleteUsers}
                    cancelText={t('actions.cancel')}
                />
                <Box mt={3}>
                    <AccountUserList
                        managerAccountActiveUsers={managerAccountActiveUsers}
                        managerAccountUsersLoading={managerAccountUsersLoading}
                        managerDetailAccountData={managerDetailAccountData}
                        unlockAuth0User={unlockAuth0User}
                        resendAuth0VerifyMail={resendAuth0VerifyMail}
                        viewRegisterToggle={viewRegisterToggle}
                        viewDeleteToggle={viewUserDeleteToggle}
                        account_id={managerDetailAccountData.uuid}
                        t={t}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ManagerAccountUserView;
