/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（serviceContracts）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const serviceContractListWatcherSagas = Object.values(watchersSagas);
export {default as serviceContractListSelectors} from "./selectors";
export {default as serviceContractListOperations} from "./operations";
export {default as serviceContractListTypes} from "./types";
export {default as serviceContractListForms} from "./forms"

export {serviceContractListState, default as serviceContractListReducer} from './reducers';
