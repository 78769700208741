import {Button, Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../theme/globalTheme";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import RestrictAdministrator from "../../../../../atoms/RestrictAdministrator";
import RestrictSystem from "../../../../../atoms/RestrictSystem";
import RestrictSales from "../../../../../atoms/RestrictSales";

export const AccountUserListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container key={props.data.users[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={5} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.users[props.index].last_name} {props.data.users[props.index].first_name}
                </Typography>
            </Grid>
            <Grid item xs={5} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.users[props.index].email}
                </Typography>
            </Grid>
            <RestrictSales>
                <RestrictSystem>
                    <Grid item xs={1} style={{textAlign: "center"}}>
                        <Button
                            variant={"text"}
                            size={"small"}
                            className={commonTheme.listButton}
                            onClick={() => props.data.handleEditClick(props.data.users[props.index])}
                        >
                            <CreateIcon style={{
                                fontSize: 12,
                                marginRight: 3,
                                marginTop: "-3px"
                            }}/>{props.data.t('users.list_contents.edit')}
                        </Button>
                    </Grid>
                </RestrictSystem>
            </RestrictSales>
            <RestrictAdministrator>
                <Grid item xs={1} style={{textAlign: "center", opacity: 0.25}}>
                    <CreateIcon style={{
                        fontSize: 12,
                        marginRight: 3,
                        marginTop: "-3px"
                    }}/>{props.data.t('users.list_contents.edit')}
                </Grid>
            </RestrictAdministrator>
            <RestrictSales>
                <RestrictSystem>
                    <Grid item xs={1} style={{textAlign: "center"}}>
                        <Button
                            variant={"text"}
                            size={"small"}
                            className={commonTheme.listButton}
                            onClick={() => props.data.handleDeleteClick(props.data.users[props.index])}
                        >
                            <DeleteIcon
                                style={{
                                    fontSize: 12,
                                    marginRight: 3,
                                    marginTop: "-3px"
                                }}/>{props.data.t('users.list_contents.delete_user')}
                        </Button>
                    </Grid>
                </RestrictSystem>
            </RestrictSales>
            <RestrictAdministrator>
                <Grid item xs={1} style={{textAlign: "center", opacity: 0.25}}>
                    <DeleteIcon
                        style={{
                            fontSize: 12,
                            marginRight: 3,
                            marginTop: "-3px"
                        }}/>{props.data.t('users.list_contents.delete_user')}
                </Grid>
            </RestrictAdministrator>
        </Grid>
    )
}
