import {takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {selectAndFilterOtherAccountContacts, selectSingleServiceContract, updateServiceContractContact} from "./sagas";


/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchDetailedServiceContractContact() {
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT, selectSingleServiceContract);
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS, selectAndFilterOtherAccountContacts);
    yield takeLeading(types.REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE, updateServiceContractContact);
}
