import {empty} from "../../state/utils/Common";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import React from "react";

const FinalFormCheckBox = (
    {
        input: {name, onChange, checked, value, multiline = 0, ...restInput},
        meta,
        loadingOnDisable,
        listStyle = "horizontal",
        disableRipple = true,
        ...rest
    }) => {
    let style = {display: "flex-inline"}
    if(listStyle === "vertical") {
        style = {display: "flex"}
    }

    value = (!empty(value) ? value : null)
    return (
        <FormControlLabel
            style={style}
            control={
                <Checkbox
                    {...rest}
                    color={"primary"}
                    name={name}
                    data-testid={name}
                    disabled={rest.disabled}
                    helperText={meta.touched ? meta.error : undefined}
                    error={meta.error && meta.touched}
                    inputProps={restInput}
                    onChange={onChange}
                    checked={Boolean(checked)}
                    value={value}
                />
            }
            label={rest.label}
            />

    )
}

export default FinalFormCheckBox;
