/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないmanagerAccountsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const MANAGER_GET_ACCOUNT_USERS_API_PATH = "/manager/accounts/{SFID}/users"
export const GET_USERS_API_PATH = "/manager/accounts/{UID}/users/";
export const UNLOCK_AUTH0_USER_API_PATH = "/manager/accounts/{UID}/users/{UUID}/unlock"
export const RESEND_AUTH0_USER_VERIFY_API_PATH = "/manager/accounts/{UID}/users/{UUID}/verify"
