import {call, put} from "redux-saga/effects";
import {
    loadOtherAccountContactsFailed,
    loadOtherAccountContactsLoading,
    loadOtherAccountContactsSuccess,
    loadSingleServiceContract,
    requestSingleServiceContract,
    singleServiceContractFailed,
    singleServiceContractSuccess,
    updateServiceContractContactFailed,
    updateServiceContractContactLoading,
    updateServiceContractContactSuccess
} from "./actions";
import {error_raise} from "../../../root/actions";
import {callSingleServiceContract, updateServiceContractContactAPI} from "./api";
import {callAccountContacts} from "../../accounts/contacts/api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * 利用契約詳細取得リクエスト
 * @param data
 */
export function* selectSingleServiceContract(data) {
    yield put(loadSingleServiceContract())
    try {
        const serviceContracts = yield call(callSingleServiceContract, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId);
        yield put(singleServiceContractSuccess(serviceContracts.data));
    } catch (e) {
        yield put(singleServiceContractFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 他のアカウントの連絡先を選択し、フィルタリングする
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* selectAndFilterOtherAccountContacts(data) {
    yield put(loadOtherAccountContactsLoading())
    try {
        const contacts = yield call(callAccountContacts, data.payload.access_token, data.payload.user_id, null);
        yield put(loadOtherAccountContactsSuccess(contacts.data));
    } catch (e) {
        yield put(loadOtherAccountContactsFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * サービスの担当者変更リクエスト
 * @param data
 * @returns {Generator<*, void, *>}
 */
export function* updateServiceContractContact(data) {
    yield put(updateServiceContractContactLoading())
    try {
        yield call(updateServiceContractContactAPI, data.payload.access_token, data.payload.accountId, data.payload.serviceContractId, data.payload.data);
        yield put(updateServiceContractContactSuccess());
        yield put(requestSingleServiceContract(data.payload.access_token, data.payload.accountId, data.payload.serviceContractId));
    } catch (e) {
        yield put(updateServiceContractContactFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
