import React from 'react';
import {Box, Button, Chip, Divider, FormLabel, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {Field, Form} from "react-final-form";
import FinalFormTextField from "../../../../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../../../../state/utils/Validation";
import BusinessModels from "../../../../../../../../state/utils/data/business_models.json";
import IndustryTypes from "../../../../../../../../state/utils/data/industry_type.json";
import {
    ebisAccountValidation,
    ebisAccountValidationWebsites
} from "../../../../../../../../state/ducks/user/requests/validation";
import {FieldArray} from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import globalTheme from "../../../../../../../theme/globalTheme";
import {empty} from "../../../../../../../../state/utils/Common";
import {Link} from "react-router-dom";
import FinalFormSelect from "../../../../../../../atoms/FinalFormSelect";
import {confirmLogIdInput} from "../../../../../../../../state/ducks/user/requests/api";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../../../../state/utils/Auth0Utils";
import FinalFormTextArea from "../../../../../../../atoms/FinalFormTextArea";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
const inputTypes = {
    'text': FinalFormTextField,
    'textarea': FinalFormTextArea
}

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const EbisInformation = (
    {
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        additionalFieldsArray,
        requestGroupId,
        managerProfile,
        settingAdditionalSteps,
        additionalOptionValidation,
        mailValidationPackage,
        t
    }) => {
    const classes = useStyles();
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    const globalStyle = globalTheme._default();
    const formClasses = globalTheme.defaultFormStyle();

    const handleOnSubmit = async values => {
        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
            () => {
                return getAccessTokenSilently();
            },
        );
        const outPut = await confirmLogIdInput(
            access_token,
            managerProfile?.account?.uuid,
            requestGroupId,
            values.ebis_log_id,
            values.ebis_client_name,
            values.ebis_host_names
        ).then(response => {
            if (response.status === 201) {
                if(!empty(additionalFieldsArray)) {
                    settingAdditionalSteps(values?.additional_fields)
                }
                settingSubmitEbisFormInformation(values)
            }
        }).catch(error => {
            if (error?.message?.code === 'E400_EBIS_LOG_ID_ALREADY_EXISTS') {
                return {ebis_log_id: t('error_codes.400.E400_EBIS_LOG_ID_ALREADY_EXISTS')}
            }
        })
        await outPut
        if(!empty(outPut)) {
            return outPut
        }
        return null;
    }

    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography variant="body1">{t('requests.detail.quotes.setting.step_1_ebis.description')}</Typography>
            </Box>
            <Box className={clsx(classes.root)}>
                <Form
                    onSubmit={handleOnSubmit}
                    mutators={{
                        ...arrayMutators
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        const formValidation = translate(t, Validation({...e}, ebisAccountValidation))
                        const additionalValidation = translate(t, Validation({...e.additional_fields}, additionalOptionValidation))
                        return {...formValidation, ...additionalValidation}
                    }}
                    // （編集モード）最初のバリュー
                    initialValues={requestStartUpInformation}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({
                                 handleSubmit,
                                 form: {
                                     mutators: {push, pop}
                                 },
                                 submitting,
                                 pristine,
                                 hasValidationErrors,
                                 values
                             }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item md={4}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <Field
                                                fullWidth
                                                size={"small"}
                                                required={true}
                                                label={t('requests.detail.quotes.setting.step_1_ebis.id')}
                                                name="ebis_log_id"
                                                component={FinalFormTextField}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{paddingTop: 5}}
                                                        variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.id_hint')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item md={4}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <Field
                                                fullWidth
                                                size={"small"}
                                                required={true}
                                                label={t('requests.detail.quotes.setting.step_1_ebis.account_name')}
                                                name="ebis_client_name"
                                                component={FinalFormTextField}
                                                type="text"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography style={{paddingTop: 5}}
                                                        variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.account_name_hint')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item md={4}>
                                    <FormLabel style={{display: "block"}}>
                                        {t('requests.detail.quotes.setting.step_1_ebis.calculate_sites')}
                                        <Chip label={"必須"} size="small" className={[formClasses.chip]}/>
                                    </FormLabel>
                                    <FieldArray name={"ebis_host_names"} validateFields={e => {
                                        return translate(t, Validation({...e}, ebisAccountValidationWebsites))
                                    }}>
                                        {({fields}) =>
                                            fields.map((name, index) => (
                                                <div key={name}>
                                                    <Grid container justify={"center"} alignContent={"center"}
                                                          alignItems={"center"}>
                                                        <Grid item xs={11}>
                                                            <Field
                                                                validate={e => {
                                                                    e = {"ebis_host_name": e};
                                                                    const translatedObject = translate(t, Validation({...e}, ebisAccountValidationWebsites))
                                                                    if (!empty(translatedObject)) {
                                                                        return translatedObject['ebis_host_name'];
                                                                    }
                                                                    return;
                                                                }}
                                                                fullWidth
                                                                style={{marginTop: 7, marginBottom: 7}}
                                                                size={"small"}
                                                                name={`${name}.ebis_host_name`}
                                                                component={FinalFormTextField}
                                                                type="text"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} style={{padding: 4}}>
                                                             <span
                                                                 onClick={() => pop('ebis_host_names')}
                                                                 style={{cursor: 'pointer', color: "#7E7E7E"}}
                                                             >
                                                                 ×
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            ))
                                        }
                                    </FieldArray>
                                    <Typography style={{marginTop: 10, color: "#0D66D0", cursor: "pointer"}}
                                                onClick={() => push('ebis_host_names', undefined)}
                                    >
                                        追加
                                    </Typography>
                                        </Grid>

                                <Grid container alignItems="flex-start" style={{marginTop: 5, marginBottom: 10}}
                                      spacing={2}>
                                    <Grid item md={4}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <Field
                                                        fullWidth
                                                        name="industry_type"
                                                        component={FinalFormSelect}
                                                        inputStyle={{padding: "5.5px 10px"}}
                                                        label={t('requests.detail.quotes.setting.step_1_ebis.industry_type')}
                                                        required={true}
                                                        options={IndustryTypes.industry_type}
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                <Grid container alignItems="flex-start" style={{marginBottom: 10}} spacing={2}>
                                    <Grid item md={4}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <Field
                                                        fullWidth
                                                        name="business_model"
                                                        component={FinalFormSelect}
                                                        inputStyle={{padding: "5.5px 10px"}}
                                                        label={t('requests.detail.quotes.setting.step_1_ebis.business_model')}
                                                        required={true}
                                                        options={BusinessModels.business_models}
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                <Grid container alignItems="flex-start" style={{marginBottom: 10}} spacing={2}>
                                    <Grid item md={4}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <Field
                                                        fullWidth
                                                        size={"small"}
                                                        label={ t("requests.detail.quotes.setting.step_1_ebis.official_company_name")}
                                                        name="official_company_name"
                                                        component={FinalFormTextField}
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography style={{paddingTop: 5}}
                                                            variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.official_company_name_hint')}</Typography>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            {!empty(additionalFieldsArray) && additionalFieldsArray.map((value) => {
                                return (
                                    <div>
                                        <Divider style={{marginTop: 15, marginBottom: 15}} />
                                        <h2>{value.label}</h2>
                                        <Typography variant="body1" style={{ marginTop: 15, marginBottom: 15 }}>{ value.description }</Typography>
                                        {value.fields.map((field_value) => {
                                            return (<Grid container alignItems="flex-start" spacing={2}>
                                                <Grid item md={3}>
                                                    <Grid container>
                                                        <Grid item xs={11}>
                                                            <Field
                                                                fullWidth
                                                                size={"small"}
                                                                required={field_value.required}
                                                                label={t(field_value.label)}
                                                                name={ `additional_fields.${field_value.name}`}
                                                                disabled={field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckLoading === true}
                                                                onBlur={() => {
                                                                    if(field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage)) {
                                                                        mailValidationPackage.sendMailValidation(values[field_value.name])
                                                                    }
                                                                }}
                                                                component={inputTypes[field_value.input]}
                                                                type="text"
                                                            />
                                                        </Grid>
                                                        {field_value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckPassed === false &&
                                                            <Grid item xs={12}>
                                                                <Typography className={globalStyle.error}>
                                                                    {t(mailValidationPackage.resendEmailCheckError?.translation_error_key)}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                    {!empty(field_value?.hint) &&
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Typography style={{paddingTop: 5}}
                                                                            variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.id_hint')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>);
                                        })}
                                        <Divider style={{marginTop: 15, marginBottom: 15}} />
                                    </div>
                                )
                            })}
                            <Box className={clsx(classes.root)} marginY={2} alignContent="middle">
                                <Grid container justify="center" alignItems={"center"} spacing={5}>
                                    <Link
                                        to={'/requests/' + requestGroupId}>
                                        <Button variant={"contained"} color={"secondary"}
                                                style={{marginRight: 20, fontWeight: 900}}>
                                            {t('actions.back')}
                                        </Button>
                                    </Link>
                                    <Button variant={"contained"} style={{fontWeight: 900}}
                                            color={"primary"}
                                            type="submit"
                                            disabled={hasValidationErrors}
                                    >
                                        {t('actions.next')}
                                    </Button>
                                </Grid>
                            </Box>
                        </form>)}/>
            </Box>
        </>
    );
}

export default EbisInformation;
