import React from 'react';
import {Box, Button, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {Field, Form} from "react-final-form";
import FinalFormTextField from "../../../../../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../../../../../state/utils/Validation";
import arrayMutators from 'final-form-arrays'
import {empty} from "../../../../../../../../../state/utils/Common";
import FinalFormTextArea from "../../../../../../../../atoms/FinalFormTextArea";
import globalTheme from "../../../../../../../../theme/globalTheme";
import {Link} from "react-router-dom";


const inputTypes = {
    'text': FinalFormTextField,
    'textarea': FinalFormTextArea
}

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const AdvertAgentStep = (
    {
        additionalOptionInformation,
        settingAdditionalSteps,
        additionalOptionValidation,
        settingEbisBackButton,
        mailValidationPackage,
        advertAgentInformation,
        requestGroupId,
        requestId,
        quoteId,
        t
    }) => {
    const classes = useStyles();
    const globalStyle = globalTheme._default();
    let additionalValidation = false;
    // @TODO: Messy code, need to refactor
    if (advertAgentInformation?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckPassed === false) {
        additionalValidation = true;
    }

    return (
        <>
            <Box className={clsx(classes.root)}>
                <Form
                    onSubmit={e => {
                        settingAdditionalSteps(e)
                    }}
                    mutators={{
                        ...arrayMutators
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        return translate(t, Validation({...e}, additionalOptionValidation))
                    }}
                    // （編集モード）最初のバリュー
                    initialValues={additionalOptionInformation}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({
                                 handleSubmit,
                                 form: {
                                     mutators: {push, pop}
                                 },
                                 submitting,
                                 pristine,
                                 invalid,
                                 values
                             }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div>
                                <h2>{advertAgentInformation.label}</h2>
                                <Typography variant="body1"
                                            style={{
                                                marginTop: 15,
                                                marginBottom: 15
                                            }}>{advertAgentInformation.description}</Typography>
                                {advertAgentInformation.fields.map((value) => {
                                    return (<Grid container alignItems="flex-start" spacing={2}>
                                        <Grid item md={3}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <Field
                                                        fullWidth
                                                        size={"small"}
                                                        required={value.required}
                                                        label={t(value.label)}
                                                        name={value.name}
                                                        disabled={value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckLoading === true}
                                                        onBlur={() => {
                                                            if (value?.required_mypage_email_validation === true && !empty(mailValidationPackage)) {
                                                                mailValidationPackage.sendMailValidation(values[value.name])
                                                            }
                                                        }}
                                                        component={inputTypes[value.input]}
                                                        type="text"
                                                    />
                                                </Grid>
                                                {value?.required_mypage_email_validation === true && !empty(mailValidationPackage) && mailValidationPackage.resendEmailCheckPassed === false &&
                                                    <Grid item xs={12}>
                                                        <Typography className={globalStyle.error}>
                                                            {t(mailValidationPackage.resendEmailCheckError?.translation_error_key)}
                                                        </Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                            {!empty(value?.hint) &&
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography style={{paddingTop: 5}}
                                                                    variant={"subtitle1"}>{t('requests.detail.quotes.setting.step_1_ebis.id_hint')}</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>);
                                })}
                                <Divider style={{marginTop: 15, marginBottom: 15}}/>
                            </div>
                            <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                                <Grid container justify="center" alignItems={"center"} spacing={5}>
                                    <Link to={'/requests/' + requestGroupId + '/' + requestId + '/quotes/' + quoteId}>
                                        <Button
                                            variant={"contained"}
                                            color={"secondary"}
                                            style={{marginRight: 20, fontWeight: 900}}
                                        >
                                            {t('actions.back')}
                                        </Button>
                                    </Link>
                                    <Button variant={"contained"} style={{fontWeight: 900}}
                                            color={"primary"}
                                            type="submit"
                                            disabled={invalid || additionalValidation === true}
                                    >
                                        {t('actions.next')}
                                    </Button>
                                </Grid>
                            </Box>
                        </form>)}/>
            </Box>
        </>
    );
}

export default AdvertAgentStep;
