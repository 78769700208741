import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import AccountContactList from "./list/AccountContactList";
import AccountContactToolbar from "./AccountContactToolbar";
import AccountContactModal from "./modal/AccountContactModal";
import EditableCheck from "../../../../atoms/EditableCheck";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    }
}));

const AccountContactPage = (
    {
        
        accountContactListData,
        accountContactListLoading,
        accountContactListError,

        toggleAccountContactRegistrationModal,
        isAccountContactRegistrationModalOpen,

        newContactRegistrationLoading,
        showContactRegistrationCompleteModel,
        accountContactRegistrationError,
        registerAccountContact,
        profile,
        
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('users.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('account_contacts.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <EditableCheck>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.button}
                                data-testid="create-button"
                                onClick={() => toggleAccountContactRegistrationModal(true)}
                            >
                                {t('account_contacts.new_account')}
                            </Button>
                        </EditableCheck>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 0}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.accounts.index.href}>
                                <Typography color="textPrimary">{t('user_accounts.title')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.accounts.index.sub_links.contacts.href}>
                                <Typography color="textPrimary">{t('account_contacts.title')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                {/* 作成/編集 モーダル コンポーネント */}
                <AccountContactModal
                    isOpen={isAccountContactRegistrationModalOpen}
                    setClose={toggleAccountContactRegistrationModal}
                    activeAccountContact={null}
                    accountContactRegisterRequest={registerAccountContact}
                    accountContactEditRequest={null}
                    accountContactLoading={newContactRegistrationLoading}
                    accountContactError={accountContactRegistrationError}
                    profile={profile}
                    t={t}
                />
                
                {/*<AccountContactToolbar toggleRegisterView={toggleRegisterView} searchLoad={searchLoad}*/}
                {/*                       searchUserResult={searchUserResult} searchUser={searchUser}/>*/}
                <Box mt={3}>
                    {/* ユーザー一覧 */}
                    <AccountContactList
                        loading={accountContactListLoading}
                        accountContactListData={accountContactListData}
                        t={t}/>
                </Box>
            </Container>
        </Page>
    );
};

export default AccountContactPage;
