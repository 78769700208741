import {takeLeading} from "redux-saga/effects";
import {default as types} from "./types";
import {
    approveTerminateRequestSaga,
    cancelRequestSaga,
    downloadRequestsList,
    managerDownloadRequestQuotePDF,
    managerGetAccountDetailSaga,
    managerRequestsDetailLoad,
    managerRequestsLoad,
    managerRequestsQuoteLoad, 
    requestQuotePDFToIframe
} from "./sagas";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */

export function* fetchRequests() {
    yield takeLeading(types.MANAGER_REQUESTS_LOAD, managerRequestsLoad)
    yield takeLeading(types.MANAGER_REQUESTS_LIST_DOWNLOAD, downloadRequestsList)
    yield takeLeading(types.MANAGER_REQUESTS_DETAIL_LOAD, managerRequestsDetailLoad)
    yield takeLeading(types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD, managerRequestsQuoteLoad)
    yield takeLeading(types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD, managerRequestsDetailLoad)
    yield takeLeading(types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF, managerDownloadRequestQuotePDF)
    yield takeLeading(types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST, cancelRequestSaga)
    yield takeLeading(types.MANAGER_APPROVE_TERMINATE_CONTRACT, approveTerminateRequestSaga)
    yield takeLeading(types.MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY, managerGetAccountDetailSaga)
    yield takeLeading(types.MANAGER_REQUEST_LOAD_QUOTE_PDF, requestQuotePDFToIframe)
}
