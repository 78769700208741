/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

import ApiUtils from "../../../../utils/ApiUtils";
import {
    GET_SERVICE_CONTRACT_DATA_API_PATH,
    GET_SERVICE_CONTRACT_VIEWABLE_PRODUCTS_API_PATH,
    PUT_PLAN_UPDATE_REQUEST_API_PATH
} from "./constants";

/**
 * 契約内容照会取得
 * @param accessToken
 * @param account_id
 * @param service_contract_id
 * @returns {AxiosPromise}
 */
export function serviceContractContentRequestAPI(accessToken, account_id, service_contract_id) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_DATA_API_PATH.replace('{UID}', account_id)
            .replace('{SUID}', service_contract_id),
        null,
        null
    )
}

/**
 * すべてのプラン
 * @param accessToken
 * @param account_id
 * @param service_contract_id
 * @returns {AxiosPromise}
 */
export function allPlansRequestAPI(accessToken, account_id, service_contract_id) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_VIEWABLE_PRODUCTS_API_PATH.replace('{UID}', account_id)
            .replace('{SUID}', service_contract_id),
        null,
        null
    )
}

/**
 * ご契約内容の承認リクエスト
 * @param accessToken
 * @param account_id
 * @param service_id
 * @param plans
 * @param inquiry
 * @returns {AxiosPromise}
 */
export function confirmPlansUpdateRequestAPI(accessToken, account_id, service_id, plans, inquiry) {
    return new ApiUtils(accessToken).put(
        PUT_PLAN_UPDATE_REQUEST_API_PATH.replace('{UID}', account_id)
            .replace('{SUID}', service_id),
        "",
        {
            plans: plans,
            inquiry: inquiry
        },
        null
    )
}
