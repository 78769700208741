import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../../Page";
import clsx from "clsx";
import urlLinks from "../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import {empty} from "../../../../../../state/utils/Common";
import {roles} from "../../../../../../state/utils/data/roles";
import CurrentServicesList from "./list/CurrentServicesList";
import EditableCheck from "../../../../../atoms/EditableCheck";
import AccountContactModal from "../modal/AccountContactModal";
import {ConfirmationModal} from "../../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../../atoms/AlertModal";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const AccountContactDetailPage = (
    {
        accountContactId,
        AccountContactDetailData,
        AccountContactDetailLoading,
        AccountContactDetailError,

        isAccountContactUpdateModalOpen,
        isAccountContactDeleteModalOpen,
        isAccountContactDeleteCompleteModalOpen,
        toggleAccountContactUpdateModal,
        toggleAccountContactDeleteModal,
        toggleAccountContactDeleteCompleteModal,

        accountContactUpdateLoading,
        accountContactUpdateError,
        updateAccountContact,

        accountContactDeleteLoading,
        accountContactDeleteError,
        deleteAccountContact,

        profile,
        history,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.title')}
        >
            {AccountContactDetailLoading !== true && !empty(AccountContactDetailData) &&
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{t('navigation.accounts.contacts.detail.title')}</Typography>
                            <Grid container alignContent={"center"} alignItems={"center"}>
                                <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                                    <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                        <Link to={urlLinks.navigation.top.index.href}>
                                            <Typography color="textPrimary">{t('general.top')}</Typography>
                                        </Link>
                                        <Link to={urlLinks.navigation.accounts.index.href}>
                                            <Typography
                                                color="textPrimary">{t('navigation.accounts.title')}</Typography>
                                        </Link>
                                        <Link to={urlLinks.navigation.accounts.index.sub_links.contacts.href}>
                                            <Typography
                                                color="textPrimary">{t('navigation.accounts.contacts.title')}</Typography>
                                        </Link>
                                        <Link
                                            to={urlLinks.navigation.accounts.index.sub_links.contacts.sub_links.detail.href.replace("{ACID}", accountContactId)}>
                                            <Typography
                                                color="textPrimary">{t('navigation.accounts.contacts.detail.title')}</Typography>
                                        </Link>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant={"subtitle1"} style={{paddingBottom: 5, whiteSpace: "pre-wrap"}}>
                                {t('account_contacts.detail.notes')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <h3>{t('account_contacts.detail.contact.header')}</h3>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container justify="flex-end">
                                            <EditableCheck>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    style={{ padding: "10px 10px", marginRight: 20}}
                                                    className={classes.button}
                                                    data-testid="create-button"
                                                    onClick={() => toggleAccountContactUpdateModal(true)}
                                                >
                                                    {t('actions.edit')}
                                                </Button>
                                            </EditableCheck>
                                            {/** 紐づく契約が存在しない場合のみ削除可能 */}
                                            {AccountContactDetailData.service_contracts.length == 0 &&
                                                <EditableCheck>
                                                    <Button
                                                        color="default"
                                                        variant="contained"
                                                        style={{ padding: "10px 10px", marginRight: 20, color: "#FFFFFF"}}
                                                        className={classes.button}
                                                        data-testid="delete-button"
                                                        onClick={() => toggleAccountContactDeleteModal(true)}
                                                    >
                                                        {t('actions.delete')}
                                                    </Button>
                                                </EditableCheck>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <AccountContactModal
                                isOpen={isAccountContactUpdateModalOpen}
                                setClose={toggleAccountContactUpdateModal}
                                activeAccountContact={AccountContactDetailData.contact}
                                accountContactRegisterRequest={null}
                                accountContactEditRequest={updateAccountContact}
                                accountContactLoading={accountContactUpdateLoading}
                                accountContactError={accountContactUpdateError}
                                profile={profile}
                                t={t}
                            />
                            <ConfirmationModal
                                isOpen={isAccountContactDeleteModalOpen}
                                setClose={() => toggleAccountContactDeleteModal(false)}
                                confirmationTitle={t('account_contacts.delete.title')}
                                deleteInformation={t('account_contacts.delete.delete_account_title', {manager: AccountContactDetailData.contact.last_name + " " + AccountContactDetailData.contact.first_name})}
                                confirmationDescription={t('account_contacts.delete.description')}
                                cancelText={t('actions.cancel')}
                                buttonText={t('actions.ok')}
                                loadingText={t('actions.sending')}
                                isLoading={accountContactDeleteLoading}
                                error={accountContactDeleteError}
                                id={AccountContactDetailData.contact.uuid}
                                profile={profile}
                                event={(account_manager_uuid, access_token, account_id) => deleteAccountContact(access_token, account_id, account_manager_uuid)}
                            />
                            <AlertModal
                                isOpen={isAccountContactDeleteCompleteModalOpen}
                                event={() => history.push('/account/contacts')}
                                buttonText={t('actions.ok')}
                                confirmationTitle={t('account_contacts.delete.delete_complete')}
                            />
                            <Divider/>
                            <Box className={clsx(classes.root)}>
                                <Grid container style={{marginTop: 10}}>
                                    {/** ステータス */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.status')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.status_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** お名前 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.name')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.last_name} {AccountContactDetailData.contact.first_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 担当者の役割 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.manager_responsibility')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.role_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 国 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.country')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.country}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 住所 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.address')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">〒{AccountContactDetailData.contact.postal_code} {AccountContactDetailData.contact.state}{AccountContactDetailData.contact.city}{AccountContactDetailData.contact.address}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 電話番号(会社) */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.company_phone')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.work_phone}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 電話番号(携帯) */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.mobile_phone')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.mobile_phone}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** メールアドレス */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.email')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.email}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 部署 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.department')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.department}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 最終更新日 */}
                                    <Grid item xs={2} xl={2}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('account_contacts.detail.contact.content.last_update_date')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={10}>
                                        <Typography
                                            variant="body1">{AccountContactDetailData.contact.updated_at}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Box>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <h3>{t('account_contacts.detail.running_services.header')}</h3>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider/>
                            <CurrentServicesList
                                current_services={AccountContactDetailData.service_contracts}
                                t={t}
                            />
                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    )
        ;
}

export default AccountContactDetailPage;
