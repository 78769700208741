import React, {useEffect} from "react";
import {managerAccountListOperations} from "../../../../state/ducks/manager/managerAccounts";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {useHistory, useParams} from "react-router";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {makeStyles} from "@material-ui/core";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import ManagerAccountMailView from "../../../components/manager/accounts/mail/Page";

/**
 * コンテイナのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        
        managerDetailAccountData: state.managerAccount.managerDetailAccountData,

        managerAccountMailLoading: state.managerAccount.managerAccountMailLoading,
        managerAccountMailList: state.managerAccount.managerAccountMailList,
        managerAccountMailError: state.managerAccount.managerAccountUsersError,
        managerAccountMailMaximumPage: state.managerAccount.managerAccountMailMaximumPage,
        managerAccountMailTotalRows: state.managerAccount.managerAccountMailTotalRows,
        managerAccountMailCurrentPage: state.managerAccount.managerAccountMailCurrentPage
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    saveContractNameToSidebarLink: managerAccountListOperations.saveContractNameToSidebarLink,
    
    managerSelectAccountMail: managerAccountListOperations.managerSelectAccountMail,
    managerSelectAccountMailChangePage: managerAccountListOperations.managerSelectAccountMailChangePage,
}

/**
 * 管理者側のユーザー一覧コンテイナ
 *
 * @param {Object} managerProfile
 * @param managerAccountUsersLoading
 * @param managerAccountActiveUsers
 * @param managerAccountUsersError
 * @param saveContractNameToSidebarLink
 * @param managerSelectAccountUsers
 * @param managerDetailAccountData
 * @param loadSidebarItems
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountMailContainer = (
    {
        managerProfile,

        managerDetailAccountData,
        
        managerAccountMailLoading,
        managerAccountMailList,
        managerAccountMailError,
        managerAccountMailMaximumPage,
        managerAccountMailTotalRows,
        managerAccountMailCurrentPage,

        loadSidebarItems,
        saveContractNameToSidebarLink,
        managerSelectAccountMail,
        managerSelectAccountMailChangePage,
        
        t
    }) => {
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    // URL履歴
    let {id} = useParams();
    const classes = useStyles();

    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    }
                );
                managerSelectAccountMail(access_token, id, managerAccountMailCurrentPage);
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, managerSelectAccountMail, managerAccountMailCurrentPage])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(managerAccountMailList)) {
            saveContractNameToSidebarLink("@TODO: アカウント名をAPIに追加する")
        }
    }, [saveContractNameToSidebarLink, managerProfile, managerAccountMailList])

    const history = useHistory();
    // 連絡先情報があるかどうかのチェック、
    // 連絡先情報が無ければ、/service_redirectionにリダイレクトする。
    if (
        managerAccountMailLoading === false
        && !empty(managerAccountMailError)
    ) {
        history.push('/manager/accounts')
        return (<div> リダイレクト中</div>)
    }



    return (
        <ManagerAccountMailView
            classes={classes}
            managerDetailAccountData={managerDetailAccountData}
            
            managerAccountMailMaximumPage={managerAccountMailMaximumPage}
            managerAccountMailTotalRows={managerAccountMailTotalRows}
            managerAccountMailCurrentPage={managerAccountMailCurrentPage}
            managerAccountMailList={managerAccountMailList}
            managerAccountMailLoading={managerAccountMailLoading}
            accountId={id}
            
            managerSelectAccountMail={managerSelectAccountMail}
            managerSelectAccountMailChangePage={managerSelectAccountMailChangePage}
            
            t={t}
        />
    )
};


/**
 * Redux
 * i18next Translations
 * を
 * ManagerAccountView コンポーネントにマージする。
 */
const managerAccountMailContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ManagerAccountMailContainer)

export default managerAccountMailContainer;
