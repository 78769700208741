/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */


import {call, put} from "redux-saga/effects";
import {error_raise} from "../../root/actions";
import {
    cancelRequestSendFailure,
    cancelRequestSendLoading,
    cancelRequestSendSuccess,
    closeResendModal,
    contractUpdateRequestFailure,
    contractUpdateRequestLoading,
    contractUpdateRequestSuccess,
    requestsDetailLoad as actionLoad,
    downloadQuoteFailure,
    downloadQuoteLoading,
    downloadQuoteSuccess,
    failureConfirmEbisFormInformation,
    formEditEvent,
    getServiceContractInformationError,
    getServiceContractInformationLoading,
    getServiceContractInformationSuccess,
    getTermsOfServiceFailure,
    getTermsOfServiceLoading,
    getTermsOfServiceSuccess,
    loadingConfirmEbisFormInformation,
    parseServiceContractIdFailure,
    parseServiceContractIdLoading,
    parseServiceContractIdSuccess,
    requestsDetailFailed,
    requestsDetailLoading,
    requestsDetailQuoteDetailFailed,
    requestsDetailQuoteDetailLoading,
    requestsDetailQuoteDetailSuccess,
    requestsDetailSuccess,
    requestsFailed,
    requestsLoading,
    requestsSuccess,
    sendResendMailFailure,
    sendResendMailLoading,
    sendResendMailSuccess,
    successConfirmEbisFormInformation,
    sendMailValidation,
    sendMailValidationLoading,
    sendMailValidationSuccess,
    sendMailValidationError,
    downloadQuotePDFToIframeLoading,
    downloadQuotePDFToIframeSuccess,
    downloadQuotePDFToIframeFailure
} from "./actions";
import {
    callRequestQuote,
    callRequestQuotePDFDownload, callRequestQuotePDFToIframe,
    callRequests,
    callRequestsDetail,
    cancelRequestAPI, confirmAdOwnerEmailInput,
    loadHtmlContract,
    putChangeContractApproveAPI,
    putEbisInformationAPI, sendEmailAPI
} from "./api";
import {serviceContractContentRequestAPI} from "../service_contracts/contents/api";

/**
 * アカウント取得リクエスト
 * @param data
 */
export function* requestsSearch(data) {
    yield put(formEditEvent(data.payload.form))
    yield put(requestsLoading())
    try {
        const requests = yield call(callRequests, data.payload.access_token, data.payload.form, data.payload.account_id);
        yield put(requestsSuccess(requests.data.requests));
    } catch (e) {
        yield put(requestsFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お申込一覧取得
 * @param data
 */
export function* requestsLoad(data) {
    yield put(requestsLoading())
    try {
        const requests = yield call(callRequests, data.payload.access_token, null, data.payload.account_id);
        yield put(requestsSuccess(requests.data.requests));
    } catch (e) {
        yield put(requestsFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お申込詳細ページ
 * @param data
 * @returns
 */
export function* requestsDetailLoad(data) {
    yield put(requestsDetailLoading())
    try {
        const requests = yield call(callRequestsDetail, data.payload.access_token, null, data.payload.account_id, data.payload.request_group_id ?? data.payload.request_id);
        yield put(requestsDetailSuccess(requests.data));
    } catch (e) {
        yield put(requestsDetailFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お申込見積リクエスト
 * @param data
 */
export function* requestsQuoteLoad(data) {
    yield put(requestsDetailQuoteDetailLoading())
    try {
        const requests = yield call(callRequestQuote, data.payload.access_token, null, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id);
        yield put(requestsDetailQuoteDetailSuccess(requests.data));
    } catch (e) {
        yield put(requestsDetailQuoteDetailFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * PDF形式で見積もりファイルダウンロード機能
 * @param data
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<(function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ((function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => Promise<infer RT>) ? RT : ((function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: {binaryFileData: *}, type: string}>>, void, *>}
 */
export function* downloadRequestQuotePDF(data) {
    yield put(downloadQuoteLoading())
    try {
        yield call(callRequestQuotePDFDownload, data.payload.access_token, null, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id, data.payload.quote_no);
        yield put(downloadQuoteSuccess(null));
    } catch (e) {
        yield put(downloadQuoteFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* requestQuotePDFToIframe(data) {
    yield put(downloadQuotePDFToIframeLoading())
    try {
        const requests = yield call(callRequestQuotePDFToIframe, data.payload.access_token, null, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id, data.payload.quote_no);
        yield put(downloadQuotePDFToIframeSuccess(requests.data.file_data));
    } catch (e) {
        yield put(downloadQuotePDFToIframeFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 本申フォームの送信処理処理
 * @param data
 */
export function* sendEbisInformation(data) {
    yield put(loadingConfirmEbisFormInformation())
    try {
        yield call(putEbisInformationAPI, data.payload.accessToken, {...data.payload.ebis_form_data, ...{option_information: data.payload.additional_form_data}}, data.payload.account_id, data.payload.request_group_id, data.payload.quote_id);
        yield put(successConfirmEbisFormInformation());
    } catch (e) {
        yield put(failureConfirmEbisFormInformation(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 本申フォームの送信処理処理 (契約変更承認)
 * @param data
 */
export function* changeContractApproved(data) {
    yield put(contractUpdateRequestLoading())
    try {
        yield call(putChangeContractApproveAPI, data.payload.accessToken, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id, { option_information: data.payload.reorderedFormData });
        yield put(contractUpdateRequestSuccess());
    } catch (e) {
        yield put(contractUpdateRequestFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 申込キャンセル処理。
 * @param data
 */
export function* cancelRequestSaga(data) {
    yield put(cancelRequestSendLoading())
    try {
        yield call(cancelRequestAPI, data.payload.access_token, data.payload.account_id, data.payload.request_id);
        yield put(cancelRequestSendSuccess());
    } catch (e) {
        yield put(cancelRequestSendFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 契約内容照会の取得
 * @param data
 */
export function* parseServiceContractNameRequest(data) {
    yield put(parseServiceContractIdLoading())
    try {
        const requests = yield call(serviceContractContentRequestAPI, data.payload.access_token, data.payload.account_id, data.payload.service_contract_id);
        yield put(parseServiceContractIdSuccess(requests.data));
    } catch (e) {
        yield put(parseServiceContractIdFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* parseHtmlContractData(data) {
    yield put(getServiceContractInformationLoading())
    try {
        const request = yield call(loadHtmlContract, data.payload.htmlPath)
        yield put(getServiceContractInformationSuccess(request.data))
    } catch (e) {
        yield put(getServiceContractInformationError(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* parseHtmlTermsData(data) {
    yield put(getTermsOfServiceLoading())
    try {
        const request = yield call(loadHtmlContract, data.payload.htmlPath)
        yield put(getTermsOfServiceSuccess(request.data))
    } catch (e) {
        console.log(e)
        yield put(getTermsOfServiceFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* verifyResendEmail(data) {
    yield put(sendMailValidationLoading())
    try {
        yield call(confirmAdOwnerEmailInput, data.payload.access_token, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.params);
        yield put(sendMailValidationSuccess())
    } catch (e) {
        yield put(sendMailValidationError(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* sendResendEmail(data) {
    yield put(sendResendMailLoading())
    try {
        yield call(sendEmailAPI, data.payload.access_token, data.payload.account_id, data.payload.request_group_id, data.payload.request_id, data.payload.formData)
        yield put(sendResendMailSuccess())
        yield put(closeResendModal())
        yield put(actionLoad(data.payload.access_token, data.payload.account_id, data.payload.request_group_id))
    } catch (e) {
        yield put(sendResendMailFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
