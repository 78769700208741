import {Grid, Typography} from "@material-ui/core";

import React from "react";
import globalTheme from "../../../../../theme/globalTheme";
import {Link} from "react-router-dom";

export const AccountContactListRow = props => {
    const commonTheme = globalTheme._default();
    console.log(props.data.accountContacts[props.index]);
    return (
        <Link to={'/account/contacts/' + props.data.accountContacts[props.index].uuid}>
            <Grid container key={props.data.accountContacts[props.index].uuid} style={{
                ...props.style,
                paddingLeft: 21,
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 0,
                borderBottom: "1px #F2F2F2 solid"
            }}>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].last_name} {props.data.accountContacts[props.index].first_name}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].status_name}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].role_name}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].department}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].postal_code}
                    </Typography>
                </Grid>
                <Grid item xs={3} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].state}
                        {props.data.accountContacts[props.index].city}
                        {props.data.accountContacts[props.index].address}
                    </Typography>
                </Grid>
                <Grid item xs={3} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.accountContacts[props.index].email}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.t('account_contacts.table_data.cases', {cases: props.data.accountContacts[props.index].service_count})} {}
                    </Typography>
                </Grid>
            </Grid>
        </Link>
    )
}
