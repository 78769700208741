import {accountBasicInformationOperations} from "../../../../../state/ducks/user/accounts/basic_info";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {rootOperations} from "../../../../../state/ducks/root";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {accountContactsOperations} from "../../../../../state/ducks/user/accounts/contacts";
import AccountContactPage from "../../../../components/user/accounts/contacts/AccountContactPage";
import {useHistory, useParams} from "react-router";
import AccountContactDetailPage from "../../../../components/user/accounts/contacts/detail/AccountContactDetailPage";
import {toggleAccountContactUpdateModal} from "../../../../../state/ducks/user/accounts/contacts/actions";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        accountContactDetailsData: state.accountContact.accountContactDetailsData,
        accountContactDetailsLoading: state.accountContact.accountContactDetailsLoading,
        accountContactDetailsError: state.accountContact.accountContactDetailsError,

        isAccountContactUpdateModalOpen: state.accountContact.isAccountContactUpdateModalOpen,
        isAccountContactDeleteModalOpen: state.accountContact.isAccountContactDeleteModalOpen,
        isAccountContactDeleteCompleteModalOpen: state.accountContact.isAccountContactDeleteCompleteModalOpen,
        
        accountContactUpdateLoading: state.accountContact.accountContactUpdateLoading,
        accountContactUpdateError: state.accountContact.accountContactUpdateError,

        accountContactDeleteLoading: state.accountContact.accountContactDeleteLoading,
        accountContactDeleteError: state.accountContact.accountContactDeleteError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadAccountContactDetails: accountContactsOperations.loadAccountContactDetails,
    
    toggleAccountContactUpdateModal: accountContactsOperations.toggleAccountContactUpdateModal,
    toggleAccountContactDeleteModal: accountContactsOperations.toggleAccountContactDeleteModal,
    toggleAccountContactDeleteCompleteModal: accountContactsOperations.toggleAccountContactDeleteCompleteModal,
    
    updateAccountContact: accountContactsOperations.updateAccountContact,
    deleteAccountContact: accountContactsOperations.deleteAccountContact,
}

/**
 * ユーザーコンテイナー
 * @param loading
 * @param activeProfile
 * @param profile
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _accountsContactDetailContainer = (
    {
        loadAccountContactDetails,
        loadSidebarItems,
        
        reLoad,
        profile,
        accountContactDetailsData,
        accountContactDetailsLoading,
        accountContactDetailsError,

        isAccountContactUpdateModalOpen,
        isAccountContactDeleteModalOpen,
        isAccountContactDeleteCompleteModalOpen,
        
        toggleAccountContactUpdateModal,
        toggleAccountContactDeleteModal,
        toggleAccountContactDeleteCompleteModal,

        accountContactUpdateLoading,
        accountContactUpdateError,
        updateAccountContact,


        accountContactDeleteLoading,
        accountContactDeleteError,
        deleteAccountContact,
        
        t,
    }
) => {
    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();
    let {acid} = useParams();
    
    //アプリケーションテーマを取得
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadAccountContactDetails(access_token, profile?.account?.uuid, acid);
            })();
        }
    }, [loadAccountContactDetails, getAccessTokenSilently, profile, reLoad, acid])

    const account_id = profile?.account?.uuid;
    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    const history = useHistory();

    return (
        <AccountContactDetailPage
            profile={profile}
            accountContactId={acid}
            AccountContactDetailData={accountContactDetailsData}
            AccountContactDetailLoading={accountContactDetailsLoading}
            AccountContactDetailError={accountContactDetailsError}
            isAccountContactUpdateModalOpen={isAccountContactUpdateModalOpen}
            toggleAccountContactUpdateModal={toggleAccountContactUpdateModal}
            accountContactUpdateLoading={accountContactUpdateLoading}
            accountContactUpdateError={accountContactUpdateError}
            updateAccountContact={updateAccountContact}
            isAccountContactDeleteModalOpen={isAccountContactDeleteModalOpen}
            isAccountContactDeleteCompleteModalOpen={isAccountContactDeleteCompleteModalOpen}
            toggleAccountContactDeleteModal={toggleAccountContactDeleteModal}
            toggleAccountContactDeleteCompleteModal={toggleAccountContactDeleteCompleteModal}
            accountContactDeleteLoading={accountContactDeleteLoading}
            accountContactDeleteError={accountContactDeleteError}
            deleteAccountContact={deleteAccountContact}
            history={history}
            t={t}
        >
        </AccountContactDetailPage>
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const AccountsContactDetailContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_accountsContactDetailContainer)

export default AccountsContactDetailContainer;
