/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

const terminateForm = {
    service_name: null,
    cancel_reasons1: null,
    cancel_reason2: null,
    cancel_reason3: null,
    cancel_subscriptions: null
}


export const forms = {
    terminateForm
}

export default forms
