import ApiUtils from "../../../../utils/ApiUtils";
import {GET_SERVICE_CONTRACT_API_PATH, UPDATE_SERVICE_CONTRACT_CONTACT_API_PATH} from "./constants";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 利用契約詳細取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - アカウントID
 * @param {string} serviceContractId - 利用契約ID
 * TODO: 一時的にレスポンスバリデーションを無効状態になっています。APIレスポンス構成は決まりましたら、レスポンスバリデーションを修正してください。
 * @returns {AxiosPromise}
 */
export function callSingleServiceContract(accessToken, accountId, serviceContractId) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACT_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        null,
        null
    )
}

/**
 * サービスの担当者変更リクエスト
 * @param accessToken
 * @param accountId
 * @param serviceContractId
 * @param data
 * @returns {AxiosPromise}
 */
export function updateServiceContractContactAPI(accessToken, accountId, serviceContractId, data) {
    return new ApiUtils(accessToken).put(
        UPDATE_SERVICE_CONTRACT_CONTACT_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        '',
        data
    )
}


