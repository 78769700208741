import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";
import globalTheme from "../../../../theme/globalTheme";
import {currencyFormatter} from "../../../../../state/utils/Common";

export const ServiceContractRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Link
            to={'/service_contracts/' + props.data.service_contracts[props.index].uuid + "/contents"}
            data-testid="show-more-button">
            <Grid container key={props.data.service_contracts[props.index].service_account_id} style={{
                ...props.style,
                paddingLeft: 21,
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 45,
                borderBottom: "1px #F2F2F2 solid"
            }}>
                <Grid item xs={2} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                        style={{fontWeight: 900}}
                    >
                        {props.data.service_contracts[props.index].service_name}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.service_contracts[props.index].service_account_id}
                    </Typography>
                </Grid>
                <Grid item xs={5} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.service_contracts[props.index].service_account_name}
                    </Typography>
                </Grid>
                <Grid item xs={2} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.service_contracts[props.index].sold_to_contact?.last_name} {props.data.service_contracts[props.index].sold_to_contact?.first_name}
                    </Typography>
                </Grid>
                <Grid item xs={2} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.service_contracts[props.index].bill_to_contact?.last_name} {props.data.service_contracts[props.index].bill_to_contact?.first_name}
                    </Typography>
                </Grid>
            </Grid>
        </Link>
    )
}
