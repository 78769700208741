import {accountBasicInformationOperations} from "../../../../../state/ducks/user/accounts/basic_info";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {rootOperations} from "../../../../../state/ducks/root";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import BasicInformationPage from "../../../../components/user/accounts/basicInformation/BasicInformationPage";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,
        
        basicInformationData: state.accountsBasicInformation.basic_information,
        basicInformationLoading: state.accountsBasicInformation.loading
        // loading: state.user.loading,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadAccountBasicInformation: accountBasicInformationOperations.loadAccountBasicInformation
    
}

/**
 * ユーザーコンテイナー
 * @param loading
 * @param activeProfile
 * @param profile
 * @param loadSidebarItems
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _basicInformationContainer = (
    {
        reLoad,
        profile,
        loadSidebarItems,
        loadAccountBasicInformation,
        basicInformationLoading,
        basicInformationData,
        t
    }
) => {
    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();
    //アプリケーションテーマを取得
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadAccountBasicInformation(access_token, profile?.account?.uuid);
            })();
        }
    }, [loadAccountBasicInformation, getAccessTokenSilently, profile, reLoad])

    const account_id = profile?.account?.uuid;
    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])

    return (
        <BasicInformationPage
            basicInformationData={basicInformationData}
            basicInformationLoading={basicInformationLoading}
            t={t}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const BasicInformationContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_basicInformationContainer)

export default BasicInformationContainer;
