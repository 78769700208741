import types, {
    REQUEST_CHECK_RESEND_EMAIL_ADDRESS,
    REQUEST_OPEN_RESEND_MAIL_MODAL,
    REQUEST_SEND_RESEND_MAIL_SUCCESS,
    REQUEST_TERMS_OF_SERVICE_LOADING
} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているrequestsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const requestState = {
    requestListLoading: true,
    requestListError: {},
    requestList: {},

    searchFormInformation: {
        ebis_client_name: null,
        application_at_from: null,
        application_at_to: null,
        status: null,
        type: null,
        application_complete_at_from: null,
        application_complete_at_to: null
    },

    requestDetailLoading: true,
    requestDetailError: {},
    requestDetail: {},

    requestQuoteLoading: true,
    requestQuoteError: {},
    requestQuote: {},

    requestStartUpInformationStep: 0,
    updateHasCheckedRequiresOptionInformation: false,
    updateRequiresOptionInformation: false,
    updateOptionStep: 0,
    requestStartUpInformation: {
        ebis_log_id: "",
        ebis_client_name: "",
        ebis_host_names: [""],
        industry_type: "",
        business_model: "",
        official_company_name: ""
    },

    additionalTerms: "",
    additionalTermsLoading: true,
    additionalTermsError: {},

    additionalOptionInformation: null,
    formattedAdditionalOptionInformation: null,

    additionalOptionValidation: null,

    ebisAccountConfirmedLoading: false,
    ebisAccountConfirmedError: {},

    changePlanApprovedLoading: false,
    changePlanApprovedError: {},

    termsLoading: true,
    terms: "",
    termsError: {},

    hasScrolledTermsToBottom: false,
    requestApplyAcceptTerms: false,
    termsAndConditionsIsOpen: false,

    pdfIsLoading: false,
    pdfData: null,
    pdfError: {},

    isCancelConfirmationOpen: false,
    cancelRequestLoading: false,
    cancelRequestError: {},

    isCancelConfirmedModalOpen: false,
    
    pdfDataBlobPreview: "",
    pdfDataBlobPreviewLoading: false,
    pdfDataBlobPreviewError: {},

    resendMailModalOpen: false,
    resendMailLoading: false,
    resendMailError: {},
    
    resendEmailCheckLoading: false,
    resendEmailCheckPassed: true,
    resendEmailCheckError: {}
};

/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function requestReducer(state = requestState, action) {
    switch (action.type) {
        case types.REQUESTS_LOADING:
            return {
                ...state,
                requestListLoading: state.requestListLoading = true,
                requestListError: state.requestListError = {},
                requestList: state.requestList = {}
            }
        case types.REQUESTS_SUCCESS:
            return {
                ...state,
                requestListLoading: state.requestListLoading = false,
                requestListError: state.requestListError = {},
                requestList: state.requestList = action.payload.requests
            }
        case types.REQUESTS_FAILED:
            return {
                ...state,
                requestListLoading: state.requestListLoading = false,
                requestListError: state.requestListError = action.payload.error,
                requestList: state.requestList = {}
            }
        case types.REQUESTS_SEARCH_SUCCESS:
            return {
                ...state,
                requestListLoading: state.requestListLoading = false,
                requestListError: state.requestListError = {},
                requestList: state.requestList = action.payload.requests
            }
        case types.EDIT_SEARCH_FORM:
            return {
                ...state,
                searchFormInformation: state.searchFormInformation = action.payload.form
            }
        case types.CONTRACT_NAME_SEND_TO_FORM: {
            return {
                ...state,
                searchFormInformation: state.searchFormInformation = {
                    ...state.searchFormInformation,
                    ebis_client_name: state.searchFormInformation.ebis_client_name = action.payload.account_name
                }
            }
        }
        /*********
         * お申込詳細
         ********/
        case types.REQUESTS_DETAIL_LOAD:
            return {
                ...state,
                isCancelConfirmedModalOpen: state.isCancelConfirmedModalOpen = false
            }
        case types.REQUESTS_DETAIL_LOAD_LOADING:
            return {
                ...state,
                requestDetailLoading: state.requestDetailLoading = true,
                requestDetailError: state.requestDetailError = {},
                requestDetail: state.requestDetail = {}
            }
        case types.REQUESTS_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                requestDetailLoading: state.requestDetailLoading = false,
                requestDetailError: state.requestDetailError = {},
                requestDetail: state.requestDetail = action.payload.request_detail,
            }
        case types.REQUESTS_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                requestDetailLoading: state.requestDetailLoading = false,
                requestDetailError: state.requestDetailError = action.payload.error,
                requestDetail: state.requestDetail = {}
            }
        /*********
         * お申込詳細
         *********/
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING:
            return {
                ...state,
                requestQuoteLoading: state.requestQuoteLoading = true,
                requestQuoteError: state.requestQuoteError = {},
                requestQuote: state.requestQuote = {}
            }
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                requestQuoteLoading: state.requestQuoteLoading = false,
                requestQuoteError: state.requestQuoteError = {},
                requestQuote: state.requestQuote = action.payload.quote
            }
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                requestQuoteLoading: state.requestQuoteLoading = false,
                requestQuoteError: state.requestQuoteError = action.payload.error,
                requestQuote: state.requestQuote = {}
            }

        /**
         * 申請
         */
        case types.SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING:
            return {
                ...state,
                updateHasCheckedRequiresOptionInformation: state.updateHasCheckedRequiresOptionInformation = true,
                updateRequiresOptionInformation: state.updateRequiresOptionInformation = true,
                additionalOptionInformation: state.additionalOptionInformation = action.payload.initialFields,
                additionalOptionValidation: state.additionalOptionValidation = action.payload.validation
            }
        case types.SETTING_EBIS_OPTION_NO_UPDATE_NEEDED:
            return {
                ...state,
                updateHasCheckedRequiresOptionInformation: state.updateHasCheckedRequiresOptionInformation = true,
                updateRequiresOptionInformation: state.updateRequiresOptionInformation = false,
            }
        case types.RESET_FORM:
            return {
                ...state,
                requestStartUpInformation: state.requestStartUpInformation = {
                    ebis_log_id: "",
                    ebis_client_name: "",
                    ebis_host_names: [""],
                    industry_type: "",
                    business_model: "",
                    official_company_name: ""
                },
                updateHasCheckedRequiresOptionInformation: state.updateHasCheckedRequiresOptionInformation = false,
                updateRequiresOptionInformation: state.updateRequiresOptionInformation = false,
                updateOptionStep: state.updateOptionStep = 0,
                additionalOptionInformation: state.additionalOptionInformation = null,
                formattedAdditionalOptionInformation: state.formattedAdditionalOptionInformation = null,
                additionalOptionValidation: state.additionalOptionValidation = null,

                requestStartUpInformationStep: state.requestStartUpInformationStep = 0,
                requestApplyAcceptTerms: state.requestApplyAcceptTerms = false,
                hasScrolledTermsToBottom: state.hasScrolledTermsToBottom = false,

                additionalTermsLoading: state.additionalTermsLoading = true,
                additionalTerms: state.additionalTerms = "",
                additionalTermsError: state.additionalTermsError = {},

                termsLoading: state.termsLoading = true,
                terms: state.terms = "",
                termsError: state.termsError = {}
            }
        case types.SETTING_EBIS_ACCOUNT_INFORMATION_INPUT:
            return {
                ...state,
                requestStartUpInformation: state.requestStartUpInformation = action.payload.requestStartUpInformation,
                requestStartUpInformationStep: state.requestStartUpInformationStep = action.payload.stepBy,
                requestApplyAcceptTerms: state.requestApplyAcceptTerms = false,
                hasScrolledTermsToBottom: state.hasScrolledTermsToBottom = false
            }
        case types.SETTING_ADDITIONAL_OPTION_INPUT:
            return {
                ...state,
                additionalOptionInformation: state.additionalOptionInformation = action.payload.additionalFormInformation,
                formattedAdditionalOptionInformation: state.formattedAdditionalOptionInformation = action.payload.formattedAdditionalForm,
                requestStartUpInformationStep: state.requestStartUpInformationStep = 1,
                requestApplyAcceptTerms: state.requestApplyAcceptTerms = false,
                hasScrolledTermsToBottom: state.hasScrolledTermsToBottom = false,
            }
        case types.UPDATE_SETTING_ADDITIONAL_OPTION_INPUT:
            return {
                ...state,
                additionalOptionInformation: state.additionalOptionInformation = action.payload.additionalFormInformation,
                formattedAdditionalOptionInformation: state.formattedAdditionalOptionInformation = action.payload.formattedAdditionalForm,
                updateOptionStep: state.updateOptionStep = 1,
                requestApplyAcceptTerms: state.requestApplyAcceptTerms = false,
                hasScrolledTermsToBottom: state.hasScrolledTermsToBottom = false,
            }
        case types.BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT:
            return {
                ...state,
                updateOptionStep: state.updateOptionStep = 0,
                requestStartUpInformationStep: state.requestStartUpInformationStep = 0,
            }
        /****
         * 本申込フォーム送信
         ****/
        case types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING:
            return {
                ...state,
                ebisAccountConfirmedLoading: state.ebisAccountConfirmedLoading = true,
                ebisAccountConfirmedError: state.ebisAccountConfirmedError = {}
            }
        case types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS:
            return {
                ...state,
                ebisAccountConfirmedLoading: state.ebisAccountConfirmedLoading = false,
                requestStartUpInformationStep: state.requestStartUpInformationStep = 3
            }
        case types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE:
            return {
                ...state,
                ebisAccountConfirmedLoading: state.ebisAccountConfirmedLoading = false,
                ebisAccountConfirmedError: state.ebisAccountConfirmedError = action.payload.error
            }
        /****
         * 本申込フォーム送信 (契約変更のみ）
         ****/
        case types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING:
            return {
                ...state,
                changePlanApprovedLoading: state.changePlanApprovedLoading = true,
                changePlanApprovedError: state.changePlanApprovedError = {}
            }
        case types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS:
            return {
                ...state,
                changePlanApprovedLoading: state.changePlanApprovedLoading = false,
                requestStartUpInformationStep: state.requestStartUpInformationStep = 2
            }
        case types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE:
            return {
                ...state,
                changePlanApprovedLoading: state.changePlanApprovedLoading = false,
                changePlanApprovedError: state.changePlanApprovedError = action.payload.error
            }
        /***
         *
         */
        case types.SETTING_EBIS_ACCOUNT_BACK_BUTTON:
            return {
                ...state,
                requestStartUpInformationStep: state.requestStartUpInformationStep -= action.payload.stepsBackBy
            }
        case types.SETTING_SCROLLED_TO_TERMS_BOTTOM:
            return {
                ...state,
                hasScrolledTermsToBottom: state.hasScrolledTermsToBottom = true
            }
        case types.SETTING_APPLY_TOGGLE_TERMS:
            return {
                ...state,
                requestApplyAcceptTerms: state.requestApplyAcceptTerms = !state.requestApplyAcceptTerms
            }
        case types.SETTING_APPLY_INFORMATION_CONFIRMED:
            return {
                ...state,
                requestStartUpInformationStep: state.requestStartUpInformationStep = 1
            }
        case types.CLOSE_TERMS_AND_CONDITIONS_MODAL:
            return {
                ...state,
                termsAndConditionsIsOpen: state.termsAndConditionsIsOpen = false
            }
        case types.OPEN_TERMS_AND_CONDITIONS_MODAL:
            return {
                ...state,
                termsAndConditionsIsOpen: state.termsAndConditionsIsOpen = true
            }
        /****
         * 見積もりダウンロードエヴェント
         ****/
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING:
            return {
                ...state,
                pdfIsLoading: state.pdfIsLoading = true,
                pdfError: state.pdfError = {},
                pdfData: state.pdfData = null
            }
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS:
            return {
                ...state,
                pdfIsLoading: state.pdfIsLoading = false,
                pdfData: state.pdfData = action.payload.binaryFileData
            }
        case types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE:
            return {
                ...state,
                pdfIsLoading: state.pdfIsLoading = true,
                pdfError: state.pdfError = action.payload.error,
            }
        /*********
         * 申込キャンセルエベント
         *********/
        case types.REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE:
            return {
                ...state,
                isCancelConfirmationOpen: state.isCancelConfirmationOpen = action.payload.willOpen
            }
        case types.REQUESTS_DETAIL_CANCEL_REQUEST_LOADING:
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = true,
                cancelRequestError: state.cancelRequestError = {},
            }
        case types.REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS:
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = false,
                isCancelConfirmationOpen: state.isCancelConfirmationOpen = false,
                isCancelConfirmedModalOpen: state.isCancelConfirmedModalOpen = true
            }
        case types.REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE:
            return {
                ...state,
                cancelRequestLoading: state.cancelRequestLoading = false,
                cancelRequestError: state.cancelRequestError = action.payload.error
            }
        /*********
         * アカウントIDパース処理
         *********/
        case types.REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS:
            return {
                ...state,
                searchFormInformation: {
                    ...state.searchFormInformation,
                    ebis_client_name: action.payload.account_name
                }
            }
        case types.REQUEST_LOAD_QUOTE_PDF_LOADING:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = "",
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = true,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = {}
            }
        case types.REQUEST_LOAD_QUOTE_PDF_SUCCESS:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = action.payload.blobData,
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = false,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = {}
            }
        case types.REQUEST_LOAD_QUOTE_PDF_FAILURE:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = "",
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = false,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = action.payload.error
            }
        /******
         * 外部HTML契約内容の取得 (お客様への注意事項)
         *******/
        case types.REQUEST_TERMS_OF_SERVICE_LOADING:
            return {
                ...state,
                termsLoading: state.termsLoading = true,
                terms: state.terms = "",
                termsError: state.termsError = {}
            }
        case types.REQUEST_TERMS_OF_SERVICE_SUCCESS:
            return {
                ...state,
                termsLoading: state.termsLoading = false,
                terms: state.terms = action.payload.termsContent,
                termsError: state.termsError = {}
            }
        case types.REQUEST_TERMS_OF_SERVICE_FAILURE:
            return {
                ...state,
                termsLoading: state.termsLoading = false,
                terms: state.terms = "",
                termsError: state.termsError = action.payload.error
            }
        /******
         * 外部HTML契約内容の取得 (オプションによっての注意事項）
         *******/
        case types.REQUEST_ADDITIONAL_CONTRACT_HTML_LOADING:
            return {
                ...state,
                additionalTermsLoading: state.additionalTermsLoading = true,
                additionalTerms: state.additionalTerms = "",
                additionalTermsError: state.additionalTermsError = {}
            }
        case types.REQUEST_ADDITIONAL_CONTRACT_HTML_SUCCESS:
            return {
                ...state,
                additionalTermsLoading: state.additionalTermsLoading = false,
                additionalTerms: state.additionalTerms = action.payload.html_terms
            }
        case types.REQUEST_ADDITIONAL_CONTRACT_HTML_FAILURE:
            return {
                ...state,
                additionalTermsLoading: state.additionalTermsLoading = false,
                additionalTerms: {},
                additionalTermsError: state.additionalTermsError = action.payload.error
            }
        /********
         * メール再送信機能メールアドレスのバリデーション
         ********/
        case types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_LOADING:
            return {
                ...state,
                resendEmailCheckLoading: state.resendEmailCheckLoading = true,
                resendEmailCheckPassed: state.resendEmailCheckPassed = false,
                resendEmailCheckError: state.resendEmailCheckError = {}
            }
        case types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_SUCCESS:
            return {
                ...state,
                resendEmailCheckLoading: state.resendEmailCheckLoading = false,
                resendEmailCheckPassed: state.resendEmailCheckPassed = true,
                resendEmailCheckError: state.resendEmailCheckError = {}
            }
        case types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_FAILURE:
            return {
                ...state,
                resendEmailCheckLoading: state.resendEmailCheckLoading = false,
                resendEmailCheckPassed: state.resendEmailCheckPassed = false,
                resendEmailCheckError: state.resendEmailCheckError = action.payload.error
            }
        /********
         * メール再送信機能
         ********/
        case types.REQUEST_OPEN_RESEND_MAIL_MODAL:
            return {
                ...state,
                resendMailModalOpen: state.resendMailModalOpen = true
            }
        case types.REQUEST_CLOSE_RESEND_MAIL_MODAL:
            return {
                ...state,
                resendMailModalOpen: state.resendMailModalOpen = false
            }
        case types.REQUEST_SEND_RESEND_MAIL_LOADING:
            return {
                ...state,
                resendMailLoading: state.resendMailLoading = true,
                resendMailError: state.resendMailError = {}
            }
        case types.REQUEST_SEND_RESEND_MAIL_SUCCESS:
            return {
                ...state,
                resendMailLoading: state.resendMailLoading = false
            }
        case types.REQUEST_SEND_RESEND_MAIL_FAILURE:
            return {
                ...state,
                resendMailLoading: state.resendMailLoading = false,
                resendMailError: state.resendMailError = action.payload.error
            }
        default:
            return state;
    }
}
