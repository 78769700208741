import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import Page from "../../../Page";
import clsx from "clsx";
import {GetAppRounded} from "@material-ui/icons";
import managerUrlLinks from "../../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import requestType from "../../../../../../state/utils/data/application_request_type_flat.json"
import requestStatus from "../../../../../../state/utils/data/application_request_status_flat.json"
import ManagerBasicInformation from "./segments/ManagerBasicInformation";
import ManagerQuotePDFViewer from "./segments/ManagerQuotePDFViewer";
import ManagerCorrespondence from "./segments/ManagerCorrespondence";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerRequestQuoteDetailView = (
    {
        requestGroupId,
        requestId,
        quoteId,
        requestQuoteLoading,
        requestQuote,
        requestDetail,
        termsIsOpen,
        termsOpen,
        termsClose,
        managerPdfIsLoading,
        managerPdfError,
        managerDownloadQuotePDF,
        getAccessTokenSilently,

        pdfDataBlobPreview,
        pdfDataBlobPreviewLoading,
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestQuoteLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Breadcrumbs separator={">"} style={{color: "#000000"}} aria-label="breadcrumb">
                                <Link to={managerUrlLinks.navigation.requests.index.href}>
                                    <Typography color="textPrimary">{t('requests.manager.breadcrumb')}</Typography>
                                </Link>
                                <Link
                                    to={managerUrlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestGroupId)}>
                                    <Typography
                                        color="textPrimary">{t('requests.manager.detail.breadcrumb')}</Typography>
                                </Link>
                                <Link
                                    to={managerUrlLinks.navigation.requests.index.sub_links.detail.sub_links.quote.href.replace('{RGIDRID}', requestGroupId + '/' + requestId ).replace('{QID}', requestQuote.uuid)}>
                                    <Typography
                                        color="textPrimary">{t('requests.manager.detail.quote.breadcrumb')}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Grid>

                        {/** PDFダウンロード */}
                        <Grid item xs={6} md={6} style={{textAlign: "right", marginTop: "-10px"}}>
                            <Button type={"button"} variant={"text"} disabled={managerPdfIsLoading}
                                    onClick={async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        await managerDownloadQuotePDF(access_token, requestGroupId, requestId, quoteId, requestQuote.number);
                                    }}
                                    style={{
                                        border: "none",
                                        fontSize: 11
                                    }}><GetAppRounded/> {t('requests.detail.quotes.download_document')}</Button>

                        </Grid>
                        <Grid container alignItems={"flex-end"} alignContent={"flex-end"} justify={"flex-end"}>
                            <Grid item>
                                {managerPdfIsLoading === true &&
                                    <LinearProgress
                                        style={{width: 132, marginRight: 10, marginTop: "-10px", float: "right"}}/>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                    <Box mt={1}>
                        <Card>
                            {/** 宛先 */}
                            <ManagerCorrespondence
                                requestQuoteLoading={requestQuoteLoading}
                                quoteId={quoteId}
                                requestId={requestId}
                                requestQuote={requestQuote}
                                requestDetail={requestDetail}
                                t={t}
                            />
                            <Divider/>
                            {/** 基本情報 */}
                            <ManagerBasicInformation
                                t={t}
                                requestQuote={requestQuote}
                                requestId={requestId}
                                quoteId={quoteId}
                                requestDetail={requestDetail}
                                requestQuoteLoading={requestQuoteLoading}
                            />
                            <Divider/>
                            {!empty(pdfDataBlobPreview) && pdfDataBlobPreviewLoading === false &&
                                <ManagerQuotePDFViewer pdfDataBlobPreview={pdfDataBlobPreview} t={t}/>
                            }
                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    );
}

export default ManagerRequestQuoteDetailView;
