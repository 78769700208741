import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../theme/globalTheme";
import {Link} from "react-router-dom";

export const RequestsRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Link
            to={'/requests/' + props.data.requests[props.index].uuid}>
            <Grid container key={props.data.requests[props.index].uuid} style={{
                ...props.style,
                paddingLeft: 21,
                paddingTop: 13,
                paddingBottom: 13,
                paddingRight: 29,
                borderBottom: "1px #CFCFCF solid"
            }}>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >

                        <Typography variant="body1" style={{paddingLeft: 15}}>
                            {props.data.requests[props.index].id}
                        </Typography>

                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{fontWeight: 900}}
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].service_name}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].status_name}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].type_name}
                    </Typography>
                </Grid>
                <Grid item xs={1} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].ebis_log_id}
                    </Typography>
                </Grid>
                <Grid item xs={3} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].ebis_client_name}
                    </Typography>
                </Grid>
                <Grid item xs={2} zeroMinWidth={true}>
                    <Typography
                        noWrap={true}
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].application_user_name}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].application_complete_at}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.requests[props.index].reflect_at}
                    </Typography>
                </Grid>
            </Grid>
        </Link>
    )
}
