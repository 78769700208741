import types from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているservice_contractsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractListState = {
    loading: true,
    isError: false,
    error: {},
    serviceContractList_CurrentPage: 0,
    serviceContractList: {},

    sideBarContractName: "",
}

export default function serviceContractsList(state = serviceContractListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // 利用契約一覧取得
        // -----------------------------------------------
        case types.REQUEST_SERVICE_CONTRACTS_LOADING:
            return {
                ...state,
                sideBarContractName: state.sideBarContractName = "",
                allowedDelete: state.allowedDelete = false,
                loading: state.loading = true,
                isError: state.isError = false,
                error: state.error = {}
            }
        case types.REQUEST_SERVICE_CONTRACTS_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                serviceContractList: state.serviceContractList = action.payload.serviceContracts,
                isError: state.isError = false,
                error: state.error = {},
            }
        case types.REQUEST_SERVICE_CONTRACTS_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = true,
                error: state.error = action.payload.error
            }
        case types.SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK:
            return {
                ...state,
                sideBarContractName: state.sideBarContractName = action.payload.contactNameToSidebar,
                allowedDelete: state.allowedDelete = action.payload.canCancel
            }
        default:
            return state
    }
}
