import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// アカウント担当者
//-------------------------------------------------
/**
 * アカウント担当者取得リクエスト
 * @param access_token
 * @param user_id
 * @returns {{payload: {access_token: *, user_id}, type: string}}
 */
export function loadAccountContacts(access_token, user_id) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    }
}

/**
 * アカウント担当者取得リクエスト（読み込み中）
 */
export function loadingAccountContacts() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_LOADING
    }
}

/**
 * アカウント担当者取得リクエスト（成功）
 * @param accountContacts
 * @returns {{payload: {accountContacts: *}, type: string}}
 */
export function loadedAccountContactsSuccess(accountContacts) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_SUCCESS,
        payload: {
            accountContacts: accountContacts.data
        }
    }
}

/**
 * アカウント担当者取得リクエスト（失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function loadedAccountContactsFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_FAILURE,
        payload: {
            error: error
        }
    }
}

// -----------------------------------------------
// 担当者詳細画面
// -----------------------------------------------
/**
 * アカウント担当者詳細取得リクエスト
 * @param access_token
 * @param user_id
 * @param account_id
 * @returns {{payload: {access_token, account_id, user_id}, type: string}}
 */
export function loadAccountContactDetails(access_token, user_id, account_id) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DETAILS,
        payload: {
            access_token: access_token,
            user_id: user_id,
            account_id: account_id
        }
    }
}

/**
 * アカウント担当者詳細取得リクエスト（読み込み中）
 * @returns {{type: string}}
 */
export function loadingAccountContactDetails() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DETAILS_LOADING
    }
}

/**
 * アカウント担当者詳細取得リクエスト（成功）
 * @param accountContactDetails
 * @returns {{payload: {accountContactDetails: *}, type: string}}
 */
export function loadedAccountContactDetailsSuccess(accountContactDetails) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DETAILS_SUCCESS,
        payload: {
            accountContactDetails: accountContactDetails.data
        }
    }
}

/**
 * アカウント担当者詳細取得リクエスト（失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function loadedAccountContactDetailsFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DETAILS_FAILURE,
        payload: {
            error: error
        }
    }
}

// -----------------------------------------------
// アカウント担当者登録モーダルまたは更新モーダル
// -----------------------------------------------
/**
 * アカウント担当者登録モーダルトグル
 * @returns {{type: string}}
 * @param {bool|null} forceState 
 */
export function toggleAccountContactRegistrationModal(forceState = null) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_REGISTRATION_MODAL_TOGGLE,
        payload: {
            forceState: forceState
        }
    }
}

/**
 * アカウント担当者更新モーダルトグル
 * @param forceState
 * @returns {{payload: {forceState: null}, type: string}}
 */
export function toggleAccountContactUpdateModal(forceState = null) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_UPDATE_MODAL_TOGGLE,
        payload: {
            forceState: forceState
        }
    }
}

/**
 * アカウント担当者削除モーダルトグル
 * @param {bool|null} forceState
 * @returns {{payload: {forceState: null}, type: string}}
 */
export function toggleAccountContactDeleteModal(forceState = null) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACTS_DELETE_MODAL_TOGGLE,
        payload: {
            forceState: forceState
        }
    }
}

export function toggleAccountContactDeleteCompleteModal(forceState = null) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DELETE_COMPLETE_MODAL_TOGGLE,
        payload: {
            forceState: forceState
        }
    }
}


// -----------------------------------------------
// 新規アカウント担当者登録
// -----------------------------------------------
/**
 * 新規アカウント担当者登録リクエスト
 * @param access_token
 * @param user_id
 * @param accountContactData
 * @returns {{payload: {access_token, accountContact, user_id}, type: string}}
 */
export function registerAccountContact(access_token, user_id, accountContactData) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_REGISTRATION,
        payload: {
            access_token: access_token,
            user_id: user_id,
            accountContactData: accountContactData
        }
    }
}

/**
 * 新規アカウント担当者登録リクエスト（読み込み中）
 * @returns {{type: string}}
 */
export function registerAccountContactLoading() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_LOADING
    }
}

/**
 * 新規アカウント担当者登録リクエスト（成功）
 * @returns {{type: string}}
 */
export function registerAccountContactSuccess() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_SUCCESS
    }
}

/**
 * 新規アカウント担当者登録リクエスト（失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function registerAccountContactFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_FAILURE,
        payload: {
            error: error
        }
    }
}

// -----------------------------------------------
// アカウント担当者更新
// -----------------------------------------------

/**
 * アカウント担当者更新リクエスト
 * @param access_token
 * @param user_id
 * @param account_contact_uuid
 * @param accountContactData
 * @returns {{payload: {access_token, user_id, account_contact_uuid, accountContactData}, type: string}}
 */
export function updateAccountContact(access_token, user_id, account_contact_uuid, accountContactData) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_UPDATE,
        payload: {
            access_token: access_token,
            user_id: user_id,
            account_contact_uuid: account_contact_uuid,
            accountContactData: accountContactData
        }
    }
}

/**
 * アカウント担当者更新リクエスト（読み込み中）
 * @returns {{type: string}}
 */
export function updateAccountContactLoading() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_UPDATE_LOADING
    }
}

/**
 * アカウント担当者更新リクエスト（成功）
 * @returns {{type: string}}
 */
export function updateAccountContactSuccess() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_UPDATE_SUCCESS
    }
}

/**
 * アカウント担当者更新リクエスト（失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function updateAccountContactFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_UPDATE_FAILURE,
        payload: {
            error: error
        }
    }
}

// -----------------------------------------------
// アカウント担当者削除
// -----------------------------------------------
/**
 * アカウント担当者削除リクエスト
 * @param access_token
 * @param user_id
 * @param account_contact_uuid
 * @returns {{payload: {access_token, user_id, account_contact_uuid}, type: string}}
 */
export function deleteAccountContact(access_token, user_id, account_contact_uuid) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DELETE,
        payload: {
            access_token: access_token,
            user_id: user_id,
            account_contact_uuid: account_contact_uuid
        }
    }
}

/**
 * アカウント担当者削除リクエスト（読み込み中）
 * @returns {{type: string}}
 */
export function deleteAccountContactLoading() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DELETE_LOADING
    }
}

/**
 * アカウント担当者削除リクエスト（成功）
 * @returns {{type: string}}
 */
export function deleteAccountContactSuccess() {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DELETE_SUCCESS
    }
}

/**
 * アカウント担当者削除リクエスト（失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function deleteAccountContactFailure(error) {
    return {
        type: types.REQUEST_ACCOUNT_CONTACT_DELETE_FAILURE,
        payload: {
            error: error
        }
    }
}

const actions = {
    // アカウント担当者
    loadAccountContacts,
    loadingAccountContacts,
    loadedAccountContactsSuccess,
    loadedAccountContactsFailure,

    // アカウント担当者詳細
    loadAccountContactDetails,
    loadingAccountContactDetails,
    loadedAccountContactDetailsSuccess,
    loadedAccountContactDetailsFailure,

    // アカウント担当者登録モーダルまたは更新モーダル
    toggleAccountContactRegistrationModal,
    toggleAccountContactUpdateModal,
    toggleAccountContactDeleteModal,
    toggleAccountContactDeleteCompleteModal,

    // 新規アカウント担当者登録
    registerAccountContact,
    registerAccountContactLoading,
    registerAccountContactSuccess,
    registerAccountContactFailure,

    updateAccountContact,
    updateAccountContactLoading,
    updateAccountContactSuccess,
    updateAccountContactFailure,

    deleteAccountContact,
    deleteAccountContactLoading,
    deleteAccountContactSuccess,
    deleteAccountContactFailure
};
export default actions;

