import React from 'react';
import {Box, Button, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../../../../state/utils/Auth0Utils";
import EditableCheck from "../../../../../../../atoms/EditableCheck";
import NotEditableCheck from "../../../../../../../atoms/NotEditableCheck";
import {empty} from "../../../../../../../../state/utils/Common";
import OptionInputBlocks from "../../../../../../../../state/utils/data/request_application/option_input_blocks.json";
import AdditionalTermsContainer
    from "../../../../../../../containers/user/requests/detail/quote/apply/Terms/AdditionalTermsContainer";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeContractSettingsConfirmView = (
    {
        managerProfile,
        requestGroupId,
        requestStartUpInformation,
        settingConfirmEbisFormInformation,
        ebisAccountConfirmedLoading,
        additionalOptionInformation,
        formattedAdditionalOptionInformation,
        ebisAccountConfirmedError,
        settingEbisBackButton,
        t
    }) => {
    const classes = useStyles();
    const {getAccessTokenSilently} = useAuth0();

    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography
                    variant="body1">{t('requests.detail.quotes.setting.step_2_confirm.description')}</Typography>
            </Box>
            <Box className={clsx(classes.root)}>
                {!empty(formattedAdditionalOptionInformation) &&
                    <>
                        {Object.keys(formattedAdditionalOptionInformation).map((value) => {
                            return (
                                <>
                                    <Divider style={{marginTop: 10}}/>
                                    <Grid container style={{marginTop: 20}}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('additional_option_label.form_block_headers.' + value)}</Typography>
                                            {Object.keys(formattedAdditionalOptionInformation[value]).map((value2) => {
                                                return (
                                                    <Grid container style={{marginTop: 20, marginBottom: 8}}>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1"
                                                                        className={classes.strong}>{t('additional_option_label.form_labels.' + value2)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography style={{whiteSpace: "pre-wrap"}}
                                                                        variant={"body1"}>{formattedAdditionalOptionInformation[value][value2]}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                            {!empty(OptionInputBlocks[value]) && 'terms_of_service_html' in OptionInputBlocks[value] &&
                                                <Grid container style={{marginTop: 20, marginBottom: 8}}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="body1"
                                                                    className={classes.strong}>{t('additional_option_label.form_labels.' + value + '.terms')}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <AdditionalTermsContainer></AdditionalTermsContainer>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        })}
                    </>
                }
                <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                    <Grid container justify="center" alignItems={"center"} spacing={5}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            disabled={ebisAccountConfirmedLoading}
                            style={{marginRight: 20, fontWeight: 900}}
                            onClick={() => settingEbisBackButton()}
                        >
                            {t('actions.back')}
                        </Button>
                        <EditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    disabled={ebisAccountConfirmedLoading}
                                    color={"primary"}
                                    onClick={() => (async () => {
                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                            () => {
                                                return getAccessTokenSilently();
                                            },
                                        );
                                        settingConfirmEbisFormInformation(access_token, managerProfile?.account?.uuid, requestGroupId, requestStartUpInformation, additionalOptionInformation)
                                    })()}
                            >
                                {t('actions.send')}
                            </Button>
                        </EditableCheck>
                        <NotEditableCheck>
                            <Button variant={"contained"} style={{fontWeight: 900}}
                                    color={"primary"}
                                    disabled={true}>
                                {t('actions.send')}
                            </Button>
                        </NotEditableCheck>
                    </Grid>
                </Box>
            </Box>
        </>
    )
        ;
}

export default ChangeContractSettingsConfirmView;
