import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React from "react";
import InitialSettingsPage from "../../../../../components/user/requests/detail/intialSetting/initial/InitialSettingsPage";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {}
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _initialContractSettingsContainer = (
    {
        managerProfile,
        requestQuote,
        requestDetailLoading,
        requestStartUpInformationStep,
        requestStartUpInformation,
        settingSubmitEbisFormInformation,
        settingConfirmEbisFormInformation,
        additionalOptionInformation,
        formattedAdditionalOptionInformation,
        settingAdditionalSteps,
        additionalOptionValidation,
        settingEbisBackButton,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        additionalFieldsArray,
        requestGroupId,
        mailValidationPackage,

        t
    }) => {


    return (
        <InitialSettingsPage
            requestDetailLoading={requestDetailLoading}
            requestQuote={requestQuote}
            additionalFieldsArray={additionalFieldsArray}
            settingAdditionalSteps={settingAdditionalSteps}
            additionalOptionValidation={additionalOptionValidation}
            additionalOptionInformation={additionalOptionInformation}
            formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
            requestStartUpInformationStep={requestStartUpInformationStep}
            requestStartUpInformation={requestStartUpInformation}
            settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}
            settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
            ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
            ebisAccountConfirmedError={ebisAccountConfirmedError}
            managerProfile={managerProfile}
            requestGroupId={requestGroupId}
            settingEbisBackButton={settingEbisBackButton}
            mailValidationPackage={mailValidationPackage}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const InitialContractSettingsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_initialContractSettingsContainer)

export default InitialContractSettingsContainer;
