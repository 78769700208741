import {Box, Grid, Typography,} from "@material-ui/core";
import React from "react";
import { commonStyles } from "../../../../../theme/user/styles/common";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {MailRow} from "./MailRow";

/**
 * 通常ユーザー側の申込詳細画面の見積り一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const MailList =
    ({
         className,
         requestUUID,
         related_mail,
         t
     }) => {
        const common = commonStyles();
        let dynamicHeight = related_mail.length * 55;
        if(dynamicHeight > 450) {
            dynamicHeight = 450;
        }
        
        return (
            <>
                <Grid container className={common.tableHeader} style={{paddingRight:29}}>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.mail_id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.quote_id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.send_day')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.sent_to_mail_address')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.manager_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.send_type')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.mail_send_headers.send_user_name')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box style={{height:dynamicHeight}} data-testid={"has-mail"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={related_mail.length}
                                itemSize={55}
                                itemData={{
                                    related_mail: related_mail,
                                    requestUUID: requestUUID,
                                    t: t,
                                }}
                            >
                                {MailRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </>
        );
    };

export default MailList;
