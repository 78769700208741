import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractContactOperations} from "../../../../../state/ducks/user/service_contracts/contacts";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../../state/utils/Common";
import {useParams} from "react-router";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {rootOperations} from "../../../../../state/ducks/root";
import ContactOverview from "../../../../components/user/serviceContracts/contact/ContactOverview";
import {serviceContractListOperations} from "../../../../../state/ducks/user/service_contracts";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        activeServiceContract: state.serviceContractContact.activeServiceContract,
        activeServiceContractError: state.serviceContractContact.activeServiceContractError,
        contactLoading: state.serviceContractContact.contactLoading,
        isUpdateModalOpen: state.serviceContractContact.isUpdateModalOpen,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    requestSingleServiceContract: serviceContractContactOperations.requestSingleServiceContract,
    saveContractNameToSidebarLink: serviceContractListOperations.saveContractNameToSidebarLink,
    toggleUpdateModal: serviceContractContactOperations.toggleUpdateModal,
    loadSidebarItems: rootOperations.loadSidebarItems,
}

/*
利用契約 -> 連絡先コンテイナ
 */
const _contactsOverviewContainer = (
    {
        activeServiceContract,
        activeServiceContractError,
        contactLoading,
        
        requestSingleServiceContract,
        toggleUpdateModal,
        saveContractNameToSidebarLink,
        isUpdateModalOpen,
        managerProfile,
        loadSidebarItems,
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {id} = useParams();
    // 利用契約一覧をAPIから取得する。

    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestSingleServiceContract(access_token, managerProfile?.account?.uuid, id)
            })()
        }
    }, [getAccessTokenSilently, id, managerProfile, requestSingleServiceContract])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(activeServiceContract)) {
            saveContractNameToSidebarLink(activeServiceContract?.service_account_name, activeServiceContract?.service_contract?.cancelable)
        }
    }, [managerProfile, activeServiceContract, saveContractNameToSidebarLink])

    return (
        <ContactOverview
            managerProfile={managerProfile}
            activeServiceContractError={activeServiceContractError}
            contactLoading={contactLoading}
            activeServiceContract={activeServiceContract}
            serviceContractId={id}
            isUpdateModalOpen={isUpdateModalOpen}
            toggleUpdateModal={toggleUpdateModal}
            t={t}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const ContactsOverviewContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_contactsOverviewContainer)

export default ContactsOverviewContainer;
