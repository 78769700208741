import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */


export function saveTransferForm(formData) {
    return {
        type: types.SAVE_TRANSFER_FORM_INFORMATION,
        payload: {
            transferForm: formData
        }
    }
}

export function transferToggleTerms() {
    return {
        type: types.TRANSFER_TOGGLE_TERMS_AND_CONDITIONS,
    }
}
export function transferToggleOtherPartyInformed() {
    return {
        type: types.TRANSFER_TOGGLE_OTHER_PARTY_INFORMED
    }
}
export function transferToggleOtherPartyDataDisclosureAgreement() {
    return {
        type: types.TRANSFER_TOGGLE_OTHER_PARTY_DATA_DISCLOSURE_AGREEMENT
    }
}
export function scrolledTermsToBottom() {
    return {
        type: types.TRANSFER_TERMS_REACHED_BOTTOM
    }
}

export function transferResetTerms() {
    return {
        type: types.TRANSFER_RESET_TERMS_AND_CONDITIONS
    }
}
export function transferClearPartyAgreements() {
    return {
        type: types.TRANSFER_CLEAR_OTHER_PARTY_AGREEMENTS
    }
}

//-------------------------------------------------
// 契約移管データの取得
//-------------------------------------------------

/***
 * 契約移管データの取得
 * @param access_token - auth0アクセストークン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function getTransferData(access_token, accountId, serviceContractId) {
    return {
        type: types.GET_SERVICE_CONTRACT_TRANSFER_DATA,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId,
        },
    }
}

/***
 * 契約移管データの取得（ロード中）
 * @returns {{type: string}}
 */
export function getTransferDataLoading() {
    return {
        type: types.GET_SERVICE_CONTRACT_TRANSFER_DATA_LOADING
    }
}

/***
 * 契約移管データの取得 (成功）
 * @returns {{payload: {activeServiceContract}, type: string}}
 * @param reasons
 */
export function getTransferDataSuccess(reasons) {
    return {
        type: types.GET_SERVICE_CONTRACT_TRANSFER_DATA_SUCCESS,
        payload: {
            reasons: reasons
        }
    }
}

/***
 * 契約移管データの取得（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function getTransferDataFailed(e) {
    return {
        type: types.GET_SERVICE_CONTRACT_TRANSFER_DATA_FAILED,
        payload: {
            error: e
        }
    }
}

//-------------------------------------------------
// 移管申込リクエスト
//-------------------------------------------------

/***
 * @param access_token - auth0アクセストークン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @param form
 * @param callBackAction
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function sendTransferRequest(access_token, accountId, serviceContractId, form, callBackAction) {
    return {
        type: types.SEND_TRANSFER_FORM_REQUEST,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId,
            form: form
        },
        meta: {
            callBackAction: callBackAction
        }
    }
}

/***
 * 移管申込リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function sendTransferRequestLoading() {
    return {
        type: types.SEND_TRANSFER_FORM_REQUEST_LOADING
    }
}

/***
 * 移管申込リクエスト (成功）
 * @returns {{type: string}}
 */
export function sendTransferRequestSuccess() {
    return {
        type: types.SEND_TRANSFER_FORM_REQUEST_SUCCESS,
    }
}

/***
 * 移管申込リクエスト（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function sendTransferRequestFailure(e) {
    return {
        type: types.SEND_TRANSFER_FORM_REQUEST_FAILURE,
        payload: {
            error: e
        }
    }
}


const actions = {
    saveTransferForm,

    transferToggleTerms,
    transferToggleOtherPartyInformed,
    transferToggleOtherPartyDataDisclosureAgreement,
    scrolledTermsToBottom,

    transferClearPartyAgreements,
    transferResetTerms,

    getTransferData,
    getTransferDataLoading,
    getTransferDataSuccess,
    getTransferDataFailed,

    sendTransferRequest,
    sendTransferRequestLoading,
    sendTransferRequestSuccess,
    sendTransferRequestFailure,
};


export default actions;
