/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（users）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const accountsBasicInfoWatcherSagas = Object.values(watchersSagas);
export {default as accountBasicInformationSelectors} from "./selectors";
export {default as accountBasicInformationOperations} from "./operations";
export {default as accountBasicInformationTypes} from "./types";
export {default as accountBasicInformationForms} from "./forms"

export {accountsBasicInformationState, default as accountsBasicInformationReducer} from './reducers';
