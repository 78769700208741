import {call, put} from 'redux-saga/effects';
import actions, {managerSelectAccountUsers} from "./actions";
import {error_raise} from "../../../root/actions";
import * as api from './api';

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */
/**
 * 選択したアカウントでユーザー取得リクエスト
 * @param data
 */
export function* searchAccountUsers(data) {
    yield put(actions.managerSelectAccountUserLoad())
    try {
        // 成功
        let users = yield call(api.managerCallAccountUsers, data.payload.access_token, data.payload.accountId);
        yield put(actions.managerSelectAccountUserSuccess(users.data))
    } catch (e) {
        // 失敗
        yield put(actions.managerSelectAccountUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 新規ユーザーリクエスト
 * @param userData
 */
export function* addUserList(userData) {
    yield put(actions.userRegistrationLoad());
    try {
        yield call(api.addUsers, userData.payload.user, userData.payload.access_token, userData.payload.uuid)
        yield put(actions.registerNewUserSuccess());
        yield put(actions.managerSelectAccountUsers(userData.payload.access_token, userData.payload.uuid))
    } catch (e) {
        yield put(actions.registerNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー更新リクエスト
 * @param userData
 */
export function* updateUser(userData) {
    yield put(actions.userRegistrationLoad())
    try {
        yield call(api.updateUsers, userData.payload.user_id, userData.payload.access_token, userData.payload?.current_user_id, userData.payload.user)
        yield put(actions.registerNewUserSuccess());
        yield put(actions.managerSelectAccountUsers(userData.payload.access_token, userData.payload?.current_user_id))
    } catch (e) {
        yield put(actions.registerNewUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ユーザー削除リクエスト
 * @param accountData
 */
export function* deleteUser(accountData) {
    yield put(actions.deleteLoading())
    try {
        yield call(api.deleteUsers, accountData.payload.user, accountData.payload.access_token, accountData.payload.account_id)
        yield put(actions.deleteUserSuccess())
        yield put(actions.managerSelectAccountUsers(accountData.payload.access_token, accountData.payload.account_id))
    } catch (e) {
        yield put(actions.deleteUserFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * ブロックされたauth0ユーザーの解除
 * @param accountData
 */
export function* unblockAuth0User(accountData) {
    yield put(actions.unlockAuth0UserLoading())
    try {
        yield call(api.unlockAuth0UserAPI, accountData.payload.userId, accountData.payload.accessToken, accountData.payload.accountId)
        yield put(actions.unlockAuth0UserSuccess())
        yield put(actions.managerSelectAccountUsers(accountData.payload.accessToken, accountData.payload.accountId))
    } catch (e) {
        yield put(actions.unlockAuth0UserFailed(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/***
 * 認証メールの再送信処理。
 * @param accountData
 */
export function* resendAuth0VerificationEmail(accountData) {
    yield put(actions.resendAuth0VerifyMailLoading())
    try {
        yield call(api.resendVerificationEmailAPI, accountData.payload.userId, accountData.payload.accessToken, accountData.payload.accountId)
        yield put(actions.resendAuth0VerifyMailSuccess())
    } catch (e) {
        yield put(actions.resendAuth0VerifyMailFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
