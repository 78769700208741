import {Box, Grid, Typography,} from "@material-ui/core";
import React from "react";
import { commonStyles } from "../../../../../theme/user/styles/common";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {InitialSettingRow} from "./InitialSettingRow";

/**
 * 通常ユーザー側の申込詳細画面の見積り一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param requests
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const InitialSettingList =
    ({
         className,
         requestDetail,
         t
     }) => {
        const common = commonStyles();
        return (
            <>
                <Grid container className={common.tableHeader} style={{paddingRight:29}}>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.initial_setting.complete.list_header.service_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.initial_setting.complete.list_header.initial_setting')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.initial_setting.complete.list_header.initial_setting_date')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('requests.detail.initial_setting.complete.list_header.initial_setting_user')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box style={{height:110}} data-testid={"has-mail"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={requestDetail ? 1 : 0}
                                itemSize={55}
                                itemData={{
                                    request: requestDetail ? [requestDetail] : null,
                                    t: t,
                                }}
                            >
                                {InitialSettingRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </>
        );
    };

export default InitialSettingList;
