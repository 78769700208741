import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import Loading from "../../../../atoms/Loading";
import {empty} from "../../../../../state/utils/Common";
import {commonStyles} from "../../../../theme/user/styles/common"
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {ServiceContractRow} from "./ServiceContractRow";

/**
 * コンポーネントスタイル
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: 30,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

/**
 * 利用契約一覧 (/service_contracts)
 *
 * @param {Object} className - 親のスタイル
 * @param {boolean} loading - コンポーネントのロード中状態
 * @param {Object} serviceContractList - 利用契約の一覧
 * @param {*} t - 翻訳ファイル
 * @param {Object} activeServiceContractError - 利用契約情報取得エラー
 * @returns {JSX.Element}
 * @constructor
 */
const ServiceContractList = (
    {
        className,
        loading,
        serviceContractList,
        activeServiceContractError,
        t
    }) => {
    // アプリケーションのスタイル
    const classes = useStyles();
    const common = commonStyles()
    const _globalTheme = globalTheme._default();

    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{fontWeight: 900}}
                        >
                            {t('service_contracts.list')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'textAlign': 'right'}}
                        >
                            {t('general.list_amount', {"amount": !empty(serviceContractList) && !empty(serviceContractList.service_contracts) ? serviceContractList.service_contracts.length : "0"})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Grid container className={common.tableHeader}>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.list_headers.service_name')}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.list_headers.account_id')}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.list_headers.account_name')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.list_headers.account_contact')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography style={{fontWeight: "bold"}}>
                        {t('service_contracts.list_headers.billing_contact')}
                    </Typography>
                </Grid>
            </Grid>
            {loading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          data-testid="loading"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                : !empty(serviceContractList) && !empty(serviceContractList.service_contracts) &&
                <Box className={_globalTheme.list} data-testid="has-contracts">
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={serviceContractList.service_contracts.length}
                                itemSize={45}
                                itemData={{
                                    service_contracts: serviceContractList.service_contracts,
                                    t: t,
                                }}
                            >
                                {ServiceContractRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            }
        </Card>
    );
};

export default ServiceContractList;
