import React, {useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractContactOperations} from "../../../../../state/ducks/user/service_contracts/contacts";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import ContactUpdateModal from "../../../../components/user/serviceContracts/contact/modal/ContactUpdateModal";
import {useParams} from "react-router";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,
        otherContactContacts: state.serviceContractContact.otherContactContacts,
        otherInvoiceContacts: state.serviceContractContact.otherInvoiceContacts,
        otherContactsLoading: state.serviceContractContact.otherContactsLoading,
        otherContactsError: state.serviceContractContact.otherContactsError,
        isUpdateModalOpen: state.serviceContractContact.isUpdateModalOpen,

        activeServiceContract: state.serviceContractContact.activeServiceContract,

        updateLoading: state.serviceContractContact.updateLoading,
        updateError: state.serviceContractContact.updateError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadOtherAccountContacts: serviceContractContactOperations.loadOtherAccountContacts,
    toggleUpdateModal: serviceContractContactOperations.toggleUpdateModal,
    updateServiceContractContact: serviceContractContactOperations.updateServiceContractContact,
}

const _contactUpdateContainer = (
    {
        activeServiceContract,
        managerProfile,

        otherContactContacts,
        otherInvoiceContacts,
        otherContactsLoading,
        otherContactsError,

        toggleUpdateModal,
        loadOtherAccountContacts,
        updateLoading,
        
        isUpdateModalOpen,
        updateServiceContractContact,
        
        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    let {id} = useParams();

    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadOtherAccountContacts(access_token, managerProfile?.account?.uuid)
            })()
        }
    }, [getAccessTokenSilently, managerProfile, loadOtherAccountContacts])

    const [contractContactUuid, setContractContactUuid] = React.useState(activeServiceContract.sold_to_contact?.uuid);
    const [invoiceContactUuid, setInvoiceContactUuid] = React.useState(activeServiceContract.bill_to_contact?.uuid);
    const isRequiredUpdate = contractContactUuid !== activeServiceContract.sold_to_contact?.uuid || invoiceContactUuid !== activeServiceContract.bill_to_contact?.uuid;

    // アクティブな契約担当者がまだotherContactContacts配列に存在しない場合は追加する
    if (activeServiceContract.sold_to_contact && !otherContactContacts.some(contact => contact.uuid === activeServiceContract.sold_to_contact.uuid)) {
        otherContactContacts.push(activeServiceContract.sold_to_contact);
    }

    // アクティブな請求担当者がまだotherInvoicesContacts配列に存在しない場合は追加する
    if (activeServiceContract.bill_to_contact && !otherInvoiceContacts.some(contact => contact.uuid === activeServiceContract.bill_to_contact.uuid)) {
        otherInvoiceContacts.push(activeServiceContract.bill_to_contact);
    }
    
    
    // 現在の契約担当者をotherContactContacts配列の先頭に持ってくる
    otherContactContacts = otherContactContacts.slice().sort((a, b) => {
        if (a.uuid === activeServiceContract.sold_to_contact?.uuid) {
            return -1;
        }
        if (b.uuid === activeServiceContract.sold_to_contact?.uuid) {
            return 1;
        }
        return 0;
    });

    // 現在の請求担当者をotherContactContacts配列の先頭に持ってくる
    otherInvoiceContacts = otherInvoiceContacts.slice().sort((a, b) => {
        if (a.uuid === activeServiceContract.bill_to_contact?.uuid) {
            return -1;
        }
        if (b.uuid === activeServiceContract.bill_to_contact?.uuid) {
            return 1;
        }
        return 0;
    });
    
    return (
        <ContactUpdateModal
            activeContractContact={activeServiceContract.sold_to_contact}
            activeInvoiceContact={activeServiceContract.bill_to_contact}
            otherContactContacts={otherContactContacts}
            otherInvoiceContacts={otherInvoiceContacts}
            otherContactsLoading={otherContactsLoading}
            updateLoading={updateLoading}
            managerProfile={managerProfile}
            isUpdateModalOpen={isUpdateModalOpen}
            toggleUpdateModal={toggleUpdateModal}
            otherContactsError={otherContactsError}
            contractContactUuid={contractContactUuid}
            setContractContactUuid={setContractContactUuid}
            invoiceContactUuid={invoiceContactUuid}
            setInvoiceContactUuid={setInvoiceContactUuid}
            updateServiceContractContact={updateServiceContractContact}
            isRequiredUpdate={isRequiredUpdate}
            id={id}
            t={t}
        />
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * _contentsContainer コンポーネントに追加する。
 */
const ContactUpdateContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_contactUpdateContainer)

export default ContactUpdateContainer;
