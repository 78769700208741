import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, makeStyles, Typography} from '@material-ui/core';
import {useAuth0} from "@auth0/auth0-react";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {empty} from "../../../../../state/utils/Common";

/**
 * コンポーネントスタイル
 * @type {(props?: any) => ClassNameMap<"title">}
 */
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "900"
    },
    contactInfo: {
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
    }
}));

/**
 * 担当者情報コンポーネント
 *
 * @param {*} toggleEditContact - 担当者情報変更モーダル表示・非表示状態
 * @param {Object} activeServiceContract - 選択した利用契約
 * @param {Object} activeServiceContract - 担当者情報
 * @param {string} title - 担当者のタイトル
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const Contact = (
    {
        t,
        activeServiceContract,
        contactLoading,
        title,
    }) => {
    // グローバルコンポーネントスタイル
    const classes = useStyles();
    const cardStyle = globalTheme.defaultCards();
    const defaultTheme = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Card elevation={0} className={defaultTheme.border}>
            <CardHeader title={title} className={cardStyle.headers}></CardHeader>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {activeServiceContract.last_name} {activeServiceContract.first_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.country')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {activeServiceContract.country}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.address.address')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            〒{activeServiceContract.postal_code} {activeServiceContract.state}{activeServiceContract.city}{activeServiceContract.address} 
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.work_phone')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {!empty(activeServiceContract.work_phone) ? activeServiceContract.work_phone : t('general.not_yet_registered')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.mobile_phone')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {!empty(activeServiceContract.mobile_phone) ? activeServiceContract.mobile_phone : t('general.not_yet_registered')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.email')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {activeServiceContract.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.contactInfo}>
                        <Typography variant="body1" className={classes.title}>
                            {t('service_contracts.detail.department')}
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">
                            {!empty(activeServiceContract.department) ? activeServiceContract.department : t('general.not_yet_registered')}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default Contact;
