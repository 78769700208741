import {call, put} from "redux-saga/effects";
import {error_raise} from "../../root/actions";
import {confirmContactRequestFailure, confirmContactRequestLoading, confirmContactRequestSuccess} from "./actions";
import {postContactFormAPI} from "./api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

export function* sendContactInformation(data) {
    yield put(confirmContactRequestLoading())
    try {
        yield call(postContactFormAPI, data.payload.accessToken, data.payload.accountId, data.payload.message);
        yield call(data.meta.callBackAction);
        yield put(confirmContactRequestSuccess());
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(confirmContactRequestFailure(e));
    }
}
