import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトの管理者アカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているmanagerAccountsグループに束縛されています。
 * //////////////////////////////////////////
 */

export const managerAccountListState = {
    managerAccountIsRegisterView: false,
    managerAccountIsEditView: false,

    managerAccountRegisterError: {},
    managerAccountRegisterLoading: false,

    managerAccountVisibleKeyword: "",
    managerAccountSearchKeyword: "",
    managerAccountCurrentPage: 1,
    managerAccountMaximumPage: 0,
    managerAccountTotalRows: 0,

    managerDeleteView: false,
    managerLoading: true,
    managerIsError: false,

    managerSearchLoad: false,
    managerSearchAccountResult: {},

    managerError_code: "",
    managerError: {},
    managerAccountList: {},

    loadingSFUser: false,
    loadingSFAccount: false,
    sfAccountError: {},
    sfUserError: {},
    managerActiveSalesForceAccount: {},
    managerActiveSalesForceAccountList: {},
    managerActiveSalesForceUser: {},
    managerActiveSalesForceUserList: {},
    
    managerAccountMailLoading: true,
    managerAccountMailList: {},
    managerAccountMailError: {},
    managerAccountMailCurrentPage: 1,
    managerAccountMailMaximumPage: 0,
    managerAccountMailTotalRows: 0,

    managerDeleteError: {},
    managerDeleteLoading: false,

    managerActiveId: null,

    managerAccountLoginLoading: false,
    managerAccountLoginError: {},

    managerDetailAccountData: {},

    managerDetailListLoading: true,
    managerDetailListError: {},
    managerDetailListData: {},

    sideBarContractName: ""
};
export default function accountList(state = managerAccountListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // アカウント取得
        // -----------------------------------------------
        case types.MANAGER_REQUEST_ACCOUNTS_LOADING:
            return {
                ...state,
                sideBarContractName: state.sideBarContractName = null,
                managerDetailListData: state.managerDetailListData = {},
                managerDetailAccountData: state.managerDetailAccountData = {},
                managerLoading: state.managerLoading = true,
                managerIsError: state.managerIsError = false,
                managerAccountTotalRows: state.managerAccountTotalRows = 0,
                managerAccountMaximumPage: state.managerAccountMaximumPage = 0,
            }
        case types.MANAGER_REQUEST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerAccountList: state.managerAccountList = action.payload,
                managerAccountMaximumPage: state.managerAccountMaximumPage = action.payload.total_page,
                managerAccountTotalRows: state.managerAccountTotalRows = action.payload.total_rows,
                managerIsError: state.managerIsError = false,
                managerError: state.managerError = {},
                managerError_code: state.managerError_code = ""
            }
        case types.MANAGER_REQUEST_ACCOUNTS_FAILURE:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerError: state.managerError = action.payload.managerError,
                managerAccountList: state.managerAccountList = {},
                managerIsError: state.managerIsError = true,
            }
        case types.MANAGER_CHANGE_ACCOUNT_PAGE:
            return {
                ...state,
                managerAccountCurrentPage: state.managerAccountCurrentPage = action.payload.pageNo
            }
        // -----------------------------------------------
        // アカウント登録
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_TOGGLE_REGISTER:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {},
                sfAccountError: state.sfAccountError = {},
                managerAccountRegisterError: state.managerAccountRegisterError = {},
                sfUserError: state.sfUserError = {},
                managerAccountIsRegisterView: !state.managerAccountIsRegisterView
            }
        case types.MANAGER_TOGGLE_DELETE:
            return {
                ...state,
                managerDeleteView: !state.managerDeleteView,
                managerDeleteError: state.managerDeleteError = {},
                managerActiveId: state.managerActiveId = action.payload.accountId
            }

        case types.MANAGER_ADD_ACCOUNT_LOADING:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = true,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }

        case types.MANAGER_ADD_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
                managerAccountIsRegisterView: state.managerAccountIsRegisterView = false,
            }

        case types.MANAGER_ADD_ACCOUNT_FAILURE:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = action.payload.managerError,
            }
        // -----------------------------------------------
        // アカウント検索
        // -----------------------------------------------
        case types.MANAGER_SEARCH_ACCOUNT_REQUEST:
            return {
                ...state,
                managerLoading: state.managerLoading = true,
                managerAccountSearchKeyword: state.managerAccountSearchKeyword = action.payload.keyword,
                managerAccountCurrentPage: state.managerAccountCurrentPage = 1,
                managerAccountMaximumPage: state.managerAccountMaximumPage = 0,
                managerAccountTotalRows: state.managerAccountTotalRows = 0,
            }
        case types.MANAGER_REQUEST_SEARCH_CHANGE_VALUE:
            return {
                ...state,
                managerAccountVisibleKeyword: state.managerAccountVisibleKeyword = action.payload.keyword,
            }
        // -----------------------------------------------
        // アカウント削除
        // -----------------------------------------------
        case types.MANAGER_DELETE_ACCOUNT_LOADING:
            return {
                ...state,
                managerDeleteLoading: true,
                managerDeleteError: {}
            }
        case types.MANAGER_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerDeleteLoading: false,
                managerDeleteError: state.managerDeleteError = {},
                managerDeleteView: state.managerDeleteView = false
            }

        case types.MANAGER_DELETE_ACCOUNT_FAILURE:
            return {
                ...state,
                managerDeleteLoading: false,
                managerDeleteError: state.managerError = action.payload.error,
            }
        // -----------------------------------------------
        // アカウント変更
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_TOGGLE_EDIT:
            return {
                ...state,
                managerAccountIsEditView: !state.managerAccountIsEditView,
                managerActiveId: action.payload.account,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }
        case types.MANAGER_EDIT_ACCOUNT_LOADING:
            return {
                ...state,
                managerAccountRegisterLoading: !state.managerAccountRegisterLoading,
            }
        case types.MANAGER_EDIT_ACCOUNT_SUCCESS:
            return {
                ...state,
                managerAccountIsEditView: state.managerAccountIsEditView = false,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = {},
            }
        case types.MANAGER_EDIT_ACCOUNT_FAILURE:
            return {
                ...state,
                managerAccountRegisterLoading: state.managerAccountRegisterLoading = false,
                managerAccountRegisterError: state.managerAccountRegisterError = action.payload,
            }
        // -----------------------------------------------
        // SFアカウント編集
        // -----------------------------------------------
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_LOADING:

            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = true,
                sfAccountError: state.sfAccountError = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_CLEAR:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_USER_CLEAR:
            return {
                ...state,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = false,
                sfAccountError: state.sfAccountError = {},
                managerActiveSalesForceAccountList: state.managerActiveSalesForceAccountList = action.payload.salesForceAccounts
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingSFAccount: state.loadingSFAccount = false,
                sfAccountError: state.sfAccountError = action.payload
            }
        case types.MANAGER_SEARCH_SALESFORCE_ACCOUNT_SELECT:
            return {
                ...state,
                managerActiveSalesForceAccount: state.managerActiveSalesForceAccount = action.payload.salesForceAccount,

            }
        // -----------------------------------------------
        // SFユーザー検索
        // -----------------------------------------------
        case types.MANAGER_SEARCH_SALESFORCE_USER_LOADING:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = true,
                sfUserError: state.sfUserError = {},
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {}
            }

        case types.MANAGER_SEARCH_SALESFORCE_USER_SUCCESS:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = false,
                sfUserError: state.sfUserError = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = action.payload.salesForceUsers
            }

        case types.MANAGER_SEARCH_SALESFORCE_USER_FAILURE:
            return {
                ...state,
                loadingSFUser: state.loadingSFUser = false,
                sfUserError: state.sfUserError = action.payload,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = {},
                managerActiveSalesForceUserList: state.managerActiveSalesForceUserList = {}
            }
        case types.MANAGER_SEARCH_SALESFORCE_USER_SELECT:
            return {
                ...state,
                managerActiveSalesForceUser: state.managerActiveSalesForceUser = action.payload.salesForceUser,
            }
        case types.MANAGER_REQUEST_ACCOUNT_MAIL_LOADING:
            return {
                ...state,
                managerAccountMailLoading: state.managerAccountMailLoading = true,
                managerAccountMailError: state.managerAccountMailError = {},
                managerAccountMailList: state.managerAccountMailList = {},
                managerAccountMailMaximumPage: state.managerAccountMailMaximumPage = 0,
                managerAccountMailTotalRows: state.managerAccountMailTotalRows = 0,
            }
        case types.MANAGER_REQUEST_ACCOUNT_MAIL_CHANGE_PAGE:
            return {
                ...state,
                managerAccountMailCurrentPage: state.managerAccountMailCurrentPage = action.payload.currentPage
            }
        case types.MANAGER_REQUEST_ACCOUNT_MAIL_SUCCESS:
            return {
                ...state,
                managerAccountMailLoading: state.managerAccountMailLoading = false,
                managerAccountMailError: state.managerAccountMailError = {},
                managerAccountMailList: state.managerAccountMailList = action.payload.mailList,
                managerAccountMailMaximumPage: state.managerAccountMailMaximumPage = action.payload.total_page,
                managerAccountMailTotalRows: state.managerAccountMailTotalRows = action.payload.total_rows,
                managerDetailAccountData: state.managerDetailAccountData = action.payload.account
            }
        case types.MANAGER_REQUEST_ACCOUNT_MAIL_FAILURE:
            return {
                ...state,
                managerAccountMailLoading: state.managerAccountMailLoading = false,
                managerAccountMailError: state.managerAccountMailError = action.payload.error,
            }
        // -----------------------------------------------
        // 管理者がフロントアカウントとしてフロントにログイン
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_LOGIN_AS_USER_LOADING:
            return {
                ...state,
                managerAccountLoginLoading: state.managerAccountLoginLoading = true,
                managerAccountLoginError: state.managerAccountLoginError = {}
            }
        case types.MANAGER_ACCOUNT_LOGIN_AS_USER_SUCCESS:
            return {
                ...state,
                managerAccountLoginLoading: state.managerAccountLoginLoading = false,
            }
        case types.MANAGER_ACCOUNT_LOGIN_AS_USER_FAILURE:
            return {
                ...state,
                managerAccountLoginLoading: state.managerAccountLoginLoading = false,
                managerAccountLoginError: state.managerAccountLoginError = action.payload.error
            }
        // -----------------------------------------------
        // ご契約内容照会情報の取得エベント
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_GET_ACCOUNT_DETAIL_LOADING:
            return {
                ...state,
                sideBarContractName: state.sideBarContractName = null,
                managerDetailListLoading: state.managerDetailListLoading = true,
                managerDetailListError: state.managerDetailListError = {},
                managerDetailListData: state.managerDetailListData = {}
            }
        case types.MANAGER_ACCOUNT_GET_ACCOUNT_DETAIL_SUCCESS:
            return {
                ...state,
                managerDetailListLoading: state.managerDetailListLoading = false,
                managerDetailListData: state.managerDetailListData = action.payload.service_contracts,
                managerDetailAccountData: state.managerDetailAccountData = action.payload.account
            }
        case types.MANAGER_ACCOUNT_GET_ACCOUNT_DETAIL_FAILURE:
            return {
                ...state,
                managerDetailListLoading: state.managerDetailListLoading = false,
                managerDetailListError: state.managerDetailListError = action.payload.error
            }
        case types.MANAGER_SAVE_ACCOUNT_NAME_TO_SIDEBAR_LINK:
            return {
                ...state,
                sideBarContractName: state.sideBarContractName = action.payload.contactNameToSidebar
            }
        default:
            return state;
    }
}
