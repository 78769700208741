/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

// 利用契約詳細取得リクエスト
export const REQUEST_CONTACT_SERVICE_CONTRACT = "REQUEST_CONTACT_SERVICE_CONTRACT";
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_FAILED";

// 他の担当者情報の取得
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS = "REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_FAILED";

export const REQUEST_CONTACT_SERVICE_CONTRACT_TOGGLE_UPDATE_MODAL = "REQUEST_CONTACT_SERVICE_CONTRACT_TOGGLE_UPDATE_MODAL";

// 担当者更新
export const REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE = "REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE";
export const REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_LOADING = "REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_LOADING";
export const REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_SUCCESS = "REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_SUCCESS";
export const REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_FAILED = "REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_FAILED";



const types = {
    REQUEST_CONTACT_SERVICE_CONTRACT,
    REQUEST_CONTACT_SERVICE_CONTRACT_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_FAILED,

    REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS,
    REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_LOAD_OTHERS_FAILED,
    
    REQUEST_CONTACT_SERVICE_CONTRACT_TOGGLE_UPDATE_MODAL,

    REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE,
    REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_LOADING,
    REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_SUCCESS,
    REQUEST_CONTACT_SERVICE_CONTRACT_UPDATE_FAILED
}
export default types;
