import React from 'react';
import {Box, Card, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import InitialSettingList from "../list/InitialSettingList";


/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        textAlign: "center"
    },
    root_alternative: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 *
 *
 * @param requestDetail
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const InitialSetting = (
    {
        requestDetail,
        t
    }) => {
    const classes = useStyles();
    if ((requestDetail?.ebis_log_id ?? null) !== null) {
        return (
            <Box marginTop={4}>
                <Card>

                    <>
                        <Box className={clsx(classes.root_alternative)}>
                            {/** 新規+エビスログID設定済み（アカウント設定済み）の場合 */}
                            <Typography variant="h4">{t('requests.detail.initial_setting.complete.title')}</Typography>
                        </Box>
                        <InitialSettingList requestDetail={requestDetail} t={t}/>
                    </>
                </Card>
            </Box>
        );
    }
    return (
        <></>
    )

}

export default InitialSetting;
