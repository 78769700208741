import React, {useRef} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        border: "1px solid #CCCCCC",
        maxHeight: 300,
        overflow: 'auto'
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const TermsAndConditionsScroll = (
    {
        onScroll,
        hasScrolledToTermsBottom,
        listInnerRef,
        terms,
        t
    }) => {
    const classes = useStyles();

    
    return (
        <Box className={clsx(classes.root)} onScroll={onScroll} ref={listInnerRef}>
            <Typography id="modal-modal-description" style={{whiteSpace: "pre-wrap"}} sx={{mt: 2}}>
                {terms}
            </Typography>
        </Box>
    );
}

export default TermsAndConditionsScroll;
