import ApiUtils from "../../../../utils/ApiUtils";
import {TRANSFER_CONTRACT_REQUEST_API_PATH, TRANSFER_REQUEST_API_PATH} from "./constants";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

export function updateServiceContractApi(accessToken, accountId, serviceContractId, transferForm) {
    return new ApiUtils(accessToken).put(
        TRANSFER_REQUEST_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        "",
        transferForm,
        null
    )
}

/**
 * 解約理由の取得リクエスト
 * @param accessToken
 * @param accountId
 * @param serviceContractId
 * @returns {AxiosPromise}
 */
export function getTransferDataAPI(accessToken, accountId, serviceContractId) {
    return new ApiUtils(accessToken).get(
        TRANSFER_CONTRACT_REQUEST_API_PATH
            .replace('{UID}', accountId)
            .replace('{SUID}', serviceContractId),
        null,
        null
    )
}
