import ApiUtils from "../../../utils/ApiUtils";
import {GET_SERVICE_CONTRACTS_API_PATH} from "./constants";
import service_contracts from './schema/service_contracts.json'

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /service_contractsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * 利用契約一覧取得リクエスト
 * @param {string} accessToken - auth0 アクセストークン
 * @param {string} accountId - 利用契約ID
 * @param {Object} params - クエリー
 * @returns {AxiosPromise}
 */
export function callServiceContracts(accessToken, accountId, params) {
    return new ApiUtils(accessToken).get(
        GET_SERVICE_CONTRACTS_API_PATH.replace('{UID}', accountId),
        null,
        service_contracts
    )
}
