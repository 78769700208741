import {accountBasicInformationOperations} from "../../../../../state/ducks/user/accounts/basic_info";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {rootOperations} from "../../../../../state/ducks/root";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {accountContactsOperations} from "../../../../../state/ducks/user/accounts/contacts";
import AccountContactPage from "../../../../components/user/accounts/contacts/AccountContactPage";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,
        
        accountContactListLoading: state.accountContact.accountContactListLoading,
        accountContactListData: state.accountContact.accountContactListData,
        accountContactListError: state.accountContact.accountContactListError,

        newContactRegistrationLoading: state.accountContact.newContactRegistrationLoading,
        showContactRegistrationCompleteModel: state.accountContact.showContactRegistrationCompleteModel,
        accountContactRegistrationError: state.accountContact.accountContactRegistrationError,

        isAccountContactRegistrationModalOpen: state.accountContact.isAccountContactRegistrationModalOpen,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    loadAccountContacts: accountContactsOperations.loadAccountContacts,
    toggleAccountContactRegistrationModal: accountContactsOperations.toggleAccountContactRegistrationModal,
    registerAccountContact: accountContactsOperations.registerAccountContact,
}

/**
 * ユーザーコンテイナー
 * @param loading
 * @param activeProfile
 * @param profile
 * @param t
 * @returns {JSX.Element}
 * @private
 */
const _accountsContactContainer = (
    {
        loadSidebarItems,
        loadAccountContacts,
        
        toggleAccountContactRegistrationModal,

        newContactRegistrationLoading,
        showContactRegistrationCompleteModel,
        accountContactRegistrationError,
        
        accountContactListLoading,
        accountContactListData,
        accountContactListError,
        isAccountContactRegistrationModalOpen,

        registerAccountContact,
        
        reLoad,
        profile,
        t
    }
) => {
    // auth0 アクショントークンを取得
    const {getAccessTokenSilently} = useAuth0();
    //アプリケーションテーマを取得
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                loadAccountContacts(access_token, profile?.account?.uuid);

            })();
        }
    }, [loadAccountContacts, getAccessTokenSilently, profile, reLoad])

    const account_id = profile?.account?.uuid;
    /**
     * ナビ項目のロードエベント
     */
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.accounts)
    }, [loadSidebarItems])


    return (
        <AccountContactPage
            accountContactListData={accountContactListData}
            accountContactListLoading={accountContactListLoading}
            accountContactListError={accountContactListError}
            toggleAccountContactRegistrationModal={toggleAccountContactRegistrationModal}
            isAccountContactRegistrationModalOpen={isAccountContactRegistrationModalOpen}

            newContactRegistrationLoading={newContactRegistrationLoading}
            showContactRegistrationCompleteModel={showContactRegistrationCompleteModel}
            accountContactRegistrationError={accountContactRegistrationError}
            registerAccountContact={registerAccountContact}
            profile={profile}
            t={t}
        ></AccountContactPage>
    )
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const AccountsContactContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_accountsContactContainer)

export default AccountsContactContainer;
