import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const saveTerminateFormData = actions.saveTerminateFormData
const toggleTerms = actions.toggleTerms
const sendCancelServiceContract = actions.sendCancelServiceContract
const resetCancellationForm = actions.resetCancellationForm
const getCancelContractReasons = actions.getCancelContractReasons;
const resetTerms = actions.resetTerms
const scrolledTermsToBottom = actions.scrolledTermsToBottom

const operations = {
    saveTerminateFormData,
    toggleTerms,
    sendCancelServiceContract,
    resetCancellationForm,
    getCancelContractReasons,
    resetTerms,
    scrolledTermsToBottom
}
export default operations;
