/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（serviceContracts）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const serviceContractContactWatcherSagas = Object.values(watchersSagas);
export {default as serviceContractContactSelectors} from "./selectors";
export {default as serviceContractContactOperations} from "./operations";
export {default as serviceContractContactTypes} from "./types";
export {default as serviceContractContactForms} from "./forms"

export {serviceContractContactState, default as serviceContractContactReducer} from './reducers';
