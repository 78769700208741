import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, makeStyles, Tab, Tabs, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {withTranslation} from "react-i18next";
import {managerCommonStyles} from '../../theme/manager/styles/common'
import LogoutMenu from "../../atoms/LogoutMenu";
import {NavLink as RouterLink, useLocation} from "react-router-dom";
import {empty} from "../../../state/utils/Common";
import manager_roles from "../../../state/utils/data/application_manager_user_roles_flat.json"

/**
 * ヘッダースタイル
 */
const useStyles = makeStyles(() => ({
    root: {
        height: 70,
    },
    title: {
        fontSize: 16,
        fontWeight: "Bold"
    },
    selected: {
        fontWeight: 900,
        color: "#FFFFFF"
    }
}));

/**
 * ユーザー側のヘッダーコンポーネント
 * @param {string} className - 親からのクラス名
 * @param {boolean} onMobileNavOpen - スマートフォンのナビメニュー開閉
 * @param {Object} managerProfile - ログインユーザープロフィールデータ
 * @param {*} t - 翻訳ファイル
 * @param {*} i18n - 翻訳ファイル
 */
const TopBar = ({
                    className,
                    onMobileNavOpen,
                    managerProfile,
                    t,
                    i18n,
                }) => {
    const classes = useStyles();
    const managerStyles = managerCommonStyles()

    // URLパスの取得
    const location = useLocation();

    // ナビ強調表示
    const NavigationMap = new Map([
        ['accounts', '/manager/accounts'],
        ['requests', '/manager/requests'],
        ['users', '/manager/users'],
        ['notices', '/manager/notices']
    ]);

    NavigationMap.forEach((value, key, map) => {
        if (location.pathname.includes(key)) {
            NavigationMap.set(key, location.pathname)
        }
    })
    
    if(location.pathname.includes("account") && location.pathname.includes("users")) {
        NavigationMap.set("accounts", location.pathname)
        NavigationMap.delete("users")
    }

    return (
        <AppBar
            elevation={0}
            color={"inherit"}
            className={managerStyles.navHeaderBackground}
        >
            <Toolbar className={classes.root}>
                <Typography component="h1" variant='h5' color="inherit" className={classes.title}>
                    {t('root.company_name')}<br/>{t('root.application')}
                </Typography>
                <Tabs
                    value={location.pathname}
                    indicatorColor="secondary"
                    textColor="secondary"
                    centered
                >
                    <Tab

                        textColor={"secondary"}
                        value={NavigationMap.get('accounts')}
                        label={<span
                            className={location.pathname === NavigationMap.get('accounts') ? classes.selected : null}>{t('accounts.title')}</span>}
                        to={"/manager/accounts"}
                        component={RouterLink}
                    />
                    <Tab
                        value={NavigationMap.get('requests')}
                        label={<span
                            className={location.pathname === NavigationMap.get('requests') ? classes.selected : null}>申込管理</span>}
                        to={"/manager/requests"}
                        component={RouterLink}
                    />
                    {/** TODO: <RestrictSales> JSXタグを追加しかったけど、CSSスティールが崩れしまうので、分岐を付けた。出来れば<RestrictSales>を利用したい. **/}
                    {!empty(managerProfile) && managerProfile.role_type !== manager_roles.Sales &&
                    <Tab
                        value={NavigationMap.get('users')}
                        label={<span
                            className={location.pathname === NavigationMap.get('users') ? classes.selected : null}>ユーザー管理</span>}
                        to={"/manager/users"}
                        component={RouterLink}
                    />
                    }
                    <Tab
                        value={NavigationMap.get('notices')}
                        label={<span
                            className={location.pathname === NavigationMap.get('notices') ? classes.selected : null}>お知らせ管理</span>}
                        to={"/manager/notices"}
                        component={RouterLink}
                    />
                </Tabs>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    <LogoutMenu
                        userName={managerProfile?.last_name + " " + managerProfile?.first_name}
                        signOutUri={"/manager/sign_out"}
                        t={t}
                    />
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
        ;
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default withTranslation('common')(TopBar);
