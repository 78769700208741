import {requestOperations} from "../../../../../../../../state/ducks/user/requests";
import React, {useEffect, useRef} from "react";
import { TERMS_OF_SERVICE_NEW, TERMS_OF_SERVICE_OLD, CAPPICO_TERMS_OF_SERVICE, SPOT_TERMS_OF_SERVICE, SPOT_GSP_TERMS_OF_SERVICE} from "../../../../../../../../state/ducks/user/requests/constants";
import {empty} from "../../../../../../../../state/utils/Common";
import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import Loading from "../../../../../../../atoms/Loading";
import globalTheme from "../../../../../../../theme/globalTheme";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import TermsAndConditionsScroll
    from "../../../../../../../components/user/requests/detail/quote/segments/TermsAndConditionsScroll";
import {useParams} from "react-router";
import services from "../../../../../../../../state/utils/data/services.json";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        border: "1px solid #CCCCCC",
        maxHeight: 300,
        overflow: 'auto'
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        termsLoading: state.request.termsLoading,
        terms: state.request.terms,
        termsError: state.request.termsError,
        hasScrolledTermsToBottom: state.request.hasScrolledTermsToBottom,
        requestQuote: state.request.requestQuote,
        requestDetail: state.request.requestDetail
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    getTermsOfService: requestOperations.getTermsOfService,
    scrolledToTermsBottom: requestOperations.scrolledToTermsBottom,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _additionTermsContainer = (
    {
        terms,
        termsLoading,
        termsError,

        getTermsOfService,
        hasScrolledTermsToBottom,
        scrolledToTermsBottom,
        requestQuote,
        requestDetail,
        t
    }) => {

    let {rgid, rid, qid} = useParams();

    const selectedRequest = requestDetail?.requests?.find((request) => {
        return request?.uuid === rid
    });
    
    useEffect(() => {
        if (empty(terms) && !empty(requestQuote) && !empty(selectedRequest.service_name)) {
            // @TODO: サービスがこれ以上増えたら、if分岐ロジックを変更する必要がある。
            // @TODO: そのおかわりにgetTermsOfService(terms_of_service[services.TERMS_OF_SERVICE〇〇])みたいな感じでよいと思う。
            if(selectedRequest.service_name === services.ADEBIS) {
                if (requestQuote.is_old_plan === false) {
                    getTermsOfService(TERMS_OF_SERVICE_NEW)
                } else if (requestQuote.is_old_plan === true) {
                    getTermsOfService(TERMS_OF_SERVICE_OLD)
                }
            } else if(selectedRequest.service_name === services.CAPICO) {
                getTermsOfService(CAPPICO_TERMS_OF_SERVICE)
            } else if (selectedRequest.service_name === services.SPOT) {
                if (requestQuote.is_gsp_paied) {
                    getTermsOfService(SPOT_GSP_TERMS_OF_SERVICE)
                } else {
                    getTermsOfService(SPOT_TERMS_OF_SERVICE)
                }
            }
        }
    }, [terms, getTermsOfService, requestQuote, selectedRequest])

    const _globalTheme = globalTheme._default();
    const listInnerRef = useRef();


    const onScroll = () => {
        if (listInnerRef.current && hasScrolledTermsToBottom === false) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100) {
                scrolledToTermsBottom()
            }
        }
    }

    return (
        <>
            {termsLoading === true ?
                <Box className={_globalTheme.list} display="flex" alignItems="center">
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                          data-testid="loading"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                </Box>
                :
                <TermsAndConditionsScroll
                    terms={terms}
                    listInnerRef={listInnerRef}
                    onScroll={onScroll}
                    hasScrolledToTermsBottom={hasScrolledTermsToBottom}
                    t={t}
                />
            }
        </>
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const AdditionTermsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_additionTermsContainer)

export default AdditionTermsContainer;
