import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../../../state/ducks/root";
import userSideBarLinks from "../../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {empty} from "../../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../../state/utils/Auth0Utils";
import {requestOperations} from "../../../../../../../state/ducks/user/requests";
import {useParams} from "react-router";
import RequestApplyPage from "../../../../../../components/user/requests/detail/quote/apply/apply/Page";
import PlanOptionDetails
    from "../../../../../../../state/utils/data/request_application/plan_option_to_input_block.json";
import PlanOptionInputBlocks from "../../../../../../../state/utils/data/request_application/option_input_blocks.json";
import FullScreenLoading from "../../../../../../atoms/FullScreenLoading";
import services from "../../../../../../../state/utils/data/services.json";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestQuote: state.request.requestQuote,
        requestDetail: state.request.requestDetail,
        requestDetailLoading: state.request.requestDetailLoading,
        requestStartUpInformationStep: state.request.requestStartUpInformationStep,
        requestApplyAcceptTerms: state.request.requestApplyAcceptTerms,
        termsAndConditionsIsOpen: state.request.termsAndConditionsIsOpen,

        changePlanApprovedLoading: state.request.changePlanApprovedLoading,
        changePlanApprovedError: state.request.changePlanApprovedLoading,

        additionalOptionValidation: state.request.additionalOptionValidation,
        additionalOptionInformation: state.request.additionalOptionInformation,
        updateHasCheckedRequiresOptionInformation: state.request.updateHasCheckedRequiresOptionInformation,
        updateRequiresOptionInformation: state.request.updateRequiresOptionInformation,
        updateOptionStep: state.request.updateOptionStep,
        formattedAdditionalOptionInformation: state.request.formattedAdditionalOptionInformation,
        hasScrolledTermsToBottom: state.request.hasScrolledTermsToBottom,
        pdfDataBlobPreview: state.request.pdfDataBlobPreview,
        pdfDataBlobPreviewLoading: state.request.pdfDataBlobPreviewLoading,

        resendEmailCheckLoading: state.request.resendEmailCheckLoading,
        resendEmailCheckPassed: state.request.resendEmailCheckPassed,
        resendEmailCheckError: state.request.resendEmailCheckError,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailQuoteDetailLoad: requestOperations.requestsDetailQuoteDetailLoad,
    resetFormInformation: requestOperations.resetFormInformation,
    applyToggleTerms: requestOperations.applyToggleTerms,
    contractUpdateRequest: requestOperations.contractUpdateRequest,
    settingApplyConfirm: requestOperations.settingApplyConfirm,
    closeTermsAndConditionsModal: requestOperations.closeTermsAndConditionsModal,
    openTermsAndConditionsModal: requestOperations.openTermsAndConditionsModal,
    initializeOptionFormInformation: requestOperations.initializeOptionFormInformation,
    settingAdditionalSteps: requestOperations.settingAdditionalSteps,
    declareNoUpdateOptionNeeded: requestOperations.declareNoUpdateOptionNeeded,
    settingUpdateAdditionalSteps: requestOperations.settingUpdateAdditionalSteps,
    backToAdditionalSteps: requestOperations.backToAdditionalSteps,
    scrolledToTermsBottom: requestOperations.scrolledToTermsBottom,
    downloadQuotePDFToIframe: requestOperations.downloadQuotePDFToIframe,
    sendMailValidation: requestOperations.sendMailValidation,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _requestApplyContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestDetailLoading,
        requestsDetailQuoteDetailLoad,
        resetFormInformation,
        requestStartUpInformationStep,
        applyToggleTerms,

        hasScrolledTermsToBottom,

        requestApplyAcceptTerms,
        contractUpdateRequest,
        changePlanApprovedLoading,
        changePlanApprovedError,
        settingApplyConfirm,
        requestDetail,

        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        updateOptionStep,

        additionalOptionValidation,
        additionalOptionInformation,

        initializeOptionFormInformation,
        settingAdditionalSteps,

        updateHasCheckedRequiresOptionInformation,
        updateRequiresOptionInformation,
        declareNoUpdateOptionNeeded,
        settingUpdateAdditionalSteps,
        backToAdditionalSteps,
        formattedAdditionalOptionInformation,
        
        pdfDataBlobPreview,
        pdfDataBlobPreviewLoading,
        downloadQuotePDFToIframe,

        sendMailValidation,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,

        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rgid, rid, qid} = useParams();
    let selectedRequest = requestDetail?.requests?.find((value) => {
        return value?.uuid === rid
    });
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailQuoteDetailLoad(access_token, managerProfile?.account?.uuid, rgid, rid, qid)
            })()
        }
    }, [rid, qid, getAccessTokenSilently, requestsDetailQuoteDetailLoad, managerProfile])
    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                downloadQuotePDFToIframe(access_token, managerProfile?.account?.uuid, rgid, rid, qid)
            })()
        }
    }, [rid, qid, getAccessTokenSilently, downloadQuotePDFToIframe, managerProfile])
    // 利用契約一覧をAPIから取得する。

    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.requests)
    }, [loadSidebarItems])

    // 申請情報のリセット
    useEffect(() => {
        resetFormInformation()
    }, [resetFormInformation])

    let quoteDetailsArray = [];
    let advertAgentInformation = null;
    
    if(selectedRequest?.is_other_site !== false && selectedRequest?.service_name === services.ADEBIS) {
        advertAgentInformation = PlanOptionInputBlocks.AD_OWNER;
    }

    const mailValidationPackage = {
        sendMailValidation: async (emailValue) => {
            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                () => {
                    return getAccessTokenSilently();
                },
            );
            await sendMailValidation(access_token, managerProfile?.account?.uuid, rgid, rid, emailValue);
        },
        resendEmailCheckLoading: resendEmailCheckLoading,
        resendEmailCheckPassed: resendEmailCheckPassed,
        resendEmailCheckError: resendEmailCheckError,
    }

    useEffect(() => {
        if (advertAgentInformation && !updateHasCheckedRequiresOptionInformation) {
            initializeOptionFormInformation([advertAgentInformation])
        }
        if(empty(advertAgentInformation)) {
            declareNoUpdateOptionNeeded()
        }
    }, [additionalOptionInformation, initializeOptionFormInformation, updateHasCheckedRequiresOptionInformation, declareNoUpdateOptionNeeded])

    requestStartUpInformationStep = requestStartUpInformationStep + (advertAgentInformation ? 0 : 1);
    
    if(updateHasCheckedRequiresOptionInformation) {
        return (
            <RequestApplyPage
                requestDetail={selectedRequest}
                settingUpdateAdditionalSteps={settingUpdateAdditionalSteps}
                backToAdditionalSteps={backToAdditionalSteps}
                updateOptionStep={updateOptionStep}
                advertAgentInformation={advertAgentInformation}
                settingAdditionalSteps={settingAdditionalSteps}
                additionalOptionInformation={additionalOptionInformation}
                additionalOptionValidation={additionalOptionValidation}
                updateRequiresOptionInformation={updateRequiresOptionInformation}
                requestStartUpInformationStep={requestStartUpInformationStep}
                applyToggleTerms={applyToggleTerms}
                hasScrolledToTermsBottom={hasScrolledTermsToBottom}
                requestApplyAcceptTerms={requestApplyAcceptTerms}
                requestDetailLoading={requestDetailLoading}
                settingApplyConfirm={settingApplyConfirm}
                contractUpdateRequest={contractUpdateRequest}
                changePlanApprovedLoading={changePlanApprovedLoading}
                changePlanApprovedError={changePlanApprovedError}
                requestQuote={requestQuote}
                t={t}
                managerProfile={managerProfile}
                quoteId={qid}
                requestGroupId={rgid}
                requestId={rid}
                termsAndConditionsIsOpen={termsAndConditionsIsOpen}
                closeTermsAndConditionsModal={closeTermsAndConditionsModal}
                openTermsAndConditionsModal={openTermsAndConditionsModal}
                formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                pdfDataBlobPreview={pdfDataBlobPreview}
                pdfDataBlobPreviewLoading={pdfDataBlobPreviewLoading}
                mailValidationPackage={mailValidationPackage}
            />
        )
    }
    return (
        <FullScreenLoading/>
    )

}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const RequestApply = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_requestApplyContainer)

export default RequestApply;
