import {takeLeading} from 'redux-saga/effects';
import * as saga from './sagas';
import {default as types, MANAGER_ACCOUNT_USER_ADD_USER} from "./types";
/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* managerFetchAccounts() {
    yield takeLeading(types.MANAGER_REQUEST_ACCOUNT_USERS, saga.searchAccountUsers)
    yield takeLeading(types.MANAGER_ACCOUNT_USER_ADD_USER, saga.addUserList)
    yield takeLeading(types.MANAGER_ACCOUNT_USER_UPDATE_USER, saga.updateUser)
    yield takeLeading(types.MANAGER_ACCOUNT_USER_DELETE_USER, saga.deleteUser)
    // yield takeEvery(types.MANAGER_ACCOUNT_USER_SEARCH_USER_REQUEST, filterUserList)
    yield takeLeading(types.MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER, saga.unblockAuth0User)
    yield takeLeading(types.MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL, saga.resendAuth0VerificationEmail)
}
