import {call, put} from 'redux-saga/effects';
import {
    failureUserList, loadedAccountBasicInformationFailure,
    loadedAccountBasicInformationSuccess,
    loadedUserList,
    loadingAccountBasicInformation,
    loadingUserList
} from "./actions";
import {callBasicInformation} from "./api";
import {error_raise} from "../../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * ユーザー取得リクエスト
 * @param data
 */
export function* selectAccountBasicInformation(data) {
    yield put(loadingAccountBasicInformation())
    try {
        const users = yield call(callBasicInformation, data.payload.access_token, data.payload?.account_id, null);
        yield put(loadedAccountBasicInformationSuccess(users));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(loadedAccountBasicInformationFailure(e));
    }
}
