import React from 'react';
import {Box, Breadcrumbs, Button, Card, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import clsx from "clsx";
import {empty} from "../../../../../state/utils/Common";
import EditableCheck from "../../../../atoms/EditableCheck";
import requestType from "../../../../../state/utils/data/application_request_type_flat.json"
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Link} from "react-router-dom";
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../atoms/AlertModal";
import NotEditableCheck from "../../../../atoms/NotEditableCheck";
import requestStatus from "../../../../../state/utils/data/application_request_status_flat.json"
import RequestBlock from "./block/RequestBlock";
import InitialSetting from "./block/InitialSetting";
import ChangeContractInitialSetting from "./block/ChangeContractInitialSetting";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestDetailView = (
    {
        managerProfile,

        requestDetailLoading,
        requestDetail,
        requestGroupId,

        isCancelConfirmationOpen,
        cancelRequestLoading,
        cancelRequestError,
        cancelRequestSend,
        toggleCancelConfirmation,
        isCancelConfirmedModalOpen,
        history,

        sendMailValidation,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,

        resendMailModalOpen,
        resendMailLoading,
        resendMailError,
        openResendModal,
        closeResendModal,
        sendResendMail,

        additionalFieldsArray,

        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.title')}
        >
            {requestDetailLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">{t('requests.detail.title')}</Typography>
                            <Grid container alignContent={"center"} alignItems={"center"}>
                                <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                                    <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                        <Link to={urlLinks.navigation.top.index.href}>
                                            <Typography color="textPrimary">{t('general.top')}</Typography>
                                        </Link>
                                        <Link to={urlLinks.navigation.requests.index.href}>
                                            <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                                        </Link>
                                        <Link
                                            to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestGroupId)}>
                                            <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                                        </Link>
                                    </Breadcrumbs>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                            {requestDetail.cancelable &&
                                /** キャンセル関係 */
                                <Box alignContent={"end"} style={{float: "right"}}>
                                    <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                        {/** キャンセル期限日 */}
                                        {requestDetail.cancelable && !empty(requestDetail?.cancel_limit_at) &&
                                            <Typography
                                                variant="body1">{t('requests.detail.request_cancel_final_date')}：{requestDetail.cancel_limit_at}</Typography>
                                        }
                                        {requestDetail.cancelable && !empty(requestDetail?.cancel_limit_at) &&
                                            /** キャンセル確定ボタン＋モーダル */
                                            /** 本申込完了移行～アクティベーション待ちまでキャンセル可能 */
                                            <>
                                                <EditableCheck>
                                                    <Button variant={"outlined"}
                                                            onClick={() => toggleCancelConfirmation(true)}
                                                            style={{marginLeft: 15, fontWeight: 900}}
                                                            color={"primary"}>
                                                        {t('requests.detail.request_cancel_application')}
                                                    </Button>
                                                    <ConfirmationModal
                                                        isOpen={isCancelConfirmationOpen}
                                                        setClose={() => toggleCancelConfirmation(false)}
                                                        confirmationTitle={t('requests.detail.request_cancel_application')}
                                                        cancelText={t('actions.cancel')}
                                                        buttonText={t('actions.ok')}
                                                        loadingText={t('actions.sending')}
                                                        isLoading={cancelRequestLoading}
                                                        error={cancelRequestError}
                                                        id={requestDetail.uuid}
                                                        profile={managerProfile}
                                                        event={(id, access_token, uuid) => cancelRequestSend(access_token, uuid, id)}
                                                    />
                                                    <AlertModal
                                                        isOpen={isCancelConfirmedModalOpen}
                                                        event={() => history.push('/requests')}
                                                        buttonText={t('actions.ok')}
                                                        confirmationTitle={t('requests.detail.request_cancel_application')}
                                                    />
                                                </EditableCheck>
                                                <NotEditableCheck>
                                                    <Button variant={"outlined"}
                                                            disabled={true}
                                                            style={{marginLeft: 15, fontWeight: 900}}
                                                            color={"primary"}>
                                                        {t('requests.detail.request_cancel_application')}
                                                    </Button>
                                                </NotEditableCheck>
                                            </>
                                        }
                                    </Grid>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Typography variant="h4"> {t('requests.detail.information')}</Typography>
                                <Grid container style={{marginTop: 26}}>
                                    {/** 申込担当者 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_manager')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.application_user_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込年月日 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_date')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.application_at}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込種別 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_category')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.type_name}</Typography>
                                    </Grid>
                                </Grid>
                                {(requestDetail.type === requestType.NEW) && !empty(requestDetail?.usage_category) &&
                                    <Grid container style={{marginTop: 15}}>
                                        <Grid item xs={2} xl={1}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('requests.detail.usage_category')}</Typography>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <Typography variant="body1">{requestDetail?.usage_category}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込担当者 */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.service_name')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{requestDetail.service_name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{marginTop: 15}}>
                                    {/** 申込ステータス */}
                                    <Grid item xs={2} xl={1}>
                                        <Typography variant="body1"
                                                    className={classes.strong}>{t('requests.detail.request_status')}</Typography>
                                    </Grid>
                                    <Grid item xs={10} xl={11}>
                                        <Typography variant="body1">{requestDetail.status_name}</Typography>
                                    </Grid>
                                </Grid>
                                {requestDetail.status === requestStatus.CANCELED &&
                                    <Grid container style={{marginTop: 15}}>
                                        {/** 申込手続き完了日 */}
                                        <Grid item xs={2} xl={1}>
                                            <Typography variant="body1"
                                                        className={classes.strong}>{t('requests.detail.request_cancel_day')}</Typography>
                                        </Grid>
                                        <Grid item xs={10} xl={11}>
                                            <Typography variant="body1">
                                                {requestDetail.cancelled_at}
                                                {!empty(requestDetail?.cancel_user_name) &&
                                                    <>
                                                        ({requestDetail.cancel_user_name})
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Box>
                        </Card>

                        {/** 新規 + 本申込完了 */}
                        {requestDetail?.status === requestStatus.APPLIED && requestDetail?.type === requestType.NEW 
                            && (requestDetail.requests.length === 1 && requestDetail.requests[0].service_name === 'その他') === false &&
                            <InitialSetting
                                t={t}
                                requestDetail={requestDetail}
                            >
                            </InitialSetting>
                        }
                        {/** ※再確認要 更新・変更 + 本申込前  + 子申込 = 申込完了 + 要初期設定 */}
                        {requestDetail?.status === requestStatus.WAIT_FOR_APPLY && [requestType.CHANGE, requestType.RENEW].includes(requestDetail?.type) && !empty(additionalFieldsArray) 
                            && (requestDetail.requests.length === 1 && requestDetail.requests[0].service_name === 'その他') === false &&
                            <ChangeContractInitialSetting
                                t={t}
                                requestDetail={requestDetail}
                            ></ChangeContractInitialSetting>
                        }
                        {/** ※再確認要 更新・変更・解約・自動更新 + 本申込完了 + 子申込 = 申込完了 */}
                        {requestDetail?.status >= requestStatus.APPLIED && requestDetail?.status <= requestStatus.COMPLETE && [requestType.CHANGE, requestType.RENEW, requestType.CANCEL, requestType.RENEW_AUTO].includes(requestDetail?.type) && requestDetail.is_child_request_applied === true 
                            && (requestDetail.requests.length === 1 && requestDetail.requests[0].service_name === 'その他') === false &&
                            <InitialSetting
                                t={t}
                                requestDetail={requestDetail}
                            >
                            </InitialSetting>
                        }


                        {!empty(requestDetail.requests) && requestDetail.requests.map((request, index) => (
                            <RequestBlock
                                managerProfile={managerProfile}
                                request={request}
                                requestGroupId={requestGroupId}
                                requestId={request?.uuid}
                                resendEmailCheckLoading={resendEmailCheckLoading}
                                resendEmailCheckPassed={resendEmailCheckPassed}
                                resendEmailCheckError={resendEmailCheckError}

                                resendMailModalOpen={resendMailModalOpen}
                                resendMailLoading={resendMailLoading}
                                resendMailError={resendMailError}
                                openResendModal={openResendModal}
                                closeResendModal={closeResendModal}
                                sendResendMail={sendResendMail}
                                sendMailValidation={sendMailValidation}

                                t={t}
                            />
                        ))}
                    </Box>
                </Container>
            }
        </Page>
    )
        ;
}

export default RequestDetailView;
