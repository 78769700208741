import {call, put} from 'redux-saga/effects';
import {error_raise, getProfileFailure, getProfileSuccess} from "./actions";
import {callManagerProfileAPI, sendErrorReportAPI} from "./api";
import {empty, stackTracer} from "../../utils/Common";
import LoginAsUtils from "../../utils/LoginAsUtils";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * ユーザープロファイルの取得リクエスト
 * @param data
 */
export function* callManagerProfile(data) {
    try {
        const profile = yield call(callManagerProfileAPI, data.payload.auth0_id, data.payload.access_token);
        let profileData = profile.data;
        if(data.payload.isUserScreen && !empty(LoginAsUtils.get())) {
            profileData = {
                ...profileData,
                is_pretend: true,
                account: {
                    ...profileData.account,
                    uuid: LoginAsUtils.get(),
                    pretend_account_name: LoginAsUtils.getAccountName()
                }
            }
        }
        yield put(getProfileSuccess(profileData));
    } catch (e) {
        e.stack = stackTracer();
        e.message = 'ユーザープロフィール取得することができませんでした。';
        yield put(getProfileFailure(e));
        yield put(error_raise(e))
    }
}

/**
 * 発生されたエラー内容をAPIに送信
 * @param data
 */
export function* sendErrorReport(data) {
    try {
        yield call(sendErrorReportAPI, data.payload.access_token, data.payload.error)
    } catch (e) {
        console.log(e)
        console.log('クライアントエラー内容を送信出来ない')
    }
}

