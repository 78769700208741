import React from 'react';
import {Box, Breadcrumbs, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../Page";
import Loading from "../../../../atoms/Loading";
import {empty} from "../../../../../state/utils/Common";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import Contact from "./Contact";
import EditableCheck from "../../../../atoms/EditableCheck";
import {ConfirmationModal} from "../../../../atoms/ConfirmationModal";
import {AlertModal} from "../../../../atoms/AlertModal";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {useAuth0} from "@auth0/auth0-react";
import ContactUpdateModal from "./modal/ContactUpdateModal";
import ContactUpdateContainer from "../../../../containers/user/serviceContracts/contacts/ContactUpdateContainer";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1)
    }
}));

/**
 * 利用契約の詳細ページコンポーネント
 * /service_contactの利用契約を選択したレコードからの画面
 *
 */
const ContactOverview = (
    {
        managerProfile,
        activeServiceContractError,
        contactLoading,
        activeServiceContract,
        serviceContractId,
        toggleUpdateModal,
        isUpdateModalOpen,
        t
    }) => {
    // アプリケーションスタイル
    const classes = useStyles();
    // URL用ライブラリのロード
    const history = useHistory();
    // 連絡先情報がない場合は、serviceContractsページにリダイレクトされます。

    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.detail.title', {contract_name: !empty(activeServiceContract) ? activeServiceContract?.service_account_name : ""})}
        >
            {contactLoading === false ?
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h2">{t('service_contracts.detail.title', {contract_name: !empty(activeServiceContract) ? activeServiceContract?.service_account_name : ""})}</Typography>
                            <Breadcrumbs aria-label="breadcrumb" separator={">"} style={{fontSize: 11}}>
                                <Link to={urlLinks.navigation.top.index.href}>
                                    <Typography color="textPrimary">
                                        {t('general.top')}
                                    </Typography>
                                </Link>
                                <Link to={urlLinks.navigation.service_contracts.index.href}>
                                    <Typography
                                        color="textPrimary">
                                        {t('service_contracts.title')}
                                    </Typography>
                                </Link>
                                <Link
                                    to={urlLinks.navigation.service_contracts.index.sub_links.contacts.href.replace('{SCID}', serviceContractId)}>
                                    <Typography
                                        color={"textPrimary"}>{t('service_contracts.contact_breadcrumb')}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={12} md={6} style={{marginTop: 7, marginBottom: 15}}>
                            <Box alignContent={"end"} style={{float: "right"}}>
                                <Grid container justify="center" alignContent={"center"} alignItems={"center"}>
                                    <EditableCheck>
                                        <Button
                                                onClick={() => toggleUpdateModal(true) }
                                                color="primary"
                                                variant="contained"
                                                style={{ fontWeight: 900 }}
                                        >
                                            {t('actions.settings_change')}
                                        </Button>
                                    </EditableCheck>
                                </Grid>
                                {isUpdateModalOpen &&
                                    <ContactUpdateContainer/>
                                }
                            </Box>
                        </Grid>
                        {(activeServiceContract?.sold_to_contact?.status === 'changing' || activeServiceContract?.bill_to_contact?.status === 'changing') &&
                            <Grid item xs={12} md={12}>
                                <Typography variant={"subtitle1"} style={{paddingBottom: 5, whiteSpace: "pre-wrap"}}>
                                    {t('service_contracts.detail.in_change_process')}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={12}>
                            <Typography variant={"subtitle1"} style={{paddingBottom: 5, whiteSpace: "pre-wrap"}}>
                                {t('service_contracts.detail.account_contract_change_notice', {account_name: !empty(activeServiceContract) ? activeServiceContract?.service_account_name : ""})}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Contact
                            title={t('service_contracts.detail.sold_to_title')}
                            activeServiceContract={activeServiceContract.sold_to_contact}
                            contactLoading={contactLoading}
                            t={t}
                        />
                    </Box>
                    <Box mt={3}>
                        
                        <Contact
                            title={t('service_contracts.detail.billed_to_title')}
                            activeServiceContract={activeServiceContract.bill_to_contact}
                            contactLoading={contactLoading}
                            t={t}
                        />
                    </Box>
                </Container>
                :
                <Container maxWidth={false}>
                    <Loading/>
                </Container>
            }
        </Page>
    );
}

export default ContactOverview;
