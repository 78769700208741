import ApiUtils from "../../../utils/ApiUtils";
import {
    MANAGER_DOWNLOAD_REQUESTS_API_PATH,
    MANAGER_GET_REQUEST_DETAIL_API_PATH,
    MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH,
    MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD,
    MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD_DATA,
    MANAGER_GET_REQUESTS_API_PATH,
    MANAGER_SEND_APPROVE_TERMINATE_REQUEST_API_PATH,
    MANAGER_SEND_CANCEL_REQUEST_API_PATH
} from "./constants";

/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /noticesのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */
export function managerCallRequests(accessToken, params) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_REQUESTS_API_PATH,
        params,
        null
    )
}

export function managerDownloadRequests(accessToken, formData) {
    return new ApiUtils(accessToken).get(
        MANAGER_DOWNLOAD_REQUESTS_API_PATH,
        formData,
        null,
        {
            responseType: "blob",
            timeout: 1000 * process.env.REACT_APP_CSV_DOWNLOAD_TIMEOUT,
        }
    ).then(response => {
        let blob = new Blob([response.data], {type: 'text/csv; charset=utf-8'}),
            url = window.URL.createObjectURL(blob)

        window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
        return response;
    });
}

/**
 * 申込詳細取得APIリクエスト
 * @param accessToken
 * @param params
 * @param request_group_id
 * @returns {AxiosPromise}
 */
export function managerCallRequestsDetail(accessToken, params, request_group_id) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_REQUEST_DETAIL_API_PATH.replace('{RGID}', request_group_id),
        params,
        null
    )
}

/**
 * 申込見積詳細取得APIリクエスト
 * @param accessToken
 * @param params
 * @param request_group_id
 * @param request_id
 * @param quote_id
 * @returns {AxiosPromise}
 */
export function managerCallRequestQuote(accessToken, params, request_group_id, request_id, quote_id) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH
            .replace('{RGID}', request_group_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        params,
        null
    )
}

export function managerCallRequestQuotePDFDownload(accessToken, params, request_group_id, request_id, quote_id, quote_no) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD
            .replace('{RGID}', request_group_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        params,
        null,
    ).then(response => {
        let userAgentString = navigator.userAgent;
        if (userAgentString.indexOf("Firefox") > -1) {
            const url = response.data.url;
            window.open(url, '_blank');
        } else {
            const a = document.createElement("a"); //Create <a>
            a.href = response.data.url
            a.click(); //Downloaded file
        }
        return response;
    });
}

export function callRequestQuotePDFToIframe(accessToken, params, request_group_id, request_id, quote_id) {
    return new ApiUtils(accessToken).get(
        MANAGER_GET_REQUEST_DETAIL_QUOTE_DETAIL_PATH_PDF_DOWNLOAD_DATA
            .replace('{RGID}', request_group_id)
            .replace('{RID}', request_id)
            .replace('{QID}', quote_id),
        params,
        null
    );
}

export function managerCancelRequestAPI(accessToken, request_group_id) {
    return new ApiUtils(accessToken).put(
        MANAGER_SEND_CANCEL_REQUEST_API_PATH
            .replace('{RGID}', request_group_id),
        "",
        null
    )
}

/**
 * @param accessToken
 * @param request_group_id
 */
export function managerApproveTerminateRequestAPI(accessToken, request_group_id) {
    return new ApiUtils(accessToken).put(
        MANAGER_SEND_APPROVE_TERMINATE_REQUEST_API_PATH
            .replace('{RGID}', request_group_id),
        "",
        null
    )
}
