import React from 'react';
import {Grid, Radio, Typography} from "@material-ui/core";
import globalTheme from "../../../../../../theme/globalTheme";

/**
 * コンポーネントスタイル
 * @returns {{transform: string, top: string, left: string, width: number}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: 705,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 利用契約 - 連絡先｜担当者情報の住所変更モーダル
 *
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
export const ContactUpdateRow = props => {
    const commonTheme = globalTheme._default();

    return (
        <Grid container key={props.data.contacts[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            cursor: props.updateLoading ? "" : "pointer",
            paddingBottom: 13,
            paddingRight: 45,
            borderBottom: "1px #F2F2F2 solid"
        }}>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                    style={{fontWeight: 900}}
                >
                    {props.index !== 0 &&
                        <Radio
                            style={{padding: 0, marginTop: -2}}
                            color={"primary"}
                            onChange={(e) => props.data.setContactUuid(e.target.value)}
                            checked={props.data.selectedContactUuid === props.data.contacts[props.index].uuid.toString()}
                            value={props.data.contacts[props.index].uuid.toString()}
                            name={"client_addresses"}
                        />
                    }
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.contacts[props.index].status_name}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.contacts[props.index].name}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.contacts[props.index].department}
                </Typography>
            </Grid>
            <Grid item xs={4} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.t('address.postal_code_sign')}{props.data.contacts[props.index].postal_code} {props.data.contacts[props.index].state}{props.data.contacts[props.index].city}{props.data.contacts[props.index].address}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography
                    noWrap={true}
                    color="textPrimary"
                    variant="body1"
                    className={commonTheme.breakWrap}
                >
                    {props.data.contacts[props.index].email}
                </Typography>
            </Grid>
        </Grid>
    )
};
