import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractListOperations} from "../../../../state/ducks/user/service_contracts";
import ServiceContractView from "../../../components/user/serviceContracts/Page";
import {rootOperations} from "../../../../state/ducks/root";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.serviceContractList.loading,
        isError: state.serviceContractList.isError,
        serviceContractList: state.serviceContractList.serviceContractList,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,

    loadServiceContractList: serviceContractListOperations.loadServiceContractList,
}

/**
 * Redux
 * i18next Translations
 * を
 * ServiceContractView コンポーネントに追加する。
 */
const ServiceContractsListContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ServiceContractView)

export default ServiceContractsListContainer;
