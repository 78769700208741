/**
 * //////////////////////////////////////////////////
 * REDUCKSメインファイル
 * --------------------------
 *
 * セレクタ、オペレーション、サガ、リデューサを束ねるメインファイル。
 * Reducksの他のコンポーネント（managerAccountsなど）と結合するために、主にducks/index.jsファイルにエクスポートされます。
 * ///////////////////////////////////////////////////
 */

import * as watchersSagas from './watchersSagas';

export const managerAccountUserWatcherSagas = Object.values(watchersSagas);
export {default as managerAccountUserSelectors} from "./selectors";
export {default as managerAccountUserOperations} from "./operations";
export {default as managerAccountUserTypes} from "./types";
export {default as managerAccountUserForms} from "./forms"

export {managerAccountUserState, default as managerAccountUserReducer} from './reducers';
