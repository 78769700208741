import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

export function managerLoadRequestSearch(form) {
    return {
        type: types.MANAGER_REQUESTS_SEARCH,
        payload: {
            form: form,
        }
    }
}

export function managerRequestsLoad(access_token, currentPage, form) {
    const rowTotal = parseInt(process.env.REACT_APP_MANAGER_REQUEST_LIST_MAX_ROWS)
    return {
        type: types.MANAGER_REQUESTS_LOAD,
        payload: {
            access_token: access_token,
            data: {
                ...form,
                current_page: currentPage,
                row_per_page: rowTotal
            }
        }
    }
}

export function managerRequestsLoading() {
    return {
        type: types.MANAGER_REQUESTS_LOADING,
    }
}

export function managerRequestsSuccess(requests, total_rows) {
    const rowTotal = parseInt(process.env.REACT_APP_MANAGER_REQUEST_LIST_MAX_ROWS)
    return {
        type: types.MANAGER_REQUESTS_SUCCESS,
        payload: {
            requests: requests,
            total_page: Math.ceil(total_rows / rowTotal),
            total_rows: total_rows
        }
    }
}

export function managerRequestsFailed(e) {
    return {
        type: types.MANAGER_REQUESTS_FAILED,
        payload: {
            error: e
        }
    }
}

export function formEditEvent(form) {
    return {
        type: types.MANAGER_EDIT_SEARCH_FORM,
        payload: {
            form: form
        }
    }
}

/**
 * ページ変更
 *
 * @param {number|string} pageNo - 次のページ番号
 */
export function managerChangePageAccountList(pageNo) {
    return {
        type: types.MANAGER_CHANGE_REQUESTS_PAGE,
        payload: {
            pageNo: pageNo
        }
    }
}

/*************
 * 申込一覧をダウンロード
 ****+********/

/**
 * 申込一覧ダウンロードの起動開始エベント
 * @param access_token
 * @param form
 * @returns {{payload: {access_token, account_id, quote_id, request_id}, type: *}}
 */
export function downloadRequestList(access_token, form) {
    return {
        type: types.MANAGER_REQUESTS_LIST_DOWNLOAD,
        payload: {
            access_token: access_token,
            form: form
        }
    }
}

/**
 * ダウンロード中エベント
 * @returns {{type: *}}
 */
export function downloadRequestListLoading() {
    return {
        type: types.MANAGER_REQUESTS_LIST_DOWNLOAD_LOADING
    }
}

/**
 * ダウンロード成功のエベント
 * @returns {{payload: {binaryFileData}, type: *}}
 */
export function downloadRequestListSuccess() {
    return {
        type: types.MANAGER_REQUESTS_LIST_DOWNLOAD_SUCCESS,
    }
}

/**
 * ダウンロード失敗のエベント
 * @returns {{payload: {error}, type: *}}
 */
export function downloadRequestListFailure(e) {
    return {
        type: types.MANAGER_REQUESTS_LIST_DOWNLOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * お申込詳細ページ
 ***********************************/

/**
 * 申込詳細の取得エベント
 * @param access_token
 * @param account_id
 * @param request_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function managerRequestsDetailLoad(access_token, request_group_id) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            request_group_id: request_group_id
        }
    }
}

/**
 * 申込詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function managerRequestsDetailLoading() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param request_detail
 */
export function managerRequestsDetailSuccess(request_detail) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_LOAD_SUCCESS,
        payload: {
            request_detail: request_detail
        }
    }
}

/**
 * 申込詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function managerRequestsDetailFailed(e) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * お申込詳細の見積詳細
 ***********************************/

/**
 * 申込見積詳細の取得エベント
 * @param access_token
 * @param request_id
 * @param quote_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function managerRequestsDetailQuoteDetailLoad(access_token, request_group_id, request_id, quote_id) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            request_group_id: request_group_id,
            request_id: request_id,
            quote_id: quote_id
        }
    }
}

/**
 * 申込見積詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function managerRequestsDetailQuoteDetailLoading() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込見積詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param quote
 */
export function managerRequestsDetailQuoteDetailSuccess(quote) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS,
        payload: {
            quote: quote
        }
    }
}

/**
 * 申込見積詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function managerRequestsDetailQuoteDetailFailed(e) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

export function managerCloseTermsAndConditionsModal() {
    return {
        type: types.MANAGER_CLOSE_TERMS_AND_CONDITIONS_MODAL
    }
}

export function managerOpenTermsAndConditionsModal() {
    return {
        type: types.MANAGER_OPEN_TERMS_AND_CONDITIONS_MODAL
    }
}

/*************
 * 申込PDFダウウンロード＋IframeにBLOBを渡す
 */
export function downloadQuotePDFToIframe(accessToken, request_group_id, request_id, quote_id) {
    return {
        type: types.MANAGER_REQUEST_LOAD_QUOTE_PDF,
        payload: {
            access_token: accessToken,
            request_group_id: request_group_id,
            request_id: request_id,
            quote_id: quote_id
        }
    }
}

export function downloadQuotePDFToIframeLoading() {
    return {
        type: types.MANAGER_REQUEST_LOAD_QUOTE_PDF_LOADING
    }
}

export function downloadQuotePDFToIframeSuccess(blobData) {
    return {
        type: types.MANAGER_REQUEST_LOAD_QUOTE_PDF_SUCCESS,
        payload: {
            blobData: blobData
        }
    }
}

export function downloadQuotePDFToIframeFailure(error) {
    return {
        type: types.MANAGER_REQUEST_LOAD_QUOTE_PDF_FAILURE,
        payload: {
            error: error
        }
    }
}

/*************
 * 見積PDFダウンロード
 ****+********/
export function managerDownloadQuotePDF(access_token, request_group_id, request_id, quote_id, quote_no) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF,
        payload: {
            access_token: access_token,
            request_id: request_id,
            request_group_id: request_group_id,
            quote_id: quote_id,
            quote_no: quote_no
        }
    }
}

export function managerDownloadQuoteLoading() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING
    }
}

export function managerDownloadQuoteSuccess() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS,
    }
}

export function managerDownloadQuoteFailure(e) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * 申込のキャンセル処理
 ***********************************/
export function toggleCancelConfirmation(willOpen = true) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE,
        payload: {
            willOpen: willOpen
        }
    }
}

export function cancelRequestSend(access_token, request_id) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST,
        payload: {
            access_token: access_token,
            request_id: request_id,
        }
    }
}

export function cancelRequestSendLoading() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_LOADING,
    }
}

export function cancelRequestSendSuccess() {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS,
    }
}

export function cancelRequestSendFailure(e) {
    return {
        type: types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * 解約申込の受理アクション
 ***********************************/
export function toggleApproveTerminateRequest(willOpen = true) {
    return {
        type: types.MANAGER_APPROVE_TERMINATE_CONTRACT_MODAL_TOGGLE,
        payload: {
            willOpen: willOpen
        }
    }
}

export function approveTerminateRequest(access_token, request_id) {
    return {
        type: types.MANAGER_APPROVE_TERMINATE_CONTRACT,
        payload: {
            access_token: access_token,
            request_id: request_id,
        }
    }
}

export function approveTerminateRequestLoading() {
    return {
        type: types.MANAGER_APPROVE_TERMINATE_CONTRACT_LOADING,
    }
}

export function approveTerminateRequestSuccess() {
    return {
        type: types.MANAGER_APPROVE_TERMINATE_CONTRACT_SUCCESS,
    }
}

export function approveTerminateRequestFailure(e) {
    return {
        type: types.MANAGER_APPROVE_TERMINATE_CONTRACT_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * アカウントIDからアカウント名を取得するエベント
 ***********************************/
export function managerSetAccountNameToSearchRequest(account_name) {
    return {
        type: types.MANAGER_ACCOUNT_SET_ACCOUNT_NAME_TO_FORM,
        payload: {
            account_name: account_name
        }
    }
}

export function parseAccountIdLoading() {
    return {
        type: types.MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_LOADING
    }
}

export function parseAccountIdSuccess(account_data) {
    return {
        type: types.MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_SUCCESS,
        payload: {
            account_name: account_data.account.name
        }
    }
}

export function parseAccountIdFailure(e) {
    return {
        type: types.MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_FAILURE,
        payload: {
            error: e
        }
    }
}


const actions = {
    managerLoadRequestSearch,
    formEditEvent,

    managerRequestsLoad,
    managerRequestsLoading,
    managerRequestsSuccess,
    managerRequestsFailed,

    managerChangePageAccountList,

    downloadRequestList,
    downloadRequestListLoading,
    downloadRequestListSuccess,
    downloadRequestListFailure,

    managerRequestsDetailLoad,
    managerRequestsDetailLoading,
    managerRequestsDetailSuccess,
    managerRequestsDetailFailed,

    managerRequestsDetailQuoteDetailLoad,
    managerRequestsDetailQuoteDetailLoading,
    managerRequestsDetailQuoteDetailSuccess,
    managerRequestsDetailQuoteDetailFailed,

    managerCloseTermsAndConditionsModal,
    managerOpenTermsAndConditionsModal,

    managerDownloadQuotePDF,
    managerDownloadQuoteLoading,
    managerDownloadQuoteSuccess,
    managerDownloadQuoteFailure,

    toggleCancelConfirmation,
    cancelRequestSend,
    cancelRequestSendLoading,
    cancelRequestSendSuccess,
    cancelRequestSendFailure,

    toggleApproveTerminateRequest,
    approveTerminateRequest,
    approveTerminateRequestLoading,
    approveTerminateRequestSuccess,
    approveTerminateRequestFailure,

    managerSetAccountNameToSearchRequest,
    parseAccountIdLoading,
    parseAccountIdSuccess,
    parseAccountIdFailure,

    downloadQuotePDFToIframe,
};

export default actions;
