import {
    default as types,
    MANAGER_ACCOUNT_USER_ADD_USER_FAILURE,
    MANAGER_ACCOUNT_USER_ADD_USER_LOADING,
    MANAGER_ACCOUNT_USER_ADD_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_FAILURE,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_LOADING,
    MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_CLOSE_MODAL,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_FAILURE,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_LOADING,
    MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_DELETE_USER_FAILURE,
    MANAGER_ACCOUNT_USER_DELETE_USER_LOADING,
    MANAGER_ACCOUNT_USER_DELETE_USER_SUCCESS,
    MANAGER_ACCOUNT_USER_DELETE_USER_TOGGLE,
    MANAGER_ACCOUNT_USER_TOGGLE_REGISTER
} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトの管理者アカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているmanagerAccountsグループに束縛されています。
 * //////////////////////////////////////////
 */

export const managerAccountUserState = {
    managerAccountUsersLoading: true,
    managerAccountActiveUsers: {},
    managerAccountUsersError: {},
    managerActiveProfile: {},

    managerIsRegisterView: false,
    managerDeleteView: false,

    managerDeleteLoading: false,
    managerDeleteError: {},
    managerRegisterLoading: false,
    managerRegisterError: {},

    managerLoading: true,
    managerIsError: false,
    managerUserList_CurrentPage: 0,

    managerSearchLoad: false,
    managerSearchUserResult: {},

    managerAuth0UserUnblockLoading: false,
    managerAuth0UserUnblockError: {},
    managerUnblockNotificationShow: false,

    managerResendAuth0MailLoading: false,
    managerResendAuth0MailError: {},
    managerResendNotificationShow: false,

    managerError_code: "",
    managerError: {},
}
export default function accountUser(state = managerAccountUserState, action) {
    switch (action.type) {
        //-------------------------------------
        // アカウントのユーザー一覧リクエスト
        //-------------------------------------
        case types.MANAGER_REQUEST_ACCOUNT_USERS_LOADING:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = true,
                managerAccountUsersError: state.managerAccountUsersError = {},
                managerAccountActiveUsers: state.managerAccountActiveUsers = {}
            }
        case types.MANAGER_REQUEST_ACCOUNT_USERS_SUCCESS:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = false,
                managerAccountUsersError: state.managerAccountUsersError = {},
                managerAccountActiveUsers: state.managerAccountActiveUsers = action.payload.accountUsers,
                managerDetailAccountData: state.managerDetailAccountData = action.payload.account
            }
        case types.MANAGER_REQUEST_ACCOUNT_USERS_FAILURE:
            return {
                ...state,
                managerAccountUsersLoading: state.managerAccountUsersLoading = false,
                managerAccountUsersError: state.managerAccountUsersError = action.payload.managerError,
            }
        // -----------------------------------------------
        // ユーザー登録
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_USER_TOGGLE_REGISTER:
            return {
                ...state,
                managerIsRegisterView: !state.managerIsRegisterView,
                managerActiveProfile: state.managerActiveProfile = action.payload.user_id,
                managerRegisterError: state.managerRegisterError = {},
            }
        case types.MANAGER_ACCOUNT_USER_ADD_USER_LOADING:
            return {
                ...state,
                managerRegisterError: state.managerRegisterError = {},
                managerRegisterLoading: state.managerRegisterLoading = true
            }
        case types.MANAGER_ACCOUNT_USER_ADD_USER_SUCCESS:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerIsError: state.managerIsError = false,
                managerError: state.managerError = {},
                managerRegisterLoading: state.managerRegisterLoading = false,
                managerRegisterError: state.managerRegisterError = {},
                managerIsRegisterView: state.managerIsRegisterView = false,
                managerError_code: ""
            }
        case types.MANAGER_ACCOUNT_USER_ADD_USER_FAILURE:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerIsError: state.managerIsError = false,
                managerRegisterLoading: state.managerRegisterLoading = false,
                managerRegisterError: state.managerRegisterError = action.payload.managerError,
            }
        // -----------------------------------------------
        // ユーザー削除
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_USER_DELETE_USER_TOGGLE:
            return {
                ...state,
                managerDeleteView: !state.managerDeleteView,
                managerDeleteError: state.managerDeleteError = {},
                managerActiveProfile: state.managerActiveProfile = action.payload.accountId
            }
        case types.MANAGER_ACCOUNT_USER_DELETE_USER_LOADING:
            return {
                ...state,
                managerDeleteLoading: state.managerDeleteLoading = true,
                managerDeleteError: state.managerDeleteError = {},
            }
        case types.MANAGER_ACCOUNT_USER_DELETE_USER_SUCCESS:
            return {
                ...state,
                managerDeleteLoading: state.managerDeleteLoading = false,
                managerDeleteError: state.managerDeleteError = {},
                managerActiveProfile: state.managerActiveProfile = {},
                managerDeleteView: state.managerDeleteView = false
            }
        case types.MANAGER_ACCOUNT_USER_DELETE_USER_FAILURE:
            return {
                ...state,
                managerDeleteError: state.managerDeleteError = action.payload.managerError,
                managerDeleteLoading: state.managerDeleteLoading = false,
            }
        // -----------------------------------------------
        // ブロック解除
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_LOADING:
            return {
                ...state,
                managerAuth0UserUnblockLoading: state.managerAuth0UserUnblockLoading = true,
                managerAuth0UserUnblockError: state.managerAuth0UserUnblockError = {},
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_SUCCESS:
            return {
                ...state,
                managerUnblockNotificationShow: state.managerUnblockNotificationShow = true,
                managerAuth0UserUnblockLoading: state.managerAuth0UserUnblockLoading = false
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_FAILURE:
            return {
                ...state,
                managerAuth0UserUnblockLoading: state.managerAuth0UserUnblockLoading = false,
                managerAuth0UserUnblockError: state.managerAuth0UserUnblockError = action.payload.managerError,
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_UNLOCK_USER_CLOSE_MODAL:
            return {
                ...state,
                managerUnblockNotificationShow: state.managerUnblockNotificationShow = false
            }
        // -----------------------------------------------
        // 認証メール再送信
        // -----------------------------------------------
        case types.MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_LOADING:
            return {
                ...state,
                managerResendAuth0MailLoading: state.managerResendAuth0MailLoading = true,
                managerResendAuth0MailError: state.managerResendAuth0MailError = {},
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                managerResendNotificationShow: state.managerResendNotificationShow = true,
                managerResendAuth0MailLoading: state.managerResendAuth0MailLoading = false
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                managerResendAuth0MailLoading: state.managerResendAuth0MailLoading = false,
                managerResendAuth0MailError: state.managerResendAuth0MailError = action.payload.managerError,
            }
        case types.MANAGER_ACCOUNT_USER_AUTH0_RESEND_VERIFY_EMAIL_CLOSE_MODAL:
            return {
                ...state,
                managerResendNotificationShow: state.managerResendNotificationShow = false
            }
        default:
            return state;
    }
}
