import {takeLeading} from 'redux-saga/effects';
import {selectAccountBasicInformation} from './sagas';
import {default as types} from "./types";

/**
 * //////////////////////////////////////////
 * SAGA WATCHERS (サガ・ウォッチャー)
 * --------------------
 * actions.jsからのすべてのアクションは、ここで読み込まれてからreducerに送られます。
 * イベントタイプが一致した場合、下記の第2パラメータの関数が呼び出され、任意のアクションデータを使用することができます。
 * ////////////////////////////////////////////
 */
export function* fetchUsers() {
    yield takeLeading(types.REQUEST_ACCOUNT_BASIC_INFORMATION, selectAccountBasicInformation);
}
