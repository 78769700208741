/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

// アカウント担当者一覧
export const REQUEST_ACCOUNT_CONTACTS = 'REQUEST_ACCOUNT_CONTACTS'
export const REQUEST_ACCOUNT_CONTACTS_LOADING = 'REQUEST_ACCOUNT_CONTACTS_LOADING'
export const REQUEST_ACCOUNT_CONTACTS_SUCCESS = 'REQUEST_ACCOUNT_CONTACTS_SUCCESS'
export const REQUEST_ACCOUNT_CONTACTS_FAILURE = 'REQUEST_ACCOUNT_CONTACTS_FAILURE'

// アカウント担当者登録モーダル
export const REQUEST_ACCOUNT_CONTACTS_REGISTRATION_MODAL_TOGGLE = 'REQUEST_ACCOUNT_CONTACTS_REGISTRATION_MODAL_TOGGLE'
export const REQUEST_ACCOUNT_CONTACTS_UPDATE_MODAL_TOGGLE = 'REQUEST_ACCOUNT_CONTACTS_UPDATE_MODAL_TOGGLE'
// アカウント担当者削除モーダル
export const REQUEST_ACCOUNT_CONTACTS_DELETE_MODAL_TOGGLE = 'REQUEST_ACCOUNT_CONTACTS_DELETE_MODAL_TOGGLE'
export const REQUEST_ACCOUNT_CONTACT_DELETE_COMPLETE_MODAL_TOGGLE = 'REQUEST_ACCOUNT_CONTACT_DELETE_COMPLETE_MODAL_TOGGLE'

// 新規アカウント担当者登録
export const REQUEST_ACCOUNT_CONTACT_REGISTRATION = 'REQUEST_ACCOUNT_CONTACT_REGISTRATION'
export const REQUEST_ACCOUNT_CONTACT_REGISTRATION_LOADING = 'REQUEST_ACCOUNT_CONTACT_REGISTRATION_LOADING'
export const REQUEST_ACCOUNT_CONTACT_REGISTRATION_SUCCESS = 'REQUEST_ACCOUNT_CONTACT_REGISTRATION_SUCCESS'
export const REQUEST_ACCOUNT_CONTACT_REGISTRATION_FAILURE = 'REQUEST_ACCOUNT_CONTACT_REGISTRATION_FAILURE'

// アカウント担当者詳細
export const REQUEST_ACCOUNT_CONTACT_DETAILS = 'REQUEST_ACCOUNT_CONTACT_DETAILS'
export const REQUEST_ACCOUNT_CONTACT_DETAILS_LOADING = 'REQUEST_ACCOUNT_CONTACT_DETAILS_LOADING'
export const REQUEST_ACCOUNT_CONTACT_DETAILS_SUCCESS = 'REQUEST_ACCOUNT_CONTACT_DETAILS_SUCCESS'
export const REQUEST_ACCOUNT_CONTACT_DETAILS_FAILURE = 'REQUEST_ACCOUNT_CONTACT_DETAILS_FAILURE'

// アカウント担当者更新
export const REQUEST_ACCOUNT_CONTACT_UPDATE = 'REQUEST_ACCOUNT_CONTACT_UPDATE'
export const REQUEST_ACCOUNT_CONTACT_UPDATE_LOADING = 'REQUEST_ACCOUNT_CONTACT_UPDATE_LOADING'
export const REQUEST_ACCOUNT_CONTACT_UPDATE_SUCCESS = 'REQUEST_ACCOUNT_CONTACT_UPDATE_SUCCESS'
export const REQUEST_ACCOUNT_CONTACT_UPDATE_FAILURE = 'REQUEST_ACCOUNT_CONTACT_UPDATE_FAILURE'

// アカウント担当者削除
export const REQUEST_ACCOUNT_CONTACT_DELETE = 'REQUEST_ACCOUNT_CONTACT_DELETE'
export const REQUEST_ACCOUNT_CONTACT_DELETE_LOADING = 'REQUEST_ACCOUNT_CONTACT_DELETE_LOADING'
export const REQUEST_ACCOUNT_CONTACT_DELETE_SUCCESS = 'REQUEST_ACCOUNT_CONTACT_DELETE_SUCCESS'
export const REQUEST_ACCOUNT_CONTACT_DELETE_FAILURE = 'REQUEST_ACCOUNT_CONTACT_DELETE_FAILURE'

const types = {
    // アカウント担当者一覧
    REQUEST_ACCOUNT_CONTACTS,
    REQUEST_ACCOUNT_CONTACTS_LOADING,
    REQUEST_ACCOUNT_CONTACTS_SUCCESS,
    REQUEST_ACCOUNT_CONTACTS_FAILURE,
    
    // アカウント担当者登録モーダル
    REQUEST_ACCOUNT_CONTACTS_REGISTRATION_MODAL_TOGGLE,
    REQUEST_ACCOUNT_CONTACTS_UPDATE_MODAL_TOGGLE,
    REQUEST_ACCOUNT_CONTACTS_DELETE_MODAL_TOGGLE,
    REQUEST_ACCOUNT_CONTACT_DELETE_COMPLETE_MODAL_TOGGLE,
    
    // 新規アカウント担当者登録
    REQUEST_ACCOUNT_CONTACT_REGISTRATION,
    REQUEST_ACCOUNT_CONTACT_REGISTRATION_LOADING,
    REQUEST_ACCOUNT_CONTACT_REGISTRATION_SUCCESS,
    REQUEST_ACCOUNT_CONTACT_REGISTRATION_FAILURE,
    
    // アカウント担当者詳細
    REQUEST_ACCOUNT_CONTACT_DETAILS,
    REQUEST_ACCOUNT_CONTACT_DETAILS_LOADING,
    REQUEST_ACCOUNT_CONTACT_DETAILS_SUCCESS,
    REQUEST_ACCOUNT_CONTACT_DETAILS_FAILURE,
    
    // アカウント担当者更新
    REQUEST_ACCOUNT_CONTACT_UPDATE,
    REQUEST_ACCOUNT_CONTACT_UPDATE_LOADING,
    REQUEST_ACCOUNT_CONTACT_UPDATE_SUCCESS,
    REQUEST_ACCOUNT_CONTACT_UPDATE_FAILURE,

    REQUEST_ACCOUNT_CONTACT_DELETE,
    REQUEST_ACCOUNT_CONTACT_DELETE_LOADING,
    REQUEST_ACCOUNT_CONTACT_DELETE_SUCCESS,
    REQUEST_ACCOUNT_CONTACT_DELETE_FAILURE,
    
};

export default types;
