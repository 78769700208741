import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

const loadRequestSearch = actions.loadRequestSearch

const requestsLoad = actions.requestsLoad
const requestsLoading = actions.requestsLoading
const requestsSuccess = actions.requestsSuccess
const requestsFailed = actions.requestsFailed

const formEditEvent = actions.formEditEvent

const requestsDetailLoad = actions.requestsDetailLoad;

const requestsDetailQuoteDetailLoad = actions.requestsDetailQuoteDetailLoad;

const resetFormInformation = actions.resetFormInformation

const settingSubmitEbisFormInformation = actions.settingSubmitEbisFormInformation
const contractUpdateRequest = actions.contractUpdateRequest

const settingConfirmEbisFormInformation = actions.settingConfirmEbisFormInformation

const settingEbisBackButton = actions.settingEbisBackButton
const applyToggleTerms = actions.applyToggleTerms
const scrolledToTermsBottom = actions.scrolledToTermsBottom

const settingApplyConfirm = actions.settingApplyConfirm
const closeTermsAndConditionsModal = actions.closeTermsAndConditionsModal;
const openTermsAndConditionsModal = actions.openTermsAndConditionsModal;
const downloadQuotePDF = actions.downloadQuotePDF;
const toggleCancelConfirmation = actions.toggleCancelConfirmation
const cancelRequestSend = actions.cancelRequestSend
const parseServiceContractId = actions.parseServiceContractId
const initializeOptionFormInformation = actions.initializeOptionFormInformation
const settingAdditionalSteps = actions.settingAdditionalSteps
const declareNoUpdateOptionNeeded = actions.declareNoUpdateOptionNeeded
const settingUpdateAdditionalSteps = actions.settingUpdateAdditionalSteps;
const backToAdditionalSteps = actions.backToAdditionalSteps;
const sendContractNameToSearchRequest = actions.sendContractNameToSearchRequest;
const downloadQuotePDFToIframe = actions.downloadQuotePDFToIframe;

const getServiceContractInformation = actions.getServiceContractInformation;
const getServiceContractInformationLoading = actions.getServiceContractInformationLoading;
const getServiceContractInformationSuccess = actions.getServiceContractInformationSuccess;
const getServiceContractInformationError = actions.getServiceContractInformationError;

const getTermsOfService = actions.getTermsOfService;
const getTermsOfServiceLoading = actions.getTermsOfServiceLoading;
const getTermsOfServiceSuccess = actions.getTermsOfServiceSuccess;
const getTermsOfServiceFailure = actions.getTermsOfServiceFailure;

const sendMailValidation = actions.sendMailValidation;

const openResendModal = actions.openResendModal;
const closeResendModal = actions.closeResendModal;
const sendResendMail = actions.sendResendMail;

const operations = {
    loadRequestSearch,

    requestsLoad,
    requestsLoading,
    requestsSuccess,
    requestsFailed,
    formEditEvent,

    requestsDetailLoad,

    requestsDetailQuoteDetailLoad,

    resetFormInformation,
    settingSubmitEbisFormInformation,

    settingConfirmEbisFormInformation,
    contractUpdateRequest,

    settingEbisBackButton,
    applyToggleTerms,
    scrolledToTermsBottom,
    settingApplyConfirm,

    closeTermsAndConditionsModal,
    openTermsAndConditionsModal,
    downloadQuotePDF,

    toggleCancelConfirmation,
    cancelRequestSend,
    parseServiceContractId,

    initializeOptionFormInformation,
    settingAdditionalSteps,
    declareNoUpdateOptionNeeded,
    settingUpdateAdditionalSteps,
    backToAdditionalSteps,

    sendContractNameToSearchRequest,

    getServiceContractInformation,
    getServiceContractInformationLoading,
    getServiceContractInformationSuccess,
    getServiceContractInformationError,

    getTermsOfService,
    getTermsOfServiceLoading,
    getTermsOfServiceSuccess,
    getTermsOfServiceFailure,

    sendMailValidation,

    openResendModal,
    closeResendModal,
    sendResendMail,
    downloadQuotePDFToIframe
};
export default operations;
