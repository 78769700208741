import React, {useEffect, useRef} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import services from "../../../../../state/utils/data/services.json";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),

        border: "1px solid #CCCCCC",
        maxHeight: 200,
        overflow: 'auto'
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const TermsAndConditionsScroll = (
    {
        scrolledToTermsBottom,
        hasScrolledToTermsBottom,
        service_name,
        isOldPlan = false,
        t
    }) => {
    const classes = useStyles();
    const listInnerRef = useRef();

    const onScroll = () => {
        if (listInnerRef.current && hasScrolledToTermsBottom[service_name] === false) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100) {
                scrolledToTermsBottom(service_name)
            }
        }
    };
    
    
    
    return (
        <Box className={clsx(classes.root)} style={{marginTop: 10, backgroundColor: "white"}} onScroll={onScroll} ref={listInnerRef}>
            {service_name === services.ADEBIS &&
                <Typography id="modal-modal-description" style={{whiteSpace: "pre-line"}} sx={{mt: 2}}>
                    (1)株式会社イルグルムは、AD EBiSに関するご契約・ご請求手続きの処理（お客様のご担当者様等の個人データを取扱う業務を含みます）を、外部の事業者（米国その他の外国事業者を含みます）に委託する場合があります。そのため、本手続きを行っていただくに際しては、画面上にお名前等を入力いただいたご担当者様等にその旨説明をし、必ず同意をいただくようにお願いいたします。なお、株式会社イルグルムは、個人データの取扱いの委託にあたっては、 業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りに留意します。<br/>
                    <br/>
                    (2)解約日以降は、AD EBiSの管理画面へのログインができなくなります。これまでの計測データ等は、必ず契約期間内にダウンロードを行ってくださいます<br/><br/>
                    ようお願いいたします。<br/><br/>
                    (3)埋め込まれたエビスタグにつきましては、削除して下さい。タグを埋め込まれたままでいらっしゃいますと、画像切れの表示を起こしてしまうことがございます。 お手数をおかけいたしますが、お早めに削除して下さいますようお願いいたします。<br/><br/>
                    (4)広告登録にて発行いただいたリダイレクト計測のURLに関しまして、 アカウント終了後、30日間はリンクされます。それ以降に関しましては遷移が行われなくなりますので、ご注意ください。<br/><br/>
                    (5)ご解約後のアカウント復活はできません。<br/><br/>
                    (6)ご契約内容によっては、ご希望の日程での解約ができない場合がございます。ご了承ください。<br/>
                    こちらのページでお客様にお手続きいただいた後、当社の承諾をもってすべての解約手続きが完了となります。<br/>
                    また内容を弊社にて確認の上、必要に応じて担当者よりご連絡させていただく場合がございます。<br/><br/>
            </Typography>
            }
            {service_name === services.CAPICO &&
                <Typography id="modal-modal-description" style={{whiteSpace: "pre-line"}} sx={{mt: 2}}>
                    (1)株式会社イルグルムは、CAPiCOに関するご契約・ご請求手続きの処理（お客様のご担当者様等の個人データを取扱う業務を含みます）を、外部の事業者（米国その他の外国事業者を含みます）に委託する場合があります。そのため、本手続きを行っていただくに際しては、画面上にお名前等を入力いただいたご担当者様等にその旨説明をし、必ず同意をいただくようにお願いいたします。なお、株式会社イルグルムは、個人データの取扱いの委託にあたっては、 業務委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を規定し、情報が適正に管理される体制作りに留意します。<br/>
                    <br/>
                    (2)解約日以降はCAPiCOの管理画面へのログインができなくなります。<br/>
                    <br/>
                    (3)埋め込まれたエビスタグにつきましては、削除して下さい。タグを埋め込まれたままでいらっしゃいますと、画像切れの表示を起こしてしまうことがございます。お手数をおかけいたしますが、お早めに削除して下さいますようお願いいたします。<br/>
                    ※AD EBiSをセット利用されていた方：CAPiCOのみ解約する場合は、削除せず引き続きご利用ください。<br/>
                    <br/>
                    (4)ご解約後のアカウント復活はできません。<br/>
                    <br/>
                    (5)ご契約内容によっては、ご希望の日程での解約ができない場合がございます。ご了承ください。<br/>
                    こちらのページでお客様にお手続きいただいた後、当社の承諾をもってすべての解約手続きが完了となります。<br/>
                    また内容を弊社にて確認の上、必要に応じて担当者よりご連絡させていただく場合がございます。
                </Typography>
            }
        </Box>
    );
}

export default TermsAndConditionsScroll;
