import {Button, Card, FormLabel, Grid, IconButton, InputAdornment, Popper, TextField} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import {Field, Form} from "react-final-form";
import React from "react";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import FinalFormSelect from "../../../../atoms/FinalFormSelect";
import {managerRequestForms} from "../../../../../state/ducks/manager/managerRequests";
import FinalFormDate from "../../../../atoms/FinalFormDate";

/**
 *
 * /noticesのメインコンポーネント
 * @param handleClick
 * @param searchFieldOpen
 * @param searchFieldAnchorObject
 * @param {Object} error - ページエラーの詳細
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const RequestsSearchBox = (
    {
        toggleSearchBox,
        searchFieldOpen,
        managerProfile,
        anchorEl,
        applicationCategories,
        applicationStatus,
        searchFieldCloseEvent,
        managerLoadRequestSearch,
        searchFormInformation,
        formEditEvent,
        requestListLoading,
        t,
    }) => {
    return (
        <>
            <TextField variant={"outlined"}
                       size={"small"}
                       style={{backgroundColor: "white"}}
                       value={
                           (!empty(searchFormInformation.account_name) ? t('requests.search.supplier_name') + "：" + searchFormInformation.account_name + "　" : "") +
                           (!empty(searchFormInformation.ebis_client_name) ? t('requests.search.account_name') + "：" + searchFormInformation.ebis_client_name + "　" : "") +
                           (!empty(searchFormInformation.application_at_from) || !empty(searchFormInformation.application_at_to) ?
                               t('requests.search.request_date') + "：" +
                               (!empty(searchFormInformation.application_at_from) ? searchFormInformation.application_at_from : "") + "～" +
                               (!empty(searchFormInformation.application_at_to) ? searchFormInformation.application_at_to + "　" : "")
                               : "") +
                           (parseInt(searchFormInformation.status) === 0 || !empty(searchFormInformation.status) === true ? t('requests.search.status') + "：" + applicationStatus.statuses.filter((v) => {
                               return parseInt(v.value) === parseInt(searchFormInformation.status)
                           })[0].translation_view + "　" : "") +
                           (parseInt(searchFormInformation.type) === 0 || !empty(searchFormInformation.type) === true ? t('requests.search.request_category') + "：" + applicationCategories.categories.filter((v) => {
                               return parseInt(v.value) === parseInt(searchFormInformation.type)
                           })[0].translation_view + "　" : "") +
                           (!empty(searchFormInformation.application_complete_at_from) || !empty(searchFormInformation.application_complete_at_to) ?
                               t('requests.search.request_completed_date') + "：" +
                               (!empty(searchFormInformation.application_complete_at_from) ? searchFormInformation.application_complete_at_from : "") + "～" +
                               (!empty(searchFormInformation.application_complete_at_to) ? searchFormInformation.application_complete_at_to : "")
                               : "")
                           +
                           (!empty(searchFormInformation.reflect_at_from) || !empty(searchFormInformation.reflect_at_to) ?
                               t('requests.search.request_completed_date') + "：" +
                               (!empty(searchFormInformation.reflect_at_from) ? searchFormInformation.reflect_at_from : "") + "～" +
                               (!empty(searchFormInformation.reflect_at_to) ? searchFormInformation.reflect_at_to : "")
                               : "")
                       }
                       onClick={(event) => toggleSearchBox(event)}
                       inputStyle={{textOverflow: "ellipse"}}
                       InputProps={{
                           readOnly: true,
                           endAdornment:
                               <InputAdornment position="end">
                                   <IconButton>
                                       <Search/>
                                   </IconButton>
                               </InputAdornment>
                       }}
                       fullWidth/>
            <Popper
                id={"simple-popover"}
                open={searchFieldOpen}
                anchorEl={anchorEl}
                onClose={() => (!searchFieldOpen) ? toggleSearchBox(false) : null}
            >
                <Form
                    onSubmit={e => {
                        e = mergeWithDefaultForm(e, managerRequestForms.searchForm)
                        searchFieldCloseEvent()
                        managerLoadRequestSearch(e)
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    // TODO: 妥当性確認？？
                    // validate={e => {
                    //     return null
                    //     // return translate(t, Validation({...e}, userStoreValidation))
                    // }}
                    // （編集モード）最初のバリュー
                    initialValues={searchFormInformation}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({handleSubmit, form, submitting, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Card style={{width: 600, padding: 15, height: 370}}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.supplier_name')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            fullWidth
                                            size={"small"}
                                            name="account_name"
                                            component={FinalFormTextField}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.account_name')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            fullWidth
                                            size={"small"}
                                            name="ebis_client_name"
                                            component={FinalFormTextField}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.request_date')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container
                                              direction="row"
                                              alignItems="center">
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="application_at_from"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{textAlign: "center"}}>
                                                ~
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="application_at_to"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.status')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Field
                                            fullWidth
                                            size={"small"}
                                            name="status"
                                            component={FinalFormSelect}
                                            options={applicationStatus.statuses}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.request_category')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Field
                                            fullWidth
                                            size={"small"}
                                            name="type"
                                            component={FinalFormSelect}
                                            options={applicationCategories.categories}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.request_completed_date')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container
                                              direction="row"
                                              alignItems="center">
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="application_complete_at_from"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{textAlign: "center"}}>
                                                ~
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="application_complete_at_to"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={3}>
                                        <FormLabel>
                                            {t('requests.search.reflection_date')}
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container
                                              direction="row"
                                              alignItems="center">
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="reflect_at_from"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{textAlign: "center"}}>
                                                ~
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    fullWidth
                                                    size={"small"}
                                                    name="reflect_at_to"
                                                    component={FinalFormDate}
                                                    type="text"
                                                    clearable={true}
                                                    okByDateClick={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} style={{paddingTop: 10}}
                                      justify="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                form.change('account_name', undefined)
                                                form.change('ebis_client_name', undefined)
                                                form.change('application_at_from', undefined)
                                                form.change('application_at_to', undefined)
                                                form.change('status', undefined)
                                                form.change('type', undefined)
                                                form.change('application_complete_at_from', undefined)
                                                form.change('application_complete_at_to', undefined)
                                                form.change('reflect_at_from', undefined)
                                                form.change('reflect_at_to', undefined)
                                            }}
                                        >
                                            {t('actions.reset')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => searchFieldCloseEvent()}
                                        >
                                            {t('actions.close')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={invalid||requestListLoading}
                                        >
                                            {t('actions.search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </form>)}/>
            </Popper>
        </>
    );
}

export default RequestsSearchBox;
