/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやリデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const REQUEST_SERVICE_CONTRACT = 'REQUEST_SERVICE_CONTRACTS'
export const REQUEST_SERVICE_CONTRACTS_LOADING = 'REQUEST_SERVICE_CONTRACTS_LOADING'
export const REQUEST_SERVICE_CONTRACTS_SUCCESS = 'REQUEST_SERVICE_CONTRACTS_SUCCESS'
export const REQUEST_SERVICE_CONTRACTS_FAILURE = 'REQUEST_SERVICE_CONTRACTS_FAILURE';

export const SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK = "SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK";

const types = {
    REQUEST_SERVICE_CONTRACT,
    REQUEST_SERVICE_CONTRACTS_LOADING,
    REQUEST_SERVICE_CONTRACTS_SUCCESS,
    REQUEST_SERVICE_CONTRACTS_FAILURE,
    SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK,
}
export default types;
