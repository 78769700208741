import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// 利用契約一覧取得リクエスト
//-------------------------------------------------

/***
 * 利用契約一覧取得リクエスト
 * @param {string} access_token - auth0アクセストークン
 * @param user_id - ユーザーアカウントID
 * @returns {number|string} {{payload: {access_token, user_id}, type: string}}
 */
export function loadServiceContractList(access_token, user_id) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    };
}

/***
 *利用契約一覧取得リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function loadingServiceContractList() {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_LOADING
    }
}

/***
 * 利用契約一覧取得リクエスト(成功）
 * @param {Object} serviceContracts - 利用契約一覧データ
 * @returns {{payload: {serviceContracts}, type: string}}
 */
export function successServiceContractList(serviceContracts) {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_SUCCESS,
        payload: {
            serviceContracts: serviceContracts
        }
    }
}

/***
 * 利用契約一覧取得リクエスト(失敗）
 * @param {Object }error - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function failureServiceContractList(error) {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_FAILURE,
        payload: {
            error: error
        }
    }
}

/**
 *
 * @param  {string} contractName
 * @param {boolean} canCancel
 * @returns {{payload: {contactNameToSidebar, canCancel}, type: string}}
 */
export function saveContractNameToSidebarLink(contractName, canCancel = false) {
    return {
        type: types.SAVE_CONTRACT_NAME_TO_SIDEBAR_LINK,
        payload: {
            contactNameToSidebar: contractName,
            canCancel: canCancel
        }
    }
}

const actions = {
    loadServiceContractList,
    loadingServiceContractList,
    successServiceContractList,
    failureServiceContractList,

    saveContractNameToSidebarLink
};
export default actions;
