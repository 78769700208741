import React from "react";
import {Redirect, useLocation} from "react-router-dom";


const UserAccountContainer = ({profile, children}) => {
    const location = useLocation();
    const currentPath = location.pathname;
    
    if(profile && profile?.maintenance_mode === true && currentPath !== '/') {
        return (
            <Redirect to="/"/>
        )
    }
    
    return (
        <>
            {children}
        </>
    )
}

export default UserAccountContainer;
