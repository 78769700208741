import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {rootOperations} from "../../../../../../state/ducks/root";
import managerSidebarLinks from "../../../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json"
import {empty} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {useParams} from "react-router";
import {managerRequestOperations} from "../../../../../../state/ducks/manager/managerRequests";
import ManagerRequestQuoteDetailView from "../../../../../components/manager/requests/detail/quote/ManagerPage";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestDetail: state.managerRequest.managerRequestDetail,
        requestQuote: state.managerRequest.managerRequestQuote,
        requestQuoteLoading: state.managerRequest.managerRequestQuoteLoading,

        managerTermsAndConditionsIsOpen: state.managerRequest.managerTermsAndConditionsIsOpen,
        managerPdfIsLoading: state.managerRequest.managerPdfIsLoading,
        managerPdfError: state.managerRequest.managerPdfError,
        pdfDataBlobPreview: state.managerRequest.pdfDataBlobPreview,
        pdfDataBlobPreviewLoading: state.managerRequest.pdfDataBlobPreviewLoading,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    requestsDetailQuoteDetailLoad: managerRequestOperations.managerRequestsDetailQuoteDetailLoad,
    managerCloseTermsAndConditionsModal: managerRequestOperations.managerCloseTermsAndConditionsModal,
    managerOpenTermsAndConditionsModal: managerRequestOperations.managerOpenTermsAndConditionsModal,
    managerDownloadQuotePDF: managerRequestOperations.managerDownloadQuotePDF,
    downloadQuotePDFToIframe: managerRequestOperations.downloadQuotePDFToIframe
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _managerQuoteContainer = (
    {
        managerProfile,
        loadSidebarItems,
        requestQuote,
        requestQuoteLoading,
        requestsDetailQuoteDetailLoad,
        requestDetail,
        managerTermsAndConditionsIsOpen,
        managerCloseTermsAndConditionsModal,
        managerOpenTermsAndConditionsModal,
        managerPdfIsLoading,
        managerPdfError,
        managerDownloadQuotePDF,

        pdfDataBlobPreview,
        pdfDataBlobPreviewLoading,
        downloadQuotePDFToIframe,

        t
    }) => {
    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    let {rgid, rid, qid} = useParams();
    const selectedRequest = requestDetail?.requests?.find((request) => {
        return request?.uuid === rid
    });
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                requestsDetailQuoteDetailLoad(access_token, rgid, rid, qid)
            })()
        }
    }, [rgid, rid, qid, getAccessTokenSilently, requestsDetailQuoteDetailLoad, managerProfile])
    useEffect(() => {
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                downloadQuotePDFToIframe(access_token, rgid, rid, qid)
            })()
        }
    }, [rgid, rid, qid, getAccessTokenSilently, downloadQuotePDFToIframe, managerProfile])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(managerSidebarLinks.navigation.requests)
    }, [loadSidebarItems])

    return (
        <ManagerRequestQuoteDetailView
            requestGroupId={rgid}
            requestId={rid}
            managerDownloadQuotePDF={managerDownloadQuotePDF}
            quoteId={qid}
            pdfIsLoading={managerPdfIsLoading}
            managerProfile={managerProfile}
            getAccessTokenSilently={getAccessTokenSilently}
            closeTermsAndConditionsModal={managerCloseTermsAndConditionsModal}
            openTermsAndConditionsModal={managerOpenTermsAndConditionsModal}
            termsAndConditionsIsOpen={managerTermsAndConditionsIsOpen}
            requestQuoteLoading={requestQuoteLoading}
            requestQuote={requestQuote}
            requestDetail={selectedRequest}
            pdfDataBlobPreview={pdfDataBlobPreview}
            pdfDataBlobPreviewLoading={pdfDataBlobPreviewLoading}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerQuoteContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerQuoteContainer)

export default ManagerQuoteContainer;
