/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */


import {call, put} from "redux-saga/effects";
import {error_raise} from "../../root/actions";
import {
    approveTerminateRequestFailure,
    approveTerminateRequestLoading,
    approveTerminateRequestSuccess,
    cancelRequestSendFailure,
    cancelRequestSendLoading,
    cancelRequestSendSuccess,
    downloadQuotePDFToIframeFailure,
    downloadQuotePDFToIframeLoading,
    downloadQuotePDFToIframeSuccess,
    downloadRequestListFailure,
    downloadRequestListLoading,
    downloadRequestListSuccess,
    managerDownloadQuoteFailure,
    managerDownloadQuoteLoading,
    managerDownloadQuoteSuccess,
    managerRequestsDetailFailed,
    managerRequestsDetailLoading,
    managerRequestsDetailQuoteDetailFailed,
    managerRequestsDetailQuoteDetailLoading,
    managerRequestsDetailQuoteDetailSuccess,
    managerRequestsDetailSuccess,
    managerRequestsFailed,
    managerRequestsLoading,
    managerRequestsSuccess,
    parseAccountIdFailure,
    parseAccountIdLoading,
    parseAccountIdSuccess
} from "./actions";
import {
    callRequestQuotePDFToIframe,
    managerApproveTerminateRequestAPI,
    managerCallRequestQuote,
    managerCallRequestQuotePDFDownload,
    managerCallRequests,
    managerCallRequestsDetail,
    managerCancelRequestAPI,
    managerDownloadRequests
} from "./api";
import {managerCallAccountDetail} from "../managerAccounts/api";

/**
 *
 * @param data
 */
export function* managerRequestsLoad(data) {
    yield put(managerRequestsLoading())
    try {
        const requests = yield call(managerCallRequests, data.payload.access_token, data.payload.data);
        yield put(managerRequestsSuccess(requests.data.requests, requests.data.total_rows));
    } catch (e) {
        yield put(managerRequestsFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * CSV形式で申込一覧のファイルダウンロード機能
 * @param data
 * */
export function* downloadRequestsList(data) {
    yield put(downloadRequestListLoading())
    try {
        yield call(managerDownloadRequests, data.payload.access_token, data.payload.form);
        yield put(downloadRequestListSuccess());
    } catch (e) {
        yield put(downloadRequestListFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お申込詳細ページ
 * @param data
 * @returns
 */
export function* managerRequestsDetailLoad(data) {
    yield put(managerRequestsDetailLoading())
    try {
        const requests = yield call(managerCallRequestsDetail, data.payload.access_token, null, data.payload.request_group_id);
        yield put(managerRequestsDetailSuccess(requests.data));
    } catch (e) {
        yield put(managerRequestsDetailFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * お申込見積リクエスト
 * @param data
 */
export function* managerRequestsQuoteLoad(data) {
    yield put(managerRequestsDetailQuoteDetailLoading())
    try {
        const requests = yield call(managerCallRequestQuote, data.payload.access_token, null, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id);
        yield put(managerRequestsDetailQuoteDetailSuccess(requests.data));
    } catch (e) {
        yield put(managerRequestsDetailQuoteDetailFailed(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * PDF形式で見積もりファイルダウンロード機能
 * @param data
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<(function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => SagaIterator<infer RT>) ? RT : ((function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => Promise<infer RT>) ? RT : ((function(*, *, *, *, *): *|Promise<unknown>)|* extends ((...args: any[]) => infer RT) ? RT : never))>>|SimpleEffect<"PUT", PutEffectDescriptor<{payload: {binaryFileData: *}, type: string}>>, void, *>}
 */
export function* managerDownloadRequestQuotePDF(data) {
    yield put(managerDownloadQuoteLoading())
    try {
        yield call(managerCallRequestQuotePDFDownload, data.payload.access_token, null, data.payload.request_group_id, data.payload.request_id, data.payload.quote_id, data.payload.quote_no);
        yield put(managerDownloadQuoteSuccess());
    } catch (e) {
        yield put(managerDownloadQuoteFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

export function* requestQuotePDFToIframe(data) {
    yield put(downloadQuotePDFToIframeLoading())
    try {
        const requests = yield call(callRequestQuotePDFToIframe, data.payload.access_token, null,  data.payload.request_group_id, data.payload.request_id, data.payload.quote_id);
        yield put(downloadQuotePDFToIframeSuccess(requests.data.file_data));
    } catch (e) {
        yield put(downloadQuotePDFToIframeFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 申込キャンセル処理。
 * @param data
 */
export function* cancelRequestSaga(data) {
    yield put(cancelRequestSendLoading())
    try {
        yield call(managerCancelRequestAPI, data.payload.access_token, data.payload.request_id);
        yield put(cancelRequestSendSuccess());
    } catch (e) {
        yield put(cancelRequestSendFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 申込キャンセル処理。
 * @param data
 */
export function* approveTerminateRequestSaga(data) {
    yield put(approveTerminateRequestLoading())
    try {
        yield call(managerApproveTerminateRequestAPI, data.payload.access_token, data.payload.request_id);
        yield put(approveTerminateRequestSuccess());
    } catch (e) {
        yield put(approveTerminateRequestFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}

/**
 * 選択したアカウントでユーザー取得リクエスト
 * @param data
 */
export function* managerGetAccountDetailSaga(data) {
    yield put(parseAccountIdLoading())
    try {
        // 成功
        let service_contracts = yield call(managerCallAccountDetail, data.payload.access_token, data.payload.account_id);
        yield put(parseAccountIdSuccess(service_contracts.data))
    } catch (e) {
        // 失敗
        yield put(parseAccountIdFailure(e))
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
