import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, Button, Hidden, IconButton, makeStyles, Tab, Tabs, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {withTranslation} from "react-i18next";
import {commonStyles} from '../../theme/user/styles/common'
import {ReactComponent as Logo} from "../../../assests/img/logo.svg";
import LogoutMenu from "../../atoms/LogoutMenu";
import {NavLink as RouterLink, useLocation} from "react-router-dom";
import {empty} from "../../../state/utils/Common";

/**
 * ヘッダースタイル
 */
const useStyles = makeStyles(() => ({
    root: {
        height: 70,
    },
    title: {
        fontSize: 16,
        fontWeight: "Bold"
    },
}));

/**
 * 通常ユーザー側のヘッダーコンポーネント
 * @param {string} className - 親からのクラス名
 * @param {boolean} onMobileNavOpen - スマートフォンのナビメニュー開閉
 * @param {*} t - 翻訳ファイル
 * @param {Object} managerProfile - ログインユーザープロフィールデータ
 * @param {*} i18n - 翻訳ファイル
 * @param {*} rest - 他の変数
 */
const TopBar = ({
                    className,
                    onMobileNavOpen,
                    t,
                    managerProfile,
                    i18n,
                    ...rest
                }) => {
    const classes = useStyles();
    // アプリケーションテーマ
    const styles = commonStyles();

    // URLパスの取得
    const location = useLocation();

    const NavigationMap = new Map([
        ['notifications', '/notifications'],
        ['service_contract', '/service_contracts'],
        ['requests', '/requests'],
        ['account', '/account'],
        ['contact', '/contact']
    ]);

    NavigationMap.forEach((value, key, map) => {
        if(location.pathname.includes(key)) {
            NavigationMap.set(key, location.pathname)
        }
    })
    
    // ホームページのリンク
    let homePageLink = "/";
    if(location.pathname === "/") {
        NavigationMap.set("notifications", location.pathname)
    } else {
        homePageLink = NavigationMap.get("notifications");
    }
    
    // お問い合わせページと担当者変更ページの同時表示を防ぐ
    if(location.pathname.includes("service_contracts") && location.pathname.includes("contacts")) {
        NavigationMap.set("service_contract", location.pathname)
        NavigationMap.delete("contact")
    }
    // 各種設定の担当者ページとお問い合わせページの同時表示を防ぐ
    if(location.pathname.includes("account") && location.pathname.includes("contacts")) {
        NavigationMap.set("account", location.pathname)
        NavigationMap.delete("contact")
    }

    return (
        <AppBar
            elevation={0}
            color={"inherit"}
            className={styles.navHeaderBackground}
        >
            <Toolbar className={classes.root}>
                {!empty(managerProfile?.is_pretend) &&
                    <Logo style={{marginRight: 10, cursor: "pointer"}} onClick={() => { window.location.href = "/manager/accounts/" }}>

                    </Logo>
                }
                {empty(managerProfile?.is_pretend) &&
                <RouterLink to={"/"}>
                    <Logo style={{marginRight: 10}}>

                    </Logo>
                </RouterLink>
                }

                <Typography component="h1" variant='h5' color="inherit" noWrap className={classes.title}>
                    {t('root.company_name')}<br/>{t('root.application')}
                </Typography>
                {managerProfile?.is_pretend &&
                    <Box marginLeft={2} marginRight={0} paddingRight={0}>
                        <Button variant={"text"}>
                            {t('general.pretend_mode')}
                        </Button>
                    </Box>
                }
                <Tabs
                    value={location.pathname}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab
                        value={homePageLink}
                        label="TOP"
                        to={"/"}
                        component={RouterLink}
                        disabled={ managerProfile?.maintenance_mode === true }
                    >
                    </Tab>
                    <Tab
                        value={NavigationMap.get('service_contract')}
                        label="ご利用サービス"
                        to={"/service_contracts"}
                        component={RouterLink}
                        disabled={ managerProfile?.maintenance_mode === true }
                    />
                    <Tab
                        value={NavigationMap.get('requests')}
                        label="お申込"
                        to={"/requests"}
                        component={RouterLink}
                        disabled={ managerProfile?.maintenance_mode === true }
                    />
                    <Tab
                        value={NavigationMap.get('contact')}
                        label="お問い合わせ"
                        to={"/contact"}
                        component={RouterLink}
                        disabled={ managerProfile?.maintenance_mode === true }
                    />
                    <Tab
                        value={NavigationMap.get('account')}
                        label="各種設定"
                        to={"/account"}
                        component={RouterLink}
                        disabled={ managerProfile?.maintenance_mode === true }
                    />
                </Tabs>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    <LogoutMenu
                        companyName={managerProfile?.account?.name}
                        userName={managerProfile?.last_name + " " + managerProfile?.first_name}
                        isPretendMode={managerProfile?.is_pretend === true}
                        pretendCompanyName={managerProfile?.account?.pretend_account_name}
                        signOutUri={"/sign_out"}
                        t={t}
                    />
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default withTranslation('common')(TopBar);
