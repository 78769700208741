import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

/**
 * 契約一覧取得
 * @type {(function(string, *=): (number|string))|*}
 */
const loadServiceContractList = actions.loadServiceContractList;
const loadingServiceContractList = actions.loadingServiceContractList;
const successServiceContractList = actions.successServiceContractList;
const failureServiceContractList = actions.failureServiceContractList;


/**
 *
 * @type {(function(*=): {payload: {contactNameToSidebar: *}, type: string})|*}
 */
const saveContractNameToSidebarLink = actions.saveContractNameToSidebarLink;


const operations = {
    loadServiceContractList,
    loadingServiceContractList,
    successServiceContractList,
    failureServiceContractList,
    
    saveContractNameToSidebarLink
}
export default operations;
