import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import RequestsView from "../../../components/manager/requests/Page";
import {rootOperations} from "../../../../state/ducks/root";
import adminSideBarLinks from "../../../../state/utils/data/sitemap/admin/sidebar_navigation_urls.json"
import {managerRequestOperations} from "../../../../state/ducks/manager/managerRequests";
import {empty} from "../../../../state/utils/Common";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import {useLocation} from "react-router-dom";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        requestList: state.managerRequest.requestList,
        requestListLoading: state.managerRequest.requestListLoading,

        managerRequestsCurrentPage: state.managerRequest.managerRequestsCurrentPage,
        managerRequestsMaximumPage: state.managerRequest.managerRequestsMaximumPage,
        managerRequestsTotalRows: state.managerRequest.managerRequestsTotalRows,
        searchFormInformation: state.managerRequest.searchFormInformation,

        downloadRequestsListLoading: state.managerRequest.downloadRequestsListLoading,
        downloadRequestListError: {},
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    managerRequestsLoad: managerRequestOperations.managerRequestsLoad,
    managerChangePageAccountList: managerRequestOperations.managerChangePageAccountList,
    downloadRequestList: managerRequestOperations.downloadRequestList,
    parseAccountId: managerRequestOperations.parseAccountId
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
管理者側の申込コンテイナー
*/
const _managerRequestsContainer = (
    {
        managerProfile,
        loadSidebarItems,
        managerLoadNoticeList,
        managerLoadRequestSearch,
        managerRequestsLoad,
        requestListLoading,
        requestList,

        managerRequestsCurrentPage,
        managerRequestsMaximumPage,
        managerRequestsTotalRows,

        managerChangePageAccountList,
        searchFormInformation,

        downloadRequestsListLoading,
        downloadRequestListError,

        downloadRequestList,
        parseAccountId,

        t
    }) => {

    // このステートをReducksに入れたらREDUX-DEVTOOLSがクラッシュするから、こちらのコンポーネントで検索非表示・表示ステートを管理する。
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    let {search} = useLocation();

    const query = new URLSearchParams(search);
    const account_name = query.get('accountName')

    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();

    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                if(!empty(account_name) && empty(searchFormInformation.account_name)) {
                    searchFormInformation.account_name = account_name
                }
                managerRequestsLoad(access_token, managerRequestsCurrentPage, searchFormInformation)
            })()
        }
    }, [getAccessTokenSilently, managerRequestsLoad, managerProfile, managerRequestsCurrentPage, searchFormInformation, account_name])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(adminSideBarLinks.navigation.requests)
    }, [loadSidebarItems])
    return (
        <RequestsView
            managerLoadNoticeList={handleClick}
            searchFieldOpen={open}
            searchFieldCloseEvent={handleClose}
            anchorEl={anchorEl}
            managerProfile={managerProfile}
            managerLoadRequestSearch={managerLoadRequestSearch}
            requestListLoading={requestListLoading}
            requestList={requestList}
            managerRequestsCurrentPage={managerRequestsCurrentPage}
            managerRequestsMaximumPage={managerRequestsMaximumPage}
            managerRequestsTotalRows={managerRequestsTotalRows}
            managerChangePageAccountList={managerChangePageAccountList}
            downloadRequestsListLoading={downloadRequestsListLoading}
            downloadRequestListError={downloadRequestListError}
            downloadRequestList={downloadRequestList}
            getAccessTokenSilently={getAccessTokenSilently}
            searchFormInformation={searchFormInformation}
            t={t}
        />
    )
}


/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ManagerRequestsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_managerRequestsContainer)

export default ManagerRequestsContainer;
