import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているusersグループに束縛されています。
 * //////////////////////////////////////////
 */
export const accountContactsState = {
    // アカウント担当者一覧
    accountContactListData: {},
    accountContactListLoading: false,
    accountContactListError: {},

    // アカウント担当者登録モーダルまたは更新モーダル
    isAccountContactRegistrationModalOpen: false,
    isAccountContactUpdateModalOpen: false,
    isAccountContactDeleteModalOpen: false,
    isAccountContactDeleteCompleteModalOpen: false,
    

    // アカウント担当者登録
    newContactRegistrationLoading: false,
    accountContactRegistrationError: {},

    // アカウント担当者更新
    accountContactUpdateLoading: false,
    accountContactUpdateError: {},
    
    // アカウント担当者削除
    accountContactDeleteLoading: false,
    accountContactDeleteError: {},

    // アカウント担当者詳細
    accountContactDetailsData: {},
    accountContactDetailsLoading: false,
    accountContactDetailsError: {},
};

export default function accountContactsReducer(state = accountContactsState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // アカウント担当者一覧取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACTS_LOADING:
            return {
                ...state,
                isAccountContactDeleteModalOpen: state.isAccountContactDeleteModalOpen = false,
                isAccountContactDeleteCompleteModalOpen: state.isAccountContactDeleteCompleteModalOpen = false,
                accountContactListLoading: state.accountContactListLoading = true,
                accountContactListData: state.accountContactListData = {},
                accountContactListError: state.accountContactListError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACTS_SUCCESS:
            return {
                ...state,
                accountContactListLoading: state.accountContactListLoading = false,
                accountContactListData: state.accountContactListData = action.payload.accountContacts.contacts,
                accountContactListError: state.accountContactListError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACTS_FAILURE:
            return {
                ...state,
                accountContactListLoading: state.accountContactListLoading = false,
                accountContactListError: state.accountContactListError = action.payload.error,
            }
        // -----------------------------------------------
        // アカウント担当者登録モーダル表示切替
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACTS_REGISTRATION_MODAL_TOGGLE:
            return {
                ...state,
                isAccountContactRegistrationModalOpen: state.isAccountContactRegistrationModalOpen = action?.payload?.forceState ?? !state.isAccountContactRegistrationModalOpen,
                accountContactRegistrationError: state.accountContactRegistrationError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACTS_UPDATE_MODAL_TOGGLE:
            return {
                ...state,
                isAccountContactUpdateModalOpen: state.isAccountContactUpdateModalOpen = action?.payload?.forceState ?? !state.isAccountContactUpdateModalOpen,
                accountContactUpdateError: state.accountContactUpdateError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACTS_DELETE_MODAL_TOGGLE:
            return {
                ...state,
                isAccountContactDeleteModalOpen: state.isAccountContactDeleteModalOpen = action?.payload?.forceState ?? !state.isAccountContactDeleteModalOpen,
                
            }
        case types.REQUEST_ACCOUNT_CONTACT_DELETE_COMPLETE_MODAL_TOGGLE:
            return {
                ...state,
                isAccountContactDeleteCompleteModalOpen: state.isAccountContactDeleteCompleteModalOpen = action?.payload?.forceState ?? !state.isAccountContactDeleteCompleteModalOpen,
                accountContactDeleteError: state.accountContactDeleteError = {},
            }
        // -----------------------------------------------
        // 新規アカウント担当者登録
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_LOADING:
            return {
                ...state,
                newContactRegistrationLoading: state.newContactRegistrationLoading = true,
                accountContactRegistrationError: state.accountContactRegistrationError = {},
                isAccountContactRegistrationModalOpen: state.isAccountContactRegistrationModalOpen = true,
            }
        case types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_SUCCESS:
            return {
                ...state,
                newContactRegistrationLoading: state.newContactRegistrationLoading = false,
                accountContactRegistrationError: state.accountContactRegistrationError = {},
                isAccountContactRegistrationModalOpen: state.isAccountContactRegistrationModalOpen = false,
            }
        case types.REQUEST_ACCOUNT_CONTACT_REGISTRATION_FAILURE:
            return {
                ...state,
                newContactRegistrationLoading: state.newContactRegistrationLoading = false,
                accountContactRegistrationError: state.accountContactRegistrationError = action.payload.error,
                isAccountContactRegistrationModalOpen: state.isAccountContactRegistrationModalOpen = true,
            }
        // -----------------------------------------------
        // アカウント担当者詳細取得リクエスト
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACT_DETAILS_LOADING:
            return {
                ...state,
                isAccountContactDeleteModalOpen: state.isAccountContactDeleteModalOpen = false,
                isAccountContactDeleteCompleteModalOpen: state.isAccountContactDeleteCompleteModalOpen = false,
                accountContactDetailsLoading: state.accountContactDetailsLoading = true,
                accountContactDetailsData: state.accountContactDetailsData = {},
                accountContactDetailsError: state.accountContactDetailsError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                accountContactDetailsLoading: state.accountContactDetailsLoading = false,
                accountContactDetailsData: state.accountContactDetailsData = action.payload.accountContactDetails,
                accountContactDetailsError: state.accountContactDetailsError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACT_DETAILS_FAILURE:
            return {
                ...state,
                accountContactListData: state.accountContactListData = {},
                accountContactDetailsLoading: state.accountContactDetailsLoading = false,
                accountContactDetailsError: state.accountContactDetailsError = action.payload.error,
            }
        // -----------------------------------------------
        // アカウント担当者更新
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACT_UPDATE_LOADING:
            return {
                ...state,
                accountContactUpdateLoading: state.accountContactUpdateLoading = true,
                accountContactUpdateError: state.accountContactUpdateError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                accountContactUpdateLoading: state.accountContactUpdateLoading = false,
                accountContactUpdateError: state.accountContactUpdateError = {},
                isAccountContactUpdateModalOpen: state.isAccountContactUpdateModalOpen = false,
            }
        case types.REQUEST_ACCOUNT_CONTACT_UPDATE_FAILURE:
            return {
                ...state,
                accountContactUpdateLoading: state.accountContactUpdateLoading = false,
                accountContactUpdateError: state.accountContactUpdateError = action.payload.error,
                isAccountContactUpdateModalOpen: state.isAccountContactUpdateModalOpen = true,
            }
        // -----------------------------------------------
        // アカウント担当者削除
        // -----------------------------------------------
        case types.REQUEST_ACCOUNT_CONTACT_DELETE_LOADING:
            return {
                ...state,
                accountContactDeleteLoading: state.accountContactDeleteLoading = true,
                accountContactDeleteError: state.accountContactDeleteError = {},
            }
        case types.REQUEST_ACCOUNT_CONTACT_DELETE_SUCCESS:
            return {
                ...state,
                accountContactDeleteLoading: state.accountContactDeleteLoading = false,
                accountContactDeleteError: state.accountContactDeleteError = {},
                isAccountContactDeleteModalOpen: state.isAccountContactDeleteModalOpen = false,
                isAccountContactDeleteCompleteModalOpen: state.isAccountContactDeleteCompleteModalOpen = true,
            }
        case types.REQUEST_ACCOUNT_CONTACT_DELETE_FAILURE:
            return {
                ...state,
                accountContactDeleteLoading: state.accountContactDeleteLoading = false,
                accountContactDeleteError: state.accountContactDeleteError = action.payload.error,
                isAccountContactDeleteModalOpen: state.isAccountContactDeleteModalOpen = true,
            }
        default:
            return state;
    }
}
