import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../theme/globalTheme";

export const AccountMailListRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Grid container alignItems="center" key={props.data.users[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 8,
            paddingBottom: 13,
            paddingRight: 14,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].id}
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].request_id}
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].quote_number}
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].ebis_log_id}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].send_at}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].email}
                </Typography>
            </Grid>
            <Grid item xs={2} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].company_name}<br/>
                    {props.data.users[props.index].contact_name}
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].type_name}
                </Typography>
            </Grid>
            <Grid item xs={1} zeroMinWidth={true}>
                <Typography variant={"body1"} className={commonTheme.breakWrap} noWrap={true}>
                    {props.data.users[props.index].user_name}
                </Typography>
            </Grid>
        </Grid>
    )
}
