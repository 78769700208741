import {requestOperations} from "../../../../../../../../state/ducks/user/requests";
import {useEffect} from "react";
import {PARTNER_ADVERT_INFO_HTML_TERMS_SERVICE} from "../../../../../../../../state/ducks/user/requests/constants";
import {empty} from "../../../../../../../../state/utils/Common";
import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import Loading from "../../../../../../../atoms/Loading";
import clsx from "clsx";
import globalTheme from "../../../../../../../theme/globalTheme";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),

        border: "1px solid #CCCCCC",
        maxHeight: 300,
        overflow: 'auto'
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        additionalTerms: state.request.additionalTerms,
        additionalTermsLoading: state.request.additionalTermsLoading,
        additionalTermsError: state.request.additionalTermsError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    getServiceContractInformation: requestOperations.getServiceContractInformation
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込詳細コンテイナー
*/
const _additionTermsContainer = (
    {
        additionalTerms,
        additionalTermsLoading,
        additionalTermsError,
        getServiceContractInformation,
        t,
        onScroll = null,
        listInnerRef= null,
        height = 120
    }) => {

    useEffect(() => {
        if (empty(additionalTerms)) {
            getServiceContractInformation(PARTNER_ADVERT_INFO_HTML_TERMS_SERVICE)
        }
    }, [additionalTerms, getServiceContractInformation])

    const classes = useStyles();
    const _globalTheme = globalTheme._default();


    return (
        <>
        {additionalTermsLoading === true ?
            <Box className={_globalTheme.list} display="flex" alignItems="center">
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      data-testid="loading"
                >
                    <Grid item xs={12}>
                        <Loading/>
                    </Grid>
                </Grid>
            </Box>
            :
            <Box className={clsx(classes.root)} style={{ height: height }} onScroll={onScroll} ref={listInnerRef}>
                    <Typography id="modal-modal-description" style={{whiteSpace: "pre-wrap"}} sx={{mt: 2}}>
                        { additionalTerms }
                    </Typography>
            </Box>
        }
        </>
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const AdditionTermsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_additionTermsContainer)

export default AdditionTermsContainer;
