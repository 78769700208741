/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないserviceContractsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * /////////////////////////////////////////////////////////////////
 *
 */
export const GET_CONTACTS_API_PATH = "/accounts/{UID}/contacts";
export const GET_CONTACT_DETAIL_API_PATH = "/accounts/{UID}/contacts/{ACID}";

export const POST_CONTACTS_API_PATH = "/accounts/{UID}/contacts/";

export const PUT_CONTACTS_API_PATH = "/accounts/{UID}/contacts/";
