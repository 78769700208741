import React from 'react'
import {Chip, FormLabel} from "@material-ui/core";
import globalTheme from '../theme/globalTheme'
import {DatePicker} from "@material-ui/pickers";
import {empty} from "../../state/utils/Common";

/**
 * Final FormプラグインとMaterial UI Formフィールドを結合するコンポーネント
 * final formの日付入力欄
 *
 * @param {string} name - 日付欄の名前
 * @param {*} onChange - 日付変更イベント
 * @param {string} value - 日付値
 * @param {number} multiline - ？？
 * @param clearable
 * @param {*} restInput - 他の属性
 * @param {Object} meta - final formメタ情報
 * @param loadingOnDisable - ロード中の状態
 * @param okByDateClick
 * @param {string} dateFormat - 日付
 * @param {*} rest - 他の属性
 * @returns {JSX.Element}
 */
const FinalFormDate = (
    {
        input: {name, onChange, value, multiline = 0, ...restInput},
        meta,
        loadingOnDisable,
        clearable = false,
        okByDateClick = false,
        dateFormat = "YYYY/MM/DD",
        initialFocusedDate = null,
        ...rest
    }) => {
    const classes = globalTheme.defaultFormStyle();
    value = (!empty(value) ? value : null)

    const loadOnDisable = loadingOnDisable && rest.disabled ? classes.loadingOnDisable : undefined
    return (
        <div>
            <FormLabel className={loadOnDisable}>
                {rest.label}
                {rest.required &&
                    <Chip label={"必須"} size="small" className={[classes.chip, loadOnDisable].join(" ")}/>
                }
            </FormLabel>
            <DatePicker
                className={[classes.textfield, loadOnDisable].join(" ")}
                {...rest}
                name={name}
                data-testid={name}
                inputVariant="outlined"
                variant={"dialog"}
                disableToolbar={true}
                disabled={rest.disabled}
                cancelLabel={"キャンセル"}
                helperText={meta.touched ? meta.error : undefined}
                error={meta.error && meta.touched}
                inputProps={restInput}
                clearLabel={"リセット"}
                initialFocusedDate={initialFocusedDate}
                clearable={clearable}
                autoOk={okByDateClick}
                onChange={(event) => {
                    if(event) {
                        onChange(event.format(dateFormat))
                        return;
                    }
                    onChange(null)
                }}
                value={value}
                format={dateFormat}
                label=""
            >
            </DatePicker>
        </div>)
}
export default FinalFormDate;
