import ApiUtils from "../../../utils/ApiUtils";
import * as constants from './constants'
import _users from "./schema/users.json";
import {MANAGER_RESEND_AUTH0_USER_VERIFY_API_PATH, MANAGER_UNLOCK_AUTH0_USER_API_PATH} from "./constants";


/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /managerUsersのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

/**
 * ユーザーデータを取得APIリクエスト
 *
 * @param accessToken - auth0アクセストークン
 * @param params - 検索パラメーター
 * @returns {AxiosPromise}
 */
export function managerAllUsers(accessToken, params) {
    return new ApiUtils(accessToken).get(
        constants.MANAGER_USERS_API_PATH,
        params,
        _users
    );
}

/**
 * 新規ユーザー登録リクエスト
 *
 * @param user - 新規ユーザーデータ
 * @param accessToken - auth0アクセストークン
 * @returns {AxiosPromise}
 */
export function managerAddUsers(user, accessToken) {
    return new ApiUtils(accessToken).post(
        constants.MANAGER_USERS_API_PATH,
        user,
        null
    )
}

/**
 * ユーザー削除APIリクエスト
 *
 * @param id - ユーザーID
 * @param accessToken - auth0アクセストークン
 * @returns {AxiosPromise}
 */
export function managerDeleteUser(id, accessToken) {
    return new ApiUtils(accessToken).delete(
        constants.MANAGER_USERS_API_PATH,
        id
    )
}

/**
 * ユーザー更新APIリクエスト
 *
 * @param user_id - ユーザーID
 * @param params - 更新したユーザーデータ
 * @param accessToken - auth0アクセスデータ
 * @returns {AxiosPromise}
 */
export function managerUpdateUser(user_id, params, accessToken) {
    return new ApiUtils(accessToken).put(
        constants.MANAGER_USERS_API_PATH,
        user_id.uuid,
        params,
        null
    )
}

/**
 * ブロックされたAUTH0ユーザーの解除処理。
 * @param userId - ユーザーID
 * @param accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function managerUnlockAuth0UserAPI(userId, accessToken) {
    return new ApiUtils(accessToken).put(
        MANAGER_UNLOCK_AUTH0_USER_API_PATH.replace('{UUID}', userId),
        ""
    )
}

/**
 * 認証メールの再送信APIリクエスト
 *
 * @param userId - ユーザーID
 * @param accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function managerResendVerificationEmailAPI(userId, accessToken) {
    return new ApiUtils(accessToken).put(
        MANAGER_RESEND_AUTH0_USER_VERIFY_API_PATH.replace('{UUID}', userId),
        ""
    )
}
