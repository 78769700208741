import React from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormLabel,
    Grid, Link as LinkMaterial,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Link} from "react-router-dom";
import Page from "../../Page";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {empty} from "../../../../../state/utils/Common";
import clsx from "clsx";
import EditableCheck from "../../../../atoms/EditableCheck";
import urlLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";
import {Trans} from "react-i18next";
import TermsAndConditionsScroll from "../contents/TermsAndConditionsScroll";
import NotEditableCheck from "../../../../atoms/NotEditableCheck";
import services from "../../../../../state/utils/data/services.json";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(1)
    },
    button: {
        fontWeight: 900,
    }
}));

/**
 *
 * @param t
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const TerminateConfirmPage = (
    {
        t,
        terminateForm,
        service_contract_id,
        cancelReasonsData,
        SendSuccessCallBackAction,
        toggleTerms,
        cancelRequestLoading,
        accepted_terms,
        managerProfile,
        sendCancelServiceContract,
        scrolledTermsToBottom,
        termsHasReachedBottom
    }) => {
    const classes = useStyles();
    const {getAccessTokenSilently} = useAuth0();
    const active_services = Object.values(services).filter(item => {
        return terminateForm?.service_name?.includes(item)
    });
    
    
    let i89AgreementKey = "general.agree_terms";
    let i89InternalText = (<></>);
    if(active_services.includes(services.ADEBIS) && active_services.includes(services.CAPICO)) {
        i89InternalText = (
            <>
                上記申込内容、お客様への注意事項、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.ebis.ne.jp/policy/rule.php">AD EBiS
                利用契約約款</LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.ebis.ne.jp/rule_capico/">CAPiCO 利用契約約款
                </LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://support.ebis.ne.jp/search_want/218/">AD EBiSのご利用に際して
                </LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://support.ebis.ne.jp/s/article/000004889">CAPiCOのご利用に際して
                </LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href={ process.env.REACT_APP_COMPANY_SERVICE_POLICY_PAGE }>ご契約者さま専用マイページ利用規約</LinkMaterial> 」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」
                ならびに
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                に同意の上、申し込みます。
            </>
        )
    } else if(active_services.includes(services.ADEBIS)) {
        i89AgreementKey = 'general.ebis_only_agree_terms';
        i89InternalText = (
            <>
                上記申込内容、お客様への注意事項、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.ebis.ne.jp/policy/rule.php">AD EBiS
                利用契約約款</LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://support.ebis.ne.jp/search_want/218/">AD EBiSのご利用に際して
                </LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href={ process.env.REACT_APP_COMPANY_SERVICE_POLICY_PAGE }>ご契約者さま専用マイページ利用規約</LinkMaterial> 」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」
                ならびに
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                に同意の上、申し込みます。
            </>
        )
    } else if(active_services.includes(services.CAPICO)) {
        i89AgreementKey = 'general.capico_only_agree_terms';
        i89InternalText = (
            <>
                上記申込内容、お客様への注意事項、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.ebis.ne.jp/rule_capico/">CAPiCO 利用契約約款
                </LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://support.ebis.ne.jp/s/article/000004889">CAPiCOのご利用に際して</LinkMaterial>」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href={ process.env.REACT_APP_COMPANY_SERVICE_POLICY_PAGE }>ご契約者さま専用マイページ利用規約</LinkMaterial> 」、
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/">個人情報保護方針</LinkMaterial> 」
                ならびに
                「<LinkMaterial target="_blank" style={{color: "#0D66D0"}}
                               href="https://www.yrglm.co.jp/policy/privacy_f/">個人情報の取扱い</LinkMaterial>」
                に同意の上、申し込みます。
            </>
        )
    }
    
    
    
    return (
        <Page
            className={classes.root}
            title={t('service_contracts.terminate.title', {service_contract_name: !empty(cancelReasonsData?.service_account_name) ? cancelReasonsData?.service_account_name : "..."})}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h2">{t('service_contracts.terminate.title', {service_contract_name: !empty(cancelReasonsData?.service_account_name) ? cancelReasonsData?.service_account_name : "..."})}</Typography>
                    </Grid>
                </Grid>
                <Grid container alignContent={"center"} alignItems={"center"} style={{paddingTop: 6}}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs separator={">"} aria-label="breadcrumb">
                            <Link to={urlLinks.navigation.top.index.href}>
                                <Typography color="textPrimary">{t('general.top')}</Typography>
                            </Link>
                            <Link to={urlLinks.navigation.service_contracts.index.href}>
                                <Typography color="textPrimary">{t('service_contracts.title')}</Typography>
                            </Link>
                            <Link
                                to={urlLinks.navigation.service_contracts.index.sub_links.terminate.href.replace('{SCID}', service_contract_id)}>
                                <Typography
                                    color="textPrimary">{t('service_contracts.terminate.breadcrumb')}</Typography>
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Card>
                        <Box p={2}>
                            <Typography
                                variant={"h4"}>{t('service_contracts.terminate.terminate_form.header')}</Typography>
                        </Box>
                        <Divider/>

                        <Box p={2}>
                            <Box paddingBottom={2} paddingTop={1}>
                                <Typography variant={"body1"}>
                                    {t('service_contracts.terminate.confirm.description')}
                                </Typography>
                            </Box>
                            <FormLabel>{t('service_contracts.terminate.terminate_form.associated_account')}</FormLabel>
                            <Box paddingY={1}>
                                <Typography variant={"body1"}>
                                    <span
                                        style={{paddingRight: 25}}>{cancelReasonsData.service_account_id}</span>
                                    <span
                                        style={{paddingRight: 60}}>{cancelReasonsData.service_account_name}</span>
                                </Typography>
                            </Box>

                            <FormLabel>{t('service_contracts.terminate.terminate_form.associated_contracts')}</FormLabel>

                            { Object.values(services).map(item => {
                                if(terminateForm?.service_name?.includes(item)) {
                                    return (
                                        <>
                                            <br/>
                                            <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                {item}
                                            </Typography>
                                            <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                {t('service_contracts.terminate.terminate_form.contract_number')}: {cancelReasonsData?.[item]?.subscription_number}
                                            </Typography>
                                            <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                {t('service_contracts.contents.card_header.contract_period', {term: !empty(cancelReasonsData?.[item]) ? cancelReasonsData?.[item]?.current_term : '', period_measurement: !empty(cancelReasonsData?.[item]) ? t('general.time_periods.' + cancelReasonsData?.[item]?.current_term_period_type?.toLowerCase()) : "" })}
                                            </Typography>
                                            <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                {t('service_contracts.contents.card_header.next_update', {contract_update_date: !empty(cancelReasonsData?.[item]) ? cancelReasonsData?.[item].renewal_date : ''})}
                                            </Typography>
                                            { !empty(cancelReasonsData?.[item]) && cancelReasonsData?.[item]?.service_contract?.allow_prefer_ym === true && item === 'ADEBiS' && !cancelReasonsData?.[item]?.service_contract?.applied_cancel ? (
                                                <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                    {t('service_contracts.terminate.terminate_form.preferred_date_of_terminate_input', {cancel_date: terminateForm.ADEBiS_cancel_prefferd_ym})}
                                                </Typography>
                                            ) : (
                                                <Typography variant={"body1"} style={{marginRight: 15, display: "inline-block"}}>
                                                    {t('service_contracts.terminate.terminate_form.termination_date', {cancel_date: cancelReasonsData?.[item]?.service_contract?.cancel_at})}
                                                </Typography>
                                            )}
                                            <Box marginTop={2} marginBottom={3} style={{backgroundColor: "#EDE8DF", borderRadius: 3, padding: 13}}>
                                                <Typography variant={"h4"}>{t('service_contracts.terminate.terminate_form.service_name_contract_title', {service_name: item})}</Typography>
                                                <TermsAndConditionsScroll
                                                    scrolledToTermsBottom={scrolledTermsToBottom}
                                                    hasScrolledToTermsBottom={termsHasReachedBottom}
                                                    service_name={item}
                                                    isOldPlan={false}
                                                    t={t}
                                                />
                                            </Box>
                                        </>
                                    )
                                }
                            })}
                  
                            <FormLabel>{t('service_contracts.terminate.terminate_form.terminate_reason')}</FormLabel>
                            <Box paddingY={1}>
                                {!empty(cancelReasonsData?.cancel_reasons) && cancelReasonsData.cancel_reasons.map((item) => {
                                    if (terminateForm.cancel_reasons1.includes(item.value)) {
                                        return (
                                            <Box paddingBottom={1}>
                                                <Typography variant={"body1"}>
                                                    {item.label}
                                                </Typography>
                                            </Box>
                                        )
                                    } else {
                                        return ""
                                    }
                                })}
                            </Box>
                            <FormLabel>{t('service_contracts.terminate.terminate_form.reason_for_option')}</FormLabel>
                            <Box paddingY={1}>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-line"}}>
                                    {!empty(terminateForm?.cancel_reason2) ?
                                        terminateForm.cancel_reason2
                                        :
                                        t('service_contracts.terminate.confirm.no_input')
                                    }
                                </Typography>
                            </Box>

                            <FormLabel>{t('service_contracts.terminate.terminate_form.changing_service_to')}</FormLabel>
                            <Box paddingY={1}>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-line"}}>
                                    {!empty(terminateForm?.cancel_reason3) ?
                                        terminateForm.cancel_reason3
                                        :
                                        t('service_contracts.terminate.confirm.no_input')
                                    }
                                </Typography>
                            </Box>

                            <Divider/>

                            <Box marginTop={2} marginBottom={3}
                                 style={{backgroundColor: "#EDE8DF", borderRadius: 3, padding: 13}}>
                                <Typography variant={"body1"} style={{whiteSpace: "pre-wrap"}}>
                                    {t('service_contracts.terminate.warning_detail_pt1')}
                                </Typography>
                            </Box>

                            <Box className={clsx(classes.root)} paddingX={1}>
                                <Grid container justify="flex-start" alignItems={"flex-start"} spacing={5}>
                                    <Grid item>
                                        <FormControlLabel
                                            color={"primary"}
                                            disabled={cancelRequestLoading||Object.keys(termsHasReachedBottom).some(k => !termsHasReachedBottom[k])}
                                            control={
                                                <Checkbox
                                                    color={"primary"}
                                                    onChange={() => toggleTerms()}
                                                    checked={accepted_terms}
                                                />}
                                            label={
                                                <Trans i18nKey={i89AgreementKey}>
                                                    {i89InternalText}
                                                </Trans>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box paddingBottom={5}>
                                <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}>
                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                        <Link to={"/service_contracts/" + service_contract_id + "/terminate"}
                                              disabled={cancelRequestLoading}>
                                            <Button variant={"contained"} style={{marginRight: 15}} color={"secondary"}>
                                                {t('contact.confirm.back_to_input')}
                                            </Button>
                                        </Link>
                                        <NotEditableCheck>
                                            <Button disabled={true} color={"primary"} variant={"contained"}>
                                                {t('actions.send')}
                                            </Button>
                                        </NotEditableCheck>
                                        <EditableCheck>
                                            <Button disabled={!accepted_terms || cancelRequestLoading}
                                                    onClick={() => (async () => {
                                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                            () => {
                                                                return getAccessTokenSilently();
                                                            },
                                                        );
                                                        delete terminateForm.service_name
                                                        sendCancelServiceContract(access_token, managerProfile?.account?.uuid, service_contract_id, terminateForm, SendSuccessCallBackAction)
                                                    })()
                                                    } variant={"contained"}
                                                    color={"primary"}>
                                                {t('actions.send')}
                                            </Button>
                                        </EditableCheck>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default TerminateConfirmPage;
