import React, {useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Chip, FormLabel, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import globalTheme from "../../../../../theme/globalTheme";
import {mergeWithDefaultForm} from "../../../../../../state/utils/Common";
import Auth0Utils from "../../../../../../state/utils/Auth0Utils";
import {resendMailValidation} from "../../../../../../state/ducks/user/requests/validation";
import Validation, {translate} from "../../../../../../state/utils/Validation";
import RequestForms from "../../../../../../state/ducks/user/requests/forms"
import FinalFormTextField from "../../../../../atoms/FinalFormTextField";
import EditableCheck from "../../../../../atoms/EditableCheck";
import AdditionalTermsContainer
    from "../../../../../containers/user/requests/detail/quote/apply/Terms/AdditionalTermsContainer";

/**
 * コンポーネントスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const UserModal = (
    {
        isOpen,
        requestId,
        requestGroupId,
        
        sendMailValidation,
        resendEmailCheckLoading,
        resendEmailCheckPassed,
        resendEmailCheckError,
        
        resendModalClose,
        resendModalSend,
        resendMailLoading,
        resendMailError,
        requestData,
        profile,
        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // アプリケーションテーマ
    const globalStyle = globalTheme._default();
    // auth0 アクセストークン
    const {getAccessTokenSilently} = useAuth0();

    const listInnerRef = useRef();

    const [scrollState, setState] = useState(false)

    const onScroll = () => {
        if (listInnerRef.current && scrollState === false) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 100) {
                setState(true)
            }
        }
    }
    
    async function checkEmail(emailValue) {
        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
            () => {
                return getAccessTokenSilently();
            },
        );
        await sendMailValidation(access_token, profile?.account?.uuid, requestGroupId, requestId, emailValue);
    }

    return (
        <Modal open={isOpen}
               onClose={() => resendModalClose()}
               disableBackdropClick
               className={resendMailLoading ? globalStyle.loading : undefined}
        >
            <div
                className={[resendMailLoading ? globalStyle.loading : undefined, globalStyle.modal, globalStyle.modal_800].join(' ')}
                style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{t('requests.detail.mail_send_modal.title')}</Typography>
                <Typography variant="body1"
                            style={{'paddingBottom': 30}}>{t('requests.detail.mail_send_modal.description')}</Typography>

                <Form
                    onSubmit={async (e) => {
                        e = mergeWithDefaultForm(e, RequestForms.resendMailFormModal)
                        if (!resendMailLoading) {
                            const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                () => {
                                    return getAccessTokenSilently();
                                },
                            );
                            await resendModalSend(access_token, profile?.account?.uuid,  requestGroupId, requestId, e);
                        }
                    }}
                    // ここでフォームデータを妥当性確認し、キーを変換します。
                    validate={e => {
                        return translate(t, Validation({...e}, resendMailValidation))
                    }}
                    initialValues={RequestForms.resendMailFormModal}
                    // ここでは、フォームのレンダリングと制御を行います
                    // エラー処理やダブルクリック防止などはここで行います
                    render={({handleSubmit, form, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        loadingOnDisable={resendMailLoading}
                                        disabled={resendMailLoading}
                                        size={"small"}
                                        name="company_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('requests.detail.mail_send_modal.company_name')}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        size={"small"}
                                        loadingOnDisable={resendMailLoading}
                                        disabled={resendMailLoading}
                                        name="contact_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('requests.detail.mail_send_modal.manager_name')}
                                    />
                                </Grid>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={6}>
                                    <Field
                                        name="email"
                                        fullWidth
                                        required
                                        loadingOnDisable={resendMailLoading}
                                        disabled={resendMailLoading||resendEmailCheckLoading}
                                        onBlur={() => checkEmail(values?.email)}
                                        size={"small"}
                                        component={FinalFormTextField}
                                        type="email"
                                        label={t('requests.detail.mail_send_modal.email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={globalStyle.error}>
                                        {t(resendEmailCheckError?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                <Grid container style={{margin: "15px 0"}}>
                                    <FormLabel style={{paddingLeft: 12, paddingBottom: 20}}>
                                        {t('additional_option_label.form_labels.AD_OWNER.terms')}
                                    </FormLabel>
                                    <Grid item xs={12}>
                                        <AdditionalTermsContainer height={92} onScroll={onScroll} listInnerRef={listInnerRef}></AdditionalTermsContainer>
                                    </Grid>
                                </Grid>
                                <Grid container style={{margin: "15px 0 0 0"}}>
                                    <FormLabel style={{paddingLeft: 12, paddingBottom: 20, fontWeight: "normal"}}>
                                        {t('requests.detail.mail_send_modal.notice')}
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={globalStyle.error}>
                                        {t(resendMailError?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={3} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            color="secondary"
                                            variant="contained"
                                            disabled={resendMailLoading}
                                            onClick={() => resendModalClose()}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>


                                    <Grid item>
                                        <EditableCheck>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={invalid || pristine || scrollState === false ||  resendMailLoading || resendEmailCheckPassed === false}
                                            >
                                                {resendMailLoading ? t('actions.sending') : t('actions.agree_and_send')}
                                            </Button>
                                        </EditableCheck>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>
        </Modal>
    )
};

export default UserModal;
