import React, {useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/core';
import managerTheme from './theme/manager'
import userTheme from './theme/user'
import GlobalStyles from "./GlobalStyles";
import DashboardLayout from "./layout/DashboardLayout/DashboardLayout";
import ManagerDashboardLayout from "./layout/ManagerDashboardLayout/ManagerDashboardLayout";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {rootOperations} from "../state/ducks/root";
import ManagerSignOutPage from "./components/manager/SignOutPage";
import {empty} from "../state/utils/Common";
import {useAuth0} from "@auth0/auth0-react";
import BlankLayout from "./layout/BlankLayout/Blank";
import Auth0Utils from "../state/utils/Auth0Utils";
import ErrorLayout from "./layout/ErrorLayout/Error";
import SignOutPage from "./components/user/SignOutPage";
import {ErrorBoundary} from "react-error-boundary";
import {ProtectedRoute} from "../PrivateRoute";
import SessionTimeoutUtil from "../state/utils/SessionTimeoutUtil";
import FullScreenLoading from "./atoms/FullScreenLoading";
import LoginAsUtils from "../state/utils/LoginAsUtils";
import GuestDashboardLayout from "./layout/GuestLayout/GuestDashboardLayout";
import MaintenanceModeContainer from "./containers/user/MaintenanceModeContainer";

/**
 * 選択されたユーザーのRedux状態をコンポーネントに描画
 *
 * @param state
 * @returns {{isError, managerProfile, errorDetail: *}}
 */
const mapStateToProps = state => {
    return {
        isError: state.root.isError,
        errorDetail: state.root.errorDetail,
        managerProfile: state.root.managerProfile,
        accessTokenFailureDialog: state.root.accessTokenFailureDialog,
        accessTokenFailureError: state.root.accessTokenFailureError,
        sidebarLinks: state.root.sidebarLinks
    }
}
/**
 * アクションやオペレーションをreduxからここに描く
 */
const mapEventToProps = {
    errorConfirm: rootOperations.errorConfirm,
    getProfile: rootOperations.getProfile,
    errorSend: rootOperations.errorSend
}
/**
 * メインビューコンポーネント、すべてのビューはここからロードされます。
 * これは現在、正しいテーマとテンプレートを読み込むために、現在のURLをチェックしています。
 *  注：プロファイルが読み込めない場合は何も表示されません。
 *
 * @param isError - /ducks/root/reducer redux - アプリケーションロード時の状態
 * @param errorDetail - /ducks/root/reducer redux - アプリケーションのエラーメッセージ
 * @param errorConfirm - /ducks/root/operation redux - エラーメッセージの確認
 * @param errorSend - /ducks/root/operation redux - エラー内容をAPIサーバーに送信する。
 * @param t - i18next翻訳ライブラリ
 * @param location - react-router-dom - 現在のURL
 * @param getProfile - /ducks/root/operation redux -  APIからプロファイルを取得
 * @param managerProfile - /ducks/root/reducer redux - 現在のプロファイルの状態
 * @param accessTokenFailureDialog
 * @param accessTokenFailureError
 * @param sidebarLinks
 * @returns {JSX.Element} - BlankLayout|ManagerDashboardLayout|DashboardLayout
 * @constructor
 */
const AppContainer = ({
                          isError,
                          errorDetail,
                          errorConfirm,
                          errorSend,
                          t,
                          location,
                          getProfile,
                          managerProfile,
                          accessTokenFailureDialog,
                          accessTokenFailureError,
                          sidebarLinks
                      }) => {
    const {user, getAccessTokenSilently, logout} = useAuth0();
    const [sessionUtil, setSessionUtil] = useState(null);
    useEffect(() => {
        setSessionUtil(new SessionTimeoutUtil(logout)
            .startSessionCheck()
            .enableUserTracking())
    }, [logout])
    // url == managerでユーザーが管理者でない場合、ユーザーダッシュボードにリダイレクトする。
    // accounts/contacts・各種設定の担当者画面は除く
    if (window.location.href.includes("manager") && !empty(managerProfile) && managerProfile?.is_user === true) {
        return (
            <div>
                <Redirect to={'/'}/>
            </div>
        )
    } else if (!window.location.href.includes("manager") && !empty(managerProfile) && LoginAsUtils.isUser(managerProfile) === false) {
        // url == userでユーザーが通常ユーザーでない場合、管理者ダッシュボードにリダイレクトする。
        return (
            <div>
                <Redirect to={'/manager/accounts'}/>
            </div>
        )
    }


    // ログイン後にAPIからログインユーザーのプロファイルを読み込む
    if (!empty(user)
        && empty(managerProfile)
        && isError === false
        && accessTokenFailureDialog === false
    ) {
        (async () => {
            const accessToken = await Auth0Utils.checkAndUpdateAccessToken(
                () => {
                    return getAccessTokenSilently();
                }
            );
            getProfile(user.sub, accessToken, !window.location.href.includes("manager"));
        })();
    }

    return (
        <div>
            <GlobalStyles/>
            <ErrorBoundary
                fallbackRender={props => <ThemeProvider theme={userTheme}><ErrorLayout sessionUtil={sessionUtil}
                                                                                       errorObject={!empty(props.error) ? props.error : props}
                                                                                       t={t}
                                                                                       title={t("error.application_error")}
                                                                                       message={t("error.access_token_description")}/></ThemeProvider>}>
                {accessTokenFailureDialog === true || isError === true ?
                    <ThemeProvider theme={userTheme}>
                        <ErrorLayout
                            sessionUtil={sessionUtil}
                            errorObject={!empty(accessTokenFailureError) ? accessTokenFailureError : errorDetail}
                            title={t("error.application_error")}
                            error_code={errorDetail.title}
                            message={!empty(errorDetail) ?
                                t(errorDetail.translation_error_key) :
                                t("error.access_token_description")
                            }
                        />
                    </ThemeProvider>
                    :
                    <Switch>
                        <Route exact path="/manager/sign_out">
                            <ManagerSignOutPage/>
                        </Route>
                        <Route exact path='/sign_out'>
                            <SignOutPage/>
                        </Route>
                        <Route exact path='/manager'>
                            <ThemeProvider theme={managerTheme}>
                                <BlankLayout getProfile={getProfile} managerProfile={managerProfile}/>
                            </ThemeProvider>
                        </Route>
                        <Route path={"/transfer/accept"}>
                            <ThemeProvider theme={userTheme}>
                                <GuestDashboardLayout/>
                            </ThemeProvider>
                        </Route>
                        <Route path='/manager'>
                            <ThemeProvider theme={managerTheme}>
                                <ProtectedRoute redirectTo={"/manager"} component={() => (
                                    empty(managerProfile) || managerProfile?.is_user === true ? <FullScreenLoading/> :
                                        <ManagerDashboardLayout getProfile={getProfile}
                                                                managerProfile={managerProfile}
                                                                sidebarLinks={sidebarLinks}/>
                                )}/>
                            </ThemeProvider>
                        </Route>
                        <Route path='/'>
                            <ThemeProvider theme={userTheme}>
                                <MaintenanceModeContainer profile={managerProfile}>
                                    <ProtectedRoute redirectTo={"/"} component={() => (
                                        empty(managerProfile) || LoginAsUtils.isUser(managerProfile) === false ?
                                            <FullScreenLoading/> :
                                            <DashboardLayout
                                                getProfile={getProfile}
                                                managerProfile={managerProfile}
                                                sidebarLinks={sidebarLinks}
                                            />
                                    )}/>
                                </MaintenanceModeContainer>
                            </ThemeProvider>
                        </Route>
                    </Switch>
                }
            </ErrorBoundary>
        </div>
    )
}

/**
 * ロードされたreduxのステートとプロップを上記のコンポーネントにプッシュします。
 * 注：翻訳ライブラリとルーターライブラリもここで呼ばれます。
 */

export const App = compose(
    connect(mapStateToProps, mapEventToProps),
    withRouter,
    withTranslation('common'))(AppContainer)
