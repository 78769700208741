import React, {useState} from 'react';
import {Box, Grid, makeStyles} from '@material-ui/core';
import { Document, Page as PDFPage } from 'react-pdf/dist/entry.webpack';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};
const useStyles = makeStyles((theme) => ({
    page: {
        margin: "20px 0 20px 0",
    },
    document: {
        width: "100%",
    }
}));

/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const QuotePDFViewer = (
    {
        pdfDataBlobPreview,
        t
    }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12} style={{display: "block",background: "grey"}}>
                <Box mt={1} style={{ height: 650, overflowY: "scroll",textAlign:"center",display:"grid",margin:"0 auto",justifyContent:"center"}}>
                    <Document file={ `data:application/pdf;base64,${pdfDataBlobPreview}`} className={classes.document}
                              onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        {Array.apply(null, Array(numPages))
                            .map((x, i)=>i+1)
                            .map(page => <PDFPage pageNumber={pageNumber} scale={2} className={classes.page} />)
                        }
                    </Document>
                </Box>
            </Grid>
        </Grid>
    );
}

export default QuotePDFViewer;
