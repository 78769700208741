import {Grid, Typography} from "@material-ui/core";
import React from "react";
import globalTheme from "../../../../../../theme/globalTheme";
import CheckIcon from '@material-ui/icons/Check';
import {Link} from "react-router-dom";

export const CurrentServicesRow = props => {
    const commonTheme = globalTheme._default();
    return (
        <Link to={'/service_contracts/' + props.data.current_services[props.index].ebis_log_id + '/contents'}>
            <Grid container alignItems="center" key={props.data.current_services[props.index].ebis_log_id} style={{
                ...props.style,
                paddingLeft: 21,
                paddingTop: 8,
                paddingBottom: 13,
                paddingRight: 29,
                borderBottom: "1px #CFCFCF solid"
            }}>

                <Grid item xs={1}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.current_services[props.index].service_name}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.current_services[props.index].ebis_log_id}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.current_services[props.index].ebis_client_name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        color="textPrimary"
                        variant="body1"
                        className={commonTheme.breakWrap}
                    >
                        {props.data.current_services[props.index].cutoff_date}
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{textAlign: "center"}}>
                    {props.data.current_services[props.index].is_bill_to_contact &&
                        <CheckIcon style={{color: "#71ad48"}}/>
                    }
                </Grid>
                <Grid item xs={2} style={{textAlign: "center"}}>
                    {props.data.current_services[props.index].is_sold_to_contact &&
                        <CheckIcon style={{color: "#71ad48"}}/>
                    }
                </Grid>
            </Grid>
        </Link>
    )
}
