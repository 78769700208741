import React from 'react';
import {Box, Breadcrumbs, Card, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../../../../Page";
import clsx from "clsx";
import EbisInformation from "./steps/EbisInformation";
import globalTheme from "../../../../../../theme/globalTheme";
import ConfirmView from "./steps/Confirm";
import CompleteView from "./steps/Complete";
import {Link} from "react-router-dom";
import urlLinks from "../../../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const InitialSettingsPage = (
    {
        requestDetailLoading,
        requestStartUpInformationStep,
        requestStartUpInformation,
        additionalFieldsArray,
        additionalOptionInformation,
        formattedAdditionalOptionInformation,
        settingAdditionalSteps,
        additionalOptionValidation,
        settingSubmitEbisFormInformation,
        settingConfirmEbisFormInformation,
        managerProfile,
        requestGroupId,
        settingEbisBackButton,
        ebisAccountConfirmedLoading,
        ebisAccountConfirmedError,
        mailValidationPackage,
        t
    }) => {
    const classes = useStyles();
    const globalFormStyle = globalTheme.wizard()
    const spacing = 4;
    return (
        <Page
            className={classes.root}
            title={t('requests.detail.quotes.title')}
        >
            {requestDetailLoading !== true &&
                <Container maxWidth={false}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h2">{t('requests.detail.quotes.title')}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Breadcrumbs separator={">"} aria-label="breadcrumb">
                                <Link to={urlLinks.navigation.top.index.href}>
                                    <Typography color="textPrimary">{t('general.top')}</Typography>
                                </Link>
                                <Link to={urlLinks.navigation.requests.index.href}>
                                    <Typography color="textPrimary">{t('requests.title_user')}</Typography>
                                </Link>
                                <Link
                                    to={urlLinks.navigation.requests.index.sub_links.detail.href.replace('{RID}', requestGroupId)}>
                                    <Typography color="textPrimary">{t('requests.detail.title')}</Typography>
                                </Link>
                                <Link
                                    to={urlLinks.navigation.requests.index.sub_links.detail.sub_links.initial_settings.href.replace('{RGID}', requestGroupId)}>
                                    <Typography color="textPrimary">{t('requests.detail.initial_setting.incomplete.detail.title')}</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box mt={1}>
                        <Card>
                            <Box className={clsx(classes.root)}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h4">{t('requests.detail.quotes.setting.title')}</Typography>
                                </Grid>
                            </Box>
                            <Grid container spacing={0}>
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        'active': requestStartUpInformationStep === 0,
                                        'complete': requestStartUpInformationStep > 0
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.setting.wizard_titles.step_1_ebis', {'step': 1} )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        'active': requestStartUpInformationStep === 2,
                                        'complete': requestStartUpInformationStep > 2
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.setting.wizard_titles.step_2_confirm', {'step': 2 })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={spacing} className={
                                    clsx({
                                        [globalFormStyle.headers]: true,
                                        'active': requestStartUpInformationStep >= 3
                                    })}>
                                    <Typography
                                        variant={"body1"}>{t('requests.detail.quotes.setting.wizard_titles.step_3_complete',{'step': 3})}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box className={clsx(classes.root)}>
                                {requestStartUpInformationStep === 0 &&
                                    <EbisInformation
                                        t={t}
                                        requestGroupId={requestGroupId}
                                        managerProfile={managerProfile}
                                        requestStartUpInformation={requestStartUpInformation}
                                        settingSubmitEbisFormInformation={settingSubmitEbisFormInformation}

                                        additionalOptionInformation={additionalOptionInformation}
                                        additionalFieldsArray={additionalFieldsArray}
                                        additionalOptionValidation={additionalOptionValidation}
                                        settingAdditionalSteps={settingAdditionalSteps}
                                        settingEbisBackButton={settingEbisBackButton}
                                        mailValidationPackage={mailValidationPackage}
                                    />
                                }
                                { requestStartUpInformationStep === 2 &&
                                    <ConfirmView
                                        t={t}
                                        managerProfile={managerProfile}
                                        requestGroupId={requestGroupId}
                                        additionalOptionInformation={additionalOptionInformation}
                                        requestStartUpInformation={requestStartUpInformation}
                                        formattedAdditionalOptionInformation={formattedAdditionalOptionInformation}
                                        settingConfirmEbisFormInformation={settingConfirmEbisFormInformation}
                                        ebisAccountConfirmedLoading={ebisAccountConfirmedLoading}
                                        ebisAccountConfirmedError={ebisAccountConfirmedError}
                                        settingEbisBackButton={settingEbisBackButton}
                                    />
                                }
                                {requestStartUpInformationStep >= 3 &&
                                    <CompleteView t={t}
                                                  requestGroupId={requestGroupId}
                                    />
                                }
                            </Box>

                        </Card>
                    </Box>
                </Container>
            }
        </Page>
    );
}

export default InitialSettingsPage;
