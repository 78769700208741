import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import globalTheme from "../../../../../../theme/globalTheme";
import clsx from "clsx";
import TermsAndConditionsModal from "./modal/TermsAndConditionsModal";
import requestType from "../../../../../../../state/utils/data/application_request_type_flat.json"
import TermsAndConditionsScroll from "./TermsAndConditionsScroll";
import TermsAndConditionsContainer
    from "../../../../../../containers/user/requests/detail/quote/apply/Terms/TermsAndConditionsContainer";

/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const NotesQuoteSegment = (
    {
        requestId,
        quoteId,
        requestDetail,
        requestQuoteLoading,
        requestQuote,
        termsAndConditionsIsOpen,
        closeTermsAndConditionsModal,
        openTermsAndConditionsModal,
        isModal = true,
        hasScrolledToTermsBottom=null,
        t
    }) => {
    const classes = useStyles();
    const globalClass = globalTheme._default();
    const quotationPriceList = "https://support.ebis.ne.jp/tool/introduction/{}".replace('{}', requestQuote.price_list)

    return (
        <Box className={clsx(classes.root)}>
            {isModal === true &&
            <>
                <Grid container style={{marginTop: 15}}>
                    <Grid item xs={4} sm={1}>
                        <Typography variant="body1"
                                    className={classes.strong}>{t('requests.detail.quotes.contract_warning')}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={11} className={globalClass.detail_items}
                          onClick={() => openTermsAndConditionsModal()}>
                        <Typography variant="overline"
                                    style={{cursor: "pointer", textDecoration: "underline"}}>表示する</Typography>
                    </Grid>
                </Grid>
                <TermsAndConditionsModal
                    requestQuote={requestQuote}
                    isOpen={termsAndConditionsIsOpen}
                    handleClose={closeTermsAndConditionsModal}
                    t={t}
                />
            </>
            }
            {isModal === false &&
            <Grid container style={{marginTop: 15}}>
                <Grid item xs={4} sm={1}>
                    <Typography variant="body1"
                                className={classes.strong}>{t('requests.detail.quotes.contract_warning')}</Typography>
                </Grid>
                <Grid item xs={8} sm={11} className={globalClass.detail_items}>
                    <TermsAndConditionsContainer
                        hasScrolledToTermsBottom={hasScrolledToTermsBottom}
                        requestQuote={requestQuote}
                        t={t}
                    />
                </Grid>
            </Grid>
            }
        </Box>
    );
}

export default NotesQuoteSegment;
