import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */

// アカウントのユーザー取得
const managerSelectAccountUsers = actions.managerSelectAccountUsers;
const registerNewUser = actions.registerNewUser;
const updateUser = actions.updateUser;
const deleteUsers = actions.deleteUsers;
const unlockAuth0User = actions.unlockAuth0User;
const resendAuth0VerifyMail = actions.resendAuth0VerifyMail;
const resendAuth0VerifyMailCloseNotification = actions.resendAuth0VerifyMailCloseNotification;
const closeUnlockAuth0UserModal = actions.closeUnlockAuth0UserModal;
const viewRegisterToggle = actions.viewRegisterToggle;
const viewUserDeleteToggle = actions.viewUserDeleteToggle;
// オペレーションのまとめ
const operations = {
    managerSelectAccountUsers,
    registerNewUser,
    updateUser,
    deleteUsers,
    unlockAuth0User,
    resendAuth0VerifyMail,
    resendAuth0VerifyMailCloseNotification,
    closeUnlockAuth0UserModal,
    viewRegisterToggle,
    viewUserDeleteToggle
};
export default operations;
