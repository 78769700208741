import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// エラーイベント
//-------------------------------------------------

export function error_raise(error) {
    return {
        type: types.ERROR_OCCURRED_EVENT,
        payload: {
            error: error
        }
    }
}

export function error_confirm() {
    return {
        type: types.ERROR_CONFIRM_EVENT,
    }
}

//-------------------------------------------------
// エラー送信リクエスト
//-------------------------------------------------
export function errorSend(access_token, error) {
    return {
        type: types.DEBUG_SEND_ERROR_REPORT,
        payload: {
            access_token: access_token,
            error: error
        }
    }
}

//-------------------------------------------------
// プロファイル取得リクエスト
//-------------------------------------------------
/**
 * プロファイル取得リクエスト
 * @param {string} auth0_user_id - auth0 user id
 * @param {string} accessToken - auth0 access token
 * @param {boolean} isUserScreen - フロント画面かどうか
 */
export function getProfile(auth0_user_id, accessToken, isUserScreen) {
    return {
        type: types.GET_MANAGER_PROFILE,
        payload: {
            auth0_id: auth0_user_id,
            access_token: accessToken,
            isUserScreen: isUserScreen
        }
    }
}

/**
 * プロファイル取得リクエスト（成功）
 * @param {Object} user_profile - returned user profile
 */
export function getProfileSuccess(user_profile) {
    return {
        type: types.GET_MANAGER_PROFILE_SUCCESS,
        payload: {
            user_profile: user_profile
        }
    }
}

/**
 * プロファイル取得リクエスト（失敗）
 * @param {Object} e - error message
 */
export function getProfileFailure(e) {
    return {
        type: types.GET_MANAGER_PROFILE_FAILURE,
        payload: {
            error: e
        }
    }
}

export function clearProfile() {
    return {
        type: types.CLEAR_MANAGER_PROFILE
    }
}

/**
 * auth0アクセストークン取得失敗
 * @param error - エラー内容
 * @returns {{payload: {message}, type: object}}
 */
export function accessTokenFailure(error) {
    return {
        type: types.ACCESS_TOKEN_FAILURE,
        payload: {
            error: error
        }
    }
}

/**
 * サイドバーナビのロードエベント
 * @param url_list
 * @returns {{payload: {urls}, type: string}}
 */
export function loadSidebarItems(url_list) {
    return {
        type: types.LOAD_SIDEBAR_LINKS,
        payload: {
            urls: url_list
        }
    }
}

const actions = {
    error_raise,
    error_confirm,
    errorSend,

    getProfile,
    getProfileSuccess,
    getProfileFailure,

    accessTokenFailure,
    loadSidebarItems,
    clearProfile
};
export default actions;
