import React from 'react';
import {Box, Button, Card, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import {Link} from "react-router-dom";

/**
 * /manager/users コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    mainBox: {
        width: 400,
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7)
    },
    button: {
        fontWeight: 900,
    }
}));
/**
 *
 *
 * @param loading
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const TransferAcceptCompletePage = (
    {
        t
    }) => {
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('transfer_approval.title')}
        >
            <Container maxWidth={false}>
                <Grid
                    style={{paddingTop: "131px"}}
                    container
                    spacing={0}
                    align="center"
                    justify="center"
                    direction="column"
                >
                    <Grid item>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Card className={classes.mainBox}>
                                <Typography variant={"h2"} style={{fontSize: 18}}>
                                    {t('transfer_approval.title')}
                                </Typography>
                                <Grid container spacing={1} style={{paddingTop: 20}}>
                                    <Grid item xs={12}>
                                        <Typography variant={"body1"} style={{textAlign: "left", float: "left", whiteSpace: "pre-line"}}>
                                            {t('transfer_approval.complete.description')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{paddingTop: 30}} spacing={1}>
                                    <Grid item xs={12}>
                                        <Link to={"/"}>
                                            <Button
                                                variant={"outlined"}
                                            >{t('actions.to_login_screen')}
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>

                </Grid>

            </Container>
        </Page>
    );
};

export default TransferAcceptCompletePage;
