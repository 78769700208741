import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {serviceContractContentOperations} from "../../../../../state/ducks/user/service_contracts/contents";
import userSideBarLinks from "../../../../../state/utils/data/sitemap/user/sidebar_navigation_urls.json"
import {rootOperations} from "../../../../../state/ducks/root";
import {empty} from "../../../../../state/utils/Common";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {useParams} from "react-router";
import ServiceContractsContentsView from "../../../../components/user/serviceContracts/contents/Page";
import {serviceContractListOperations} from "../../../../../state/ducks/user/service_contracts";


/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        managerProfile: state.root.managerProfile,

        serviceContractContentData: state.serviceContractContent.serviceContractContentData,
        serviceContractContentLoading: state.serviceContractContent.serviceContractContentLoading,
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadSidebarItems: rootOperations.loadSidebarItems,
    serviceContractContentLoad: serviceContractContentOperations.serviceContractContentLoad,
    resetForm: serviceContractContentOperations.resetForm,
    saveContractNameToSidebarLink: serviceContractListOperations.saveContractNameToSidebarLink,
}

/***
 * ///////////////////////////////////////////////////////////
 * CONTAINER VS COMPONENT (コンテイナ対コンポーネント)
 * --------------------------------
 * コンテナは、任意のアクションを *実行* し、任意の redux ストアやオペレーションをロードして、コンポーネントにプッシュします。
 * コンポーネントは、可能な限りダムでなければなりません。つまり、目に見えるデータのみを表示する必要があります。
 * ///////////////////////////////////////////////////////////
 */

/*
通常ユーザー側の申込コンテイナー
*/
const _serviceContractContentContainer = (
    {
        managerProfile,
        serviceContractContentData,
        serviceContractContentLoading,
        resetForm,

        loadSidebarItems,
        serviceContractContentLoad,

        saveContractNameToSidebarLink,

        t,
    }) => {
    let {scid} = useParams();

    // auth0 アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        // ログインしたユーザープロフィール取得したかどうかのチェック
        if (!empty(managerProfile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                serviceContractContentLoad(access_token, managerProfile?.account?.uuid, scid)
            })()
        }
    }, [getAccessTokenSilently, serviceContractContentLoad, managerProfile, scid])
    // 利用契約一覧をAPIから取得する。
    useEffect(() => {
        loadSidebarItems(userSideBarLinks.navigation.service_contracts)
    }, [loadSidebarItems])

    useEffect(() => {
        resetForm()
    }, [resetForm])

    useEffect(() => {
        if(!empty(managerProfile) && !empty(serviceContractContentData)) {
            saveContractNameToSidebarLink(serviceContractContentData?.service_account_name, serviceContractContentData?.cancelable)
        }
    }, [managerProfile, serviceContractContentData, saveContractNameToSidebarLink])

    return (
        <ServiceContractsContentsView
            serviceContractContentData={serviceContractContentData}
            serviceContractContentLoading={serviceContractContentLoading}
            serviceContractId={scid}
            t={t}
        />
    )
}

/**
 *
 * * Redux
 * * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const ServiceContractContentContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(_serviceContractContentContainer)

export default ServiceContractContentContainer;
