import types, {REQUEST_CHECK_RESEND_EMAIL_ADDRESS, REQUEST_TERMS_OF_SERVICE_LOAD} from "./types";
import {empty} from "../../../utils/Common";
import PlanOptionInputBlocks from "../../../../state/utils/data/request_application/option_input_blocks.json";
import PreferredServiceSort from "../../../../state/utils/data/preferred_service_sort.json";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

/**
 * 検索条件で申込データ取得
 * @param form
 * @param access_token
 * @param account_id
 * @returns {{payload: {access_token, form}, type: string}}
 */
export function loadRequestSearch(form, access_token, account_id) {
    return {
        type: types.REQUESTS_SEARCH,
        payload: {
            form: form,
            access_token: access_token,
            account_id: account_id
        }
    }
}

export function sendContractNameToSearchRequest(account_name) {
    return {
        type: types.CONTRACT_NAME_SEND_TO_FORM,
        payload: {
            account_name: account_name
        }
    }
}

/**
 * 一覧取得のロードエベント
 * @returns {{type: string}}
 */
export function requestSearchLoading() {
    return {
        type: types.REQUESTS_SEARCH_LOADING
    }
}

/**
 * 一覧取得結果エベント
 * @param requests
 * @returns {{payload: {requests}, type: string}}
 */
export function requestSearchLoaded(requests) {
    return {
        type: types.REQUESTS_SEARCH_SUCCESS,
        payload: {
            requests: requests
        }
    }
}

/**
 * 検索条件で取得の失敗
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestSearchFailed(e) {
    return {
        type: types.REQUESTS_SEARCH_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * 一覧取得開始エベント
 * @param access_token
 * @param account_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsLoad(access_token, account_id) {
    return {
        type: types.REQUESTS_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id
        }
    }
}

/**
 * リクエストロード中エベント
 * @returns {{type: string}}
 */
export function requestsLoading() {
    return {
        type: types.REQUESTS_LOADING,
    }
}

/**
 * リクエスト成功エベント
 * @param requests
 * @returns {{payload: {requests}, type: string}}
 */
export function requestsSuccess(requests) {
    return {
        type: types.REQUESTS_SUCCESS,
        payload: {
            requests: requests
        }
    }
}

/**
 * リクエスト失敗
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsFailed(e) {
    return {
        type: types.REQUESTS_FAILED,
        payload: {
            error: e
        }
    }
}

/**
 * フォーム項目の変更エベント
 * @param form
 * @returns {{payload: {form}, type: string}}
 */
export function formEditEvent(form) {
    return {
        type: types.EDIT_SEARCH_FORM,
        payload: {
            form: form
        }
    }
}

/*********************************
 * お申込詳細ページ
 ***********************************/

/**
 * 申込詳細の取得エベント
 * @param access_token
 * @param account_id
 * @param request_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsDetailLoad(access_token, account_id, request_id) {
    return {
        type: types.REQUESTS_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id
        }
    }
}

/**
 * 申込詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function requestsDetailLoading() {
    return {
        type: types.REQUESTS_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param request_detail
 */
export function requestsDetailSuccess(request_detail) {
    if(request_detail.requests.length > 0) {
        // サービスの並び替え
        request_detail.requests.sort(function (a, b) {
            return PreferredServiceSort[a.service_name] - PreferredServiceSort[b.service_name]
        })
    }
    
    return {
        type: types.REQUESTS_DETAIL_LOAD_SUCCESS,
        payload: {
            request_detail: request_detail
        }
    }
}

/**
 * 申込詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsDetailFailed(e) {
    return {
        type: types.REQUESTS_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * お申込詳細の見積詳細
 ***********************************/

/**
 * 申込見積詳細の取得エベント
 * @param access_token
 * @param account_id
 * @param request_group_id
 * @param request_id
 * @param quote_id
 * @returns {{payload: {access_token}, type: string}}
 */
export function requestsDetailQuoteDetailLoad(access_token, account_id, request_group_id, request_id, quote_id) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_group_id: request_group_id,
            request_id: request_id,
            quote_id: quote_id
        }
    }
}

/**
 * 申込見積詳細取得のロード中エベント
 * @returns {{type: string}}
 */
export function requestsDetailQuoteDetailLoading() {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING,
    }
}

/**
 * 申込見積詳細の取得成功エベント
 * @returns {{payload: {requests}, type: string}}
 * @param quote
 */
export function requestsDetailQuoteDetailSuccess(quote) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS,
        payload: {
            quote: quote
        }
    }
}

/**
 * 申込見積詳細の取得失敗エベント
 * @param e
 * @returns {{payload: {error}, type: string}}
 */
export function requestsDetailQuoteDetailFailed(e) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE,
        payload: {
            error: e
        }
    }
}

/****************
 * 申込み初期設定
 ****************/

export function initializeOptionFormInformation(rawInput) {
    // オプション入力欄
    let filter = {};
    let validation = {};
    rawInput.forEach((element) => {
        element.fields.forEach((value) => {
            filter = {
                ...filter, ...{
                    [value.name]: ""
                }
            }
        })

        validation = {...validation, ...element.validation};

    })

    return {
        type: types.SETTING_EBIS_ACCOUNT_OPTION_FORM_INPUT_SETTING,
        payload: {
            initialFields: filter,
            validation: validation
        }
    }
}

export function declareNoUpdateOptionNeeded() {
    return {
        type: types.SETTING_EBIS_OPTION_NO_UPDATE_NEEDED,
    }
}


/****************
 * 初期設定画面
 ****************/

export function resetFormInformation() {
    return {
        type: types.RESET_FORM
    }
}

export function settingSubmitEbisFormInformation(formInformation) {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_INPUT,
        payload: {
            requestStartUpInformation: formInformation,
            stepBy: 2
        }
    }
}

export function settingAdditionalSteps(formInformation) {
    let reorderedFormData = {}
    if (!empty(formInformation)) {
        for (let [key, value] of Object.entries(formInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }
    return {
        type: types.SETTING_ADDITIONAL_OPTION_INPUT,
        payload: {
            additionalFormInformation: formInformation,
            formattedAdditionalForm: reorderedFormData
        }
    }
}

export function settingUpdateAdditionalSteps(formInformation) {
    let reorderedFormData = {}
    if (!empty(formInformation)) {
        for (let [key, value] of Object.entries(formInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }
    return {
        type: types.UPDATE_SETTING_ADDITIONAL_OPTION_INPUT,
        payload: {
            additionalFormInformation: formInformation,
            formattedAdditionalForm: reorderedFormData
        }
    }
}

export function backToAdditionalSteps() {
    return {
        type: types.BACK_TO_UPDATE_SETTING_ADDITIONAL_OPTION_INPUT
    }
}

/*********************************
 * 本申フォーム①
 * 初期設定
 ***********************************/

export function settingConfirmEbisFormInformation(accessToken, account_id, request_group_id, ebis_form_data, additional_form_data) {
    if (!empty(ebis_form_data.ebis_host_names)) {
        ebis_form_data.ebis_host_names = ebis_form_data.ebis_host_names.map(value => {
            return value.ebis_host_name
        })
    }

    let reorderedFormData = {}
    if (!empty(additional_form_data)) {
        for (let [key, value] of Object.entries(additional_form_data)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }

    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED,
        payload: {
            accessToken: accessToken,
            account_id: account_id,
            request_group_id: request_group_id,
            ebis_form_data: ebis_form_data,
            additional_form_data: reorderedFormData
        }
    }
}

export function loadingConfirmEbisFormInformation() {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_LOADING
    }
}

export function successConfirmEbisFormInformation() {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_SUCCESS
    }
}

export function failureConfirmEbisFormInformation(error) {
    return {
        type: types.SETTING_EBIS_ACCOUNT_INFORMATION_CONFIRMED_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * 本申フォーム② - エビスアカウント情報は不要
 ***********************************/

export function contractUpdateRequest(accessToken, account_id, request_group_id, request_id, quote_id, additionalOptionInformation) {
    let reorderedFormData = {}
    if (!empty(additionalOptionInformation)) {
        for (let [key, value] of Object.entries(additionalOptionInformation)) {
            for (let [key2, value2] of Object.entries(PlanOptionInputBlocks)) {
                value2.fields.forEach((valuek) => {
                    if(valuek.name === key) {
                        let understand = {
                            [key2]: {
                                ...reorderedFormData[key2],
                                [key]: value
                            }
                        }
                        reorderedFormData = {...reorderedFormData, ...understand};
                    }
                })
            }
        }
    }

    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST,
        payload: {
            accessToken: accessToken,
            account_id: account_id,
            request_group_id: request_group_id,
            request_id: request_id,
            quote_id: quote_id,
            reorderedFormData: reorderedFormData
        }
    }
}

export function contractUpdateRequestLoading() {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_LOADING,
    }
}

export function contractUpdateRequestSuccess() {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_SUCCESS,
    }
}

export function contractUpdateRequestFailure(error) {
    return {
        type: types.CONTRACT_CHANGE_QUOTE_APPROVED_REQUEST_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * フォームヘルパー
 ***********************************/

export function settingEbisBackButton(steps = 2) {
    return {
        type: types.SETTING_EBIS_ACCOUNT_BACK_BUTTON,
        payload: {
            stepsBackBy: steps
        }
    }
}

export function scrolledToTermsBottom() {
    return {
        type: types.SETTING_SCROLLED_TO_TERMS_BOTTOM
    }
}

export function applyToggleTerms() {
    return {
        type: types.SETTING_APPLY_TOGGLE_TERMS
    }
}

export function settingApplyConfirm() {
    return {
        type: types.SETTING_APPLY_INFORMATION_CONFIRMED
    }
}

export function closeTermsAndConditionsModal() {
    return {
        type: types.CLOSE_TERMS_AND_CONDITIONS_MODAL
    }
}

export function openTermsAndConditionsModal() {
    return {
        type: types.OPEN_TERMS_AND_CONDITIONS_MODAL
    }
}

/*************
 * 申込PDFダウウンロード＋IframeにBLOBを渡す
 */
export function downloadQuotePDFToIframe(accessToken, account_id, request_group_id, request_id, quote_id) {
    return {
        type: types.REQUEST_LOAD_QUOTE_PDF,
        payload: {
            access_token: accessToken,
            request_group_id: request_group_id,
            account_id: account_id,
            request_id: request_id,
            quote_id: quote_id
        }
    }
}

export function downloadQuotePDFToIframeLoading() {
    return {
        type: types.REQUEST_LOAD_QUOTE_PDF_LOADING
    }
}

export function downloadQuotePDFToIframeSuccess(blobData) {
    return {
        type: types.REQUEST_LOAD_QUOTE_PDF_SUCCESS,
        payload: {
            blobData: blobData
        }
    }
}

export function downloadQuotePDFToIframeFailure(error) {
    return {
        type: types.REQUEST_LOAD_QUOTE_PDF_FAILURE,
        payload: {
            error: error
        }
    }
}

/*************
 * 見積PDFダウンロード
 ****+********/
export function downloadQuotePDF(access_token, account_id, request_group_id, request_id, quote_id, quote_no) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_group_id: request_group_id,
            request_id: request_id,
            quote_id: quote_id,
            quote_no: quote_no,
        }
    }
}


export function downloadQuoteLoading() {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING
    }
}

export function downloadQuoteSuccess(binaryFileData) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS,
        payload: {
            binaryFileData: binaryFileData
        }
    }
}

export function downloadQuoteFailure(e) {
    return {
        type: types.REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * 申込のキャンセル処理
 ***********************************/
export function toggleCancelConfirmation(willOpen = true) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE,
        payload: {
            willOpen: willOpen
        }
    }
}

export function cancelRequestSend(access_token, account_id, request_id) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_id: request_id,
        }
    }
}

export function cancelRequestSendLoading() {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_LOADING,
    }
}

export function cancelRequestSendSuccess() {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS,
    }
}

export function cancelRequestSendFailure(e) {
    return {
        type: types.REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE,
        payload: {
            error: e
        }
    }
}

/*********************************
 * アカウントIDからアカウント名を取得するエベント
 ***********************************/
export function parseServiceContractId(access_token, account_id, service_contract_id) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY,
        payload: {
            access_token: access_token,
            account_id: account_id,
            service_contract_id: service_contract_id
        }
    }
}

export function parseServiceContractIdLoading() {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_LOADING
    }
}

export function parseServiceContractIdSuccess(service_contract_data) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_SUCCESS,
        payload: {
            account_name: service_contract_data.service_contract.service_account_name
        }
    }
}

export function parseServiceContractIdFailure(e) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT_ID_QUERY_FAILURE,
        payload: {
            error: e
        }
    }
}
/*********************************
 * 外部HTML契約内容の取得 (お客様への注意事項)
 * ***********************************/
export function getTermsOfService(htmlPath) {
    return {
        type: types.REQUEST_TERMS_OF_SERVICE_LOAD,
        payload: {
            htmlPath: htmlPath
        }
    }
}

export function getTermsOfServiceLoading() {
    return {
        type: types.REQUEST_TERMS_OF_SERVICE_LOADING
    }
}

export function getTermsOfServiceSuccess(htmlText) {
    return {
        type: types.REQUEST_TERMS_OF_SERVICE_SUCCESS,
        payload: {
            termsContent: htmlText
        }
    }
}

export function getTermsOfServiceFailure(error) {
    return {
        type: types.REQUEST_TERMS_OF_SERVICE_FAILURE,
        payload: {
            error: error
        }
    }
}


/*********************************
 * 外部HTMLを取得
 ***********************************/
export function getServiceContractInformation(htmlPath) {
    return {
        type: types.REQUEST_ADDITIONAL_CONTRACT_HTML,
        payload: {
            htmlPath: htmlPath
        }
    }
}

export function getServiceContractInformationLoading() {
    return {
        type: types.REQUEST_ADDITIONAL_CONTRACT_HTML_LOADING
    }
}

export function getServiceContractInformationSuccess(htmlText) {
    return {
        type: types.REQUEST_ADDITIONAL_CONTRACT_HTML_SUCCESS,
        payload: {
            html_terms: htmlText
        }
    }
}

export function getServiceContractInformationError(error) {
    return {
        type: types.REQUEST_ADDITIONAL_CONTRACT_HTML_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * メール再送信バリデーション
 ***********************************/
export function sendMailValidation(access_token, account_id, request_group_id, request_id, email) {
    return {
        type: types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS,
        payload: {
            access_token: access_token,
            account_id: account_id,
            request_group_id: request_group_id,
            request_id: request_id,
            params: {
                email: email
            }
        }
    }
}

export function sendMailValidationLoading() {
    return {
        type: types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_LOADING
    }
}

export function sendMailValidationSuccess() {
    return {
        type: types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_SUCCESS
    }
}

export function sendMailValidationError(error) {
    return {
        type: types.REQUEST_CHECK_RESEND_EMAIL_ADDRESS_FAILURE,
        payload: {
            error: error
        }
    }
}

/*********************************
 * メール再送信
 ***********************************/

export function openResendModal() {
    return {
        type: types.REQUEST_OPEN_RESEND_MAIL_MODAL
    }
}

export function closeResendModal() {
    return {
        type: types.REQUEST_CLOSE_RESEND_MAIL_MODAL
    }
}

export function sendResendMail(accessToken, userId, requestGroupId, RequestId, formData) {
    return {
        type: types.REQUEST_SEND_RESEND_MAIL,
        payload: {
            formData: formData,
            access_token: accessToken,
            account_id: userId,
            request_group_id: requestGroupId,
            request_id: RequestId,
        }
    }
}

export function sendResendMailLoading() {
    return {
        type: types.REQUEST_SEND_RESEND_MAIL_LOADING
    }
}

export function sendResendMailSuccess() {
    return {
        type: types.REQUEST_SEND_RESEND_MAIL_SUCCESS
    }
}

export function sendResendMailFailure(e) {
    return {
        type: types.REQUEST_SEND_RESEND_MAIL_FAILURE,
        payload: {
            error: e
        }
    }
}

const actions = {
    loadRequestSearch,
    formEditEvent,

    requestsLoad,
    requestsLoading,
    requestsSuccess,
    requestsFailed,

    requestsDetailLoad,
    requestsDetailLoading,
    requestsDetailSuccess,
    requestsDetailFailed,

    requestsDetailQuoteDetailLoad,
    requestsDetailQuoteDetailLoading,
    requestsDetailQuoteDetailSuccess,
    requestsDetailQuoteDetailFailed,

    resetFormInformation,
    settingSubmitEbisFormInformation,
    settingAdditionalSteps,

    settingConfirmEbisFormInformation,
    loadingConfirmEbisFormInformation,
    successConfirmEbisFormInformation,
    failureConfirmEbisFormInformation,

    contractUpdateRequest,
    contractUpdateRequestLoading,
    contractUpdateRequestSuccess,
    contractUpdateRequestFailure,

    settingEbisBackButton,
    scrolledToTermsBottom,
    applyToggleTerms,
    settingApplyConfirm,

    openTermsAndConditionsModal,
    closeTermsAndConditionsModal,

    downloadQuotePDFToIframe,
    downloadQuotePDFToIframeLoading,
    downloadQuotePDFToIframeSuccess,
    downloadQuotePDFToIframeFailure,

    downloadQuotePDF,
    downloadQuoteLoading,
    downloadQuoteSuccess,
    downloadQuoteFailure,

    toggleCancelConfirmation,

    cancelRequestSend,
    cancelRequestSendLoading,
    cancelRequestSendSuccess,
    cancelRequestSendFailure,

    parseServiceContractId,
    parseServiceContractIdLoading,
    parseServiceContractIdSuccess,
    parseServiceContractIdFailure,

    initializeOptionFormInformation,
    declareNoUpdateOptionNeeded,
    settingUpdateAdditionalSteps,
    backToAdditionalSteps,

    sendContractNameToSearchRequest,

    getServiceContractInformation,
    getServiceContractInformationLoading,
    getServiceContractInformationSuccess,
    getServiceContractInformationError,

    getTermsOfService,
    getTermsOfServiceLoading,
    getTermsOfServiceSuccess,
    getTermsOfServiceFailure,

    sendMailValidation,
    sendMailValidationLoading,
    sendMailValidationSuccess,
    sendMailValidationError,

    openResendModal,
    closeResendModal,
    sendResendMail,
    sendResendMailLoading,
    sendResendMailSuccess,
    sendResendMailFailure,
};

export default actions;
