import {call, put} from 'redux-saga/effects';
import {
    failureNotificationAllList,
    failureNotificationList, loadedNotificationAllList,
    loadedNotificationList,
    loadingNotificationAllList,
    loadingNotificationList
} from "./actions";
import {callAllNotifications, callNotifications} from "./api";
import {error_raise} from "../../root/actions";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * お知らせ取得リクエスト
 * @param data
 */
export function* selectNotificationList(data) {
    yield put(loadingNotificationList())
    try {
        const notifications = yield call(callNotifications, data.payload.access_token, data.payload.account_id);
        yield put(loadedNotificationList(notifications.data?.notices));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(failureNotificationList(e));
    }
}

/**
 * 全てのお知らせの取得リクエスト
 * @param data
 */
export function* selectAllNotificationList(data) {
    yield put(loadingNotificationAllList())
    try {
        const notifications = yield call(callAllNotifications,  data.payload.access_token, data.payload.account_id);
        yield put(loadedNotificationAllList(notifications.data?.notices));
    } catch (e) {
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
        yield put(failureNotificationAllList(e));
    }
}

