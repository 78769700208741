import {Box, Grid, Typography,} from "@material-ui/core";
import React from "react";
import { commonStyles } from "../../../../../../theme/user/styles/common";
import {FixedSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {CurrentServicesRow} from "./CurrentServicesRow";

/**
 * 通常ユーザー側の申込詳細画面の見積り一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param current_services
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const CurrentServicesList =
    ({
         className,
         current_services,
         t
     }) => {
        const common = commonStyles();
        let dynamicHeight = current_services.length * 55;
        if(dynamicHeight > 450) {
            dynamicHeight = 450;
        }
        
        return (
            <>
                <Grid container className={common.tableHeader} style={{paddingRight:29}}>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('account_contacts.detail.running_services.content.service_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('account_contacts.detail.running_services.content.account_id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('account_contacts.detail.running_services.content.account_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('account_contacts.detail.running_services.content.connecting_date')}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                            {t('account_contacts.detail.running_services.content.contract_manager')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold", textAlign: "center"}}>
                            {t('account_contacts.detail.running_services.content.invoice_manager')}
                        </Typography>
                    </Grid>
                </Grid>
                <Box style={{height:dynamicHeight}} data-testid={"has-mail"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={current_services.length}
                                itemSize={55}
                                itemData={{
                                    current_services: current_services,
                                    t: t,
                                }}
                            >
                                {CurrentServicesRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </>
        );
    };

export default CurrentServicesList;
