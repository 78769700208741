import types from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (リデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * アプリケーションのどこでもデータを使用することができます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているusersグループに束縛されています。
 * //////////////////////////////////////////
 */
export const managerRequestState = {
    requestListLoading: true,
    requestListError: {},
    requestList: {},

    managerRequestsCurrentPage: 1,
    managerRequestsMaximumPage: 1,
    managerRequestsTotalRows: 0,

    downloadRequestsListLoading: false,
    downloadRequestListError: {},

    managerRequestDetailLoading: true,
    managerRequestDetailError: {},
    managerRequestDetail: {},

    managerRequestQuoteLoading: true,
    managerRequestQuoteError: {},
    managerRequestQuote: {},

    managerTermsAndConditionsIsOpen: false,

    searchFormInformation: {
        account_name: null,
        ebis_client_name: null,
        application_at_from: null,
        application_at_to: null,
        status: null,
        type: null,
        application_complete_at_from: null,
        application_complete_at_to: null
    },

    managerPdfIsLoading: false,
    managerPdfError: {},

    isManagerCancelConfirmationOpen: false,
    managerCancelRequestLoading: false,
    managerCancelRequestError: {},

    isManagerCancelConfirmedModalOpen: false,

    isManagerAcceptTerminateConfirmationOpen: false,
    managerTerminateRequestLoading: false,
    managerTerminateRequestError: {},

    isManagerAcceptTerminatedModalOpen: false,
    
    pdfDataBlobPreview: "",
    pdfDataBlobPreviewLoading: false,
    pdfDataBlobPreviewError: {},
};

/**
 *
 * @param state
 * @param action
 * @returns
 */
export default function managerApplicationReducer(state = managerRequestState, action) {
    switch (action.type) {
        case types.MANAGER_REQUESTS_LOADING:
            return {
                ...state,
                requestListLoading: state.requestListLoading = true,
                requestListError: state.requestListError = true,
                requestList: {}
            }
        case types.MANAGER_REQUESTS_SUCCESS:
            return {
                ...state,
                requestListLoading: state.requestListLoading = false,
                requestListError: state.requestListError = {},
                requestList: state.requestList = action.payload.requests,
                managerRequestsMaximumPage: state.managerRequestsMaximumPage = action.payload.total_page,
                managerRequestsTotalRows: state.managerRequestsTotalRows = action.payload.total_rows
            }
        case types.MANAGER_REQUESTS_FAILED:
            return {
                ...state,
                requestListLoading: state.requestListLoading = false,
                requestListError: state.requestListError = action.payload.error,
                requestList: state.requestList = {}
            }
        case types.MANAGER_REQUESTS_SEARCH:
            return {
                ...state,
                searchFormInformation: state.searchFormInformation = action.payload.form,
                managerRequestsCurrentPage: state.managerRequestsCurrentPage = 1,
                managerRequestsMaximumPage: state.managerRequestsMaximumPage = 0,
                managerRequestsTotalRows: state.managerRequestsTotalRows = 0,
            }
        case types.MANAGER_EDIT_SEARCH_FORM:
            return {
                ...state,
                searchFormInformation: state.searchFormInformation = action.payload.form,
            }
        case types.MANAGER_CHANGE_REQUESTS_PAGE:
            return {
                ...state,
                managerRequestsCurrentPage: state.managerRequestsCurrentPage = action.payload.pageNo
            }
        case types.MANAGER_ACCOUNT_SET_ACCOUNT_NAME_TO_FORM: {
            return {
                ...state,
                searchFormInformation: state.searchFormInformation = {
                    ...state.searchFormInformation,
                    account_name: state.searchFormInformation.account_name = action.payload.account_name
                }
            }
        }
        /****
         * 見積もりダウンロードエヴェント
         ****/
        case types.MANAGER_REQUESTS_LIST_DOWNLOAD_LOADING:
            return {
                ...state,
                downloadRequestsListLoading: state.downloadRequestsListLoading = true,
                downloadRequestListError: state.downloadRequestListError = {},
            }
        case types.MANAGER_REQUESTS_LIST_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloadRequestsListLoading: state.downloadRequestsListLoading = false,
            }
        case types.MANAGER_REQUESTS_LIST_DOWNLOAD_FAILURE:
            return {
                ...state,
                downloadRequestsListLoading: state.downloadRequestsListLoading = true,
                downloadRequestListError: state.downloadRequestListError = action.payload.error,
            }
        /*********
         * お申込詳細
         ********/
        case types.MANAGER_REQUESTS_DETAIL_LOAD:
            return {
                ...state,
                isManagerCancelConfirmedModalOpen: state.isManagerCancelConfirmedModalOpen = false,
                isManagerAcceptTerminatedModalOpen: state.isManagerAcceptTerminatedModalOpen = false
            }
        case types.MANAGER_REQUESTS_DETAIL_LOAD_LOADING:
            return {
                ...state,
                managerRequestDetailLoading: state.managerRequestDetailLoading = true,
                managerRequestDetailError: state.managerRequestDetailError = {},
                managerRequestDetail: state.managerRequestDetail = {}
            }
        case types.MANAGER_REQUESTS_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                managerRequestDetailLoading: state.managerRequestDetailLoading = false,
                managerRequestDetailError: state.managerRequestDetailError = {},
                managerRequestDetail: state.managerRequestDetail = action.payload.request_detail
            }
        case types.MANAGER_REQUESTS_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                managerRequestDetailLoading: state.managerRequestDetailLoading = false,
                managerRequestDetailError: state.managerRequestDetailError = action.payload.error,
                managerRequestDetail: state.managerRequestDetail = {}
            }
        /*********
         * お申込詳細
         *********/
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_LOADING:
            return {
                ...state,
                managerRequestQuoteLoading: state.managerRequestQuoteLoading = true,
                managerRequestQuoteError: state.managerRequestQuoteError = {},
                managerRequestQuote: state.managerRequestQuote = {}
            }
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_SUCCESS:
            return {
                ...state,
                managerRequestQuoteLoading: state.managerRequestQuoteLoading = false,
                managerRequestQuoteError: state.managerRequestQuoteError = {},
                managerRequestQuote: state.managerRequestQuote = action.payload.quote
            }
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_FAILURE:
            return {
                ...state,
                managerRequestQuoteLoading: state.managerRequestQuoteLoading = false,
                managerRequestQuoteError: state.managerRequestQuoteError = action.payload.error,
                managerRequestQuote: state.requestQuote = {}
            }
        case types.MANAGER_CLOSE_TERMS_AND_CONDITIONS_MODAL:
            return {
                ...state,
                managerTermsAndConditionsIsOpen: state.managerTermsAndConditionsIsOpen = false
            }
        case types.MANAGER_OPEN_TERMS_AND_CONDITIONS_MODAL:
            return {
                ...state,
                managerTermsAndConditionsIsOpen: state.managerTermsAndConditionsIsOpen = true
            }
        /****
         * 見積もりダウンロードエヴェント
         ****/
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_LOADING:
            return {
                ...state,
                managerPdfIsLoading: state.managerPdfIsLoading = true,
                managerPdfError: state.managerPdfError = {},
            }
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_SUCCESS:
            return {
                ...state,
                managerPdfIsLoading: state.managerPdfIsLoading = false
            }
        case types.MANAGER_REQUESTS_DETAIL_QUOTE_DETAIL_LOAD_PDF_FAILURE:
            return {
                ...state,
                managerPdfIsLoading: state.managerPdfIsLoading = false,
                managerPdfError: state.managerPdfError = action.payload.error,
            }
        /*********
         * 申込キャンセルエベント
         *********/
        case types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_TOGGLE:
            return {
                ...state,
                isManagerCancelConfirmationOpen: state.isManagerCancelConfirmationOpen = action.payload.willOpen
            }
        case types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_LOADING:
            return {
                ...state,
                managerCancelRequestLoading: state.managerCancelRequestLoading = true,
                managerCancelRequestError: state.managerCancelRequestError = {},
            }
        case types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_SUCCESS:
            return {
                ...state,
                managerCancelRequestLoading: state.managerCancelRequestLoading = false,
                isManagerCancelConfirmationOpen: state.isManagerCancelConfirmationOpen = false,
                isManagerCancelConfirmedModalOpen: state.isManagerCancelConfirmedModalOpen = true
            }
        case types.MANAGER_REQUESTS_DETAIL_CANCEL_REQUEST_FAILURE:
            return {
                ...state,
                managerCancelRequestLoading: state.managerCancelRequestLoading = false,
                managerCancelRequestError: state.managerCancelRequestError = action.payload.error
            }
        /*********
         * 解約受理エベント
         *********/
        case types.MANAGER_APPROVE_TERMINATE_CONTRACT_MODAL_TOGGLE:
            return {
                ...state,
                isManagerAcceptTerminateConfirmationOpen: state.isManagerAcceptTerminateConfirmationOpen = action.payload.willOpen
            }
        case types.MANAGER_APPROVE_TERMINATE_CONTRACT_LOADING:
            return {
                ...state,
                managerTerminateRequestLoading: state.managerTerminateRequestLoading = true,
                managerTerminateRequestError: state.managerTerminateRequestError = {},
            }
        case types.MANAGER_APPROVE_TERMINATE_CONTRACT_SUCCESS:
            return {
                ...state,
                managerTerminateRequestLoading: state.managerTerminateRequestLoading = false,
                isManagerAcceptTerminateConfirmationOpen: state.isManagerAcceptTerminateConfirmationOpen = false,
                isManagerAcceptTerminatedModalOpen: state.isManagerAcceptTerminatedModalOpen = true
            }
        case types.MANAGER_APPROVE_TERMINATE_CONTRACT_FAILURE:
            return {
                ...state,
                managerTerminateRequestLoading: state.managerTerminateRequestLoading = false,
                managerTerminateRequestError: state.managerTerminateRequestError = action.payload.error
            }
        /*********
         * アカウントIDパース処理
         *********/
        case types.MANAGER_REQUEST_PARSE_ACCOUNT_ID_QUERY_SUCCESS:
            return {
                ...state,
                searchFormInformation: {
                    ...state.searchFormInformation,
                    account_name: action.payload.account_name
                }
            }
        case types.MANAGER_REQUEST_LOAD_QUOTE_PDF_LOADING:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = "",
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = true,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = {}
            }
        case types.MANAGER_REQUEST_LOAD_QUOTE_PDF_SUCCESS:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = action.payload.blobData,
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = false,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = {}
            }
        case types.MANAGER_REQUEST_LOAD_QUOTE_PDF_FAILURE:
            return {
                ...state,
                pdfDataBlobPreview: state.pdfDataBlobPreview = "",
                pdfDataBlobPreviewLoading: state.pdfDataBlobPreviewLoading = false,
                pdfDataBlobPreviewError: state.pdfDataBlobPreviewError = action.payload.error
            }
        default:
            return state;
    }
}
