import React from 'react';
import {Box, Button, Grid, makeStyles, Typography} from '@material-ui/core';
import clsx from "clsx";
import {Link} from "react-router-dom";
/**
 * /manager/notices コンテンツスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
    },
    strong: {
        fontWeight: "bold"
    }
}));
/**
 *
 * /noticesのメインコンポーネント
 * @param managerLoadNoticeList
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const CompleteView = (
    {
        t,
        requestGroupId
    }) => {
    const classes = useStyles();
    return (
        <>
            <Box className={clsx(classes.root)}>
                <Typography style={{whiteSpace: "pre-line", fontSize:13}} variant={"body1"}> {t('requests.detail.initial_setting.form.new.complete.description')}</Typography>
            </Box>
            <Box className={clsx(classes.root)} marginY={1} alignContent="middle">
                <Grid container justify="center" alignItems={"center"} spacing={5}>
                    <Link
                        to={'/requests/' + requestGroupId }>
                    <Button variant={"contained"} style={{fontWeight: 900}}
                            color={"secondary"}
                    >
                        {t('requests.detail.quotes.setting.step_3_complete.back_to_detail')}
                    </Button>
                    </Link>
                </Grid>
            </Box>
        </>
    );
}

export default CompleteView;
